
export default {
    props: {
        error: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            zoom: 100,
            isLaptop: false,
        }
    },

    head() {
        return {
            title: this.errorMsg,
        }
    },

    computed: {
        errors() {
            return {
                404: `404 | ${this.$t('http_status_codes.not_found')}`,
                default: this.$t('warnings.unexpected_error'),
            }
        },
        errorMsg() {
            return this.errors[this.error.statusCode] || this.errors.default
        },
    },

    methods: {
        onResize() {
            this.zoom = Math.round((window.outerWidth / window.innerWidth) * 100)
            const actualHeight = window.outerHeight * this.zoom / 100
            this.isLaptop = actualHeight <= 1080
        },
    },
}
