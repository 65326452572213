const middleware = {}

middleware['entities'] = require('../middleware/entities.js')
middleware['entities'] = middleware['entities'].default || middleware['entities']

middleware['scope'] = require('../middleware/scope.js')
middleware['scope'] = middleware['scope'].default || middleware['scope']

middleware['selected-site-ids'] = require('../middleware/selected-site-ids.js')
middleware['selected-site-ids'] = middleware['selected-site-ids'].default || middleware['selected-site-ids']

middleware['settings'] = require('../middleware/settings.js')
middleware['settings'] = middleware['settings'].default || middleware['settings']

middleware['store-modules'] = require('../middleware/store-modules.js')
middleware['store-modules'] = middleware['store-modules'].default || middleware['store-modules']

export default middleware
