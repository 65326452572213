import { jwtDecode } from 'jwt-decode'

export default class AuthService {
    constructor({ $axios, $store }) {
        this.$store = $store
        this.$axios = $axios
    }

    async fetchUsers(params) {
        const url = 'auth-api/users'
        const {
            result: { userResult: users },
            userCount: totalUsers,
        } = await this.$axios.$get(url, {
            params: {
                ...params,
                withCustomerNames: true,
            },
        })
        return {
            users,
            totalUsers,
        }
    }

    async fetchSettings(params) {
        const settings = await this.$axios.$get('/settings-api', { params })
        if (!settings.general.currency) {
            settings.general.currency = 'GBP'
        }
        return settings
    }

    updateSettings(payload = {}) {
        return this.$axios.$put('/settings-api', payload)
    }

    updateUserState(payload = {}) {
        return this.$axios.$put('/settings-api/state', payload)
    }

    impersonate(userId, customerId) {
        return this.$axios.$post('auth-api/adminjwt', {
            customerId,
            userId,
            expiryTime: 3600 * 12,
        })
    }

    impersonator() {
        const tokenData = this.jsonToken()
        if (!tokenData) return null
        return tokenData?.impersonatorId
            ? {
                impersonatorId: tokenData.impersonatorId,
            }
            : (tokenData?.userType === 'impersonator'
                ? {
                    impersonatorId: tokenData.id,
                }
                : false)
    }

    jsonToken() {
        const token = this.$store.$auth.getToken('local')
        if (token) {
            return jwtDecode(token.replace('Bearer ', ''))
        }
    }

    getSitesByUserForAgent(userId) {
        return this.$axios.$get(`auth-v2/users/${userId}/sites`)
    }

    createTempUser(params) {
        return this.$axios.$post('onboarding/account/temp', params)
    }

    updateUser({ id, ...payload } = {}) {
        return this.$axios.$put(`/auth-api/users/${id}`, payload)
    }

    deleteUser(user) {
        return this.$axios.$delete(`auth-api/users/${user.userId}`)
    }

    acceptEula() {
        return this.$axios.$post('auth-api/users/eula-accepted')
    }
}
