import moment from '~/composables/useMoment'

export const state = {
    inbox: null,
    lastUpdate: null,
    // unseenNotifications: 0
}

export const getters = {
    scoped: (state, getters, rootState, rootGetters) => scope => {
        const alerts = rootGetters['entities/alerts/scoped'](scope).map(e => e.id)
        // console.log(alerts, getters.all())
        return getters.query().where(e => {
            return alerts.includes(e.alertId)
        }).get()
    },

    scopedUnresolved: (state, getters, rootState, rootGetters) => scope => {
        const alerts = rootGetters['entities/alerts/scoped'](scope).map(e => e.id)
        // console.log(alerts, getters.all())
        return state.unresolved.filter(e => {
            return alerts.includes(e.alertId)
        })
    },
}

export const mutations = {
    SET_INBOX(state, value) {
        state.inbox = value
    },
    SET_LAST_UPDATE(state, value) {
        state.lastUpdate = value
    },
    // SET_UNSEEN_NOTIFICATIONS(state, value){
    //     state.unseenNotifications = value
    // }
}

export const actions = {
    /**
     * called in layouts/app.vue
     * @param commit
     * @param dispatch
     * @param getters
     * @param state
     */
    init({ commit, dispatch, getters, state }) {
        // setInterval(() => dispatch('fetchInbox'), 3e3)
        const myWorker = new Worker(`//${window.location.host}/workers/scheduler.js`)
        myWorker.postMessage({ timeout: 15 * 60e3 })
        myWorker.onmessage = e => {
            dispatch('fetchInbox')
        }
        dispatch('fetchInbox', true)
    },

    async fetchInbox({ commit, dispatch, getters, state }, firstTime = false) {
        try {
            const onLoginPage = this.$router.currentRoute.path === '/auth/login'
            if (onLoginPage || !this.$auth.loggedIn) {
                return
            }
            const { data } = await this.$axios.get(`${this.$config.axios.alertsBaseURL}/inbox`)
            //
            // if(state.inbox?.counts?.unseenNotifications){
            //     data.counts.unseenNotifications++
            // }
            // console.log(state.inbox?.counts?.unseenNotifications, data.counts.unseenNotifications)

            if (!state.inbox?.counts?.unseenNotifications || data.counts.unseenNotifications !== state.inbox.counts.unseenNotifications) {
                if (!firstTime) {
                    const howManyNotifications = data.counts.unseenNotifications - state.inbox.counts.unseenNotifications
                    if (howManyNotifications > 0) {
                        this.$pushNotification.send(`${howManyNotifications} new alert${howManyNotifications > 1 ? 's' : ''} from ClearVUE`, {
                            body: 'Open alerts',
                            requireInteraction: true,
                            renotify: true,
                            tag: 'cv-alerts',
                        }, () => {
                            this.$router.push('/alerts')
                        })
                        this.$eventBus.$emit('new-notifications')
                        const myAudio = document.createElement('audio')

                        if (myAudio.canPlayType('audio/ogg')) {
                            myAudio.setAttribute('src', '/Doorbell.ogg')
                        } else {
                            console.warn('ogg file not supported')
                        }
                        myAudio.play()
                    }
                }
                // data.messages.forEach(({ alert, counts }) => {
                //     console.log(alert.id, counts.unseenNotifications)
                //     Alert.update({
                //         id: alert.id,
                //         notifications: counts.unseenNotifications
                //     })
                // })
                commit('SET_INBOX', data)
                commit('SET_LAST_UPDATE', moment())
            }
        } catch (e) {
            console.error(e)
            console.warn('cant fetch unseen notifications')
        }
    },
}
