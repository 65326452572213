
import { mapGetters } from 'vuex'

const tagIcons = {
    // 'Bus': '$bus',
    // 'Car': '$car',
    'Container truck': '$truckContainer',
    Caravan: '$caravan',
    'Gas heater': '$fire',
    'Shuttle van': '$vanShuttle',
    'Towing truck': '$truckTow',
    Truck: '$truck',
    Van: '$vanShuttle',
}

export default {
    name: 'AssetTagsAutocomplete',

    props: {
        value: {
            type: String,
            default: null,
        },
    },

    data: () => ({
        isLoading: false,
        search: null,
    }),

    computed: {
        ...mapGetters('entities/carbon-sources', [
            'tags',
        ]),
        model: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },

    methods: {
        tagIcon(tag) {
            return tagIcons[tag] || '$' + this.$_.camelCase(tag)
        },
    },
}
