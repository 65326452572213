import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"theme":"toasted-primary","duration":5000,"position":"top-center","singleton":true})

const globals = [{"name":"general-error","message":"Oops...Something went wrong.","options":{"type":"error"}},{"name":"api-error","message":"Oops... Could not fetch data from API.","options":{"type":"error"}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
