import { Model } from '@vuex-orm/core'
import { compact, orderBy } from 'lodash'
import Unit from '~/orm/models/Unit'

export default class ParametersPreset extends Model {
    static entity = 'parameters-presets'

    static primaryKey = 'id'

    static MAX_PARAMETERS = 10

    static apiConfig = {
        url: 'parameters/presets',
        actions: {
            async save(model) {
                try {
                    let method = 'put'
                    let url = ParametersPreset.apiConfig.url
                    if (model.id?.includes('temp-')) {
                        method = 'post'
                        delete model.$id
                        delete model.id
                    } else {
                        url += `/${model.id}`
                    }
                    return await this[method](url, model)
                } catch (e) {
                    console.error(e)
                }
            },
            _delete(model) {
                return this.delete(`${ParametersPreset.apiConfig.url}/${model.id}`, { delete: model.id })
            },
            async setActive(model) {
                await this.post(`${ParametersPreset.apiConfig.url}/active`, {
                    [model.commodity]: {
                        activeId: model.id,
                    },
                })
                await ParametersPreset.update({
                    where: ParametersPreset.defaultByCommodity(model.commodity).id,
                    data: {
                        active: false,
                    },
                })
                await ParametersPreset.update({
                    where: model.id,
                    data: {
                        active: true,
                    },
                })
            },
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            commodity: this.attr(null),
            fields: this.attr([]),
            readonly: this.boolean(false),
            original: this.attr(null),
            active: this.boolean(false),
        }
    }

    static async fetch() {
        await this.api().get(this.apiConfig.url)
    }

    get sortedParameterKeys() {
        return orderBy(this.fields, 'order', 'asc')
    }

    get keysOnly() {
        return this.sortedParameterKeys.map(({ key }) => key)
    }

    get parameters() {
        return compact(this.sortedParameterKeys.map(({ key }) => Unit.find(key)))
    }

    get instantParameters() {
        return this.parameters.filter(e => e.isInstant).map(({ key }) => key)
    }

    get cumulativeParameters() {
        return this.parameters.filter(e => e.isCumulative).map(({ key }) => key)
    }

    get isDefault() {
        return this.active
    }

    get isDraft() {
        return this.id.includes('temp')
    }

    get hasAdvancedParameters() {
        return !!this.parameters.find(({ parentKey }) => parentKey)
    }

    static byCommodity(commodity) {
        return ParametersPreset.query().where('commodity', commodity).orderBy('readonly', 'desc').get()
    }

    static defaultByCommodity(commodity) {
        return this.byCommodity(commodity).find(({ isDefault }) => isDefault)
    }
}
