import { Model } from '@vuex-orm/core'
import Alert from './Alert'
import moment from '~/composables/useMoment'

export default class Notification extends Model {
    static SEEN = 'seen'
    static NOT_SEEN = 'unseen'
    static RESOLVED = 'resolved'
    static DELETED = 'deleted'

    static entity = 'notifications'

    static primaryKey = 'id'

    static apiConfig = {
        actions: {
            fetchPaginated({ page, itemsPerPage, status, alertId }) {
                return this.get('notifications', {
                    params: {
                        page,
                        pageSize: itemsPerPage,
                        status,
                        alertId,
                    },
                    persistBy: 'create',
                })
            },
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            alertId: this.attr(null),
            message: this.attr(null),
            raisedAt: this.attr(null),
            recipients: this.attr([]),
            sentAt: this.attr(null),
            status: this.attr(null),
            readings: this.attr([]),
            isEditable: this.attr(null),
        }
    }

    get readableStatus() {
        return this.status === Notification.NOT_SEEN ? 'New' : ''
    }

    get seen() {
        return this.status === Notification.SEEN
    }

    get resolved() {
        return this.status === Notification.RESOLVED
    }

    get alert() {
        return Alert.find(this.alertId)
    }

    get readableTimestamp() {
        return moment(this.raisedAt).format('DD-MM-YYYY HH:mm')
    }

    get fromNow() {
        return moment(this.raisedAt).fromNow()
    }

    get title() {
        if (!this.alert) {
            return 'Alert not found'
        }
        if (this.alert.entities[0].virtual) {
            return 'New alert from virtual meter'
        } else {
            return `New alert from ${this.alert.entities[0].parent.parent.name}`
        }
    }

    static async markAsSeen(notification) {
        await Notification.api().put(
            `${Notification.fetchUri()}/${notification.id}`,
            {
                status: Notification.SEEN,
            },
            {
                save: false,
            },
        )
        // also update the unresolved array in this module
        await this.store().dispatch('entities/notifications/fetchInbox')
    }

    static async markAsSeenBulk(notifications) {
        await Notification.api().put(
            `${Notification.fetchUri()}`,
            {
                status: Notification.SEEN,
                ids: notifications.map(e => e.id),
            },
            {
                save: false,
            },
        )
        await this.store().dispatch('entities/notifications/fetchInbox')
    }

    static async markAsResolvedBulk(notifications, resolution) {
        await Notification.api().put(
            `${Notification.fetchUri()}`,
            {
                status: Notification.RESOLVED,
                ids: notifications.map(e => e.id),
                resolution,
            },
            {
                save: false,
            },
        )
        await this.store().dispatch('entities/notifications/fetchInbox')
    }
}
