export default () => ({
    initialUnit: 'kw',
    initialised: null,
    loading: false,
    tabIndex: '0',
    selectedUnit: null,
    selectedPoint: null,
    autoYAxis: false,
    defaultAutoYAxis: false,
    triadsReadings: [],
    loadFactorData: null,
    maxSelectableCircuits: 32,
    treeItems: {
        all: [],
        virtual: [],
    },
    pit: false,
    drillDown: {
        unit: '',
        entity: '',
        entityIds: [],
        entityNames: [],
        previousPeriod: {},
        period: {},
    },
    isInspectorVisible: false,
    ragEnabled: false,
    analysisFeatures: {
        maxDemands: {
            active: false,
            name: 'Max Demands',
            description: '',
            type: 'annotation',
        },
        // peakDemand: {
        //     active:      false,
        //     name:        'Peak Demand',
        //     description: '',
        //     type: 'annotation'
        // },
        loadFactor: {
            active: false,
            name: 'Load factor',
            description: '',
            type: 'flag',
        },
        // pf: {
        //     active: false,
        //     name: 'Power Factor',
        //     description: '',
        //     type: ['dataset', 'filter']
        // },
        averageLines: {
            active: false,
            name: 'Average lines',
            description: '',
            // type: ['filter', 'annotation']
        },
        peakTimes: {
            active: false,
            name: 'Peak times',
            description: '',
            type: 'annotation',
        },
        triads: {
            active: false,
            name: 'Triads',
            description: '',
            type: 'annotation',
        },
    },
    snapshots: [],
    consolidation: false,
    annotationsMode: false,
    comparison: null,
    selectedMeterContracts: [],
    selectedParametersPreset: null,
    selectedParameterKeys: ['P'],
    maxSelectableParameters: 4,
    maxSelectableCircuitsWhenMultiParameter: 1,
    tracesVisibility: {},
})
