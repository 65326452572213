export default () => {
    return {
        loaded: {},
        tabIndex: '/reports',
        virtualTree: [],
        checkedVirtualItems: [],
        reportHeaders: [
            { value: 'reportId', sortable: true, width: '65px' }, // report ID
            { value: 'createdAt', sortable: false, align: 'end' }, // when report created
            { value: 'updatedAt', sortable: false, align: 'start' }, // when report updated
            { value: 'reportName', sortable: true }, // report name
            { value: 'status', sortable: true }, // active,hold,cancelled
            { value: 'resolution', sortable: true }, // 60, 300, 1800, 3600
            { value: 'period', sortable: false }, // fixed dates or shifting
            { value: 'units', sortable: false }, // expandable row to remove units
            { value: 'extensions', sortable: false }, // expandable row to remove types
            { value: 'meters', sortable: false }, // count + expandable row to remove circuits
            { value: 'dateScheduleStart', sortable: false },
            // { value: 'timeScheduleRepeat', sortable: false }, // time 1 and/or 2
            // { value: 'expiry', sortable: false }, // stops after: indefinite, 10x, end date
            { value: 'frequency', sortable: false }, // daily, weekly, monthly,yearly or mon,tue,etc
            { value: 'weekdays', sortable: false }, // count + expandable row to remove email
            { value: 'recipients', sortable: false }, // count + expandable row to remove email
            { value: 'actions', sortable: false, width: '67px', type: 'icon' }, // buttons edit. delete, collapse/expand
            // { value: 'data-table-expand', width: '24px', sortable: false, type: 'expandable' }, // buttons edit. delete, collapse/expand
        ],
        tabs: [
            {
                id: 'all',
                active: true,
                disabled: false,
                icon: 'building',
                url: '/reports/all',
            },
            {
                id: 'virtual',
                active: false,
                disabled: false,
                icon: 'layer-group',
                url: '/reports/virtual',
            },
        ],
    }
}
