
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import CarbonSource from '~/orm/models/CarbonSource'

function createDraft(base = { img: null, attributes: {} }, extra = {}) {
    return {
        ...base,
        ...extra,
    }
}

export default {
    name: 'CarbonAssetForm',

    mixins: [validationMixin],

    props: {
        action: {
            type: Object,
            required: true,
        },
        cardProps: {
            type: Object,
            default: () => ({
                tile: true,
            }),
        },
    },

    data: () => {
        return {
            draft: createDraft(),
            loading: false,
            showAdvanced: true,
        }
    },

    validations() {
        const schema = {
            draft: {
                name: { required },
            },
        }
        if (this.requiredAttributes.length > 0) {
            schema.draft.attributes = {}
            this.requiredAttributes.forEach(att => {
                schema.draft.attributes[att.key] = { required }
            })
        }
        return schema
    },

    computed: {
        attributes() {
            return this.$_.get(this.action, 'payload.parent.attributes', [])
        },
        requiredAttributes() {
            return this.attributes?.filter(a => a.required === true || a.required === undefined) || []
        },
        advancedAttributes() {
            return this.attributes?.filter(a => a.required === false) || []
        },
        nameErrors() {
            const errors = []
            if (!this.$v.draft.name.$dirty) return errors
            !this.$v.draft.name.required && errors.push(this.$t('validation.name_is_required'))
            return errors
        },
        attributeErrors() {
            const errors = {}
            this.requiredAttributes.forEach(att => {
                const attErrors = []
                if (this.$v.draft.attributes[att.key].$dirty && !this.$v.draft.attributes[att.key].required) {
                    attErrors.push(`${att.name} is required.`)
                }
                errors[att.key] = attErrors
            })
            return errors
        },

    },

    asyncComputed: {
        async categories() {
            let categories = []
            try {
                categories = await this.$api.netZero.fetchCategories()
            } catch (err) {
                console.warn('Fetching asset categories error: ', err.message)
            }
            const descendantIds = this.action.payload.descendantIds
            if (descendantIds && descendantIds.length && categories.length) {
                const currentId = this.action.payload.parentId
                return categories.filter(category => [currentId, ...descendantIds].includes(category.id))
            }

            return categories
        },
    },

    watch: {
        'action.payload': {
            deep: true,
            handler() {
                this.updateDraft()
            },
        },
        'draft.parentId'(id) {
            if (this.action.readOnlyCategory) return
            const parent = CarbonSource.find(id)
            if (parent) {
                const action = {
                    title: this.$tUcf('label.add_new_entity_for_parent', {
                        entity: this.$tc('entity.asset.name'),
                        parent: parent.name,
                    }),
                    payload: {
                        parent: parent.$toJson(),
                        parentId: parent.id,
                        attributes: {
                            siteId: parent.siteId,
                        },
                        descendantIds: parent.getDescendantIds(),
                    },
                }
                this.$emit('update:action', action)
            }
        },
    },

    mounted() {
        this.reset()
    },

    methods: {
        reset() {
            this.draft = createDraft(
                undefined,
                this.action.payload,
            )
            this.$v.$reset()
        },
        updateDraft() {
            this.draft = {
                ...this.$_.pick(this.draft, ['img', 'attributes', 'name', 'description']),
                ...this.action.payload,
            }
        },
        onFileInput(event) {
            if (!this.isFileValid(event.target.value)) {
                this.$toast.error(this.$t('validation.img_file_of_type_jpg_png'))
                return
            }
            this.handleFile(event.target.files[0])
        },
        isFileValid(filePath) {
            const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i
            if (!allowedExtensions.exec(filePath)) return false
            return true
        },
        handleFile(file) {
            if (file) {
                const reader = new FileReader()
                reader.onload = e => {
                    this.haveUpdatedImg = true
                    this.draft.img = e.target.result
                }
                reader.readAsDataURL(file)
            } else {
                this.haveUpdatedImg = true
                setTimeout(() => {
                    this.draft.img = null
                }, 125)
            }
        },
        cancel() {
            this.$emit('cancel')
        },
        toggleAdvancedSetting() {
            this.showAdvanced = !this.showAdvanced
        },
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$toast.error(this.$t('validation.please_fill_in_the_required_info_first'))
                return
            }
            let successMsg = null
            try {
                this.loading = true
                const omit = ['parent']
                if (!this.haveUpdatedImg) {
                    omit.push('img')
                } else if (!this.draft.img) {
                    await CarbonSource.api().deleteAssetImage(this.draft)
                }
                const { response } = await CarbonSource.api().addOrUpdateAsset(this.$_.omit(this.draft, omit))

                successMsg = this.$tUcf('api.response.entity_save_success', { entity: this.$tc('entity.asset.name') })
                this.$toast.success(successMsg)
                this.$emit('saved', response.data.id)
                this.reset()
            } catch (error) {
                const errorMessage = this.$_.get(error, 'response.data.message', error.message)
                this.$toast.error(errorMessage)
            } finally {
                this.loading = false
                if (successMsg) this.cancel()
            }
        },
        openFileInput() {
            if (this.draft.img) {
                return
            }
            this.$refs.file.click()
        },
    },
}
