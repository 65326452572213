/**
 * Settings middleware
 *
 * @param {Object} context
 */
export default async function({ app, redirect, req, store }) {
    // If nuxt generate, pass this middleware
    if (process.server && !req) return

    // no settings should be fetched before auth
    if (!app.$_.get(app, '$auth.user.customer_id')) return

    // if settings were fetched already
    if (store.state.settings) return

    // fetch settings & save in store
    const settings = await store.dispatch('fetchSettings', true)
    if (!settings) {
        redirect('/auth/login')
    }
}
