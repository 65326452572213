
// import { mapGetters, mapState } from 'vuex'
import NewsCard from '~/components/news/card'

export default {
    name: 'NetZeroNews',

    components: {
        NewsCard,
    },

    data: () => ({
        news: [],
        limit: 2,
    }),

    computed: {
        latestNews() {
            return this.news.slice(0, this.limit)
        },
    },

    mounted() {
        this.fetchNews()
    },

    methods: {
        async fetchNews() {
            const params = {
                tags: 23, // Net Zero tag id = 23
            }
            try {
                this.news = await this.$api.fetchNews(params)
            } catch (e) {
                this.news = []
                console.warn('Error fetching net zero news. ', e.message)
            }
        },

        loadMore() {
            this.limit = Math.min(this.limit + 2, this.news.length)
        },
    },
}
