export default () => {
    return {
        // toggles
        showFriendlyName: false,
        showMpan: false,

        // Tabs
        tabs: [
            {
                id: 'all',
                active: true,
                disabled: false,
                icon: '$building',
                sidebar: false,
            },
            {
                id: 'virtual',
                active: false,
                disabled: false,
                icon: '$layerGroup',
                sidebar: false,
            },
        ],

        // active entities
        activeEntities: {
            all: [],
            virtual: [],
        },

        // selected entities
        selectedEntities: {
            all: [],
            virtual: [],
        },
    }
}
