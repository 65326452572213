
import { required, decimal } from 'vuelidate/lib/validators'
import FormMixin from './form.mixin'
import Geocoder from '~/components/common/geocoder'

export default {
    name: 'EditCustomerShippedAmount',

    components: { Geocoder },

    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                output: {
                    amount: {
                        required,
                        decimal,
                        minValue: val => Number(val) >= 0.00001,
                    },
                },
                supplier: {
                    business: {
                        required,
                    },
                },
            },
        }
        return schema
    },

    data() {
        return {
            title: 'Add customer and shipped amount',
        }
    },
}
