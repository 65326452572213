import ParametersPreset from '~/orm/models/ParametersPreset'

export const getters = {
    default: () => commodity => {
        const defaultPreset = ParametersPreset.defaultByCommodity(commodity)
        if (!defaultPreset) {
            console.warn('active parameters preset invalid, fallback to default')
            return `default-${commodity}`
        }
        return defaultPreset.id
    },
}
