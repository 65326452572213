export const TRACKING_EVENTS = {
    ALERTS_CREATE: 'alerts_create',
    ALERTS_EDIT: 'alerts_edit',
    ALERTS_STATUS_CHANGE: 'alerts_status_change',
    ANNOTATIONS_CREATE: 'annotations_create',
    ASSETS_ADD_DATA: 'assets_add_data',
    ASSETS_CREATE: 'assets_create',
    COMMODITY_SELECTION: 'commodity_selection',
    COMMODITY_TOGGLE: 'commodity_toggle',
    COMPARISON: 'comparison',
    DOWNLOAD: 'download',
    GROUPING_TOGGLE: 'grouping_toggle',
    HEATMAP_CLICK: 'heatmap_click',
    INSPECTOR: 'inspector',
    LEAGUE_BREAKDOWN_TOGGLE: 'league_breakdown_toggle',
    LOCK_MODE: 'lock_mode',
    LOGIN: 'login',
    PAGE_LOADED: 'page_loaded',
    PARAMETERS_SELECTION: 'parameters_selection',
    PEAK: 'peak',
    PING: 'ping',
    RAG: 'rag',
    RESOLUTION_CHANGE: 'resolution_change',
    ROUTE_TO: 'route_to',
    SITES_CREATE: 'sites_create',
    SITES_SELECTION: 'sites_selection',
    TOGGLE_ABSOLUTE_COMPARISON: 'toggle_absolute_comparison',
    TOOLTIP: 'tooltip',
    TRIADS: 'triads',
    VM_CREATE: 'vm_create',
}

// The pages that we log load time duration
export const TRACKING_PAGES = {
    DASHBOARD: 'dashboard',
    ELECTRICITY: 'electricity',
    GAS: 'gas',
    WATER: 'water',
    ALERTS: 'alerts',
    CARBON: 'carbon',
}

export const TRACKING_ROUTE_PAIRS = new Map()
TRACKING_ROUTE_PAIRS.set('get:reports/asset', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('get:reports/category', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('get:reports/comparison', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('get:reports/consumption', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('get:reports/cost', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('get:reports/eventgroup', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('get:reports/processflow', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('get:reports/secr', TRACKING_EVENTS.DOWNLOAD)
TRACKING_ROUTE_PAIRS.set('post:/notes', TRACKING_EVENTS.ANNOTATIONS_CREATE)
TRACKING_ROUTE_PAIRS.set('post:assets/asset', TRACKING_EVENTS.ASSETS_CREATE)
TRACKING_ROUTE_PAIRS.set('post:auth-api/login', TRACKING_EVENTS.LOGIN)
TRACKING_ROUTE_PAIRS.set('post:onboarding/sites/with-asset', TRACKING_EVENTS.SITES_CREATE)
TRACKING_ROUTE_PAIRS.set('post:virtuals', TRACKING_EVENTS.VM_CREATE)

export const TRACKING_STORE_PAIRS = new Map()
TRACKING_STORE_PAIRS.set('dashboard/SET_UNIT_ID', TRACKING_EVENTS.PARAMETERS_SELECTION)
TRACKING_STORE_PAIRS.set('electricity/SET_COMPARISON', TRACKING_EVENTS.COMPARISON)
TRACKING_STORE_PAIRS.set('electricity/SET_DRILL_DOWN', TRACKING_EVENTS.INSPECTOR)
TRACKING_STORE_PAIRS.set('electricity/SET_SELECTED_PARAMETER_KEYS', TRACKING_EVENTS.PARAMETERS_SELECTION)
TRACKING_STORE_PAIRS.set('electricity/SET_SELECTED_UNIT', TRACKING_EVENTS.TRIADS)
TRACKING_STORE_PAIRS.set('electricity/TOGGLE_RAG', TRACKING_EVENTS.RAG)
TRACKING_STORE_PAIRS.set('gas/SET_COMPARISON', TRACKING_EVENTS.COMPARISON)
TRACKING_STORE_PAIRS.set('period/SET_ABSOLUTE_COMPARISON', TRACKING_EVENTS.TOGGLE_ABSOLUTE_COMPARISON)
