import CacheService from '~/services/Cache'

/*
** Executed by ~/.nuxt/index.js with context given
** This method can be asynchronous
*/
export default ({ app }, inject) => {
    // Inject `cache` key
    // -> app.$cache
    // -> this.$cache in vue components
    // -> this.$cache in store actions/mutations
    inject('cache', new CacheService(app))
}
