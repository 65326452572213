
import { genericUnits } from '../const'
import FormMixin from './form.mixin'

export default {
    name: 'SetAmount',

    mixins: [FormMixin],

    data() {
        return {
            title: 'Edit input amount',
        }
    },

    computed: {
        genericUnits() {
            return genericUnits
        },
    },
}
