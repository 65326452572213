import moment, { setTimezone } from '~/composables/useMoment'

/**
 * Inject the moment js library
 * @param ctx
 * @param inject
 */
export default (ctx, inject) => {
    const CUSTOMER_TIMEZONE = ctx.store.getters['period/timezone']
    if (CUSTOMER_TIMEZONE && CUSTOMER_TIMEZONE !== moment.TIMEZONE) {
        setTimezone(CUSTOMER_TIMEZONE)
    }

    inject('moment', moment) // -> app.$moment
}
