export default () => {
    return {
        chartOnRightSide: true,
        instanceId: null,
        loadingCounters: {
            electricity: {
                table: 0,
                terminal: 0,
                chart: 0,
            },
        },
        treeviewItems: [],
        loader: {
            loadItems: {},
            indicator: 0,
            visible: false,
        },
        spinnerLoader: false,
        downloadLoader: false,
        dialogWaiting: false,
    }
}
