export default class HashState {
    params = {}

    constructor(ctx) {
        this.$router = ctx.router
        this.$auth = ctx.$auth
        // Init
        this.init()
    }

    get encodedParams() {
        return this.encodeParams(this.params)
    }

    get period() {
        return this.params?.period
    }

    get gasTreeview() {
        return this.params?.gasTreeview
    }

    init() {
        this.$router.afterEach((to, from) => {
            if (history && from.hash) { // && from.path.includes('/auth/login')
                history.replaceState(
                    {},
                    null,
                    this.$router.currentRoute.path + from.hash,
                )
                this.getParams(from)
            } else {
                this.getParams(to)
            }
        })
        this.getParams(this.$router.currentRoute)
    }

    getParams(route) {
        if (route && route.hash) {
            this.params = { ...this.decodeParams(route.hash) }
            if (!this.params.cid || !this.$auth.user || this.params.cid !== this.$auth.user.customer_id) {
                this.params = {}
            }
        } else {
            this.params = {}
        }
    }

    setParams(payload) {
        this.params = Object.assign({ cid: this.$auth.user.customer_id }, this.params, payload)
        this.setQuery()
    }

    setQuery() {
        try {
            history && history.replaceState(
                {},
                null,
                this.$router.currentRoute.path + '#' + this.encodedParams,
            )
        } catch (e) {
            console.warn('Error setting the query: ', e)
        }
    }

    encodeParams(params = {}) {
        return btoa(JSON.stringify(params))
    }

    decodeParams(hash = '') {
        let params
        try {
            params = JSON.parse(atob(hash.substring(1)))
        } catch (err) {
            console.warn('Hash is malformed: ', err)
            params = {}
        }
        return params
    }

    reset() {
        this.params = {}
        history && history.replaceState(
            {},
            null,
            this.$router.currentRoute.path,
        )
    }
}
