class NotificationService {
    async send(title, options, callback) {
        if (window.Notification && Notification.permission === 'granted') {
            (new Notification(title, options)).onclick = callback
        } else if (window.Notification && Notification.permission !== 'denied') {
            const status = await Notification.requestPermission()
            if (status === 'granted') {
                (new Notification(title, options)).onclick = callback
            }
        }
    }
}
export default (ctx, inject) => {
    inject('pushNotification', new NotificationService())
}
