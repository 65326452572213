
import { getGoogleMapsAPI } from 'gmap-vue'
import { debounce } from 'lodash'
import * as gmapCfg from '~/config/gmap'
import SearchPlace from '~/components/search/place'

export default {
    name: 'GeoCoder',

    components: {
        SearchPlace,
    },

    props: {
        value: {
            type: Object,
            default: () => ({
                business: null,
                hasShippingAddress: false,
                shippingAddress: null,
            }),
        },
        error: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        loading: false,
        search: null,
        marker: {
            weight: 100,
            title: 'Marker title',
            position: {
                label: undefined,
                lat: 0,
                lng: 0,
            },
        },
    }),

    computed: {
        location() {
            const { location } = this.value.shippingAddress || this.value.business || {}
            return location || { lat: 0, lng: 0 }
        },
        mapData() {
            return {
                center: this.location,
                zoom: 13,
                mapTypeId: 'roadmap',
                mapMode: null,
                toolbarPosition: 'TOP_CENTER',
                mapDraggable: true,
                mapCursor: null,
            }
        },
        mapOptions() {
            return {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                draggable: this.mapData.mapDraggable,
                draggableCursor: this.mapData.mapCursor,
                styles: gmapCfg.styles,
                minZoom: 2,
                maxZoom: 15,
            }
        },
        model: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        google: getGoogleMapsAPI,
    },

    watch: {
        value: {
            deep: true,
            immediate: true,
            handler() {
                this.updateMarkerPosition()
            },
        },
    },

    async mounted() {
        await this.$gmapApiPromiseLazy()
        this.geocoder = new this.google.maps.Geocoder()
        this.updateMarkerPosition = debounce(this.updateMarkerPosition, 60)
    },

    methods: {
        updateMarkerPosition() {
            if (this._skipUpdate) {
                this._skipUpdate = false
                return
            }
            const { business, shippingAddress } = this.value
            const { name } = shippingAddress || business || {}

            this.marker.position = {
                label: name,
                ...this.location,
            }
        },

        onMarkerUpdatePosition({ lat, lng }) {
            let location = this.location
            if (location.lat === lat && location.lng === lng) return
            this._skipUpdate = true
            location = { lat, lng }
            if (this.model.shippingAddress) {
                this.model = {
                    ...this.model,
                    shippingAddress: {
                        ...this.model.shippingAddress,
                        location,
                    },
                }
            } else if (this.model.business) {
                this.model = {
                    ...this.model,
                    business: {
                        ...this.model.business,
                        location,
                    },
                }
            }
        },

        updateBusiness(business) {
            this.model = {
                ...this.model,
                business,
            }
        },

        updateHasShippingAddress(hasShippingAddress) {
            const model = {
                ...this.model,
                hasShippingAddress,
            }
            if (!hasShippingAddress) {
                model.shippingAddress = null
            }
            this.model = model
        },

        updateShippingAddress(shippingAddress) {
            this.model = {
                ...this.model,
                shippingAddress,
            }
        },
    },
}
