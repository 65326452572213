import getInitialState from './state'

export default {
    SET_INITIALISED(state, value) {
        state.initialised = value
    },

    RESET(state) {
        Object.assign(state, getInitialState())
    },

    TOGGLE_LOADING(state) {
        state.loading = !state.loading
    },

    SET_LOADING(state, value) {
        state.loading = value
    },

    SET_TREEVIEWS(state, treeviews) {
        state.treeviews = treeviews
    },

    SET_COMPARISON(state, value) {
        state.comparison = value
    },

    TOGGLE_ANALYSIS_FEATURE(state, { key, active }) {
        state.analysisFeatures[key].active = active
    },

    SET_SELECTED_UNIT(state, value) {
        state.selectedUnit = value
    },

    SET_SELECTED_POINT(state, value) {
        state.selectedPoint = value
    },

    SET_AUTO_Y_AXIS(state, value) {
        state.autoYAxis = value
    },

    SET_DEFAULT_AUTO_Y_AXIS(state, value) {
        state.defaultAutoYAxis = value
    },

    SET_TRIADS_READINGS(state, v) {
        state.triadsReadings = v
    },

    SET_LOAD_FACTOR_DATA(state, v) {
        state.loadFactorData = v
    },

    SET_CONSOLIDATION(state, value) {
        state.consolidation = value
    },

    SET_RAG(state, value) {
        state.ragEnabled = value
    },

    SET_ANNOTATIONS_MODE(state, value) {
        state.annotationsMode = value
    },

    INCREMENT_LOADING_LEVEL(state) {
        state.loadingLevel++
    },

    SET_SELECTED_PARAMETERS_PRESET(state, value) {
        state.selectedParametersPreset = value
    },

    UPDATE_NODE(state, { item, prop, value }) {
        item[prop] = value
    },

    SET_IS_INSPECTOR_VISIBLE(state, value) {
        state.isInspectorVisible = value
    },
}
