
export default {
    name: 'DialogHeader',

    props: {
        title: { // Translate before passing as prop
            type: String,
            required: true,
        },
        infoText: {
            type: String,
            default: null,
        },
        showFullScreenButton: {
            type: Boolean,
            default: false,
        },
        hideCloseButton: {
            type: Boolean,
            default: false,
        },
    },

}
