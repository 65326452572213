import { Model } from '@vuex-orm/core'
import Meter from '@/orm/models/Meter'

export default class Job extends Model {
    static entity = 'jobs'

    static apiConfig = {
        actions: {
            save(model) {
                if (model.id) {
                    return this.put('jobs', { q: btoa(JSON.stringify(model)) })
                } else {
                    return this.post('jobs', { q: btoa(JSON.stringify(model)) })
                }
            },
            destroy(model) {
                return this.delete('jobs', { params: { id: model.id } })
            },
        },
    }

    static fields() {
        return {
            id: this.number(null),
            name: this.string(''),
            classification: this.string(''),
            meterId: this.number(null),
            demand: this.number(20),
            adjustmentFactor: this.number(75),
            estimatedPowerFactor: this.number(90),
            weekdayHours: this.attr([
                '07:00',
                '18:00',
            ]),
            weekendHours: this.attr([
                '09:00',
                '13:00',
            ]),
            useAdvancedOptions: this.boolean(false),
            advancedOptions: this.attr({
                dayHours: [
                    [
                        '07:00',
                        '18:00',
                    ],
                    [
                        '07:00',
                        '18:00',
                    ],
                    [
                        '07:00',
                        '18:00',
                    ],
                    [
                        '07:00',
                        '18:00',
                    ],
                    [
                        '07:00',
                        '18:00',
                    ],
                    [
                        '07:00',
                        '18:00',
                    ],
                    [
                        '07:00',
                        '18:00',
                    ],
                ],
                months: Array(12).fill(true),
                days: Array(7).fill(true),
            }),
            minutesInterval: this.number(15),
        }
    }

    static async save(model) {
        const result = await Job.api().save(model)
        return result.entities.jobs[0]
    }

    static async destroy(model) {
        return await Job.api().destroy(model)
    }

    get meter() {
        return Meter.find(this.meterId)
    }
}
