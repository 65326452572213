import { Model } from '@vuex-orm/core'
import Circuit from './Circuit'

export default class UserAnnotation extends Model {
    static entity = 'user_annotations'

    static apiConfig = {}

    static fields() {
        return {
            id: this.attr(null),
            dt: this.attr(null),
            yValue: this.attr(null),
            text: this.attr(null),
            circuitId: this.attr(null),
            entityId: this.attr(null),
            entityType: this.attr(null),
            isPublic: this.attr(null),
            isEditable: this.attr(null),
            parameter: this.attr(null),
        }
    }

    static fetchUri() {
        const baseURL = this.apiConfig.baseURL || ''
        return `${baseURL}/notes`
    }

    get circuitName() {
        return Circuit.find(this.circuitId).name
    }
}
