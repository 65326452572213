
import ItemCard from './ItemCard'

export default {
    name: 'ProcessMachine',

    components: {
        ItemCard,
    },

    props: {
        active: Boolean,
        machine: {
            type: Object,
            required: true,
        },
    },
}
