
import { genericUnits } from '../const'
import FormMixin from './form.mixin'

export default {
    name: 'RenameNode',

    mixins: [FormMixin],

    data() {
        return {
            title: 'Process',
        }
    },

    computed: {
        genericUnits() {
            return genericUnits
        },
    },
}
