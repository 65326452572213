export default {
    /**
     * Initialise the store
     */
    async init({ commit, dispatch }) {
        // init inventory
        await dispatch('fetchMaterials')
        await dispatch('fetchFlows')
        await dispatch('fetchFuels')
        await dispatch('fetchProcessCategories')
        await dispatch('fetchThirdParties')
        await dispatch('fetchDisposals')
        await dispatch('fetchMachines')
        // init
        commit('SET_INITIALISED', true)
    },

    async fetchMaterials({ commit }) {
        const { data } = await this.$api.flows.fetchMaterials()
        commit('SET_MATERIALS', data)
    },

    async fetchFuels({ commit }) {
        const { data } = await this.$api.flows.fetchFuels()
        commit('SET_FUELS', data)
    },

    async storeInventory({ commit }, payload) {
        const process = await this.$api.events.storeInventory(payload)
        commit('ADD_INVENTORY', { flows: [], process })
    },

    async fetchFlows({ commit }) {
        const flows = await this.$api.flows.fetchFlows()
        commit('SET_FLOWS', flows)
    },

    async fetchProcessCategories({ commit }) {
        const { data } = await this.$api.flows.fetchProcessCategories()
        commit('SET_PROCESS_CATEGORIES', data)
    },

    async fetchThirdParties({ commit }) {
        const { data } = await this.$api.flows.fetch3rdParties()
        commit('SET_THIRD_PARTIES', data)
    },

    async fetchDisposals({ commit }) {
        const { data } = await this.$api.flows.fetchDisposals()
        commit('SET_DISPOSALS', data)
    },

    async fetchMachines({ commit }) {
        const { data } = await this.$api.flows.fetchMachines()
        commit('SET_MACHINES', data.map(e => ({ ...e, efficiency: Math.min(e.efficiency || e.name.length, 90) })))
    },
}
