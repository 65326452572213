
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import FormMixin from './form.mixin'
import CategoryCard from './../cards/category-card'

export default {
    name: 'SelectCategory',
    components: { CategoryCard },
    mixins: [FormMixin],
    validations() {
        return {
            draft: {
                id: required,
            },
        }
    },
    data() {
        return {

        }
    },

    computed: {
        ...mapGetters('flows', {
            categories: 'processCategories',
            machinesByCategoryId: 'machinesByCategoryId',
        }),
        ...mapState('flows', ['machines']),
        filteredCategories() {
            return this.categories.filter(e => e.name !== 'metered-energy')
        },
    },

    methods: {
        onInput(e) {
            this.draft = this.draft?.id === e.id ? {} : e
        },
    },
}
