import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_54604f30 from 'nuxt_plugin_plugin_54604f30' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_55867a84 from 'nuxt_plugin_plugin_55867a84' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_workbox_6cf6843c from 'nuxt_plugin_workbox_6cf6843c' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_313b02f5 from 'nuxt_plugin_metaplugin_313b02f5' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_35e2a869 from 'nuxt_plugin_iconplugin_35e2a869' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_toast_e867e216 from 'nuxt_plugin_toast_e867e216' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_api_785206da from 'nuxt_plugin_api_785206da' // Source: ../plugins/api.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_confirm_86ddc9a6 from 'nuxt_plugin_confirm_86ddc9a6' // Source: ../plugins/confirm.js (mode: 'all')
import nuxt_plugin_directives_521c0486 from 'nuxt_plugin_directives_521c0486' // Source: ../plugins/directives.js (mode: 'all')
import nuxt_plugin_eventbus_3db4fd4a from 'nuxt_plugin_eventbus_3db4fd4a' // Source: ../plugins/eventbus.js (mode: 'all')
import nuxt_plugin_filters_2dd71012 from 'nuxt_plugin_filters_2dd71012' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_globalloadingclient_64a1e1e6 from 'nuxt_plugin_globalloadingclient_64a1e1e6' // Source: ../plugins/global-loading.client.js (mode: 'client')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_lodash_5d4c7b8c from 'nuxt_plugin_lodash_5d4c7b8c' // Source: ../plugins/lodash.js (mode: 'all')
import nuxt_plugin_plotlyclient_63367602 from 'nuxt_plugin_plotlyclient_63367602' // Source: ../plugins/plotly.client.js (mode: 'client')
import nuxt_plugin_queue_a4a3fe88 from 'nuxt_plugin_queue_a4a3fe88' // Source: ../plugins/queue.js (mode: 'all')
import nuxt_plugin_version_62640e15 from 'nuxt_plugin_version_62640e15' // Source: ../plugins/version.js (mode: 'all')
import nuxt_plugin_vueasynccomputed_59d53bbc from 'nuxt_plugin_vueasynccomputed_59d53bbc' // Source: ../plugins/vue-async-computed.js (mode: 'all')
import nuxt_plugin_vuetify_d6afc2c2 from 'nuxt_plugin_vuetify_d6afc2c2' // Source: ../plugins/vuetify.js (mode: 'all')
import nuxt_plugin_vuexorm_48ff4012 from 'nuxt_plugin_vuexorm_48ff4012' // Source: ../plugins/vuex-orm.js (mode: 'all')
import nuxt_plugin_vuexclient_47ed6ff4 from 'nuxt_plugin_vuexclient_47ed6ff4' // Source: ../plugins/vuex.client.js (mode: 'client')
import nuxt_plugin_pushnotificationclient_81572034 from 'nuxt_plugin_pushnotificationclient_81572034' // Source: ../plugins/push-notification.client.js (mode: 'client')
import nuxt_plugin_youtube_eb95502c from 'nuxt_plugin_youtube_eb95502c' // Source: ../plugins/youtube.js (mode: 'all')
import nuxt_plugin_gmapclient_66061829 from 'nuxt_plugin_gmapclient_66061829' // Source: ../plugins/gmap.client.js (mode: 'client')
import nuxt_plugin_ganttclient_2f45aa08 from 'nuxt_plugin_ganttclient_2f45aa08' // Source: ../plugins/gantt.client.js (mode: 'client')
import nuxt_plugin_asyncdialogclient_5a01cf4d from 'nuxt_plugin_asyncdialogclient_5a01cf4d' // Source: ../plugins/async-dialog.client.js (mode: 'client')
import nuxt_plugin_vuegaugeclient_c912fbcc from 'nuxt_plugin_vuegaugeclient_c912fbcc' // Source: ../plugins/vue-gauge.client.js (mode: 'client')
import nuxt_plugin_fontawesome_b8db358e from 'nuxt_plugin_fontawesome_b8db358e' // Source: ../plugins/fontawesome.js (mode: 'all')
import nuxt_plugin_periodservice_1e447df8 from 'nuxt_plugin_periodservice_1e447df8' // Source: ../plugins/period-service.js (mode: 'all')
import nuxt_plugin_plugin_0b7d8302 from 'nuxt_plugin_plugin_0b7d8302' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_moment_57893d7e from 'nuxt_plugin_moment_57893d7e' // Source: ../plugins/moment.js (mode: 'all')
import nuxt_plugin_initclient_ad36b038 from 'nuxt_plugin_initclient_ad36b038' // Source: ../plugins/init.client.js (mode: 'client')
import nuxt_plugin_cacheclient_2b9400aa from 'nuxt_plugin_cacheclient_2b9400aa' // Source: ../plugins/cache.client.js (mode: 'client')
import nuxt_plugin_hashstateclient_6347a234 from 'nuxt_plugin_hashstateclient_6347a234' // Source: ../plugins/hash-state.client.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s - ClearVue.ZERO","title":"ClearVue.ZERO","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"robots","content":"noindex, nofollow"},{"hid":"description","name":"description","content":"ClearVUE ZERO Web Application"},{"hid":"og:title","name":"og:title","content":"ClearVue.ZERO"},{"hid":"og:site_name","name":"og:site_name","content":"ClearVue.ZERO"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.png"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Roboto:100,300,400,500,700,900&display=swap"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_54604f30 === 'function') {
    await nuxt_plugin_plugin_54604f30(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_55867a84 === 'function') {
    await nuxt_plugin_plugin_55867a84(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_6cf6843c === 'function') {
    await nuxt_plugin_workbox_6cf6843c(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_313b02f5 === 'function') {
    await nuxt_plugin_metaplugin_313b02f5(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_35e2a869 === 'function') {
    await nuxt_plugin_iconplugin_35e2a869(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_e867e216 === 'function') {
    await nuxt_plugin_toast_e867e216(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (typeof nuxt_plugin_api_785206da === 'function') {
    await nuxt_plugin_api_785206da(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_confirm_86ddc9a6 === 'function') {
    await nuxt_plugin_confirm_86ddc9a6(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_521c0486 === 'function') {
    await nuxt_plugin_directives_521c0486(app.context, inject)
  }

  if (typeof nuxt_plugin_eventbus_3db4fd4a === 'function') {
    await nuxt_plugin_eventbus_3db4fd4a(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2dd71012 === 'function') {
    await nuxt_plugin_filters_2dd71012(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalloadingclient_64a1e1e6 === 'function') {
    await nuxt_plugin_globalloadingclient_64a1e1e6(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (typeof nuxt_plugin_lodash_5d4c7b8c === 'function') {
    await nuxt_plugin_lodash_5d4c7b8c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_plotlyclient_63367602 === 'function') {
    await nuxt_plugin_plotlyclient_63367602(app.context, inject)
  }

  if (typeof nuxt_plugin_queue_a4a3fe88 === 'function') {
    await nuxt_plugin_queue_a4a3fe88(app.context, inject)
  }

  if (typeof nuxt_plugin_version_62640e15 === 'function') {
    await nuxt_plugin_version_62640e15(app.context, inject)
  }

  if (typeof nuxt_plugin_vueasynccomputed_59d53bbc === 'function') {
    await nuxt_plugin_vueasynccomputed_59d53bbc(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetify_d6afc2c2 === 'function') {
    await nuxt_plugin_vuetify_d6afc2c2(app.context, inject)
  }

  if (typeof nuxt_plugin_vuexorm_48ff4012 === 'function') {
    await nuxt_plugin_vuexorm_48ff4012(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexclient_47ed6ff4 === 'function') {
    await nuxt_plugin_vuexclient_47ed6ff4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pushnotificationclient_81572034 === 'function') {
    await nuxt_plugin_pushnotificationclient_81572034(app.context, inject)
  }

  if (typeof nuxt_plugin_youtube_eb95502c === 'function') {
    await nuxt_plugin_youtube_eb95502c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gmapclient_66061829 === 'function') {
    await nuxt_plugin_gmapclient_66061829(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ganttclient_2f45aa08 === 'function') {
    await nuxt_plugin_ganttclient_2f45aa08(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_asyncdialogclient_5a01cf4d === 'function') {
    await nuxt_plugin_asyncdialogclient_5a01cf4d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuegaugeclient_c912fbcc === 'function') {
    await nuxt_plugin_vuegaugeclient_c912fbcc(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_b8db358e === 'function') {
    await nuxt_plugin_fontawesome_b8db358e(app.context, inject)
  }

  if (typeof nuxt_plugin_periodservice_1e447df8 === 'function') {
    await nuxt_plugin_periodservice_1e447df8(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_0b7d8302 === 'function') {
    await nuxt_plugin_plugin_0b7d8302(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_57893d7e === 'function') {
    await nuxt_plugin_moment_57893d7e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_initclient_ad36b038 === 'function') {
    await nuxt_plugin_initclient_ad36b038(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cacheclient_2b9400aa === 'function') {
    await nuxt_plugin_cacheclient_2b9400aa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hashstateclient_6347a234 === 'function') {
    await nuxt_plugin_hashstateclient_6347a234(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
