export const currencies = [
    {
        id: 'EUR',
        symbol: '€',
        icon: '$euroSign',
    },
    {
        id: 'GBP',
        symbol: '£',
        icon: '$sterlingSign',
    },
    {
        id: 'USD',
        symbol: '$',
        icon: '$dollarSign',
    },
    {
        id: 'CHF',
        symbol: 'Fr',
        icon: '$f',
    },
    {
        id: 'PLN',
        symbol: 'zł',
        icon: '$zł',
    },
]

export function currencyById(id = 'GBP') {
    return currencies.find(c => c.id === id)
}
