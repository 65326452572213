
import { genericUnits } from '../const'
import wasteTypes from '~/mocks/waste-types'

export default {
    name: 'DraftWaste',

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        error: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        draft: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        genericUnits() {
            return genericUnits
        },
        disposalTypes() {
            return [
                { value: 'reuse', text: 'Re-use' },
                { value: 'open_loop', text: 'Open-loop' },
                { value: 'closed_loop', text: 'Closed-loop' },
                { value: 'combustion', text: 'Combustion' },
                { value: 'composting', text: 'Composting' },
                { value: 'landfill', text: 'Landfill' },
                { value: 'anaerobic_digestion', text: 'Anaerobic digestion' },
            ].filter(({ value }) => this.$_.get(this.draft, `wasteType.${value}`, null) !== null)
        },
        wasteTypes() {
            const groupedTypes = this.$_.groupBy(wasteTypes, 'activity')
            const types = []
            this.$_.each(groupedTypes, (value, header) => {
                types.push({ header }, ...value)
            })
            return types
        },
    },

    watch: {
        'value.wasteType'() {
            this.draft.disposalType = undefined
        },
    },
}
