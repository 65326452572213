/* Models */
import { Model } from '@vuex-orm/core'
import { get, groupBy } from 'lodash'
import GasMeter from './GasMeter'
import Preset from './Preset'
import { COMMODITY_TYPES } from '~/utils/entities'
/* Utils */

export default class Pipe extends Model {
    static entity = 'pipes'

    static fields() {
        return {
            id: this.attr(null),
            uId: this.attr(null),
            parentId: this.attr(null),
            meterId: this.attr(null),
            icon: this.attr('$bolt'),
            name: this.attr(''),
            friendlyName: this.attr(''),
            presetId: this.attr(null),
            color: this.attr(null),
            backupColor: this.attr(null),
            editable: this.boolean(true),
            virtual: this.boolean(false), // flag for virtual circuits (eg: Virtual Main Incomer)
            meter: this.belongsTo(GasMeter, 'meterId'),
            preset: this.belongsTo(Preset, 'presetId'),
            isMainIncomer: this.boolean(false),
            startTime: this.attr(null),
            endTime: this.attr(null),
            childrenCount: this.attr(0),
            sampleRate: this.attr(null),
            aggregates: this.attr({}),
            totalKwData: this.number(0),
            createdAt: this.attr(null),
            updatedAt: this.attr(null),
            manufacturer: this.attr(null),
            hasData: this.boolean(true),
        }
    }

    static virtualId(presetId) {
        return 'V' + presetId
    }

    static apiConfig = {
        url: 'clamps',
        params: {
            commodity: ['gas'],
        },
        dataTransformer({ data: { data } }) {
            // TODO - update models in the database to come with the childrenCount prop from the API
            if (Array.isArray(data)) {
                const groupedByParentId = groupBy(data, 'parentId')
                return data.map(pipe => {
                    let childrenCount = 0
                    if (groupedByParentId[pipe.id]) {
                        childrenCount = groupedByParentId[pipe.id].length
                    }
                    pipe.childrenCount = childrenCount
                    return pipe
                })
            }
            return data
        },
    }

    get entity() {
        return Pipe.entity
    }

    get scope() {
        return 'gas'
    }

    get site() {
        return get(this, 'relationships.site', null)
    }

    get siteId() {
        return get(this, 'relationships.site.siteId', null)
    }

    get pipeId() {
        return this.id
    }

    get siteName() {
        return get(this, 'site.name')
    }

    /**
    * Get pipe parent
    */
    get parent() {
        if (this.virtual) {
            return this.preset || Preset.find(this.presetId)
        }
        if (this.parentId) {
            return Pipe.find(this.parentId)
        }
        return this.getMeter()
    }

    /**
    * Get pipe children
    */
    get children() {
        return this.getChildren()
    }

    /**
    * Get pipe siblings
    * Other pipes under the same parent
    */
    get siblings() {
        return get(this.parent, 'children', [])
            .filter(p => {
                if (this.virtual) {
                    return p.id !== this.id
                }
                return p.id !== this.id &&
                    p.parentId === this.parentId
            })
    }

    /**
    * Get model relationships
    *
    * @return {Object}
    */
    get relationships() {
        // meter
        const meter = this.getMeter()
        // site
        const site = this.getSite() // meter && meter.parent
        return {
            site,
            meter,
            parent: this.parent,
        }
    }

    get isNHH() {
        return this.manufacturer === 10
    }

    getName({ friendly } = {}) {
        let name
        if (this.isMainIncomer || this.virtual) {
            name = this.parent.getName({ friendly })
        } else if (friendly && this.friendlyName) {
            name = this.friendlyName
        } else {
            name = this.name
        }
        return name
    }

    getMeter() {
        return this.meter || GasMeter.find(this.meterId)
    }

    getSite() {
        return this.getMeter()?.parent
    }

    getChildren() {
        return Pipe.query()
            .where('parentId', this.id)
            .get()
    }

    getUnitData(unit, commodity) {
        if (!unit) {
            console.warn('Unit is required')
            return
        }
        if (!this.id) {
            console.warn('Pipe ID is missing for some alien reason.')
            return
        }
        if (commodity !== COMMODITY_TYPES.gas) {
            console.warn('wrong commodity is being used')
        }
        return this.$store().$cache.getUnitData(unit, this, commodity)
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
