import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// brand icons
import { faGitter } from '@fortawesome/free-brands-svg-icons'
// sharp regular icons
import {
    faAlarmClock,
    faAngleDown,
    faAngleUp,
    faArrowUp,
    faArrowDown,
    faChartBar,
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faColumns,
    faEdit,
    faGaugeHigh,
    faHome,
    faList,
    faPencil,
    faPlay,
    faPowerOff,
    faShuffle,
    faTimer,
    faTrashCan,
    faTriangleExclamation,
} from '@fortawesome/sharp-regular-svg-icons'
// sharp light icons
import {
    faChevronDown,
    faSquare,
    faSquareMinus,
} from '@fortawesome/sharp-light-svg-icons'
import {
    // sharp solid (ex duotone) icons
    fa0,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    fa8,
    fa9,
    faAbacus,
    faAlarmExclamation,
    faArrowDownArrowUp,
    faArrowDownRight,
    faArrowDownToBracket,
    faArrowLeft,
    faArrowRight,
    faArrowUpArrowDown,
    faArrowUpRight,
    faBarsFilter,
    faBoltAuto,
    faBoxesStacked,
    faBurn,
    faCalendarCircleUser,
    faCashRegister,
    faCar,
    faCaravan,
    faCars,
    faCartArrowDown,
    faCartArrowUp,
    faCartMinus,
    faChartNetwork,
    faCartPlus,
    faChartLineUp,
    faCartShoppingFast,
    faChargingStation,
    faChartTreeMap,
    faCheeseSwiss,
    faCoins,
    faCubes,
    faDownLeftAndUpRightToCenter,
    faDumpster,
    faEllipsis,
    faFaceSadTear,
    faFaucetDrip,
    faFileChartColumn,
    faFileContract,
    faFire,
    faFireFlameSimple,
    faFlagPennant,
    faFlaskVial,
    faFluxCapacitor,
    faFolderArrowDown,
    faFolderArrowUp,
    faGasPump,
    faGear,
    faHandPointer,
    faHeat,
    faImage,
    faLayerPlus,
    faLeaf,
    faLightbulb,
    faLocationCheck,
    faLocationDot,
    faMicrochip,
    faPaperPlane,
    faPaperPlaneTop,
    faParachuteBox,
    faPipeValve,
    faPlane,
    faPlateUtensils,
    faPenToSquare,
    faPercent,
    faRecycle,
    faSensor,
    faShip,
    faShirt,
    faShovel,
    faSquare1,
    faSquare2,
    faSquare3,
    faSquare4,
    faSquare5,
    faTableCellsLarge,
    faTree,
    faTruck,
    faTruckContainer,
    faTruckTow,
    faTypewriter,
    faUpDown,
    faUpload,
    faUpRightAndDownLeftFromCenter,
    faVanShuttle,
    faWheatAwn,
    faWindTurbine,
    // sharp solid icons
    faAlarmPlus,
    faArrowDownAZ,
    faArrowProgress,
    faArrowRightArrowLeft,
    faArrowsAltV,
    faBackward,
    faBellOn,
    faBolt,
    faBoltLightning,
    faBookmark,
    faBuilding,
    faBullseyeArrow,
    faC,
    faH,
    faCalculator,
    faCalendarDays,
    faCaretDown,
    faCaretUp,
    faCaretRight,
    faChartLineUpDown,
    faCheck,
    faCheckDouble,
    faChartLine,
    faCircle,
    faCircleDot,
    faCirclePlus,
    faCircleUser,
    faCircleXmark,
    faClock,
    faClone,
    faCloudArrowDown,
    faCompress,
    faCircleInfo,
    faDash,
    faDiagramProject,
    faDollarSign,
    faDown,
    faDownload,
    faEllipsisVertical,
    faEuroSign,
    faExpand,
    faEye,
    faEyeSlash,
    faFastBackward,
    faFastForward,
    faFile,
    faFileCsv,
    faFilePdf,
    faFlask,
    faFloppyDisk,
    faFolder,
    faFolderOpen,
    faForward,
    faGaugeSimpleHigh,
    faGears,
    faGlobe,
    faGripDotsVertical,
    faGripLinesVertical,
    faHandshake,
    faHourglass,
    faHourglassClock,
    faHyphen,
    faIndustryWindows,
    faInfo,
    faKey,
    faLayerGroup,
    faLeftFromLine,
    faLightbulbExclamation,
    faListCheck,
    faLock,
    faMagnifyingGlass,
    faMinus,
    faMountain,
    faN,
    faObjectsColumn,
    faPaperclip,
    faPen,
    faPlus,
    faQuestion,
    faRight,
    faRightFromLine,
    faRotateLeft,
    faRotateRight,
    faScaleUnbalanced,
    faShare,
    faShieldCheck,
    faSync,
    faTable,
    faTriangle,
    faScrewdriverWrench,
    faSortUp,
    faSquareCheck,
    faSterlingSign,
    faUp,
    faUserClock,
    faUserSecret,
    faTruckArrowRight,
    faUserGroup,
    faUserLock,
    faUserXmark,
    faWarehouseFull,
    faXmark,
    faXmarkLarge,
} from '@fortawesome/sharp-solid-svg-icons'

import { faC02, faElectricity, faGas, faWater, faAlarm, faCalendar, faDashboard, faGrid, faFlows, faNetZero } from '~/assets/svg/clearvue-icons'

library.add(
    faC02,
    faElectricity,
    faGas,
    faWater,
    faAlarm,
    faCalendar,
    faDashboard,
    faGrid,
    faFlows,
    faNetZero,
    /* Brand Icons */
    faGitter,

    /* Duotone Icons */
    fa0,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    fa8,
    fa9,
    faAbacus,
    faAlarmExclamation,
    // faArrowDown,
    faArrowDownArrowUp,
    faArrowDownRight,
    faArrowDownToBracket,
    faArrowLeft,
    faArrowRight,
    faArrowUpArrowDown,
    faArrowUpRight,
    faBarsFilter,
    // faBolt,
    // faBoltLightning,
    faBoltAuto,
    faBoxesStacked,
    faBurn,
    faCalendarCircleUser,
    faCashRegister,
    faCar,
    faCaravan,
    faCars,
    faCartArrowDown,
    faCartArrowUp,
    faCartMinus,
    faChartNetwork,
    faCartPlus,
    faCartShoppingFast,
    faChargingStation,
    faChartLineUp,
    faChartTreeMap,
    faCheeseSwiss,
    faCoins,
    faCubes,
    faDownLeftAndUpRightToCenter,
    faDumpster,
    faEllipsis,
    faFaceSadTear,
    faFaucetDrip,
    faFileChartColumn,
    faFileContract,
    faFire,
    faFireFlameSimple,
    faFlagPennant,
    faFlaskVial,
    faFluxCapacitor,
    faFolderArrowDown,
    faFolderArrowUp,
    faGasPump,
    faGaugeHigh,
    faGear,
    faHandPointer,
    faHeat,
    faImage,
    faLayerPlus,
    faLeaf,
    faLightbulb,
    faLocationCheck,
    faLocationDot,
    faMicrochip,
    faPaperPlane,
    faPaperPlaneTop,
    faParachuteBox,
    faPipeValve,
    faPlane,
    faPlateUtensils,
    faPenToSquare,
    faPercent,
    faRecycle,
    faSensor,
    faShip,
    faShirt,
    faShovel,
    // faSquare,
    faSquare1,
    faSquare2,
    faSquare3,
    faSquare4,
    faSquare5,
    faTableCellsLarge,
    faTree,
    faTruck,
    faTruckContainer,
    faTruckTow,
    faTypewriter,
    faUpDown,
    faUpload,
    faUpRightAndDownLeftFromCenter,
    faVanShuttle,
    // faWarehouseFull,
    faWheatAwn,
    faWindTurbine,

    /* Sharp Regular Icons */
    faAlarmClock,
    faAngleDown,
    faAngleUp,
    faArrowUp,
    faArrowDown,
    faChartBar,
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faColumns,
    faEdit,
    faHome,
    faList,
    faPencil,
    faPlay,
    faPowerOff,
    faRotateLeft,
    faRotateRight,
    faShuffle,
    faTimer,
    faTrashCan,
    faTriangleExclamation,

    /* Sharp Light Icons */
    faCalendarDays,
    faChevronDown,
    faSquare,
    faSquareMinus,
    faTable,

    /* Sharp Solid Icons */
    faAlarmPlus,
    faArrowDownAZ,
    faArrowProgress,
    faArrowRightArrowLeft,
    faArrowsAltV,
    faBackward,
    faBellOn,
    faBolt,
    faBoltLightning,
    faBookmark,
    faBuilding,
    faBullseyeArrow,
    faC,
    faH,
    faCalculator,
    faCaretDown,
    faCaretUp,
    faCaretRight,
    faChartLineUpDown,
    faCheck,
    faCheckDouble,
    faChevronLeft,
    faChevronRight,
    faChartLine,
    faCircle,
    faCircleDot,
    faCirclePlus,
    faCircleUser,
    faCircleXmark,
    faClock,
    faClone,
    faCloudArrowDown,
    faCompress,
    faCircleInfo,
    faDash,
    faDiagramProject,
    faDollarSign,
    faDown,
    faDownload,
    faEllipsisVertical,
    faEuroSign,
    faExpand,
    faEye,
    faEyeSlash,
    faFastBackward,
    faFastForward,
    faFile,
    faFileCsv,
    faFilePdf,
    faFlask,
    faFloppyDisk,
    faFolder,
    faFolderOpen,
    faForward,
    faGaugeSimpleHigh,
    faGears,
    faGlobe,
    faGripDotsVertical,
    faGripLinesVertical,
    faHandshake,
    faHourglass,
    faHourglassClock,
    faHyphen,
    faIndustryWindows,
    faInfo,
    faKey,
    faLayerGroup,
    faLeftFromLine,
    faLightbulbExclamation,
    faListCheck,
    faLock,
    faMagnifyingGlass,
    faMinus,
    faMountain,
    faN,
    faObjectsColumn,
    faPaperclip,
    faPen,
    faPlus,
    faQuestion,
    faRight,
    faRightFromLine,
    faScaleUnbalanced,
    faShare,
    faShieldCheck,
    faSync,
    faTriangle,
    faScrewdriverWrench,
    faSortUp,
    faSquareCheck,
    faSterlingSign,
    faUp,
    faUserClock,
    faUserSecret,
    faTruckArrowRight,
    faUserGroup,
    faUserLock,
    faUserXmark,
    faWarehouseFull,
    faXmark,
    faXmarkLarge,
)

// config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
