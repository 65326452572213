
export default {
    name: 'VideoCard',

    props: {
        video: {
            type: Object,
            required: true,
        },
    },

    methods: {
        onPlaying(e) {
            this.$emit('playing', this.video.id)
        },
    },
}
