
import AppHeader from './parts/header'
import AppFooter from './parts/footer'
import AppDrawer from './parts/drawer'
import { maskEmailAddress } from '~/utils/tools'
import DialogsManager from '~/components/dialogs/index'
import SpinnerLoader from '~/components/common/spinner-loader.vue'

export default {

    components: {
        AppHeader,
        AppFooter,
        AppDrawer,
        DialogsManager,
        SpinnerLoader,
    },

    data() {
        return {
            zoom: 100,
            isLaptop: false,
        }
    },

    watch: {
        $route(to, from) {
            const routeHasChanged = from.path !== to.path
            if (routeHasChanged && to.path) {
                this.$api.tracking.logPageChange(to.path)
            }
        },
    },

    async mounted() {
        try {
            this.$nuxt.$api.tracking.logPageChange(this.$route.path)

            const live = window && (window.location.href.includes('pro.clearvuesystems.co.uk') || window.location.href.includes('clearvue.pro'))
            let email = this.$store.state.auth.user.name
            if (!live) {
                email = email.split('@')
                email = ['test-', email[0], '@test-', email[1]].join('')
            }

            if (process.env.ENV_NAME !== 'local' && window) {
                let customer = this.$store.getters['entities/customers/query']().first()
                if (!customer) {
                    // fetch teh customer
                    await this.$store.$db().model('customers').fetch()
                    customer = this.$store.getters['entities/customers/query']().first()
                }
                const token = await this.$api.chatToken({
                    email,
                    name: (!live ? 'TEST_' : '') + customer.customerName,
                    id: (!live ? 'TEST_' : '') + this.$store.state.auth.user.id,
                })
                window.zESettings = {
                    webWidget: {
                        color: {
                            theme: '#1976d2',
                            launcher: '#2d2d2d',
                            // theme: '#FF69B4',
                            // launcher: '#CC3A83', // This will also update the badge
                            // launcherText: '#E589B7',
                            // button: '#8A0648',
                            // resultLists: '#691840',
                            // header: '#203D9D',
                            // articleLinks: '#FF4500'
                        },
                        authenticate: {
                            chat: {
                                jwtFn: callback => {
                                    callback(token)
                                },
                            },
                        },
                    },
                }
            }
            this.loadScript('https://static.zdassets.com/ekr/snippet.js?key=088eae31-40c7-4da7-89dd-ea73f34952bf', function() {
                // eslint-disable-next-line no-undef
                zE('webWidget', 'prefill', {
                    email: { value: email, readOnly: true },
                    name: { value: email, readOnly: true },
                })
            })
            /**
             * the following will generate a unique browser signature and store it in the localstorage
             * such signature will also attached to any report sent to sentry and will show up in the footer
             */
            if (!this.$cache.$localStorage.has('signature')) {
                this.$cache.$localStorage.set('signature', ('00000000' + (Math.random() * 1e8)).slice(-8))
            }
            const signature = this.$cache.$localStorage.get('signature')
            const instanceId = `${this.$store.state.auth.user.customer_id}-${this.$store.state.auth.user.id}-${signature}`
            const sentryUserData = {
                email: maskEmailAddress(this.$store.state.auth.user.name),
                id: instanceId,
            }
            this.$store.commit('global/SET_INSTANCE_ID', instanceId)
            this.$sentry.setUser(sentryUserData)
        } catch (e) {
            console.warn(e)
        }

        if (this.$config.features.alertsEnabled) {
            await this.$store.dispatch('entities/notifications/init')
        }
    },

    methods: {
        loadScript(url, callback) {
            if (typeof document === 'undefined') return
            const script = document.createElement('script')
            if (script.readyState) { // only required for IE <9
                script.onreadystatechange = function() {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null
                        callback()
                    }
                }
            } else { // Others
                script.onload = function() {
                    callback()
                }
            }
            script.setAttribute('src', url)
            script.setAttribute('id', 'ze-snippet')
            document.head.appendChild(script)
        },

        onResize() {
            this.zoom = Math.round((window.outerWidth / window.innerWidth) * 100)
            // this.zoom = Math.round((window.outerHeight / window.innerHeight) * 100)
            const actualHeight = window.outerHeight * this.zoom / 100
            this.isLaptop = actualHeight <= 1080

            if (typeof this.$vuetify.breakpoint.isLaptop === 'undefined') {
                this.$set(this.$vuetify.breakpoint, 'isLaptop', this.isLaptop)
            } else if (this.$vuetify.breakpoint.isLaptop !== this.isLaptop) {
                this.$vuetify.breakpoint.isLaptop = this.isLaptop
            }
        },
    },
}
