import pDebounce from 'p-debounce'
import { extraColors } from '~/config/colors'

export const getters = {
    notUserSite(_state, getters) {
        return getters.query()
            .where(s => !s.isUserGenerated)
            .get()
    },
}
export const actions = {
    /**
     * Setup sites
     *
     * will setup the following params for each site
     * color
     *
     * @param {Object} context
     * @return {Void}
     */
    setup: pDebounce(async function({ dispatch, state, rootGetters }) {
        const colors = extraColors(rootGetters.theme).mainEntityColors
        let colorIndex = 0
        const data = rootGetters['entities/sites/all']().map(site => {
            const color = site.color || colors[colorIndex]
            const { circuits, pipes, waterPipes } = site.relationships
            const electricityTotalKw = this.$_.sumBy(circuits, 'totalKwData')
            const gasTotalKw = this.$_.sumBy(pipes, 'totalKwData')
            const waterTotalKw = this.$_.sumBy(waterPipes, 'totalKwData')
            const backupColor = colors[colorIndex++]
            if (!colors[colorIndex]) colorIndex = 0

            return {
                ...site,
                color,
                backupColor,
                electricityTotalKw,
                gasTotalKw,
                waterTotalKw,
            }
        })
        await dispatch('update', { data })
    }, 200),
}
