
import PeriodSelect from '~/components/common/period-select'

export default {
    components: {
        PeriodSelect,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        entities: {
            type: Array,
            required: true,
        },
        demand: {
            type: [String, Number],
            required: true,
        },
    },
    data(vm) {
        return {
            loading: false,
            $demand: vm.demand,
            $demandRange: vm.value,
        }
    },
    watch: {
        entities: {
            deep: true,
            handler() {
                this.onPeriodChange(this.$data.$demandRange)
            },
        },
    },
    mounted() {
        this.onPeriodChange(this.value)
    },
    methods: {
        async onPeriodChange(p) {
            if (p.startDate && p.startTime && p.endDate && p.endTime) {
                this.$data.$demandRange = { ...p }
            }
            const period = this.$data.$demandRange
            if (this.entities.length && period.startDate && period.startTime && period.endDate && period.endTime) {
                try {
                    this.loading = true
                    const { data } = await this.$api.clickHouse.fetchAggregates({
                        avg: ['P'],
                        clampIds: this.entities.map(e => e.id),
                        resolution: 900,
                        daterange: [
                            `${period.startDate}T${period.startTime}Z`,
                            `${period.endDate}T${period.endTime}Z`,
                        ],
                        splitCircuits: false,
                    })
                    this.$data.$demand = data.avg.P.value
                } catch (e) {
                    console.warn(e)
                } finally {
                    this.loading = false
                }
            }
        },
        save() {
            this.$emit('update:demand', this.$data.$demand)
            this.$emit('close')
        },
    },
}
