
import { TRACKING_EVENTS } from '~/utils/tracking'

export default {
    name: 'InfoMark',
    props: {
        value: {
            type: String,
            default: null,
        },
        position: {
            type: Object,
            default: () => ({ bottom: true }),
        },
    },
    data() {
        return {
            trackingTimeout: null,
            sentTrackingEvent: false, // prevents duplicate events
        }
    },
    methods: {
        handleMouseEnter() {
            if (!this.trackingTimeout && !this.sentTrackingEvent) {
                this.trackingTimeout = setTimeout(() => {
                    this.sentTrackingEvent = true
                    this.$api.tracking.addToBuffer({
                        type: TRACKING_EVENTS.TOOLTIP,
                        metadata: {
                            tooltip_type: this.value,
                        },
                    })
                }, 1500)
            }
        },
        handleMouseLeave() {
            clearTimeout(this.trackingTimeout)
            this.trackingTimeout = null
        },
    },
}
