
import { mapGetters } from 'vuex'

export default {
    name: 'SummaryTimeline',

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapGetters('flows', ['fuelById', 'materialById']),
        filteredItems() {
            return this.items.filter(item => item.id !== 'summary')
        },
    },

    methods: {
        indexIcon(index) {
            return ['$gears'][index] || '$gears'
        },
    },
}
