
export default {
    name: 'SearchPlace',

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        expanded: Boolean,
        canExpand: Boolean,
        countries: {
            type: Array,
            default: () => ([
                { code: 'FR', name: 'France' },
                { code: 'GBR', name: 'United Kingdom' },
            ]),
        },
        label: {
            type: String,
            default: 'Search for a place...',
        },
        noDataLabel: {
            type: String,
            default: 'Search for any place',
        },
        error: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: 'establishment',
        },
    },

    data: () => ({
        loading: false,
        search: null,
        places: [],
        placeId: null,
    }),

    computed: {
        countryNames() {
            return this.countries.map(c => c.name)
        },
        placeItem() {
            return this.places.find(a => a.id === this.placeId)
        },
        place: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', { ...val, ...this.placeItem })
            },
        },
    },

    watch: {
        search(val) {
            this.autocomplete(val)
        },
        placeId(id) {
            this.fetchDetails(id)
        },
        loading(val) {
            this.$emit('loading', val)
        },
    },

    created() {
        this.autocomplete = this.$_.throttle(this.autocomplete, 600, { leading: false })
    },

    mounted() {
        if (!this.$_.isEmpty(this.value)) {
            this.placeId = this.value.id
            this.places = [this.value]
        }
    },

    methods: {
        autocomplete(query) {
            if (!query) {
                this.places = []
                return
            }
            const fetchId = +new Date()
            this.fetchId = fetchId
            this.loading = true
            this.$api.placeAutocomplete({ query, type: this.type })
                .then(places => {
                    if (fetchId === this.fetchId) {
                        this.places = places
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        fetchDetails(id) {
            if (!id) {
                this.place = {}
                return
            }
            const fetchId = +new Date()
            this.fetchId = fetchId
            this.loading = true

            this.$api.fetchPlaceDetails({ id })
                .then(place => {
                    if (fetchId === this.fetchId) {
                        this.place = place
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        cancel() {
            this.loading = false
            this.search = null
        },

        onKeydownEnter(evt) {
            // console.log('autocomplete:onKeydownEnter: ', evt)
        },
    },
}
