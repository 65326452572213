// Utils
import { initialState } from './state'
import { dateRadius, MONTH } from '~/utils/date'
import { FROM_ROOT } from '~/utils/state'
import { getClampModel, parseEUIDs } from '~/utils/entities'
import { VALID_RESOLUTIONS, getResolutionValue } from '~/utils/resolution'

export default {
    /**
     * Init period asap on client side
     *  - here we are setting min, max, start, end in a certain priority
     *  - url hash > entity dates > default values
     *  - and remaping all date strings to moment objects
     *
     *
     * @param {Object} storeContext
     * @param {Object} payload
     * @param entity
     */
    init({ commit }, payload = {}) {
        // if (state.initialised) return

        if (this.$router.currentRoute.hash && this.$hashState.period) {
            payload = Object.assign({}, this.$hashState.period, payload)
        }

        const initialPeriod = initialState(payload)
        const period = {}
        for (const p in initialPeriod) {
            if (['min', 'max', 'start', 'end'].includes(p)) {
                // this is needed to convert into moment objects
                period[p] = this.$moment(initialPeriod[p])
            } else {
                period[p] = initialPeriod[p]
            }
        }

        commit('UPDATE', period)
        commit('INIT')
    },

    /**
     * Update Period
     * @param  {Function} options.commit
     * @param  {Object} period
     * @return {Void}
     */
    update({ commit, state, dispatch }, period) {
        period = { ...period }
        //  when start is after end
        //  or end is before start
        if (period.start && period.end) {
            if (period.start > period.end) {
                if (period.interval === 'ytd') {
                    period.start.set('year', period.end.year())
                } else {
                    period.start = period.end
                }
            }
            if (period.end < period.start) {
                period.end = period.start
            }
        }
        // Validation
        if (typeof period.start === 'number') {
            // check for NaN
            if (isNaN(period.start)) {
                period.start = state.min
            }
            // limit start to period min
            period.start = Math.max(period.start, state.min)
        } else if (period.start && period.start._isAMomentObject) {
            if (+period.start < +state.min) {
                period.start = this.$moment(state.min)
            }
        }

        if (period.start && period.end && this.$moment(period.start).isAfter(this.$moment(period.end))) {
            const _start = period.start
            const _end = period.end
            period.end = _start
            period.start = _end
        }

        if (typeof period.end === 'number') {
            // check for NaN
            if (isNaN(period.end)) {
                period.end = state.max
            }
            // limit end to period max
            period.end = Math.min(period.end, period.max || state.max)
        } else if (period.end && period.end._isAMomentObject) {
            if (+period.end > +state.max) {
                period.end = this.$moment(state.max)
            }
        }

        // for certain period modifiers we want to reset period interval
        if (['chart', 'datetimepicker'].includes(period.by)) {
            period.interval = null
        }

        if (period.interval === null || (period.interval && period.interval !== state.interval)) {
            // signal comparison to deactivate
            dispatch('gas/setComparison', null, FROM_ROOT)
            dispatch('electricity/setComparison', null, FROM_ROOT)
            dispatch('water/setComparison', null, FROM_ROOT)
            dispatch('dashboard/setComparison', null, FROM_ROOT)
        }

        commit('UPDATE', this.$_.omit(period, 'by'))
        commit('SET_PERIOD_CHANGED')
    },

    /**
     * Reset Period
     *
     * @param {Object} storeContext
     * @param {Object} payload
     * @return {Void}
     */
    reset({ dispatch }, payload = {}) {
        this.$PeriodService.updatePeriodWithDebounce(initialState(payload), p => dispatch('update', p))
    },

    /**
     * Zoom into time point
     * @param dispatch
     * @param getters
     * @param state
     * @param  {Date} date
     * @param span
     * @return Void
     */
    zoomIntoTime({ dispatch, getters, state }, { date }) {
        if (getters.isCurrentResolutionStacking) {
            // adding a couple of hours to make sure i'm in the right day
            let start, end
            switch (state.period.resolution) {
                case 'day':
                case 86.4e3:
                    start = this.$moment(date).startOf('day').subtract(3, 'days')
                    end = this.$moment(date).endOf('day').add(3, 'days')
                    break
                case 'week':
                    return null
                case 'month':
                    return null
                case 'year':
                    start = this.$moment(date).startOf('year')
                    end = this.$moment(date).endOf('year')
                    break
            }
            this.$PeriodService.updatePeriodWithDebounce({
                start,
                end,
            }, p => dispatch('update', p))
        } else {
            // zoom into 12 hours radius
            const { start, end } = dateRadius(date, 60 * 60 * 12)
            this.$PeriodService.updatePeriodWithDebounce({
                start,
                end,
            }, p => dispatch('update', p))
        }
    },

    checkMinResolution({ rootGetters, dispatch, rootState }, { euids }) {
        const entities = parseEUIDs(euids, { reverse: true }).flatMap(({ entity: entityType, id }) => {
            const entity = rootGetters[`entities/${entityType}/find`](id)
            if (['meters', 'gas-meters', 'water-meters'].includes(entityType) && entity.mainIncomer) {
                return entity.mainIncomer
            }
            if (entityType === 'sites') {
                const scope = rootGetters['entities/treeviews/active']?.scope
                if (scope) {
                    const clampEntity = getClampModel(scope)
                    return clampEntity.query().where('siteId', Number(id)).where('isMainIncomer', true).get()
                }
                return undefined
            }
            return entity
        }).filter(e => e)
        if (entities?.length) {
            let minimumResolution = entities.some(e => e.isNHH)
                ? MONTH
                : Math.max(...entities.map(e => e.sampleRate || VALID_RESOLUTIONS._30_MINUTES))
            // if it's VALID_RESOLUTIONS.MONTH resolution we need to use the MONTH to compare them
            const currentResolution = this.$_.isNumber(rootState.period.resolution)
                ? rootState.period.resolution
                : getResolutionValue(rootState.period.resolution)
            if (currentResolution < minimumResolution) {
                if (minimumResolution === MONTH) {
                    minimumResolution = VALID_RESOLUTIONS.MONTH
                }
                this.$PeriodService.updatePeriodWithDebounce({ resolution: minimumResolution }, p => dispatch('update', p))
                if (rootState[entities[0]?.scope]?.initialised) {
                    this.$toast.info('Resolution has been changed since it was not supported by the selected circuits')
                }
            }
        }
    },
}
