import eventBus from '@/utils/eventbus'

/*
** Executed by ~/.nuxt/index.js with context given
** This method can be asynchronous
*/
export default ({ $axios }, inject) => {
    // Inject `eventBus` key
    // -> app.$eventBus
    // -> this.$eventBus in vue components
    // -> this.$eventBus in store actions/mutations
    inject('eventBus', eventBus)
}
