
import ItemCard from './ItemCard'

export default {
    name: 'ProcessLocation',

    components: {
        ItemCard,
    },

    props: {
        active: Boolean,
        location: {
            type: Object,
            required: true,
        },
    },

    computed: {
        subtitle() {
            if (this.$_.isEmpty(this.location)) {
                return ''
            }
            return [
                this.location.siteName || this.location.name,
                this.location.address1 ? [this.location.city, this.location.address1].join(', ') : this.location.address,
            ].join(', ')
        },
    },
}
