
// import Theme from '@/components/theme'
// import Languages from '@/components/languages'
// import ChangePassword from '~/components/profile/change-password'
import SettingsDialog from '~/components/dialogs/settings'
import { maskEmailAddress } from '~/utils/tools'

export default {
    components: {
        // Theme,
        // Languages,
        // ChangePassword,
        SettingsDialog,
    },

    data() {
        return {
            menu: false,
            dialog: false,
        }
    },

    computed: {
        impersonator() {
            return this.$api.auth.impersonator()
        },
        impersonatorName() {
            return `Agent ${this.impersonator?.impersonatorId}`
        },
        emailAddress() {
            return this.$auth.user.name
        },
        maskedEmailAddress() {
            return maskEmailAddress(this.emailAddress)
        },
        userName() {
            return [this.$auth.user.first_name, this.$auth.user.last_name].filter(Boolean).join(' ')
        },
        username() {
            let username = this.userName
            if (this.impersonator) {
                if (this.isImpersonatePage) {
                    username = this.impersonatorName
                } else {
                    username = `${this.impersonatorName} as ${username}`
                }
            }
            if (this.$cookie.get('anon')) {
                username = this.$t('txt.test_user')
            }
            return username
        },
        isImpersonatePage() {
            return this.$route.path.includes('impersonate')
        },
    },

    methods: {
        async logout() {
            await this.$api.tracking.logout()
            window.localStorage.clear()
            if (this.$cache.$storage) {
                await this.$cache.$storage._storage.clear()
            }
            await this.$auth.logout()
            await this.$store.dispatch('entities/deleteAll')
            await this.$hashState.reset()
            location.href = this.$auth.options.redirect.login
        },
        goTo(where) {
            setTimeout(() => {
                this.menu = false
            }, 200)
            this.$router.push(where)
        },
        toggleSettingsDialog() {
            this.$refs.settingsDialog?.toggle()
            setTimeout(() => {
                this.menu = false
            }, 200)
        },
    },
}
