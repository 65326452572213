import { assign, isArray, isFunction, isString, maxBy, orderBy, sum, truncate, uniqueId } from 'lodash'
import {
    icons,
    PROCESS_BASE_HEIGHT,
    MIN_ROW_HEIGHT,
    INVENTORY_BASE_HEIGHT,
    DISTANCE_BETWEEN_NODES,
    GENERIC_NODE_HEIGHT, MAX_SUPPLIERS_ROWS, INVENTORY_BASE_WIDTH, TYPES, colors,
} from './const'
import { n } from '~/plugins/filters'
import moment from '~/composables/useMoment'

export function makeId(elements, prefix = '') {
    let newId = uniqueId(prefix)
    while (elements.find(({ data: { id } }) => id === newId)) {
        newId = uniqueId(prefix)
    }
    return newId
}

export function createDraft(scope, blockIndex = 0) {
    return {
        elements: [
            generateInventoryNode({ draft: { elements: [] } }, blockIndex),
            // { data: { type: 'line', id: 'line'}, position: {x: -100, y: 0 }}
        ],
    }
}

export function cytoCommands(scope) {
    const ctxMenuCommands = {
        DELETE_NODE: {
            content: `<span class="ctx-menu-icon">${icons.delete}</span>`,
            select: scope.removeNode,
        },

        ADD_PROCESS: {
            content: `<span class="ctx-menu-icon">${icons.timelineArrow.svg}</span>`,
            select: scope.addProcess,
        },

        ADD_OUTPUT: {
            content: `<span class="ctx-menu-icon">${icons.asset.svg}</span>`,
            select: scope.addOutput,
        },

        ADD_OUTPUT_SHIPPING: {
            content: `<span class="ctx-menu-icon">${icons.shipping.svg}</span>`,
            select: scope.addOutputShipping,
        },

        ADD_WASTE: {
            content: `<span class="ctx-menu-icon">${icons.disposal.svg}</span>`,
            select: scope.addWaste,
        },

        EDIT_WASTE: {
            content: `<span class="ctx-menu-icon">${icons.edit}</span>`,
            select: scope.editWaste,
        },

        LINK_TO: {
            content: `<span class="ctx-menu-icon">${icons.link.svg}</span>`,
            select: scope.linkTo,
        },

        EDIT_SUPPLIER: {
            content: `<span class="ctx-menu-icon">${icons.edit}</span>`,
            select: scope.editSupplier,
        },

        EDIT_SHIPPING: {
            content: `<span class="ctx-menu-icon">${icons.shipping.svg}</span>`,
            select: scope.editShipping,
        },

        EDIT_OUTPUT_SHIPPING: {
            content: `<span class="ctx-menu-icon">${icons.edit}</span>`,
            select: scope.editOutputShipping,
        },

        EDIT_OUTPUT: {
            content: `<span class="ctx-menu-icon">${icons.edit}</span>`,
            select: scope.editOutput,
        },

        EDIT_SITE: {
            content: `<span class="ctx-menu-icon">${icons.industry.svg}</span>`,
            select: scope.editSite,
        },

        RENAME_NODE: {
            content: `<span class="ctx-menu-icon">${icons.edit}</span>`,
            select: scope.renameNode,
        },

        EDIT_ENTITIES: {
            content: `<span class="ctx-menu-icon">${icons.edit}</span>`,
            select: scope.editEntities,
        },

        ADD_INVENTORY: {
            content: `<span class="ctx-menu-icon">${icons.plus.svg}</span>`,
            select: scope.addInventory,
        },

        SELECT_MATERIALS: {
            content: `<span class="ctx-menu-icon">${icons.material.svg}</span>`,
            select: scope.selectMaterials,
        },

        EDIT_ENERGY: {
            content: `<span class="ctx-menu-icon">${icons.power.svg}</span>`,
            select: scope.editEnergy,
        },

        // ADD_INVENTORY // TODO
    }
    return ele => {
        const commands = []
        return commands

        // eslint-disable-next-line no-unreachable
        if (ele.isNode()) { // NODES
            switch (ele.data('type')) {
                case TYPES.MATERIAL:
                    commands.push(
                        ctxMenuCommands.DELETE_NODE,
                        ctxMenuCommands.EDIT_OUTPUT,
                    )
                    break
                case TYPES.SUPPLIER:
                    commands.push(
                        ctxMenuCommands.DELETE_NODE,
                        ctxMenuCommands.EDIT_SUPPLIER,
                        ctxMenuCommands.EDIT_SHIPPING,
                    )
                    break
                case TYPES.PROCESS:
                    commands.push(
                        // ctxMenuCommands.ADD_LINKED_PROCESS,
                        ctxMenuCommands.DELETE_NODE,
                        ctxMenuCommands.SELECT_MATERIALS,
                        ctxMenuCommands.ADD_WASTE,
                        ctxMenuCommands.RENAME_NODE,
                        ctxMenuCommands.ADD_OUTPUT,
                        ctxMenuCommands.EDIT_ENERGY,
                    )
                    break
                case TYPES.INVENTORY:
                    if (!ele.outgoers(`node[type="${TYPES.MATERIAL}"]`).length) {
                        commands.push(ctxMenuCommands.ADD_PROCESS)
                    }
                    commands.push(
                        ctxMenuCommands.EDIT_SITE,
                        ctxMenuCommands.ADD_OUTPUT_SHIPPING,
                    )
                    break
                case TYPES.MID_INVENTORY:
                    if (!ele.outgoers(`node[type="${TYPES.MATERIAL}"]`).length) {
                        commands.push(
                            ctxMenuCommands.ADD_PROCESS,
                        )
                    }
                    commands.push(
                        ctxMenuCommands.ADD_OUTPUT_SHIPPING,
                    )
                    break
                case TYPES.OUTPUT:
                    commands.push(
                        // ctxMenuCommands.ADD_LINKED_PROCESS,
                        ctxMenuCommands.DELETE_NODE,
                        // ctxMenuCommands.LINK_TO,
                        ctxMenuCommands.EDIT_OUTPUT,
                        // ctxMenuCommands.ADD_OUTPUT_SHIPPING
                    )
                    break
                case TYPES.SHIPPING:
                    commands.push(
                        ctxMenuCommands.DELETE_NODE,
                        ctxMenuCommands.EDIT_OUTPUT_SHIPPING,
                        ctxMenuCommands.EDIT_SHIPPING,
                    )
                    break
                case TYPES.WASTE:
                    commands.push(
                        ctxMenuCommands.EDIT_WASTE,
                        ctxMenuCommands.DELETE_NODE,
                    )
                    break
                case TYPES.ENERGY_GAS:
                case TYPES.ENERGY_POWER:
                    commands.push(
                        ctxMenuCommands.DELETE_NODE,
                        ctxMenuCommands.EDIT_ENTITIES,
                    )
                    break
            }
        } else { // EDGES

        }
        return commands
    }
}

export function importFromTemplate({ sites, materials, thirdParties, disposals }, value, isComposed = false) {
    // console.log(sites)
    const debug = false
    console.log('value', value)
    const r = {
        id: value.id,
        name: value.name,
        stage: value.stage,
        elements: [],
    }
    let outputNodes = []
    let materialNodes = []
    let supplierNodes = []
    let deliveryMaterialNodes = []
    let purchaseMaterialNodes = []
    let energyNodes = []
    // let upstreamSectionLabeled = false
    // let downstreamSectionLabeled = false
    // const manufacturingSectionLabeled = false

    const edges = []
    const inventory = value.nodes
        .find(({ process: { type } }) => type === TYPES.INVENTORY)

    const midInventories = value.nodes.filter(({ nodeId, process: { type } }) => type === TYPES.INVENTORY && nodeId !== inventory.nodeId)
    const processes = value.nodes.filter(({ process: { type } }) => type === TYPES.PROCESS || type === TYPES.MACHINE)
    const suppliers = value.nodes.filter(({ process: { type } }) => type === TYPES.SUPPLIER)
    const customers = value.nodes.filter(({ process: { type } }) => type === TYPES.CUSTOMER)
    const combustions = value.nodes.filter(({ process: { type } }) => type === TYPES.COMBUSTION || type === TYPES.ENERGY_COMBUSTION)
    // const energies = value.nodes.filter(({ process: { type } }) => type === TYPES.ENERGY_GAS || type === TYPES.ENERGY_POWER)
    const disposalItems = value.nodes.filter(({ process: { type } }) => type === TYPES.WASTE)
    // console.log('combustions', combustions)
    const inventorySite = sites.find(({ siteId }) => {
        return `${siteId}` === `${inventory.details.location.siteId}`
    })
    const mainInventoryNode = generateInventoryNode(null, 0, {
        id: inventory.nodeId,
        processId: inventory.process.id,
        site: {
            id: inventorySite.siteId,
            name: inventorySite.siteName,
            location: inventorySite.location,
        },
    })

    suppliers.forEach(e => {
        supplierNodes.push(...generateSupplierNode([], {
            data: {
                supplier: thirdParties.find(({ id }) => id === e.details.location.siteId),
                id: e.nodeId,
                col: 0,
                row: 0,
                isOutputExpanded: true,
                schedule: e.details.schedule,
            },
            position: calculatePositions[TYPES.SUPPLIER](mainInventoryNode, 0, 0, 1, true),
        }, mainInventoryNode, {}))
    })

    const processNodes = processes.map((e, k) => {
        // console.log('process', e)
        return {
            data: {
                id: e.nodeId,
                type: e.process.type, // TYPES.PROCESS,
                name: e.process.name || value.name,
                isOutputExpanded: true,
                icon: TYPES.PROCESS,
                isMaterialExpanded: true,
                schedule: e.details.schedule,
                netZero: e.process.netZero,
                categoryId: e.process.categoryId,
                site: e.details.location,
            },
            grabbable: debug,
            position: calculatePositions[TYPES.PROCESS](mainInventoryNode, k),
        }
    })

    let combustionNodes = combustions.map(e => {
        // console.log('combustionNode', e)
        const material = materials.find(({ id }) => id === e.process.inputs[0].material.id)

        return {
            data: {
                id: e.nodeId,
                type: TYPES.COMBUSTION,
                name: e.process.name,
                icon: TYPES.ENERGY_GAS,
                unit: e.process.outputs[0].unit,
                categoryId: e.process.categoryId,
                netZero: e.process.netZero,
                schedule: e.details.schedule,
                site: e.details.location,
                input: {
                    id: material.id,
                    name: material?.name || 'test',
                    amount: e.weight,
                    unit: 'kg',
                },
                output: {
                    id: e.process.outputs[0].material.id,
                    unit: 'kwh',
                },
            },
            grabbable: debug,
        }
    })

    let customerNodes = customers.map(c => {
        const customer = thirdParties.find(({ id }) => id === c.details.location.siteId)
        return {
            data: {
                id: c.nodeId,
                type: TYPES.CUSTOMER,
                customer,
                icon: TYPES.CUSTOMER,
                processId: c.process.id,
                schedule: c.details.schedule,
            },
            grabbable: debug,
        }
    })

    let disposalNodes = disposalItems.map(disposalItem => {
        const disposal = disposals.find(e => {
            return e.id === disposalItem.process.inputs[0].material.id
        })
        // console.log(disposalItem, disposal)
        return {
            data: {
                id: disposalItem.nodeId,
                type: TYPES.WASTE,
                name: disposal.name,
                icon: TYPES.WASTE,
                unit: 'kg',
                amount: 199,
                netZero: disposalItem.process.netZero,
                disposalProcessId: disposalItem.process.id,
                site: disposalItem.details.location,
            },
            grabbable: debug,
        }
    })

    let midInventoryNodes = midInventories.map((e, k) => {
        return {
            data: {
                icon: 'warehouse',
                id: e.nodeId,
                type: TYPES.MID_INVENTORY,
                site: {
                    id: inventorySite.siteId,
                    name: inventorySite.siteName,
                    location: inventorySite.location,
                },
            },
            grabbable: debug,
        }
    })

    value.edges.forEach(e => {
        const target = value.nodes.find(({ nodeId }) => nodeId === e.target)
        const source = value.nodes.find(({ nodeId }) => nodeId === e.source)
        const material = materials.find(({ id }) => id === e.resource)
        if (!material) {
            console.warn('missing material', e)
        }
        // console.log({
        //     e,
        //     targetType: target.process.type,
        //     sourceType: source.process.type
        // })
        if (
            (target.process.type === TYPES.ENERGY_COMBUSTION || target.process.type === TYPES.COMBUSTION) &&
            source.process.type === TYPES.INVENTORY
        ) {
            edges.push({
                data: {
                    id: `${e.source}-${e.target}`,
                    source: e.source,
                    target: e.target,
                    resource: e.resource,
                    unit: e.unit,
                    value: e.value,
                },
            })
        } else if (target.process.type === TYPES.PROCESS || target.process.type === TYPES.MACHINE) { // input
            // console.log('edge to process', e, source, target)
            // console.log('material', material)
            if (source.process.type === TYPES.ENERGY_COMBUSTION || source.process.type === TYPES.COMBUSTION) {
                // console.log('energy edge', e, material)
                const combustionNode = combustionNodes.find(({ data: { id } }) => id === e.source)

                combustionNode.data.amount = e.weight || e.value
                combustionNode.data.processNodeId = e.target
                const combustionEdge = {
                    data: {
                        id: `${e.source}-${e.target}`,
                        source: e.source,
                        target: e.target,
                    },
                }
                edges.push(combustionEdge)
            } else if (source.process.type === TYPES.ENERGY_POWER || source.process.type === TYPES.ENERGY_GAS) {
                // console.log('energy', {
                //     edge: e,
                //     source,
                //     target
                // })
                const energyNodeId = makeId([], source.process.type)
                const energyNode = {
                    grabbable: debug,
                    data: {
                        id: energyNodeId,
                        type: source.process.type,
                        processNodeId: e.target,
                        icon: source.process.type,
                        netZero: source.process.netZero,
                        entities: source.details.entities,
                        categoryId: source.process.categoryId,
                        processId: source.process.id,
                        location: source.details.location,
                        schedule: target.details.schedule,
                        output: {
                            id: material.id,
                            name: material?.name || 'test',
                            amount: e.weight || e.value,
                            unit: e.unit,
                        },
                    },
                }
                energyNodes.push(energyNode)
                edges.push({
                    data: {
                        id: `${energyNodeId}-${e.target}`,
                        source: energyNodeId,
                        target: e.target,
                    },
                })
            } else {
                // console.log('input material', e,  materials.find(({ id }) => id === e.resource))
                const materialNode = {
                    grabbable: debug,
                    data: {
                        id: makeId([], TYPES.MATERIAL),
                        type: TYPES.MATERIAL,
                        processNodeId: e.target,
                        icon: TYPES.MATERIAL,
                        output: {
                            id: material.id,
                            name: material?.name || 'test',
                            amount: e.weight,
                            unit: 'kg',
                        },
                    },
                }
                materialNodes.push(materialNode)
                // console.log('generating material node', e, materialNode)
                const materialEdges = [
                    {
                        data: {
                            id: `${e.source}-${materialNode.data.id}`,
                            source: e.source,
                            target: materialNode.data.id,
                        },
                    },
                    {
                        data: {
                            id: `${materialNode.data.id}-${e.target}`,
                            source: materialNode.data.id,
                            target: e.target,

                        },
                    },
                ]
                edges.push(...materialEdges)
                // console.log('materialEdges', materialEdges)
            }
        } else if ((source.process.type === TYPES.PROCESS || source.process.type === TYPES.MACHINE) && target.process.type === TYPES.INVENTORY) { // output
            // console.log('importing output edge into a node', e)
            const outputNode = {
                grabbable: debug,
                data: {
                    id: makeId([], TYPES.OUTPUT),
                    type: TYPES.OUTPUT,
                    icon: TYPES.OUTPUT,
                    processNodeId: e.source,
                    output: {
                        id: material.id,
                        name: material?.name || 'test',
                        amount: e.weight || e.value,
                        unit: 'kg',
                    },
                },
            }
            outputNodes.push(outputNode)
            const outputEdges = [
                {
                    data: {
                        id: `${outputNode.data.id}-${e.target}`,
                        source: outputNode.data.id,
                        target: e.target,
                    },

                },
                {
                    data: {
                        id: `${e.source}-${outputNode.data.id}`,
                        source: e.source,
                        target: outputNode.data.id,
                    },
                },
            ]
            edges.push(...outputEdges)
            // console.log('output edges', outputEdges)
        } else if (source.process.type === TYPES.SUPPLIER) {
            // console.log('input material', e,  material)
            const materialNode = {
                grabbable: debug,
                data: {
                    id: makeId([], TYPES.MATERIAL),
                    type: TYPES.PURCHASE_MATERIAL,
                    processNodeId: e.source,
                    icon: TYPES.MATERIAL,
                    output: {
                        id: material.id,
                        name: material?.name || 'test',
                        amount: e.weight,
                        unit: 'kg',
                    },
                    transportEmissions: sum(e.transport.legs.map(leg => leg.netZero?.emissions?.co2e || 0).concat(e.netZero?.emissions?.co2e || 0)),
                    // description: upstreamSectionLabeled ? null : 'Upstream section'
                },
            }
            // upstreamSectionLabeled = true
            purchaseMaterialNodes.push(materialNode)
            // console.log('generating material node', e, materialNode)
            const materialEdges = [
                {
                    data: {
                        id: `${e.source}-${materialNode.data.id}`,
                        source: e.source,
                        target: materialNode.data.id,
                        // target: materialNode.data.id
                    },
                },
                {
                    data: {
                        id: `${materialNode.data.id}-${mainInventoryNode.data.id}`,
                        source: materialNode.data.id,
                        target: mainInventoryNode.data.id,
                        // target: materialNode.data.id
                    },
                },
            ]
            edges.push(...materialEdges)
            // console.log('materialEdges', materialEdges)
        } else if (target.process.type === TYPES.WASTE) {
            // console.log({
            //     e,
            //     material,
            //     disposalNodes,
            //     id: e.source
            // })
            const disposalNode = disposalNodes.find(({ data: { id } }) => id === e.target)
            console.log('disposalNode', disposalNode, e)
            disposalNode.data.amount = e.weight
            disposalNode.data.input = {
                value: e.value,
                unit: e.unit,
                id: e.resource,
            }
            disposalNode.data.processNodeId = e.source
            const disposalEdge = {
                data: {
                    id: `${e.source}-${e.target}`,
                    source: e.source,
                    target: e.target,
                },
            }
            edges.push(disposalEdge)
        } else if (source.process.type === TYPES.INVENTORY && target.process.type === TYPES.CUSTOMER) {
            // console.log('delivery edge', e, material)
            const id = makeId([], TYPES.SHIPPING_MATERIAL)
            const deliveryMaterialNode = {
                grabbable: debug,
                data: {
                    id,
                    type: TYPES.SHIPPING_MATERIAL,
                    icon: TYPES.SHIPPING_MATERIAL,
                    // processNodeId: e.source,
                    transportEmissions: sum(e.transport.legs.map(leg => leg.netZero?.emissions?.co2e || 0).concat(e.netZero?.emissions?.co2e || 0)),
                    output: {
                        id: material.id,
                        name: material?.name || 'test',
                        amount: e.weight,
                        unit: 'kg',
                    },
                    // description: downstreamSectionLabeled ? null : 'Downstream section'
                },
            }
            // downstreamSectionLabeled = true
            deliveryMaterialNodes.push(deliveryMaterialNode)
            const outputEdges = [
                {
                    data: {
                        id: `${e.source}-${id}`,
                        source: e.source,
                        target: id,
                    },

                },
                {
                    data: {
                        id: `${id}-${e.target}`,
                        source: id,
                        target: e.target,
                    },
                },
            ]
            edges.push(...outputEdges)
        } else if (source.process.type === TYPES.INVENTORY && target.process.type === TYPES.INVENTORY) {
            edges.push({
                data: {
                    id: `${e.source}-${e.target}`,
                    source: e.source,
                    target: e.target,
                },
            })
        }
    })

    let allInventoryNodes = [mainInventoryNode, ...midInventoryNodes]

    let i = 0
    midInventoryNodes = midInventoryNodes.map((midInventoryNode, k) => {
        let position
        console.log('isComposed', isComposed)
        if (isComposed) {
            const inventoriesEdge = edges.find(e => e.data.target === midInventoryNode.data.id && e.data.source.includes('inv'))
            if (inventoriesEdge) {
                const connectedInventory = allInventoryNodes.find(e => e.data.id === inventoriesEdge.data.source)
                if (connectedInventory) {
                    console.log(midInventoryNode.data.id, 'connected to', connectedInventory.data.id)
                }
                if (connectedInventory && connectedInventory.position) {
                    position = connectedInventory.position
                } else {
                    // const edge = edges.find(e => e.data.source === midInventoryNode.data.id && e.data.target.includes('inv'))
                    // if()
                    // count process nodes
                    position = {
                        x: ((processNodes.length) * 1215), // + 14,
                        y: 20,
                    }
                }
            }
            position = position || calculatePositions[TYPES.MID_INVENTORY](mainInventoryNode, i)
            const outputEdge = edges.find(e => e.data.target === midInventoryNode.data.id && e.data.source.includes('output'))
            if (outputEdge) {
                i++
                console.log('inventory', midInventoryNode.data.id, 'connected to an output', outputEdge)
                // const outputNode = outputNodes.find(o => o.data.id === outputEdge.data.source)
                // position = {
                //     x: outputNode.position
                // }
            }
        } else {
            position = calculatePositions[TYPES.MID_INVENTORY](mainInventoryNode, k)
        }

        return {
            ...midInventoryNode,
            position,
        }
    })
    allInventoryNodes = [mainInventoryNode, ...midInventoryNodes]
    console.log('allInventoryNodes', allInventoryNodes)
    // console.log('materialNodes', materialNodes)
    // console.log('outputNodes', outputNodes)

    let downstreamParent
    let upstreamParent
    let manufacturingProcessParent
    if (deliveryMaterialNodes.length) {
        downstreamParent = {
            data: {
                id: 'downstreamParent',
                type: 'section',
                label: 'Downstream',
            },
            grabbable: debug,
        }
    }
    if (purchaseMaterialNodes.length) {
        upstreamParent = {
            data: {
                id: 'upstreamParent',
                type: 'section',
                label: 'Upstream',
            },
            grabbable: debug,
        }
    }
    if (outputNodes.length) {
        manufacturingProcessParent = {
            data: {
                id: 'manufacturingProcessParent',
                type: 'section',
                label: 'Manufacturing processes',
            },
            grabbable: debug,
        }
    }

    materialNodes = materialNodes.map((e, k) => {
        const processNode = processNodes.find(({ data: { id } }) => id === e.data.processNodeId) || {
            position: {
                x: mainInventoryNode.position.x + 600,
                y: mainInventoryNode.position.y,
            },
        }
        const materialNodesForProcess = materialNodes.filter(({ data: { processNodeId } }) => processNodeId === e.data.processNodeId)
        // console.log(e, materialNodesForProcess)
        e.data.parent = manufacturingProcessParent.data.id
        return {
            ...e,
            position: calculatePositions[TYPES.MATERIAL](processNode, materialNodesForProcess.findIndex(({ data: { id } }) => id === e.data.id), materialNodesForProcess.length, true),
        }
    })

    // console.log('purchaseMaterialNodes', purchaseMaterialNodes)
    purchaseMaterialNodes = purchaseMaterialNodes.map((e, k) => {
        // console.log(supplierNodes, e.data.processNodeId)
        const supplierNode = supplierNodes.find(({ data: { id } }) => id === e.data.processNodeId) || {
            position: {
                x: mainInventoryNode.position.x + 600,
                y: mainInventoryNode.position.y,
            },
        }
        const materialNodesForProcess = purchaseMaterialNodes.filter(({ data: { processNodeId } }) => processNodeId === e.data.processNodeId)
        // console.log(e, materialNodesForProcess)
        // console.log('supplierNode', supplierNode)
        e.data.parent = upstreamParent.data.id
        return {
            ...e,
            position: calculatePositions[TYPES.PURCHASE_MATERIAL](supplierNode, materialNodesForProcess.findIndex(({ data: { id } }) => id === e.data.id), materialNodesForProcess.length, true),
        }
    })

    supplierNodes = supplierNodes.map(e => {
        e.data.parent = upstreamParent.data.id
        return e
    })

    outputNodes = outputNodes.map((e, k) => {
        const processNode = processNodes.find(({ data: { id } }) => id === e.data.processNodeId)
        if (!processNode) {
            console.log(processNodes, e.data)
        }
        const nodesForProcess = outputNodes.filter(({ data: { processNodeId } }) => processNodeId === e.data.processNodeId)
        e.data.parent = manufacturingProcessParent.data.id
        return {
            ...e,
            position: calculatePositions[TYPES.OUTPUT](processNode, nodesForProcess.findIndex(({ data: { id } }) => id === e.data.id), nodesForProcess.length, true),
        }
    })

    deliveryMaterialNodes = deliveryMaterialNodes.map((e, k) => {
        // const processNode = processNodes.find(({ data: { id }}) => id === e.data.processNodeId)
        // if(!processNode){
        //     console.log(processNodes, e.data)
        // }
        const edge = edges.find(edge => edge.data.target === e.data.id)
        const inventory = allInventoryNodes.find(i => i.data.id === edge.data.source)
        e.data.parent = downstreamParent.data.id
        return {
            ...e,
            position: calculatePositions[TYPES.SHIPPING_MATERIAL](inventory, k, deliveryMaterialNodes.length, true),
        }
    })

    combustionNodes = combustionNodes.map((e, k) => {
        const processNode = processNodes.find(({ data: { id } }) => id === e.data.processNodeId)
        console.log('combustion node', { processNode, e, processNodes })
        const nodesForTheSameProcess = energyNodes.concat(combustionNodes).filter(({ data: { processNodeId, type } }) => processNodeId === e.data.processNodeId)
        const myIndex = nodesForTheSameProcess.findIndex(({ data: { id } }) => id === e.data.id)
        e.data.parent = manufacturingProcessParent.data.id
        return {
            ...e,
            position: calculatePositions[TYPES.COMBUSTION](processNode, myIndex),
        }
    })

    disposalNodes = disposalNodes.map((e, k) => {
        const processNode = processNodes.find(({ data: { id } }) => id === e.data.processNodeId)
        const nodesForTheSameProcess = disposalNodes.concat(combustionNodes).filter(({ data: { processNodeId } }) => processNodeId === e.data.processNodeId)
        const myIndex = nodesForTheSameProcess.findIndex(({ data: { id } }) => id === e.data.id)
        e.data.parent = manufacturingProcessParent.data.id
        return {
            ...e,
            position: calculatePositions[TYPES.WASTE](processNode, myIndex),
        }
    })

    energyNodes = energyNodes.map((e, k) => {
        const processNode = processNodes.find(({ data: { id } }) => id === e.data.processNodeId)
        const nodesForProcess = energyNodes.filter(({ data: { processNodeId } }) => processNodeId === e.data.processNodeId)
        // console.log(e)
        e.data.parent = manufacturingProcessParent.data.id
        return {
            ...e,
            position: calculatePositions[e.data.type](processNode, nodesForProcess.findIndex(({ data: { id } }) => id === e.data.id), true),
        }
    })

    customerNodes = customerNodes.map(e => {
        const edge = edges.find(edge => edge.data.target === e.data.id)
        const material = deliveryMaterialNodes.find(i => i.data.id === edge.data.source)
        const materialEdge = edges.find(edge => edge.data.target === material.data.id)
        const inventory = allInventoryNodes.find(i => i.data.id === materialEdge.data.source)
        e.data.parent = downstreamParent.data.id
        return {
            ...e,
            position: calculatePositions[TYPES.CUSTOMER](inventory),
        }
    })

    r.elements.push(mainInventoryNode,
        ...(downstreamParent ? [downstreamParent] : []),
        ...(upstreamParent ? [upstreamParent] : []),
        ...(manufacturingProcessParent ? [manufacturingProcessParent] : []),
        ...supplierNodes,
        ...processNodes,
        ...materialNodes,
        ...outputNodes,
        ...midInventoryNodes,
        ...combustionNodes,
        ...disposalNodes,
        ...customerNodes,
        ...deliveryMaterialNodes,
        ...purchaseMaterialNodes,
        ...energyNodes,
        ...edges,
    )

    console.log('elements', {
        ...([downstreamParent] || []),
        ...([upstreamParent] || []),
        ...([manufacturingProcessParent] || []),
        supplierNodes,
        mainInventoryNode,
        processNodes,
        materialNodes,
        outputNodes,
        midInventoryNodes,
        combustionNodes,
        disposalNodes,
        customerNodes,
        deliveryMaterialNodes,
        purchaseMaterialNodes,
        energyNodes,
        edges,
    })
    return r

    // create the inventory first, then everything else
    // const inventories = pullAt(value.nodes, value.nodes.findIndex(({ process: { type }}) => type === TYPES.INVENTORY))
    // const suppliers = pullAt(value.nodes, value.nodes.findIndex(({ process: { type }}) => type === TYPES.SUPPLIER))
    // let suppliersBag
    // const inventoryNodes = inventories.map((e, k) => {
    //     return generateInventoryNode(null, k, {
    //         id: e.nodeId
    //     })
    // })
    //
    // let nodes = inventoryNodes
    //
    // if(suppliers.length){
    //     suppliersBag = generateSuppliersBag([], inventoryNodes[0].data.id)
    //     nodes = nodes.concat(suppliersBag)
    // }
    //
    // nodes = nodes
    //     .concat(value.nodes.map(e => {
    //         return {
    //             data: {
    //                 id: e.nodeId,
    //                 type: e.process.type,
    //                 process: e.process,
    //                 details: e.details,
    //                 parent: e.process.type === TYPES.SUPPLIER ? suppliersBag[0].data.id : null
    //             },
    //             position: isFunction(calculatePositions[e.process.type]) ? calculatePositions[e.process.type](inventoryNodes[0]) : { x: 0, y: 0}
    //         }
    //     })
    // )
    // const edges = value.edges.map(e => {
    //     return {
    //         data: {
    //             id: `${e.from.processId}-${e.to.processId}`,
    //             // source: e.from.processId,
    //             // target: e.to.processId,
    //             source: e.source,
    //             target: e.target,
    //             resource: e.resource,
    //             transport: e.transport,
    //             value: e.weight,
    //             unit: e.unit || 'kg'
    //         }
    //     }
    // })
    // console.log({ inventories, nodes: value.nodes, suppliersBag, edges })
    // const r = {
    //     id: value.id,
    //     name: value.name,
    //     elements: [...nodes, ...edges]
    // }
    //
    // console.log('result', r)
    // return r
}

export function generateInventoryNode(scope, i = 0, options = { site: {} }) {
    const inventoryId = options?.id || (i === 0 ? 'inventory0' : makeId(scope?.draft?.elements || [], 'inventory'))
    return {
        data: {
            icon: 'warehouse',
            id: inventoryId,
            type: TYPES.INVENTORY,
            accepts: ['asset', 'fuel', TYPES.MATERIAL],
            ...options,
        },
        grabbable: false,
        position: {
            x: 0,
            y: (INVENTORY_BASE_HEIGHT / 2) + (MIN_ROW_HEIGHT * i),
        },
    }
}

export function createMaterialAndSupplierFromDrag(scope, draggedItem, targetNode, { material, origin: supplier, transport }) {
    const inventoryNode = targetNode.data('type') === TYPES.INVENTORY ? targetNode : targetNode.predecessors(`node[type="${TYPES.INVENTORY}"]`)
    let supplierNodeId
    if (supplier) {
        console.log(supplier)
        supplierNodeId = addSupplierToInventory(scope, supplier, transport, inventoryNode)
    }
    addMaterialToInventory(scope, material, inventoryNode, supplierNodeId)
}

export function addMaterialToInventory(scope, material, inventoryNode, supplierNodeId) {
    const draggedNodeId = makeId(scope.draft.elements, `${TYPES.MATERIAL}-inventory`)
    const newNode = []
    newNode.push(
        {
            data: {
                id: draggedNodeId,
                ...material,
                parent: inventoryNode.id(),
            },
            position: inventoryNode.position(),
        },
        {
            data: {
                id: `${supplierNodeId}-${draggedNodeId}`,
                source: supplierNodeId,
                target: draggedNodeId,
            },
        },
    )
    addElement(scope, newNode)
    return draggedNodeId
}

export function addElement(scope, nodeOrEdge) {
    nodeOrEdge = isArray(nodeOrEdge) ? nodeOrEdge : [nodeOrEdge]
    scope.draft = {
        ...scope.draft,
        elements: scope.draft.elements.concat(...nodeOrEdge),
    }
}

export function removeElement(scope, nodeOrEdge) {
    nodeOrEdge = isArray(nodeOrEdge) ? nodeOrEdge : [nodeOrEdge]
    const idsToRemove = nodeOrEdge.map(e => isFunction(e.id) ? e.id() : (e.id || e.data.id))
    scope.draft = {
        ...scope.draft,
        elements: scope.draft.elements.filter(({ data: { id } }) => !idsToRemove.includes(id)),
    }
}

export function updateElementById(scope, searchId, data) {
    const i = scope.draft.elements.findIndex(({ data: { id } }) => id === searchId)
    if (!scope.draft.elements[i]) return
    scope.$set(scope.draft.elements, i, {
        ...scope.draft.elements[i],
        data: {
            ...scope.draft.elements[i].data,
            ...data.data,
        },
        position: {
            ...scope.draft.elements[i].position,
            ...data.position,
        },
    })
}

export function addSupplierToInventory(scope, supplier, transport, inventoryNode) {
    const supplierNodes = inventoryNode.incomers(`node[type="${TYPES.SUPPLIER}"]`)
    const totalRows = Math.min(supplierNodes.length + 1, MAX_SUPPLIERS_ROWS)
    const suppliersBagNode = inventoryNode.incomers(`node[type="${TYPES.SUPPLIERS_BAG}"]`)
    const isSuppliersBagExpanded = !!suppliersBagNode?.data('isExpanded')
    const k = inventoryNode.incomers(`node[type="${TYPES.SUPPLIER}"]`).length
    const maxRows = MAX_SUPPLIERS_ROWS
    const row = Math.ceil(k % maxRows) // max rows = 5
    const col = Math.floor(k / maxRows) // max cols = 1000

    if (isSuppliersBagExpanded) {
        supplierNodes.forEach(supplierNode => {
            const newPosition = calculatePositions[TYPES.SUPPLIER](inventoryNode, supplierNode.data('col'), supplierNode.data('row'), totalRows, isSuppliersBagExpanded)
            supplierNode.animate(
                {
                    position: newPosition,
                    style: {
                        opacity: 1,
                    },
                },
                {
                    duration: 100,
                    complete: () => {
                        updateElementById(scope, supplierNode.id(), {
                            position: newPosition,
                        })
                    },
                },
            )
        })
    }
    const supplierId = supplier.id || makeId(scope.draft.elements, TYPES.SUPPLIER)
    const supplierNode = generateSupplierNode(scope.draft.elements, {
        data: {
            supplier,
            id: supplierId,
            col,
            row,

        },
        position: calculatePositions[TYPES.SUPPLIER](inventoryNode, col, row, totalRows, isSuppliersBagExpanded),
    }, inventoryNode, transport)
    addElement(scope, supplierNode)
    return supplierId
}

export function addOutputToProcess(scope, processNode, params) {
    const isOutputExpanded = !!processNode.data('isOutputExpanded')
    const outputNodes = orderBy(processNode.outgoers(`node[type="${TYPES.OUTPUT}"]`), e => e.position('y'), 'asc')
    if (isOutputExpanded) {
        outputNodes.forEach((outputNode, k) => {
            const newPosition = calculatePositions[TYPES.OUTPUT](processNode, k, outputNodes.length + 1, isOutputExpanded)
            outputNode.animate(
                {
                    position: newPosition,
                },
                {
                    duration: 100,
                    complete: () => {
                        updateElementById(scope, outputNode.id(), {
                            position: newPosition,
                        })
                    },
                },
            )
        })
    }
    const outputId = makeId(scope.draft.elements, TYPES.OUTPUT)
    const payload = { id: outputId, ...params }
    const newElements = [
        {
            data: payload,
            position: calculatePositions[TYPES.OUTPUT](processNode, outputNodes.length, outputNodes.length + 1, isOutputExpanded),
            grabbable: false,
        },
        {
            data: {
                id: `${processNode.id()}-${outputId}`,
                source: processNode.id(),
                target: outputId,
            },
        },
    ]
    const midInventory = getMidInventory(processNode)
    if (!midInventory) {
        const inventoryNode = processNode.incomers(`node[type="${TYPES.INVENTORY}"]`)
        const midInventoryId = makeId(scope.draft.elements, TYPES.MID_INVENTORY)
        newElements.push(
            {
                data: {
                    icon: 'warehouse',
                    id: midInventoryId,
                    type: TYPES.MID_INVENTORY,
                    site: inventoryNode.data('site'),
                },
                position: calculatePositions[TYPES.MID_INVENTORY](processNode),
                grabbable: false,
            },
            {
                data: {
                    id: `${outputId}-${midInventoryId}`,
                    source: outputId,
                    target: midInventoryId,
                },
            },
        )
    } else {
        newElements.push(
            {
                data: {
                    id: `${outputId}-${midInventory.id()}`,
                    source: outputId,
                    target: midInventory.id(),
                },
            },
        )
    }
    addElement(scope, newElements)
}

export function addWaste(scope, processNode, params) {
    const id = makeId(scope.draft.elements, TYPES.WASTE)
    console.log(params)
    addElement(scope, [
        {
            data: {
                id,
                icon: 'waste',
                type: TYPES.WASTE,
                ...params,
            },
            grabbable: false,
            position: calculatePositions[TYPES.WASTE](processNode),
        },
        {
            data: {
                id: `${processNode.id()}-${id}`,
                source: processNode.id(),
                target: id,
            },
        },
    ],
    )
}

export const calculatePositions = {
    // [TYPES.ENERGY_GAS]: (ele, row, rows = 1, expanded = false) => {
    //     const { x: x0, y: y0} = isFunction(ele.position) ? ele.position() : ele.position
    //     return {
    //         x: x0,
    //         y: expanded ? y0 - ((rows / 2) * PROCESS_BASE_HEIGHT * 2.5) + (GENERIC_NODE_HEIGHT * 1.25) + (row * PROCESS_BASE_HEIGHT * 2.5) : y0
    //     }
    // },
    [TYPES.PROCESS]: (ele, k) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        let x = x0 + DISTANCE_BETWEEN_NODES
        // get the process with the right most process element
        if (isFunction(ele.successors)) {
            const processElements = ele.successors(`node[type="${TYPES.PROCESS}"]`)
                .filter(e => e.predecessors(`node[type="${TYPES.INVENTORY}"]`).id() === ele.id()) // getNodesByType(ele.cy(), TYPES.PROCESS)
            if (processElements.length) {
                const rightMostProcessElement = maxBy(processElements, e => e.position('x'))
                x = rightMostProcessElement.position('x') + DISTANCE_BETWEEN_NODES
            }
        }

        const y = y0
        x = (x + 55) + (1200 * k)
        return { x, y }
    },
    [TYPES.CUSTOMER]: ele => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        let x = x0 + DISTANCE_BETWEEN_NODES
        const y = y0
        x = x + 140
        return { x, y }
    },
    [TYPES.OUTPUT]: (ele, row, rows = 1, expanded = false) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0 + (DISTANCE_BETWEEN_NODES / 2) + 46,
            y: expanded ? y0 - ((rows / 2) * PROCESS_BASE_HEIGHT * 2.5) + (GENERIC_NODE_HEIGHT * 1.25) + (row * PROCESS_BASE_HEIGHT * 2.5) : y0,
        }
    },
    [TYPES.PURCHASE_MATERIAL]: (ele, row, rows = 1, expanded = false) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0 + (DISTANCE_BETWEEN_NODES / 2) + 14,
            y: expanded ? y0 - ((rows / 2) * PROCESS_BASE_HEIGHT * 2.5) + (GENERIC_NODE_HEIGHT * 1.25) + (row * PROCESS_BASE_HEIGHT * 2.5) : y0,
        }
    },
    [TYPES.SHIPPING_MATERIAL]: (ele, row, rows = 1, expanded = false) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0 + (DISTANCE_BETWEEN_NODES / 2) + 40,
            y: expanded ? y0 - ((rows / 2) * PROCESS_BASE_HEIGHT * 2.5) + (GENERIC_NODE_HEIGHT * 1.25) + (row * PROCESS_BASE_HEIGHT * 2.5) : y0,
        }
    },
    [TYPES.MID_INVENTORY]: (ele, k) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0 + ((DISTANCE_BETWEEN_NODES + (INVENTORY_BASE_WIDTH / 2) + 600) * (k + 1)),
            y: y0,
        }
    },
    [TYPES.WASTE]: (ele, row) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return { x: x0, y: y0 + 120 + (120 * row) }
    },
    [TYPES.SHIPPING]: (ele, row, expanded = false) => {
        return {
            x: ele.position('x') + 64,
            y: expanded ? (ele.bb().y2 + (GENERIC_NODE_HEIGHT * 1.5)) + (row * GENERIC_NODE_HEIGHT * 1.5) : (ele.bb().y2 + (GENERIC_NODE_HEIGHT * 1.5)),
        }
    },
    [TYPES.MATERIALS_BAG]: ele => {
        const bb = ele.bb({ includeUnderlays: false })
        return {
            x: bb.x2 + 20,
            y: bb.y1 - 78,
        }
    },
    [TYPES.SUPPLIER]: (ele, col, row, maxRows = MAX_SUPPLIERS_ROWS, expanded = false) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        const colWidth = DISTANCE_BETWEEN_NODES / 2
        // const rowHeight = INVENTORY_BASE_HEIGHT * 2

        return {
            x: (x0 - (INVENTORY_BASE_WIDTH / 2)) - (((expanded ? col : 0) + 1) * colWidth) - 200,
            y: expanded ? y0 + (row * GENERIC_NODE_HEIGHT * 1.5) : y0,
            // y: expanded ? ele.position('y') - ((maxRows / 2) * rowHeight) + (rowHeight / 2) + (row * rowHeight) :  ele.position('y')
        }
    },
    [TYPES.MATERIAL]: (ele, row, rows = 1, expanded = false) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0 - (DISTANCE_BETWEEN_NODES / 2) - 10,
            y: expanded ? y0 - ((rows / 2) * PROCESS_BASE_HEIGHT * 2.5) + (GENERIC_NODE_HEIGHT * 1.25) + (row * PROCESS_BASE_HEIGHT * 2.5) : y0,
        }
    },
    [TYPES.ENERGY_POWER]: (ele, row = 0, expanded = true) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0,
            y: y0 - (PROCESS_BASE_HEIGHT * 2.5) - (row * PROCESS_BASE_HEIGHT * 2.5),
        }
    },
    [TYPES.ENERGY_GAS]: (ele, row = 0, expanded = true) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0,
            y: y0 - (PROCESS_BASE_HEIGHT * 2.5) - (row * PROCESS_BASE_HEIGHT * 2.5),
        }
    },
    [TYPES.COMBUSTION]: (ele, row = 0) => {
        const { x: x0, y: y0 } = isFunction(ele.position) ? ele.position() : ele.position
        return {
            x: x0,
            y: y0 - (PROCESS_BASE_HEIGHT * 2.5) - (row * PROCESS_BASE_HEIGHT * 2.5),
        }
    },
}

export function generateSuppliersBag(elements = [], inventoryId) {
    const suppliersBagId = makeId(elements, TYPES.SUPPLIERS_BAG)
    return [
        {
            data: {
                id: suppliersBagId,
                type: TYPES.SUPPLIERS_BAG,
                grabbable: false,
                isExpanded: true,
            },
        },
        {
            data: {
                id: `${suppliersBagId}-${inventoryId}`,
                source: suppliersBagId,
                target: inventoryId,
            },
        },
    ]
}

export function generateSupplierNode(elements, options, target, transport = {}) {
    const id = options.data?.id || makeId(elements || [], TYPES.SUPPLIER)
    // check if there's already a supplier bag compound
    let suppliersBagId = isFunction(target.incomers) ? target.incomers(`node[type="${TYPES.SUPPLIERS_BAG}"]`)?.id() : null
    const newNodes = []
    const targetId = isFunction(target.id) ? target.id() : target.data.id
    if (!suppliersBagId) {
        const supplierBag = generateSuppliersBag(elements, targetId)
        suppliersBagId = supplierBag[0].data.id
        newNodes.push(...supplierBag)
    }
    newNodes.push(
        {
            data: {
                id,
                icon: 'industry',
                type: TYPES.SUPPLIER,
                parent: suppliersBagId,
                ...options.data,
            },
            position: options.position,
            grabbable: false,
        },
        {
            data: {
                id: `${id}-${targetId}`,
                source: id,
                target: targetId,
                transport,
            },
        },
    )
    return newNodes
}

export function getMidInventory(ele) {
    let processNode
    if (ele.data('type') === TYPES.PROCESS) {
        processNode = ele
    } else if (ele.data('type') === TYPES.OUTPUT) {
        processNode = ele.incomers(`node[type="${TYPES.PROCESS}"]`)
    } else {
        console.warn('getMidInventory accepts only process or output as argument')
        return
    }
    const midInventory = processNode?.outgoers(`node[type="${TYPES.OUTPUT}"]`)?.outgoers(`node[type="${TYPES.MID_INVENTORY}"]`)
    return midInventory?.length ? midInventory : null
}

export function allProcesses(cy) {
    return getNodesByType(cy, TYPES.PROCESS)
}

export function getNodesByType(cy, type) {
    type = isArray(type) ? type : [type]
    return cy.nodes(type.map(t => `[type="${t}"]`).join(''))
}

export function getLabelForElement(ele) {
    let parentNode
    let emissions
    if (ele.isEdge()) {
        if (ele.source().data('type') === TYPES.PROCESS && ele.target().data('type') === TYPES.OUTPUT) {
            return `${ele.target().data('output').amount} ${ele.target().data('output').unit}`
        } else if (ele.data('input') && ele.source().data('type') === TYPES.OUTPUT && ele.target().data('type') === TYPES.PROCESS) {
            return `${ele.data('input').amount} ${ele.data('input').unit}`
        }
    } else {
        switch (ele.data('type')) {
            case TYPES.INVENTORY:
                // console.log('inventory', ele.data())
                // return `${ele.id()}\n` +
                return `Inventory\n${truncate(ele.data('site')?.name || '', { length: 24, omission: '...' })}`
            case TYPES.PROCESS:
            case TYPES.MACHINE: {
                const emissionsFromPrevNodes = ele.incomers('node').map(e => {
                    return e.data('netZero')?.emissions?.co2e || 0
                })
                emissions = sum(emissionsFromPrevNodes)
                return `${truncate(ele.data('name') || 'Process', { length: 32, omission: '...' })}\n` +
                    `${n(emissions, 2)} kgCO₂e`
            }
            case TYPES.OUTPUT: {
                parentNode = ele.incomers(`node[type="${TYPES.PROCESS}"]`)
                    .union(ele.incomers(`node[type="${TYPES.SUPPLIER}"]`))
                    .union(ele.incomers(`node[type="${TYPES.MACHINE}"]`))
                const outputNodes = parentNode.outgoers(`node[type="${TYPES.OUTPUT}"]`)
                if (parentNode.data('isOutputExpanded') || outputNodes.length === 1) {
                    return `${truncate(ele.data('output').name, { length: 36, omission: '...' })}\n` +
                        `${n(ele.data('output').amount, ele.data('output').amount < 100 ? 2 : 0)} ${ele.data('output').unit}\n` +
                        (ele.data('netZero')?.emissions ? `${n(ele.data('netZero').emissions.co2e, 2)} kgCO₂e` : '')
                } else {
                    return `${outputNodes.length} outputs`
                }
            }
            case TYPES.PURCHASE_MATERIAL:
                return `${truncate(ele.data('output').name, { length: 36, omission: '...' })}\n` +
                    `${n(ele.data('output').amount, ele.data('output').amount < 100 ? 2 : 0)} ${ele.data('output').unit}\n` +
                    `${n(ele.data('transportEmissions'), 2)} kgCO₂e`

            case TYPES.WASTE:
                // console.log('WASTE label', ele.data())
                return `${(ele.data('name') || 'Waste')}\n${ele.data('amount')}${ele.data('unit')}` +
                    (ele.data('netZero')?.emissions ? `\n${n(ele.data('netZero').emissions.co2e, 2)} kgCO₂e` : '')
            case TYPES.SHIPPING:
                if (ele.parent().children().length <= 1) {
                    return `${ele.data('output').name}\n${n(ele.data('output').amount, 0)} ${ele.data('output').unit}`
                } else {
                    return `${ele.parent().children().length} shipping nodes`
                }
            case TYPES.CUSTOMER:
                return `${truncate(ele.data('customer').name, { length: 32, omission: '...' })}\n${truncate(ele.data('customer').address, { length: 32, omission: '...' })}`
            case TYPES.SUPPLIER:
                // console.log('supplier', ele.data())
                return `${ele.data('supplier')?.name}\n${truncate(ele.data('supplier')?.address, { length: 26, omission: '...' })}` || ''
            case TYPES.ENERGY_POWER:
                return `Power\n${n(ele.data('output').amount, 2)} ${ele.data('output').unit}\n${n(ele.data('netZero')?.emissions?.co2e, 2)} kgCO₂e`
            case TYPES.ENERGY_GAS:
                return `Gas\n${n(ele.data('output').amount, 2)} ${ele.data('output').unit}\n${n(ele.data('netZero')?.emissions?.co2e, 2)} kgCO₂e`
            case TYPES.COMBUSTION:
                // console.log('combustion label', ele.data())
                return `${ele.data('name')}\n${n(ele.data('amount'), 0)} ${ele.data('unit')}\n${n(ele.data('netZero')?.emissions?.co2e, 2)} kgCO₂e`
            case TYPES.MATERIALS_BAG:
                return `Materials (${ele.children().length})`
            case TYPES.MATERIAL: {
                // console.log('material label', ele.data())
                parentNode = ele.outgoers(`node[type="${TYPES.PROCESS}"]`)
                const materialNodes = parentNode.incomers(`node[type="${TYPES.MATERIAL}"]`)
                if (!parentNode.length || parentNode.data('isMaterialExpanded') || materialNodes.length === 1) {
                    return `${truncate(ele.data('material')?.name || ele.data('output')?.name, { length: 36, omission: '...' })}\n` +
                        `${n(ele.data('output').amount, ele.data('output').amount < 100 ? 2 : 0)} ${ele.data('output').unit}\n` +
                        (ele.data('netZero')?.emissions ? `${n(ele.data('netZero').emissions.co2e, 2)} kgCO₂e` : '')
                } else {
                    return `${materialNodes.length} materials`
                }
            }
            case TYPES.MID_INVENTORY:
                return `Inventory\n${truncate(ele.data('site')?.name || '', { length: 24, omission: '...' })}`
            case TYPES.SUPPLIERS_BAG:
                return ele.children().length <= 1 ? '' : `${ele.children().length} suppliers`
            case TYPES.SHIPPING_BAG:
                return ''
            case TYPES.SHIPPING_MATERIAL:
                // console.log('SHIPPING_MATERIAL', ele.data())
                return `${truncate(ele.data('output').name, { length: 36, omission: '...' })}\n` +
                    `${n(ele.data('output').amount, 0)} ${ele.data('output').unit}\n` +
                    `${n(ele.data('transportEmissions'), 2)} kgCO₂e`
            case 'section':
                return ele.data('label')
        }
    }
    return ''
}

export function isNode(ele) {
    return isFunction(ele.isNode) && ele.isNode()
}

export function isEdge(ele) {
    return isFunction(ele.isEdge) && ele.isEdge()
}

export function openInventory(scope, ele) {
    const { w, h, x1, y1 } = ele.renderedBoundingBox({
        includeUnderlays: false,
        includeOverlays: false,
        includeLabels: false,
        includeMainLabels: false,
    })
    scope.bag = {
        id: ele.id(),
        width: 400,
        height: 400,
        initialPan: { ...ele.cy().pan() },
        position: {
            x: Math.max(10, x1 + 8 + (w / 2)),
            y: Math.max(250, y1 + 8 + (h / 2)),
        },
    }
}

export function updateMaterialsForProcess(scope, processNode, { materials }, { inputs } = {}) {
    inputs = inputs || {}
    const oldMaterials = processNode.incomers(`node[type="${TYPES.MATERIAL}"]`)
    removeNode(scope, oldMaterials)
    const inventoryNode = processNode.incomers(`node[type="${TYPES.INVENTORY}"]`).union(processNode.incomers(`node[type="${TYPES.MID_INVENTORY}"]`))
    const isMaterialExpanded = !!processNode.data('isMaterialExpanded')
    let newNodes = Object.entries(materials).concat(Object.entries(inputs)).filter(([_, amount]) => Number(amount) > 0)
    newNodes = newNodes.map(([materialNodeId, amount], k) => {
        const newMaterialNodeId = makeId(scope.draft.elements, TYPES.MATERIAL)
        const originalMaterialNode = scope.cy.$(`#${materialNodeId}`)
        return [
            {
                data: {
                    ...originalMaterialNode.data(),
                    id: newMaterialNodeId,
                    icon: 'material',
                    type: TYPES.MATERIAL,
                    sourceMaterialId: materialNodeId,
                    output: {
                        ...originalMaterialNode.data('output'),
                        amount,
                    },
                    parent: null,
                },
                grabbable: false,
                position: calculatePositions[TYPES.MATERIAL](processNode, k, newNodes.length, isMaterialExpanded),
            },
            {
                data: {
                    id: `${inventoryNode.id()}-${newMaterialNodeId}`,
                    source: inventoryNode.id(),
                    target: newMaterialNodeId,
                },
            },
            {
                data: {
                    id: `${newMaterialNodeId}-${processNode.id()}`,
                    source: newMaterialNodeId,
                    target: processNode.id(),
                },
            },
        ]
    }).flat()
    // console.log(newNodes)
    addElement(scope, newNodes)
}

export function updateEnergyForProcess(scope, processNode, { power, gas }) {
    console.log({ power, gas })
    const powerId = makeId(scope.draft.elements, TYPES.ENERGY_POWER)
    const gasId = makeId(scope.draft.elements, TYPES.ENERGY_GAS)
    const combustionNodes = scope.cy.nodes(`[type="${TYPES.COMBUSTION}"]`)
    addElement(scope, [
        {
            data: {
                id: powerId,
                draft: true,
                icon: TYPES.ENERGY_POWER,
                type: TYPES.ENERGY_POWER,
                energy: power,
            },
            position: calculatePositions[TYPES.ENERGY_POWER](processNode, combustionNodes.length),
        },
        {
            data: {
                id: `${powerId}-${processNode.id()}`,
                draft: true,
                source: powerId,
                target: processNode.id(),
                arrow: 'triangle',
            },
        },
        {
            data: {
                id: gasId,
                draft: true,
                icon: TYPES.ENERGY_GAS,
                type: TYPES.ENERGY_GAS,
                energy: gas,
            },
            position: calculatePositions[TYPES.ENERGY_GAS](processNode, combustionNodes.length + 1),
        },
        {
            data: {
                id: `${gasId}-${processNode.id()}`,
                draft: true,
                source: gasId,
                target: processNode.id(),
                arrow: 'triangle',
            },
        },
    ])
}

export function removeNode(scope, eles) {
    if (!eles || !eles.length) return
    const toRemove = eles.map(ele => scope.draft.elements
        .filter(({ data: { id, target, source, parent } }) =>
            id === ele.id() ||
            source === ele.id() ||
            target === ele.id() ||
            parent === ele.id(),
        ),
    ).flat()
    removeElement(scope, toRemove)
}

export function toggleSuppliersBag(scope, suppliersBagNode) {
    const isExpanded = !!suppliersBagNode.data('isExpanded')
    const supplierNodes = suppliersBagNode.children()
    if (supplierNodes.length === 1) return
    updateElementById(scope, suppliersBagNode.id(), {
        data: {
            isExpanded: !isExpanded,
        },
    })
    const inventoryNode = suppliersBagNode.outgoers(`node[type="${TYPES.INVENTORY}"]`)
    const totalRows = Math.min(supplierNodes.length, MAX_SUPPLIERS_ROWS)
    // const buttonNode = scope.cy.nodes(`[linkedTo="${suppliersBagNode.id()}"]`)
    // const newPosition = {
    //     x: buttonNode.position('x'),
    //     y: suppliersBagNode.position('y') - ((!isExpanded ? totalRows : 1) * GENERIC_NODE_HEIGHT) + 5
    // }
    // buttonNode.animate(
    //     {
    //         position: newPosition
    //     },
    //     {
    //         duration: 100,
    //         complete: () => {
    //             updateElementById(scope, buttonNode.id(), {
    //                 position: newPosition
    //             })
    //         }
    //     }
    // )
    supplierNodes.forEach(supplierNode => {
        const newPosition = calculatePositions[TYPES.SUPPLIER](inventoryNode, supplierNode.data('col'), supplierNode.data('row'), totalRows, !isExpanded)
        supplierNode.animate(
            {
                position: newPosition,
                style: {
                    opacity: isExpanded ? 0 : 1,
                },
            },
            {
                duration: 100,
                complete: () => {
                    updateElementById(scope, supplierNode.id(), {
                        position: newPosition,
                    })
                },
            },
        )
    })
}

export function toggleMaterials(scope, ele) {
    const processNode = ele.outgoers(`node[type="${TYPES.PROCESS}"]`).union(ele.outgoers(`node[type="${TYPES.MACHINE}"]`))
    const materialNodes = orderBy(processNode.incomers(`node[type="${TYPES.MATERIAL}"]`), e => e.position('y'), 'asc')
    if (materialNodes.length === 1) return
    const isMaterialExpanded = !!processNode.data('isMaterialExpanded')
    updateElementById(scope, processNode.id(), {
        data: {
            isMaterialExpanded: !isMaterialExpanded,
        },
    })
    materialNodes.forEach((materialNode, k) => {
        const newPosition = calculatePositions[TYPES.MATERIAL](processNode, k, materialNodes.length, !isMaterialExpanded)
        materialNode.animate(
            {
                position: newPosition,
            },
            {
                duration: 100,
                complete: () => {
                    updateElementById(scope, materialNode.id(), {
                        position: newPosition,
                    })
                },
            },
        )
    })
}

export function openShippingBag(scope, shippingBagNode) {
    const { w, h, x1, y1 } = shippingBagNode.renderedBoundingBox({
        includeUnderlays: false,
        includeOverlays: false,
        includeLabels: false,
        includeMainLabels: false,
    })
    scope.shippingBag = {
        id: shippingBagNode.id(),
        width: 800,
        height: 800,
        initialPan: { ...scope.cy.pan() },
        inventory: shippingBagNode.incomers(`node[type="${TYPES.INVENTORY}"]`).union(shippingBagNode.incomers(`node[type="${TYPES.MID_INVENTORY}"]`)).data(),
        position: {
            x: Math.max(10, x1 + 8 + (w / 2)),
            y: Math.max(400, y1 + 8 + (h / 2)),
        },
    }
    // if(shippingNodes.length === 1) return
    // const inventoryNode = shippingBagNode.incomers(`node[type="${TYPES.INVENTORY}"]`).union(shippingBagNode.incomers(`node[type="${TYPES.MID_INVENTORY}"]`))
    // const isExpanded = !!shippingBagNode.data('isExpanded')
    // updateElementById(scope, shippingBagNode.id(), {
    //     data: {
    //         isExpanded: !isExpanded
    //     }
    // })
    // shippingNodes.forEach((shippingNode, k) => {
    //     const newPosition = calculatePositions[TYPES.SHIPPING](inventoryNode, k, !isExpanded)
    //     shippingNode.animate(
    //         {
    //             position: newPosition
    //         },
    //         {
    //             duration: 100,
    //             complete: () => {
    //                 updateElementById(scope, shippingNode.id(), {
    //                     position: newPosition,
    //                 })
    //             }
    //         }
    //     )
    // })
}

export function toggleOutputs(scope, ele) {
    const processNode = ele.incomers(`node[type="${TYPES.PROCESS}"]`).union(ele.incomers(`node[type="${TYPES.MACHINE}"]`))
    const outputNodes = orderBy(processNode.outgoers(`node[type="${TYPES.OUTPUT}"]`), e => e.position('y'), 'asc')
    if (outputNodes.length === 1) return
    const isOutputExpanded = !!processNode.data('isOutputExpanded')
    updateElementById(scope, processNode.id(), {
        data: {
            isOutputExpanded: !isOutputExpanded,
        },
    })
    outputNodes.forEach((outputNode, k) => {
        const newPosition = calculatePositions[TYPES.OUTPUT](processNode, k, outputNodes.length, !isOutputExpanded)
        outputNode.animate(
            {
                position: newPosition,
            },
            {
                duration: 100,
                complete: () => {
                    updateElementById(scope, outputNode.id(), {
                        position: newPosition,
                    })
                },
            },
        )
    })
}

// eslint-disable-next-line no-unused-vars
function updateButtons(scope) {
    // add buttons to toggle bags
    const bagNodes = getNodesByType(scope.cy, TYPES.SUPPLIERS_BAG).union(getNodesByType(scope.cy, TYPES.SHIPPING_BAG))
    // console.log(bagNodes)
    bagNodes.forEach(bagNode => {
        const action = `toggle${bagNode.data('type') === TYPES.SUPPLIERS_BAG ? 'Suppliers' : 'Shipping'}Bag`
        const toggleBtn = scope.cy.nodes(`[type="${TYPES.BUTTON}"][linkedTo="${bagNode.id()}"][action="${action}"]`)
        if (!toggleBtn?.length && bagNode.children().length > 1) {
            addElement(scope, {
                data: {
                    id: makeId(scope.draft.elements, TYPES.BUTTON),
                    linkedTo: bagNode.id(),
                    type: TYPES.BUTTON,
                    action,
                    icon: 'caretDown',
                },
                grabbable: false,
                position: {
                    x: bagNode.bb().x2 - 3,
                    y: bagNode.bb().y1 + 3,
                },
            })
        } else if (toggleBtn?.length && bagNode.children().length <= 1) {
            removeNode(scope, toggleBtn)
        }
    })
}

export function cy2obj(ele) {
    if (isNode(ele)) {
        let process = {
            name: ele.data('name'), // getLabelForElement(ele),
            type: ele.data('type'),
        }
        let details = {
            id: ele.id(),
            meta: {
                position: ele.position(),
            },
        }

        switch (ele.data('type')) {
            case TYPES.SUPPLIER:
                // console.log('supplier node', ele.data())
                process = {
                    ...process,
                    categoryId: 'distribution',
                    name: ele.data('supplier').name,
                    type: TYPES.SUPPLIER,
                    inputs: [],
                    outputs: ele.outgoers(`node[type="${TYPES.PURCHASE_MATERIAL}"]`).map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                }
                details = {
                    ...details,
                    schedule: ele.data('schedule'),
                    location: {
                        siteId: ele.data('supplier').id,
                        latitude: ele.data('supplier').location.lat,
                        longitude: ele.data('supplier').location.lng,
                        type: 'third-party',
                    },
                }
                break
            case TYPES.CUSTOMER:
                process = {
                    ...process,
                    id: ele.data('processId'),
                    categoryId: 'distribution',
                    name: ele.data('customer').name,
                    outputs: [],
                    inputs: ele.incomers(`node[type="${TYPES.SHIPPING_MATERIAL}"]`).map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                }
                details = {
                    ...details,
                    schedule: ele.data('schedule'),
                    location: {
                        siteId: ele.data('customer').id,
                        latitude: ele.data('customer').location.lat,
                        longitude: ele.data('customer').location.lng,
                        type: 'third-party',
                    },
                }
                break
            case TYPES.INVENTORY:
                process = {
                    ...process,
                    id: ele.data('processId'),
                    name: 'Inventory',
                    categoryId: 'distribution',
                    inputs: ele.incomers(`node[type="${TYPES.MATERIAL}"]`)
                        .union(ele.incomers(`node[type="${TYPES.PURCHASE_MATERIAL}"]`))
                        .union(ele.incomers(`node[type="${TYPES.OUTPUT}"]`))
                        .map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                    outputs: ele.outgoers(`node[type="${TYPES.MATERIAL}"]`)
                        .union(ele.outgoers(`node[type="${TYPES.SHIPPING_MATERIAL}"]`))
                        .map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                }
                details = {
                    ...details,
                    schedule: {},
                    location: {
                        siteId: ele.data('site')?.id,
                        latitude: ele.data('site')?.business?.location?.lat,
                        longitude: ele.data('site')?.business?.location?.lng,
                        business: ele.data('site')?.business,
                    },
                }
                break
            case TYPES.MID_INVENTORY:
                process = {
                    ...process,
                    name: 'Mid-Inventory',
                    type: 'inventory',
                    categoryId: 'distribution',
                    inputs: ele.incomers(`node[type="${TYPES.OUTPUT}"]`).map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                    outputs: ele.outgoers(`node[type="${TYPES.MATERIAL}"]`).map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                }
                details = {
                    ...details,
                    location: {
                        siteId: ele.data('site')?.id,
                        latitude: ele.data('site')?.business?.location?.lat,
                        longitude: ele.data('site')?.business?.location?.lng,
                        business: ele.data('site')?.business,
                    },
                }
                break
            case TYPES.SHIPPING:
                // console.log(ele.data())
                process = {
                    ...process,
                }
                details = {
                    ...details,
                }
                break
            case TYPES.COMBUSTION:
                // console.log(ele.data())
                process = {
                    ...process,
                    netZero: ele.data('netZero'),
                    categoryId: ele.data('categoryId'),
                    outputs: [{ material: { id: ele.data('output').id }, unit: ele.data('output').unit }],
                    inputs: [{ material: { id: ele.data('input').id }, unit: ele.data('input').unit }],
                }
                details = {
                    ...details,
                    schedule: ele.data('schedule'),
                    location: ele.data('site'),
                }
                break
            case TYPES.PROCESS:
            case TYPES.MACHINE:
                // console.log('process-machine', ele.data())
                process = {
                    ...process,
                    categoryId: ele.data('categoryId'),
                    outputs: ele.outgoers(`node[type="${TYPES.OUTPUT}"]`).map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                    inputs: ele.incomers(`node[type="${TYPES.MATERIAL}"]`).map(e => ({ material: { id: e.data('output').id }, unit: e.data('output').unit })),
                }
                details = {
                    ...details,
                    location: ele.data('site'),
                }
                break
            // case TYPES.OUTPUT:
            //     process = {
            //         ...process,
            //         output: {
            //             name: ele.data('output').name,
            //             unit: ele.data('output').unit,
            //         }
            //     }
            //     break
            case TYPES.ENERGY_GAS:
            case TYPES.ENERGY_POWER:
                // console.log('energy node2obj', ele.data())
                process = {
                    ...process,
                    id: ele.data('processId'),
                    categoryId: ele.data('categoryId'),
                    netZero: ele.data('netZero'),
                    outputs: [
                        {
                            material: {
                                id: ele.data('output').id,
                            },
                            unit: ele.data('output').unit,
                        },
                    ],
                }
                details = {
                    ...details,
                    schedule: ele.data('schedule'),
                    entities: ele.data('entities'),
                    location: ele.data('location'),
                }
                break
            case TYPES.WASTE:
                // console.log('waste', ele.data())
                // return false
                process = {
                    id: ele.data('disposalProcessId'),
                    categoryId: TYPES.WASTE,
                    name: ele.data('name'),
                    outputs: [],
                    type: TYPES.WASTE,
                    inputs: [{ material: { id: ele.data('input').id } }],
                    netZero: ele.data('netZero'),
                }
                details = {
                    ...details,
                    location: ele.data('site'),
                }
                break
            default:
                return false
        }
        return {
            nodeId: ele.id(),
            process,
            details,
        }
    } else if (isEdge(ele)) {
        const sourceNode = ele.source()
        const targetNode = ele.target()
        const sourceType = sourceNode.data('type')
        const targetType = targetNode.data('type')
        let options = {
            from: {
                processId: ele.data('source'),
            },
            to: {
                processId: ele.data('target'),
            },
        }
        switch (true) {
            case sourceType === TYPES.PROCESS && targetType === TYPES.OUTPUT: {
                const outputNode = ele.target()
                // console.log('material outputNode', outputNode.data())
                options = {
                    ...options,
                    to: {
                        processId: outputNode.outgoers(`node[type="${TYPES.MID_INVENTORY}"]`).id(),
                    },
                    resource: outputNode.data('output').id,
                    unit: outputNode.data('output').unit,
                    value: outputNode.data('output').value || outputNode.data('output').amount,
                }
                break
            }
            case sourceType === TYPES.PROCESS && targetType === TYPES.WASTE: {
                // console.log('waste edge', ele.data(), ele.target())
                const wasteNode = ele.target()
                // console.log('material outputNode', outputNode.data())
                options = {
                    ...options,
                    // to: {
                    //     processId: wasteNode.outgoers(`node[type="${TYPES.MID_INVENTORY}"]`).id(),
                    // },
                    resource: wasteNode.data('input').id,
                    unit: wasteNode.data('input').unit,
                    value: wasteNode.data('input').value || wasteNode.data('input').amount,
                }
                break
            }
            case sourceType === TYPES.MATERIAL && targetType === TYPES.PROCESS:
                // console.log('material source node', sourceNode.data())
                options = {
                    ...options,
                    from: {
                        processId: sourceNode
                            .incomers(`node[type="${TYPES.INVENTORY}"]`)
                            .union(sourceNode.incomers(`node[type="${TYPES.MID_INVENTORY}"]`)).id(),
                    },
                    resource: sourceNode.data('output').id,
                    unit: sourceNode.data('output').unit,
                    value: sourceNode.data('output').value || sourceNode.data('output').amount,
                }
                break
            case sourceType === TYPES.SUPPLIER && targetType === TYPES.PURCHASE_MATERIAL: {
                // console.log('purchase edge', {
                //     sourceNode: sourceNode.data(),
                //     targetNode: targetNode.data(),
                //     inv: targetNode.outgoers('node').data()
                // })
                const mainInventory = targetNode.outgoers(`node[type="${TYPES.INVENTORY}"]`)
                // console.log(mainInventory)
                options = {
                    ...options,
                    to: {
                        processId: mainInventory.id(),
                    },
                    resource: targetNode.data('output').id,
                    unit: targetNode.data('output').unit,
                    value: targetNode.data('output').value || targetNode.data('output').amount,
                    // transport: {
                    //     legs: transportEdge.data('transport').legs.map(leg => {
                    //         console.log(leg)
                    //         return {
                    //             netZero: {
                    //                 family: {
                    //                     id: leg.type,
                    //                     owner: 'first-party',
                    //                     attributes: {
                    //                         siteId: '193'
                    //                     }
                    //                 },
                    //             },
                    //             modality: leg.type,
                    //             path: leg.coordinates.map(([latitude, longitude]) => ({ latitude, longitude })),
                    //             distanceKm: leg.stats.distance.value,
                    //             durationSeconds: leg.stats.time.value
                    //         }
                    //     })
                    // }
                }
                break
            }
            case sourceType === TYPES.SHIPPING_MATERIAL && targetType === TYPES.CUSTOMER:
                // console.log('shipping material', ele.data())
                options = {
                    ...options,
                    from: {
                        processId: sourceNode.incomers(`node[type="${TYPES.INVENTORY}"]`).id(),
                    },
                    resource: sourceNode.data('output').id,
                    unit: sourceNode.data('output').unit,
                    value: sourceNode.data('output').value || sourceNode.data('output').amount,
                }
                break
            case sourceType === TYPES.MATERIAL && targetType === TYPES.INVENTORY:
                options = {
                    ...options,
                    resource: sourceNode.data('output').name,
                    unit: sourceNode.data('output').unit,
                    value: sourceNode.data('output').value || sourceNode.data('output').amount,
                }
                break
            // case sourceType === TYPES.SUPPLIER && targetType === TYPES.INVENTORY:
            //     options = {
            //         ...options,
            //         ...ele.data()
            //     }
            //     break
            case sourceType === TYPES.COMBUSTION && targetType === TYPES.PROCESS:
                // console.log(ele.data(), sourceNode.data())
                options = {
                    ...options,
                    resource: sourceNode.data('output').id,
                    unit: sourceNode.data('output').unit,
                    value: sourceNode.data('amount') || sourceNode.data('value'),
                }
                break
            case sourceType === TYPES.INVENTORY && (targetType === TYPES.COMBUSTION || targetType === TYPES.ENERGY_COMBUSTION):
            case sourceType === TYPES.MID_INVENTORY && (targetType === TYPES.COMBUSTION || targetType === TYPES.ENERGY_COMBUSTION):
                // console.log('moses', ele.data())
                options = {
                    ...options,
                    ...ele.data(),
                }
                break
            case sourceType === TYPES.ENERGY_POWER && targetType === TYPES.PROCESS:
            case sourceType === TYPES.ENERGY_GAS && targetType === TYPES.PROCESS:
                // console.log('energy', ele.data(), sourceNode.data())
                options = {
                    ...options,
                    resource: sourceNode.data('output').id,
                    unit: sourceNode.data('output').unit,
                    value: sourceNode.data('output').amount || sourceNode.data('output').value,
                }
                break
            default:
                return false
        }
        return options
    }
}

export function makeEdge(source, target) {
    source = isString(source) ? source : (isFunction(source.id) ? source.id() : source.data.id)
    target = isString(target) ? target : (isFunction(target.id) ? target.id() : target.data.id)
    return {
        data: {
            id: `${source}-${target}`,
            source,
            target,
        },
    }
}

export function addShippingBag(scope, ele) {
    const shippingBagId = makeId(scope.draft.elements, TYPES.SHIPPING_BAG)
    addElement(scope, [
        {
            data: {
                id: shippingBagId,
                type: TYPES.SHIPPING_BAG,
            },
        },
        makeEdge(ele, shippingBagId),
    ])
    return shippingBagId
}

export function addShippingNodes(scope, ele, options) {
    const stuff = assign(...options)
    const { type: mode, materials, location, site, destination, legs, distance, transportType, vehicle, emissions, time, origin } = stuff
    const newNodes = []
    const route = {
        destination, legs, distance, transportType, vehicle, emissions, time, origin,
    }
    if (mode === 'self') { // ship to another warehouse
        const newInventory = generateInventoryNode(scope, 1, { site })
        newNodes.push(
            newInventory,
            {
                data: {
                    id: `${ele.id()}-${newInventory.data.id}`,
                    source: ele.id(),
                    target: newInventory.data.id,
                },
            },
        )
        Object.entries(materials).forEach(([nodeId, amount], k) => {
            const materialNode = scope.cy.$(`#${nodeId}`)
            const newId = makeId(scope.draft.elements, TYPES.MATERIAL)
            newNodes.push(
                {
                    data: {
                        parent: newInventory.data.id,
                        id: newId,
                        type: TYPES.MATERIAL,
                        materialNodeId: materialNode.id(),
                        output: {
                            ...materialNode.data('output'),
                            amount,
                            name: materialNode.data('material').name,
                        },
                    },
                    position: newInventory.position,
                    grabbable: false,
                },
            )
        })
    } else { // ship to 3rd party/customer
        // check if there's a shipping bag
        let shippingBagId = ele.outgoers(`node[type="${TYPES.SHIPPING_BAG}"]`)?.id()
        if (!shippingBagId) {
            shippingBagId = addShippingBag(scope, ele)
        }
        Object.entries(materials).forEach(([nodeId, amount], k) => {
            const materialNode = scope.cy.$(`#${nodeId}`)
            const inventoryNode = ele
            const shippingNodeId = makeId(scope.draft.elements, TYPES.SHIPPING)
            newNodes.push(
                {
                    data: {
                        parent: shippingBagId,
                        icon: 'shipping',
                        id: shippingNodeId,
                        type: TYPES.SHIPPING,
                        materialNodeId: materialNode.id(),
                        // material: materialNode.data(),
                        output: {
                            ...materialNode.data('output'),
                            amount,
                            name: materialNode.data('material')?.name || 'Material name missing',
                        },
                        route,
                        location,
                    },
                    position: calculatePositions[TYPES.SHIPPING](inventoryNode, k + inventoryNode.outgoers(`node[type="${TYPES.SHIPPING}"]`).length, false),
                    grabbable: false,
                },
            )
        })
        //
        // Object.entries(outputs).forEach(([nodeId, amount], k) => {
        //     const outputNode = scope.cy.$(`#${nodeId}`)
        //     const inventoryNode = outputNode.outgoers(`node[type="${TYPES.MID_INVENTORY}"]`)
        //     const shippingNodeId = makeId(scope.draft.elements, TYPES.SHIPPING)
        //     newNodes.push(
        //         {
        //             data: {
        //                 icon: 'shipping',
        //                 id: shippingNodeId,
        //                 type: TYPES.SHIPPING,
        //                 output: outputNode.data('output')
        //             },
        //             position: calculatePositions[TYPES.SHIPPING](inventoryNode, k + inventoryNode.outgoers(`node[type="${TYPES.SHIPPING}"]`).length, true),
        //             grabbable: false
        //         },
        //         {
        //             data: {
        //                 id: `${inventoryNode.id()}-${shippingNodeId}`,
        //                 source: inventoryNode.id(),
        //                 target: shippingNodeId
        //             }
        //         }
        //     )
        // })
    }

    addElement(scope, newNodes)
}

export function generateItemsForGantt(elements) {
    return elements.filter(e => e.data.type === TYPES.PROCESS && e.data.schedule)
        .map(e => {
            const start = moment(e.data.schedule.start)
            const end = moment(start).add(e.data.schedule.periods[0].duration.milliseconds)
            return {
                label: e.data.name,
                start,
                end,
                style: {
                    background: e.data.main ? colors[TYPES.PROCESS] : colors[e.data.type],
                },
                id: e.data.id,
                row: e.data.main ? 'main process' : e.data.type,
            }
        })
}

export function prepareForTemplate(r) {
    // console.log('fromWizard', r)
    const payload = {
        name: r.options ? r.options.name : 'NO NAME',
        type: r.flowType.id,
        data: {

        },
    }

    if (r.flowType.id === 'manufacturing') {
        const steps = []
        for (let i = 0; i < r.options.steps.length; i++) {
            steps.push(
                {
                    name: r.options.steps[i].name,
                    machine: { id: r[`machine-${i}`].id },
                    period: r.options.steps[i].range,
                    input: {
                        fuels: r[`fuels-${i}`].map(({ id: materialId, unit, value, combustionProcessId }) => ({
                            materialId,
                            unit,
                            value,
                            combustionProcessId,
                        })),
                        materials: r[`input-${i}`].map(({ id: materialId, unit, value }) => ({
                            materialId,
                            unit,
                            value,
                        })),
                    },
                    output: {
                        materials: r[`output-${i}`].map(({ id: materialId, unit, value }) => ({
                            materialId,
                            unit,
                            value,
                        })),
                        waste: r[`waste-${i}`].map(({ id: materialId, unit, amount: value, disposalProcessId }) => ({
                            materialId,
                            unit,
                            value,
                            disposalProcessId,
                        })),
                    },
                },
            )
        }
        payload.data = {
            ...payload.data,
            inventory: {
                siteId: r.location.siteId,
            },
            categoryId: r.category.id,
            steps,
        }
    }

    if (r.flowType.id === 'purchase') {
        // console.log(r)
        payload.data = {
            ...payload.data,
            inventory: {
                siteId: r.destination.siteId,
            },
            period: r.options.steps[0].range,
            deliveries: [
                {
                    thirdParty: { siteId: r.origin.id },
                    resources: r.materials.map(({ id: materialId, unit, value }) => ({
                        materialId,
                        unit,
                        value,
                    })),
                    // transport: {
                    //     legs: r.transport.legs.map(leg => {
                    //         return {
                    //             modality: leg.type,
                    //             path: leg.coordinates.map(([latitude, longitude]) => {
                    //                 return { latitude, longitude }
                    //             }),
                    //             distanceKm: leg.stats.distance.value,
                    //             durationSeconds: leg.stats.time.value
                    //         }
                    //     })
                    // }
                },
            ],
        }
    }

    if (r.flowType.id === 'delivery') {
        payload.data = {
            ...payload.data,
            inventory: {
                siteId: r.origin.siteId,
            },
            period: r.options.steps[0].range,
            deliveries: [
                {
                    thirdParty: { siteId: r.destination.id },
                    resources: r.materials.map(({ id: materialId, unit, value }) => ({
                        materialId,
                        unit,
                        value,
                    })),
                    // transport: {
                    //     legs: r.transport.legs.map(leg => {
                    //         return {
                    //             modality: leg.type,
                    //             path: leg.coordinates.map(([latitude, longitude]) => {
                    //                 return { latitude, longitude }
                    //             }),
                    //             distanceKm: leg.stats.distance.value,
                    //             durationSeconds: leg.stats.time.value
                    //         }
                    //     })
                    // }
                },
            ],
        }
    }

    // console.log('payload', payload)
    return payload
}

export function update(scope) {
    if (!scope.cy) return
    setTimeout(() => {
        // updateButtons(scope)
        scope.itemsForGantt = generateItemsForGantt(scope.draft.elements)
    }, 10)
}
