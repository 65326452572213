
import moment from '~/composables/useMoment'
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        automatic: {
            type: Boolean,
            default: false,
        },
        closeOnSave: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Select an interval',
        },
        min: {
            type: String,
            default: null,
        },
        max: {
            type: String,
            default: null,
        },
    },
    data(vm) {
        return {
            draft: {
                startDate: vm.value.startDate,
                startTime: vm.value.startTime,
                endDate: vm.value.endDate,
                endTime: vm.value.endTime,
            },
            menu: {
                startDate: false,
                startTime: false,
                endDate: false,
                endTime: false,
            },
        }
    },
    watch: {
        draft: {
            deep: true,
            handler(v) {
                if (
                    v.startDate &&
                    v.startTime &&
                    v.endDate &&
                    v.endTime
                ) {
                    this.save()
                }
            },
        },
    },
    methods: {
        save() {
            this.$emit('update:value', this.draft)
            this.closeOnSave && this.$emit('cancel')
        },
        format(v, f) {
            return moment(v).format(f)
        },
    },
}
