import { install, use } from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import get from 'lodash/get'
import { merge } from 'lodash'
import db from '~/orm/database'
import plugin from '~/orm/plugin'
import { setTimezone } from '~/composables/useMoment'
import { currencyById } from '~/utils/currency'

use(plugin)
use(VuexORMAxios)

export const plugins = [install(db)]

export const state = () => ({
    debug: false, // process.env.NODE_ENV !== 'production',
    news: [],
    getStarted: null,
    settings: null,
    selectedSiteIds: [],
    userState: {},
})

export const getters = {
    appMode(state) {
        return get(state, 'settings.general.appMode')
    },
    canGetStarted(state) {
        // on ssr we don't have route module
        if (!state.route) return false
        return ['/carbon'].includes(state.route.path)
    },
    currency(state) {
        return currencyById(get(state.settings, 'general.currency', 'GBP'))
    },
    hasNews(state) {
        return state.news.length > 0
    },
    isLite(_state, getters) {
        return getters.appMode === 'lite'
    },
    isPro(_state, getters) {
        return getters.appMode === 'pro'
    },
    /**
     * Theme getter
     * used to grab the current theme value from the settings object
     *
     * @param   {Object}  state
     *
     * @return  {String} theme
     */
    theme(state) {
        return get(state, 'settings.general.theme', 'dark') ?? 'dark'
    },
    userState(state) {
        return state.userState
    },
}

export const mutations = {
    UPDATE_ITEM(_state, { item, key, value }) {
        item[key] = value
    },

    SET_NEWS(state, news = []) {
        state.news = news
    },

    SET_GET_STARTED(state, value) {
        state.getStarted = value
    },

    SET_SETTINGS(state, value) {
        state.settings = value
    },

    SET_SELECTED_SITE_IDS(state, siteIds = []) {
        state.selectedSiteIds = siteIds
    },

    SET_THEME(state, theme) {
        if (!state.settings?.general) return
        state.settings.general.theme = theme
    },

    SET_USER_STATE(state, value) {
        state.userState = value
    },
}

export const actions = {
    nuxtClientInit({ dispatch }) {
        dispatch('entities-manager/init')
        dispatch('fetchNews')
    },

    async setTheme({ commit, dispatch, getters }, { theme = 'dark', updateSettings = false, updateTheme = false } = {}) {
        if (!['dark', 'light'].includes(theme)) {
            console.warn('Invalid theme: ', theme)
            return
        }
        if (theme !== getters.theme) {
            commit('SET_THEME', theme)
            this.$cookie.set('theme', theme)
            if (updateTheme) {
                const isDarkTheme = theme !== 'light'
                if (this.app.vuetify.framework.theme.dark !== isDarkTheme) {
                    this.app.vuetify.framework.theme.dark = isDarkTheme
                }
            }
            if (updateSettings) {
                await dispatch('updateSettings', { general: { theme } })
            }
        }
    },

    async fetchSettings({ commit, dispatch }, withState) {
        try {
            const settings = await this.$api.auth.fetchSettings({ withState })
            const { state, general: { theme, timezone } } = settings
            setTimezone(timezone)
            if (process.client) {
                dispatch('setTheme', { theme, updateTheme: true })
            }
            if (state) commit('SET_USER_STATE', state)
            commit('SET_SETTINGS', settings)
            return settings
        } catch (err) {
            console.warn('Error fetching user settings: ', err)
        }
    },

    async updateSettings({ commit, dispatch, state }, payload) {
        try {
            const isImpersonatingOnProd = this.$api.auth.impersonator() // && this.$api.$config.envName === 'production' // TODO Enable
            const isDemo = this.$api.$config.envName === 'demo'
            let settings
            if (isImpersonatingOnProd || isDemo) {
                // impersonators are not allowed to persist changes to db, instead we mimic the changes for testing purposes
                settings = merge({}, state.settings, payload)
            } else {
                settings = await this.$api.auth.updateSettings(payload)
                const { theme, timezone } = settings.general
                dispatch('setTheme', { theme })
                if (timezone !== state.settings?.general?.timezone) {
                    setTimezone(timezone)
                }
            }
            if (payload?.entities?.showFriendlyNames !== undefined) {
                commit('entities-manager/SET_SHOW_FRIENDLY_NAME', payload.entities.showFriendlyNames)
            }
            if (payload?.entities?.showMpanNumbers !== undefined) {
                commit('entities-manager/SET_SHOW_MPAN', payload.entities.showMpanNumbers)
            }
            commit('SET_SETTINGS', settings)
            return settings
        } catch (err) {
            console.warn('Could not update settings: ', err)
        }
    },

    async updateUserState({ commit, dispatch, state }, payload) {
        try {
            const isImpersonating = this.$api.auth.impersonator()
            if (!isImpersonating) {
                const state = await this.$api.auth.updateUserState(payload)
                commit('SET_USER_STATE', state)
            }
        } catch (err) {
            console.warn('Could not update user state: ', err)
        }
    },

    toggleGetStarted({ commit, state }) {
        let value = state.route.path
        if (state.getStarted === value) {
            value = null
        }
        commit('SET_GET_STARTED', value)
    },

    fetchNews({ state, commit }) {
        const canFetchNews = get(state, 'settings.changelog.whatsNew', false)
        if (!canFetchNews) return
        const params = {
            per_page: 5,
        }
        this.$api.fetchNews(params).then(news => {
            commit('SET_NEWS', news)
        })
    },

    setSelectedSiteIds({ commit, rootGetters }, siteIds = []) {
        commit('SET_SELECTED_SITE_IDS', siteIds.filter(id => !rootGetters['entities/sites/find'](id).isUserGenerated))
    },
}
