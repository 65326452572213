
import VideoCard from '~/components/cards/video'
import videos from '~/mocks/videos.json'

export default {
    name: 'VideoTutorialsCard',

    components: {
        VideoCard,
    },

    props: {
        progressKey: {
            type: String,
            default: 'progress--video-tutorials',
        },
    },

    data: () => ({
        expanded: false,
        progress: [],
    }),

    computed: {
        videos() {
            return videos
        },
        progressValue() {
            return 100 / (this.videos.length + 1) * this.progress.length
        },
    },

    watch: {
        progress(progress) {
            this.$cookie.set(this.progressKey, progress)
        },
    },

    mounted() {
        this.init()
    },

    methods: {
        init() {
            const storedProgress = this.$cookie.get(this.progressKey)
            if (storedProgress) {
                this.progress = storedProgress
            }
        },
        toggleExpand() {
            this.expanded = !this.expanded
            if (this.expanded && !this.progress.includes('view-all')) {
                this.progress.push('view-all')
            }
        },
        onVideoPlay(id) {
            if (!this.progress.includes(id)) {
                this.progress.push(id)
            }
        },
    },
}
