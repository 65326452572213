// import { filesDownload } from '@/utils/tools'
import { throttle } from 'lodash'
import moment from '~/composables/useMoment'
import Circuit from '~/orm/models/Circuit'
import { calcLoadFactor, formatValueWithUnit } from '~/utils/calculations'
import Unit from '~/orm/models/Unit'
import Preset from '~/orm/models/Preset'
import { calculateRequestEntitiesIds } from '~/utils/entities'
import Site from '~/orm/models/Site'

export const state = () => ({
    loading: false,
    items: [],
})

export const getters = {
    payload(_state, _getters, rootState, rootGetters) {
        const { period } = rootState
        const unit = Unit.find(rootGetters['electricity/unit'])
        const {
            tabIndex,
            selectedPoint,
        } = rootState.electricity

        const customerId = rootState.auth.user.customer_id
        const selectedCircuits = rootGetters['electricity/selectedCircuits']
        const { clampIds } = calculateRequestEntitiesIds(selectedCircuits, 'electricity')
        const payload = {
            customerId,
            end: moment(period.end).subtract(1, 's').toISOString(),
            start: moment(period.start).toISOString(),
            resolution: period.resolution,
            circuitIds: [...clampIds, ...rootGetters['electricity/selectedCircuitsIds'].filter(e => e[0] === 'V')],
            timezone: rootGetters['period/timezone'],
            extraParams: [...(unit ? [unit] : []), ...rootGetters['electricity/selectedParameters']],
            selectedPoint: selectedPoint?.x,
        }

        if (tabIndex === '0') {
            // Home tab (normal circuits)
            const meters = {}
            selectedCircuits.forEach(circuit => {
                if (circuit.virtual) return
                const { meter } = circuit.relationships
                if (!meter) return
                // group by meter
                const meterId = meter.meterId
                if (!meters[meterId]) {
                    meters[meterId] = {
                        gspGroup: meter.gspGroup,
                        circuits: [],
                        mpanTop: meter.mpanTop,
                        mpanBottom: meter.mpanBottom,
                        kVAThreshold: meter.kvaThreshold,
                    }
                }
                meters[meterId].circuits.push(circuit.id)
            })
            payload.meters = Object.values(meters)
        } else {
            // Virtual circuits tab
            let presets = []
            const presetsTree = rootGetters['electricity/virtualTreeViewItems'][0].children
            const activeEntities = rootState['entities-manager'].activeEntities.virtual
            const selectedEntities = rootState['entities-manager'].selectedEntities.virtual
            const euids = activeEntities.length > 0 ? activeEntities : selectedEntities
            if (euids.length) {
                presets = presetsTree
                    .map(item => {
                        const isSelected = euids.includes(item.euid)
                        const selectedChildren = item.children.filter(c => euids.includes(c.euid))
                            .flatMap(c => {
                                return ['sites', 'meters'].includes(c.entity)
                                    ? calculateRequestEntitiesIds([rootGetters['reports/entityModel'](c.entity).find(c.id)], 'electricity').clampIds
                                    : parseInt(c.$id)
                            })
                        if (!isSelected && !selectedChildren.length) return undefined

                        if (isSelected) {
                            selectedChildren.push(`V${item.$id}`)
                        }

                        return {
                            presetId: parseInt(item.$id),
                            circuits: [`V${item.$id}`, ...item.children.map(c => parseInt(c.$id))],
                            selected: selectedChildren,
                        }
                    })
                    .filter(v => v)
            }
            payload.presets = presets
        }

        return payload
    },
}

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading
    },

    SET_ITEMS(state, items = []) {
        state.items = items
    },
}

async function fetchItems({ commit, getters, rootState, rootGetters, state }) {
    const { period } = rootState
    const selectedCircuits = rootGetters['electricity/selectedCircuits']
    const timezone = rootGetters['period/timezone']
    if (!selectedCircuits.length) {
        commit('SET_ITEMS', [])
        return
    }
    const clampIds = calculateRequestEntitiesIds(selectedCircuits)
    const presetIds = selectedCircuits.filter(({ virtual }) => virtual).map(({ id }) => Number(id.replace('V', '')))
    const res = await fetchFromAggregates(this.$api,
        {
            ...clampIds,
            presetIds,
            period,
            timezone,
            selectedPoint: getters.payload.selectedPoint,
            extraParameters: getters.payload.extraParams,
        },
        rootState.electricity.tabIndex)
    commit('SET_ITEMS', res)
}

export async function fetchFromAggregates(
    api,
    { clampIds, siteIds, presetIds, period, timezone, selectedPoint, extraParameters = [] },
    tabIndex,
) {
    const sum = ['E', 'C', 'U_DURE', 'C_DURE', 'U_CO2E', 'RE', 'C_DUXCAP', 'U_DUXCAP']
    const extraKeys = extraParameters.map(({ key }) => key)
    for (const unit of extraParameters) {
        if (unit.isCumulative) {
            sum.push(unit.key)
        }
    }
    if (!period) return []
    const daterange = selectedPoint
        ? [moment(selectedPoint).format(), moment(selectedPoint).addResolution(period.resolution).format()]
        : [moment(period.start).format(), moment(period.end).format()]

    const res = await api.clickHouse.fetchAggregates({
        sum,
        max: ['S', 'P', 'Q', 'P', ...extraKeys],
        avg: ['P', 'PF', 'S', 'Q', ...extraKeys],
        min: ['PF', ...extraKeys],
        clampIds,
        siteIds,
        presetIds,
        resolution: period.resolution,
        daterange,
        splitCircuits: true,
        timezone,
        scopes: ['ELEC'],
    })
    // transform to expected structure
    const _items = []
    const commonParameters = {
        P: Unit.find('P'),
        E: Unit.find('E'),
        S: Unit.find('S'),
        U_CO2E: Unit.find('U_CO2E'),
        Q: Unit.find('Q'),
        RE: Unit.find('RE'),
        C: Unit.find('C'),
        U_DURE: Unit.find('U_DURE'),
        U_DUXCAP: Unit.find('U_DUXCAP'),
    }
    function transformData(data, entityId, entity) {
        let entityModel
        if (entity === 'circuits') {
            entityModel = Circuit.find(entityId)
        } else if (entity === 'meters') {
            entityModel = Circuit.query().where('meterId', Number(entityId)).where('isMainIncomer', true).first()
        } else if (entity === 'presets') {
            entityModel = Preset.find(entityId)
        } else if (entity === 'sites') {
            entityModel = Site.find(entityId)
        }
        const loadFactor = calcLoadFactor(
            data.max.P.value,
            data.sum.E.value,
            moment(period.end).diff(moment(period.start), 'hours'),
        )
        return {
            mpanBottom: entityModel.mpanBottom || entityModel.meter?.mpanBottom,
            id: entity === 'presets' ? `V${entityModel.id}` : entityModel.id,
            entity,
            period: `${moment(period.start).format('MMM DD, HH:mm, YYYY')} - ${moment(period.end).subtract(1, 's').format('MMM DD, HH:mm, YYYY')}`,
            start: period.start,
            end: moment(period.end).subtract(1, 's'),
            kvaCapacity: formatValueWithUnit((entityModel.kvaThreshold || entityModel.meter?.kvaThreshold) * 1e3, commonParameters.S),
            totalCons: formatValueWithUnit(data.sum.E.value, commonParameters.E),
            totalCarbon: formatValueWithUnit(data.sum.U_CO2E.value, commonParameters.U_CO2E),
            avgKw: formatValueWithUnit(data.avg.P.value, commonParameters.P),
            maxDemand: formatValueWithUnit(data.max.P.value, commonParameters.P),
            maxDemandTime: data.max.P.atMillis,
            loadFactor,
            avgPf: data.avg.PF.value,
            minPf: data.min.PF.value,
            avgKva: formatValueWithUnit(data.avg.S.value, commonParameters.S),
            peakKva: formatValueWithUnit(data.max.S.value, commonParameters.S),
            peakKvaTime: data.max.S.atMillis,
            peakKvar: formatValueWithUnit(data.max.Q.value, commonParameters.Q),
            avgKvar: formatValueWithUnit(data.avg.Q.value, commonParameters.Q),
            kvarh: formatValueWithUnit(data.sum.RE.value, commonParameters.RE),
            capacityCost: formatValueWithUnit(data.sum.C_DUXCAP.value, commonParameters.C),
            excessCapacityUnits: formatValueWithUnit(data.sum.U_DUXCAP.value, commonParameters.U_DUXCAP),
            reactiveUnits: formatValueWithUnit(data.sum.U_DURE.value, commonParameters.U_DURE),
            reactiveCost: formatValueWithUnit(data.sum.C_DURE.value, commonParameters.C),
            totalCost: formatValueWithUnit(data.sum.C.value, commonParameters.C),
            ...data,
        }
    }

    if (res?.data?.meters) {
        for (const [meterId, data] of Object.entries(res.data.meters)) {
            _items.push(transformData(data, meterId, 'meters'))
        }
    }

    if (res?.data?.clamps) {
        for (const [clampId, data] of Object.entries(res.data.clamps)) {
            _items.push(transformData(data, clampId, 'circuits'))
        }
    }

    if (res?.data?.presets) {
        for (const [presetId, data] of Object.entries(res.data.presets)) {
            _items.push(transformData(data, presetId, 'presets'))
        }
    }

    if (tabIndex === '1' && res?.data?.sites) {
        for (const [siteId, data] of Object.entries(res.data.sites)) {
            _items.push(transformData(data, siteId, 'sites'))
        }
    }
    return _items
}

export const actions = {
    fetchItems: throttle(fetchItems, 500, { leading: false }),

    download({ rootState, rootGetters }, payload) {
        payload.use_friendly_names = rootState['entities-manager'].showFriendlyName
        payload.useFriendlyNames = rootState['entities-manager'].showFriendlyName
        payload.timezone = rootGetters['period/timezone']
        return this.$api.electricity.downloadInspectorData(payload)
    },
}
