import _ from 'lodash'

export default {
    INIT(state) {
    },

    SET_TAB_INDEX(state, value) {
        // console.log('SET_TAB_INDEX',value)
        state.tabIndex = value
    },

    SET_VIRTUAL_TREE(state, payload) {
        state.virtualTree = payload
    },

    UPDATE_CHECKED_VIRTUAL_ITEMS(state, { item, selected }) {
        item.selected = selected

        // select children
        if (item.children) {
            _.each(item.children, child => {
                child.selected = selected
                if (child.children) {
                    _.each(child.children, lilChild => {
                        lilChild.selected = selected
                    })
                }
            })
        }

        // select parent
        if (selected) {
            const topParent = state.virtualTree[0]
            // console.log('topParent: ', topParent)
            if (!topParent.selected) {
                this._vm.$set(topParent, 'selected', true)
            }
            if (item.entity === 'circuits') {
                const parent = topParent.children.find(p => p.presetId === item.presetId)
                if (parent && !parent.selected) {
                    // console.log('parent', parent)
                    parent.selected = true
                }
            }
        }

        const selectedCircuits = []
        for (const customerIndex in state.virtualTree) {
            const customer = state.virtualTree[customerIndex]
            const presets = customer.children
            for (const presetIndex in presets) {
                const preset = presets[presetIndex]
                const circuits = preset.children
                for (const circuitIndex in circuits) {
                    const circuit = circuits[circuitIndex]
                    if (circuit.selected) {
                        selectedCircuits.push(circuit)
                    }
                }
            }
        }

        state.checkedVirtualItems = selectedCircuits
    },

    LOAD_CHECKED_VIRTUAL_ITEMS(state, selectedCircuits) {
        state.checkedVirtualItems = selectedCircuits
    },
}
