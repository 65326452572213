
export default {
    name: 'MaterialCard',

    props: {
        material: {
            type: Object,
            required: true,
        },
        genericUnits: {
            type: Array,
            default: () => [],
        },
        error: {
            type: Object,
            default: () => ({
                amount: {},
                unit: {},
                disposalProcessId: {},
            }),
        },
        selectedItems: {
            type: Array,
            default: () => [],
        },
    },

    data: vm => ({
        reveal: false,
        draft: {
            id: vm.material.id,
            amount: '',
            unit: 'kg',
            // disposalType: null,
            disposalTypes: vm.material.disposalTypes,
            disposalProcessId: vm.material.disposalProcessId,
        },
    }),

    computed: {
        selected() {
            // console.log(this.selectedItems, this.material.id)
            // return []
            return this.selectedItems.find(i => i.id === this.material.id)
        },
    },

    watch: {
        reveal(val) {
            // console.log(this.draft)
            if (val) {
                this.$nextTick(() => {
                    this.$refs.qty.$el.querySelector('input').focus()
                })
            }
        },
    },

    methods: {
        confirm() {
            this.$emit('add-or-update', {
                ...this.draft,
                amount: Number(this.draft.amount),
            })
            this.reveal = false
        },
        removeSelected() {
            this.$emit('remove', this.draft)
            this.draft.amount = ''
        },
        onCardClick() {
            this.$emit('reveal', this.material.id)
            this.reveal = true
        },
    },
}
