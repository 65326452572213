import { delay } from '~/utils/tools'

export default class EventsService {
    constructor({ $axios, $config }) {
        this.$axios = $axios
        this.$config = $config
    }

    save(p) {
        console.log(p)
        try {
            let url = 'events-api/eventgroups'
            const payload = {
                name: p.name,
                details: {
                    unit: {
                        name: p.details.unit.name,
                        unit: p.details.unit.unit,
                    },
                },
                events: p.events.map(event => {
                    return {
                        id: event.id,
                        name: event.name,
                        details: event.details,
                        entities: event.entities,
                        schedule: {
                            periods: event.occurrences.map(occurrence => {
                                return {
                                    id: occurrence.id,
                                    name: occurrence.name,
                                    start: `${occurrence.startDate}T${occurrence.startTime}Z`,
                                    end: `${occurrence.endDate}T${occurrence.endTime}Z`,
                                    details: {
                                        demand: {
                                            kWh: occurrence.demandType === 'similar' ? occurrence.similarProcess.details.demand.kWh : occurrence.demandValue,
                                        },
                                        unit: occurrence.units,
                                        additionalCosts: occurrence.extraCosts,
                                    },
                                }
                            }),
                            repeat: event.schedule.repeat && event.schedule.repeatType
                                ? {
                                    [event.schedule.repeatType === 'weeks' ? 'weeks' : (event.schedule.weekdaysOnly ? 'weekdays' : 'days')]: 1,
                                }
                                : null,
                            repeatUntil: event.schedule.repeatUntil,
                        },
                        template: {
                            name: event.name,
                            entities: event.entities,
                        },

                    }
                }),
            }
            if (p.id) {
                url = `${url}/eventgroup/${p.id}`
            }
            return this.$axios[p.id ? '$put' : '$post'](url, payload)
        } catch (e) {
            console.warn(e)
        }
    }

    async loadEventGroups() {
        return await this.$axios.$get('events-api/eventgroups')
    }

    async loadEventGroupsSummary() {
        return await this.$axios.$get('events-api/eventgroups/summary')
    }

    async loadEvent(groupId) {
        return await this.$axios.$get(`events-api/eventgroups/eventgroup/${groupId}`)
    }

    async loadCodes() {
        return await this.$axios.$get('events-api/events/additionalCostCodes')
    }

    async loadGroup(groupId) {

    }

    async loadSimilar(params) {
        const query = params.entities.map(({ id, type }, k) => {
            return [`entities[${k}][id]=${id}`, `entities[${k}][type]=${type}`].join('&')
        }).join('&')
        return await this.$axios.$get(`events-api/events/similar?${query}`)
    }

    deleteEventGroup(groupId) {
        return this.$axios.$delete(`events-api/eventgroups/eventgroup/${groupId}`)
    }

    fetchInventory() {
        return this.$axios.$get('events-api/flows/inventory')
    }

    async storeInventory(payload = {}) {
        // return this.$axios.$post('events-api/flows/inventory', payload)
        await delay(1000)
        return payload
    }

    fetchFlowTemplate(type = 'basic') {
        const url = `events-api/flows/templates/generate?type=${type}`
        return this.$axios.$get(url)
    }

    setFlow({ groupId, eventId, payload }) {
        const url = `events-api/eventgroups/eventgroup/${groupId}/events/event/${eventId}/flow`
        return this.$axios.$put(url, payload)
    }

    transformFlow({ groupId, eventId, payload }) {
        const url = `events-api/eventgroups/eventgroup/${groupId}/events/event/${eventId}/flow/evaluate`
        return this.$axios.$put(url, payload)
    }

    saveFlow(flow) {
        const url = 'events-api/flows'
        try {
            console.log('saving flow')
            return this.$axios.$post(url, flow)
        } catch (e) {
            console.error(e)
        }
    }
}
