
import FormMixin from '~/components/flows/forms/form.mixin'
import { genericUnits } from '~/components/flows/const'

export default {
    name: 'SelectMaterials',

    mixins: [FormMixin],

    props: {
        materialNodes: {
            type: Array,
            required: true,
        },
        supplierNodes: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            title: 'Process',
        }
    },

    computed: {
        genericUnits() {
            return genericUnits
        },
    },

    mounted() {
        console.log(this.materialNodes)
    },

    methods: {
        getSupplierById(supplierId) {
            if (supplierId) {
                return this.supplierNodes.find(({ id }) => id === supplierId)
            } else {
                return {
                    supplier: {
                        business: {
                            name: 'Site',
                        },
                    },
                }
            }
        },
    },
}
