import { cloneDeep, set, get, forOwn } from 'lodash'
export default {
    TOGGLE_CHART_SIDE(state, v) {
        if (typeof v === 'boolean') {
            state.chartOnRightSide = v
        }
    },

    SET_INSTANCE_ID(state, id) {
        state.instanceId = id
    },

    INCREASE_LOADING_COUNTERS(state, payload = []) {
        const nextState = cloneDeep(state.loadingCounters)
        payload.forEach(e => {
            set(nextState, e, get(nextState, e) + 1)
        })
        state.loadingCounters = nextState
    },

    DECREASE_LOADING_COUNTERS(state, payload) {
        const nextState = cloneDeep(state.loadingCounters)
        payload.forEach(e => {
            set(nextState, e, Math.max(0, get(nextState, e) - 1))
        })
        state.loadingCounters = nextState
    },

    SET_LOADING_COUNTERS(state, payload = {}) {
        const nextState = cloneDeep(state.loadingCounters)
        forOwn(payload, (e, k) => {
            set(nextState, k, e)
        })
        state.loadingCounters = nextState
    },

    SET_ENTITIES_TREEVIEW(state, v) {
        state.treeviewItems = v
    },

    SET_LOAD_ITEMS(state, { key, value }) {
        state.loader.loadItems[key] = value
    },

    RESET_LOADER(state, payload = {}) {
        state.loader = {
            indicator: 0,
            loadItems: {},
            visible: false,
            ...payload,
        }
    },

    SET_LOADER_VISIBLE(state, payload) {
        state.loader.visible = payload
    },

    SET_LOADER_INDICATOR(state, payload) {
        state.loader.indicator = payload
    },

    SET_SPINNER_LOADER(state, payload) {
        state.spinnerLoader = payload
    },

    SET_DOWNLOAD_LOADER(state, payload) {
        state.downloadLoader = payload
    },

    SET_DIALOG_WAITING(state, payload) {
        state.dialogWaiting = payload
    },
}
