
import { mapGetters } from 'vuex'
import ItemCard from './ItemCard'

export default {
    name: 'ProcessFuels',

    components: {
        ItemCard,
    },

    props: {
        active: Boolean,
        fuels: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapGetters('flows', ['fuelById']),
    },
}
