
import AppName from '~/components/app-name'

export default {
    name: 'AppFooter',

    components: {
        AppName,
    },

    props: {
        guest: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            gTooltip: '',
        }
    },

    beforeMount() {
        this.$eventBus.$on('newDataAvailable', () => {
            this.newDataAvailable = true
        })
        this.$eventBus.$on('g-tooltip', ({ a, b }) => {
            if (this.$_.get(this.$i18n.messages, `${this.$i18n.locale}.globalTooltips.${a}.${b}`, '')) {
                this.gTooltip = this.$t(`globalTooltips.${a}.${b}`)
            } else {
                this.gTooltip = ''
            }
        })
    },
}
