// Utils
import download from 'downloadjs'
import { concat, xor } from 'lodash'
import { eliberate, FROM_ROOT } from '~/utils/state'
import { buildTree, COMMODITY_TYPES } from '~/utils/entities'
import { DAY, HOUR, MONTH, WEEK } from '~/utils/date'

export default {
    /**
     * Initialise the store
     * Ideally called from the electricity page
     * @param  {Function} options.commit
     * @param  {Function} options.dispatch
     * @return {Void}
     */
    async init({ commit, dispatch, rootGetters, getters, state }) {
        // if (state.initialised) {
        //     console.warn('Electricity store is already initialised.')
        //     return
        // }

        // start loading
        if (!state.loading) {
            commit('SET_LOADING', true)
        }

        // init cache
        await this.$cache.init()

        // we check again for init here, since cache loading can be skiped
        // if (! state.initialised) {
        //     dispatch('forceInit')
        // }

        // setup
        await Promise.all([
            dispatch('entities/circuits/setup', {}, FROM_ROOT),
            dispatch('entities/circuits/setupVirtual', {}, FROM_ROOT),
            dispatch('entities/sites/setup', {}, FROM_ROOT),
        ])

        if (!getters.supported) {
            console.warn('Electricity feature is not supported atm.')
            return
        }
        // init period
        const firstInTime = rootGetters['entities/circuits/firstData']
        const lastInTime = rootGetters['entities/circuits/lastData']
        const initPeriod = {
            min: this.$_.get(firstInTime, 'startTime'),
            max: this.$_.get(lastInTime, 'endTime'),
        }

        if (firstInTime || lastInTime) {
            await dispatch('period/init', initPeriod, FROM_ROOT)
        }

        if (rootGetters['entities/circuits/isThereAtLeastOneMeterWithNoMainIncomer']) {
            commit('SET_AUTO_Y_AXIS', true)
            commit('SET_DEFAULT_AUTO_Y_AXIS', true)
        }

        // set tree items for tree views
        dispatch('setTreeItems')
        dispatch('setVirtualTreeItems')

        // init treeviews
        dispatch('initTreeviews')

        // set parameters preset
        commit('SET_SELECTED_PARAMETERS_PRESET', rootGetters['entities/parameters-presets/default']('power'))

        // init unit
        dispatch('initUnit')

        // reset comparison ? (CVP-1619)
        if (state.comparison) {
            dispatch('setComparison', null)
        }
        // stop loading
        commit('SET_LOADING', false)

        // init
        commit('SET_INITIALISED', true)
    },

    initUnit({ commit, getters }) {
        const unitIdFromHash = this.$_.get(this.$hashState, 'params.unit', null)
        const units = getters.preset.parameters
        let unit = units.find(u => (u.id === unitIdFromHash || u.param === unitIdFromHash) &&
            getters.selectedCircuits.every(c => c.supportsUnit(u)))
        if (!unit) {
            unit = units.find(u => getters.selectedCircuits.every(c => c.supportsUnit(u)))
        }
        if (unit && unit.scope === 'electricity') {
            commit('SET_SELECTED_UNIT', unit.key)
            // commit('SET_SELECTED_PARAMETER_KEYS', [unit.param])
        }
    },

    /**
     * Set tree items
     *
     * @param {Function} commit
     * @param {Object} rootGetters
     *
     * @returns {Void}
     */
    setTreeItems({ commit, rootGetters, state }, { forced = false } = {}) {
        if (!forced && state.treeItems.all.length > 0) return
        const allItems = rootGetters['entities/customers/query']()
            // .with('sites.meters.circuits', query => query.where('parentId', null))
            .with('sites', query =>
                // filter sites with no meters, eg: newly created by user
                query.whereHas('meters').with('meters.circuits', query => query.where('parentId', null)),
            )
            .get()
        const all = buildTree({ items: allItems, store: this })
        commit('SET_TREE_ITEMS', {
            all: Object.freeze(all),
        })
    },

    /**
     * Set virtual tree items
     *
     * @param {Function} commit
     * @param {Object} rootGetters
     *
     * @returns {Void}
     */
    setVirtualTreeItems({ commit, rootGetters, state }, { forced = false } = {}) {
        if (!forced && state.treeItems.virtual.length > 0) return
        const virtualItems = rootGetters['entities/customers/query']()
            .with('presets', q => q.where('scope', 'electricity'))
            .get()
        const virtual = buildTree({ items: virtualItems, store: this })
        commit('SET_TREE_ITEMS', {
            virtual: Object.freeze(virtual),
        })
    },

    /**
     *  Init the electricity treeviews
     *  check if selected entities in hash state or fallback to default
     *
     * @param { dispatch, getters } context
     */
    initTreeviews({ dispatch, getters, rootState, rootGetters }) {
        const selectedSiteIds = rootState.selectedSiteIds
        const treeview = this.$_.get(this.$hashState, 'params.powerTreeview') || this.$_.get(rootGetters.userState, 'powerTreeview')
        const userSelectedEntities = treeview ? rootGetters['entities-manager/validatedTreeview'](treeview) : null
        if (userSelectedEntities?.selected?.length || userSelectedEntities?.active?.length) {
            const tabIndex = ['all', 'virtual'].indexOf(userSelectedEntities.type)
            dispatch('electricity/setTabIndex', tabIndex, FROM_ROOT)
            dispatch('entities-manager/setSelectedEntities', userSelectedEntities, FROM_ROOT)
            dispatch('entities-manager/setActiveEntities', userSelectedEntities, FROM_ROOT)
        } else {
            const euids = selectedSiteIds.length > 0 ? getters.sitesTreeviewItems(selectedSiteIds) : getters.initialTreeViewItems
            dispatch(
                'entities-manager/setSelectedEntities',
                {
                    type: 'all',
                    selected: [...euids],
                },
                FROM_ROOT,
            )
        }
    },

    /**
     * Set tab index
     * @param {Function} options.commit
     * @param {Number} value
     */
    setTabIndex({ commit }, value) {
        value = String(value)
        if (!['0', '1'].includes(value)) {
            return // invalid index
        }
        commit('SET_TAB_INDEX', value)
    },

    toggleAnalysisFeature({ commit, dispatch, rootState }, payload) {
        commit('TOGGLE_ANALYSIS_FEATURE', payload)
        // when activating Max Demands
        if (payload.key === 'maxDemands' && payload.active) {
            dispatch(
                'period/update',
                {
                    start: rootState.period.min,
                    end: rootState.period.max,
                },
                FROM_ROOT,
            )
        }
    },

    resetAnalysisFeatures({ commit }) {
        commit('RESET_ANALYSIS_FEATURES')
    },

    setDrillDown({ commit }, value) {
        commit('SET_DRILL_DOWN', value)
    },

    closeDrilldown({ commit }) {
        commit('SET_DRILL_DOWN', {
            unit: '',
            entity: '',
            entityIds: [],
            entityNames: [],
            previousPeriod: {},
            period: {},
        })
    },

    setSelectedUnit({ commit }, unit) {
        commit('SET_SELECTED_UNIT', unit)
    },

    addSnapshot({ commit }, v) {
        commit('ADD_SNAPSHOT', { ...v, id: this.$_.uniqueId('snapshot-') })
    },

    selectSnapshot({ commit, state }, v) {
        commit(
            'SET_SNAPSHOTS',
            state.snapshots.map(snapshot => {
                return {
                    ...snapshot,
                    selected: snapshot.id === v.id,
                }
            }),
        )
    },

    setSelectedPoint({ commit }, v) {
        commit('SET_SELECTED_POINT', v)
    },

    toggleExtremePoint({ dispatch, state }, extreme = 'max') {
        let point
        if (state.selectedPoint && state.selectedPoint.extreme === extreme) {
            point = null
        } else {
            point = {
                extreme,
                uid: this.$_.uniqueId('point_'),
            }
        }
        dispatch('setSelectedPoint', point)
    },

    toggleChartAutoYAxis({ commit, state }) {
        commit('SET_AUTO_Y_AXIS', !state.autoYAxis)
    },

    setChartAutoYAxis({ commit }, v) {
        commit('SET_AUTO_Y_AXIS', v)
    },

    setTriadsReadings({ commit }, v) {
        commit('SET_TRIADS_READINGS', v)
    },

    setLoadFactorData({ commit }, v) {
        commit('SET_LOAD_FACTOR_DATA', v)
    },

    setConsolidation({ commit }, value) {
        commit('SET_CONSOLIDATION', value)
    },

    toggleConsolidation({ commit, state }) {
        commit('SET_CONSOLIDATION', !state.consolidation)
    },

    setComparison({ commit }, value) {
        commit('SET_COMPARISON', value)
    },

    toggleComparison(c) {
        const { dispatch, state, rootGetters, rootState } = c
        const comparison = state.comparison ? null : this.$_.clone(rootGetters['period/comparison'])
        if (comparison && !rootState.period.shift) {
            const secondsDuration = rootGetters['period/startEndDuration'].secondsDuration
            let baseShift
            switch (true) {
                case secondsDuration >= (MONTH + (3 * DAY)):
                    baseShift = 'year'
                    break
                case secondsDuration >= WEEK:
                    baseShift = 'month'
                    break
                case secondsDuration >= DAY:
                    baseShift = 'customWeek'
                    break
                case secondsDuration >= HOUR:
                    baseShift = 'day'
                    break
                default:
                    baseShift = 'hour'
                    break
            }
            dispatch('period/update', { shift: baseShift }, { root: true })
        }
        dispatch('setComparison', comparison)
    },

    toggleRag({ commit, state }, value = null) {
        commit('TOGGLE_RAG', this.$_.isBoolean(value) ? value : !state.ragEnabled)
    },

    async downloadJSON({ rootState, rootGetters }) {
        const { period } = rootState
        const customer = rootGetters['entities/customers/all']()[0]
        const availableDownloads =
            customer && customer.settings && customer.settings.downloadsEnabled && customer.settings.downloadsEnabled

        /**
         * temporarily only support one unit
         */
        if (!availableDownloads.length || !availableDownloads[0].unit || !availableDownloads[0].resolution) {
            return "You don't have the access to this feature"
        }
        const outputResolution = availableDownloads[0].resolution / 60
        if (outputResolution < period.resolution / 60) {
            return `Found misconfiguration in the output resolution (${outputResolution}, ${period.resolution / 60})`
        }
        const allCircuits = rootGetters['entities/circuits/haveData']
        const data = await Promise.allSettled(allCircuits.map(c => c.getUnitData(availableDownloads[0].unit, COMMODITY_TYPES.electricity)))
        const output = []
        allCircuits.forEach((circuit, k) => {
            if (data[k] && data[k].status === 'fulfilled') {
                const originalCircuitData = eliberate(data[k].value.data)
                const startTime = this.$moment(data[k].value.startTime)
                /** i want to round it up to the closest hour */
                const howManyPointsToSkip =
                    startTime.minutes() !== 0
                        ? 60 / (period.resolution / 60) - (60 / (period.resolution / 60)) * (startTime.minutes() / 60)
                        : 0
                startTime.add(howManyPointsToSkip * period.resolution, 'seconds')
                let circuitData = originalCircuitData.splice(howManyPointsToSkip)
                circuitData = this.$_.chunk(circuitData, outputResolution / (period.resolution / 60)).map((e, k) => {
                    return {
                        dt: this.$moment(startTime)
                            .add(k * outputResolution, 'minutes')
                            .format(),
                        kwh: Number(this.$_.sum(e)).toFixed(4),
                    }
                })
                output.push({
                    site: circuit.relationships.site.name,
                    circuitName: circuit.circuitName,
                    data: circuitData,
                })
            }
        })
        download(
            JSON.stringify(output, null, 4),
            `${this.$moment().format('YYYY_MM_DD_HH_mm_ss')}_kwh_output.json`,
            'text/plain',
        )
        return true
    },

    setAnnotationsMode({ commit }, value) {
        commit('SET_ANNOTATIONS_MODE', value)
    },

    reset({ commit, dispatch }, { hard = false } = {}) {
        dispatch('resetChart')
        // dispatch('entities/treeviews/reset', {}, FROM_ROOT)
        dispatch('period/reset', { resolution: 900 }, FROM_ROOT)
        this.$eventBus.$emit('hard-reset')

        if (hard) {
            commit('RESET')
        }
    },

    resetChart({ dispatch }) {
        dispatch('setSelectedPoint', null)
        dispatch('setComparison', null)
        dispatch('toggleRag', false)
        dispatch('setAnnotationsMode', false)
        dispatch('setChartAutoYAxis', true)
        dispatch('toggleContractsHistory', false)
        dispatch('resetAnalysisFeatures')
    },

    destroy({ commit }) {
        commit('SET_INITIALISED', false)
    },

    async toggleContractsHistory({ commit, rootGetters, state }, enable = true) {
        const supplierRatesKeys = {
            SUPPL_DAY: 'day_rate',
            SUPPL_NIGHT: 'night_rate',
            SUPPL_OFFPEAK_WEEKEND: 'offpeak_weekend_rate',
            SUPPL_SETTLEMENT_CHARGE: 'settlement_charge',
            SUPPL_STANDING_CHARGE: 'standing_charge',
        }
        const ratesLabels = {
            SUPPL_DAY: 'label.day_rate',
            SUPPL_NIGHT: 'label.night_rate',
            SUPPL_OFFPEAK_WEEKEND: 'label.offpeak_weekend',
            SUPPL_STANDING_CHARGE: 'label.standing_charge',
            SUPPL_SETTLEMENT_CHARGE: 'label.settlement_charge',
            DUOS_GREEN: 'color.green',
            DUOS_AMBER: 'color.amber',
            DUOS_RED: 'color.red',
            DUOS_CAPACITY: 'label.capacity',
        }
        let contracts = []
        if (!state.selectedMeterContracts.length && enable) {
            const meter = rootGetters['entities-manager/selectedCircuitsMeters']()[0]
            let duosRates = {}
            const isPassThrough = meter.contracts[0]?.passed_through_elements && meter.contracts[0].passed_through_elements.length
            if (isPassThrough) {
                const parsedTop = /^(\d{2})(\d{3})(\w{3})$/.exec(meter.mpanTop)
                const parsedBottom = /^(\d{2})(\d{8})(\d{3})$/.exec(meter.mpanBottom)
                const params = {
                    gspGroup: meter.gspGroup,
                    from: meter.contracts[0].start_date,
                    to: meter.contracts[meter.contracts.length - 1].end_date,
                    forecast: false,
                    distributorId: parsedBottom[1], // validate ?
                    lineLossFactorClass: parsedTop[3],
                    profileClass: parsedTop[1],
                }
                duosRates = await this.$api.electricity.getRates(params)
            }
            contracts = meter.contracts.map(contract => {
                let rates = []
                if (isPassThrough) {
                    rates = contract.passed_through_elements.filter(p =>
                        ['DUOS_GREEN', 'DUOS_AMBER', 'DUOS_RED', 'DUOS_CAPACITY'].includes(p)).map(p => {
                        return duosRates?.rates?.[p]?.filter(r =>
                            this.$moment(contract.start_date).isBetween(r.from, r.to) ||
                                this.$moment(contract.end_date).isBetween(r.from, r.to),
                        ).map((duos, index, length) => {
                            return {
                                name: ratesLabels[duos.name],
                                value: duos.rate,
                                to: index < length - 1 ? this.$moment(duos.to).format('DD-MM-YYYY') : undefined,
                            }
                        })
                    })
                }
                if (meter.mainIncomer?.isNonHalfHourly()) {
                    rates = contract.standing_charges.map(r => {
                        return { name: ratesLabels[r.key], value: r.rate, ...r }
                    }).concat()
                } else {
                    rates = concat(concat(contract.supplier_elements, 'SUPPL_STANDING_CHARGE').map(r => {
                        return { name: ratesLabels[r], value: contract[supplierRatesKeys[r]] }
                    }), rates)
                }
                return {
                    id: contract.id,
                    start_date: contract.start_date,
                    end_date: contract.end_date,
                    supplier: contract.supplier_name ?? contract.supplier_mpid,
                    rates,
                }
            })
        }
        commit('SET_METER_CONTRACTS', contracts)
    },

    selectParametersPreset({ commit, getters }, v) {
        commit('SET_SELECTED_PARAMETERS_PRESET', v)
        const key = getters.availableParameter
        commit('SET_SELECTED_PARAMETER_KEYS', key ? [key] : [])
    },

    toggleSelectedParameterKeys({ state, commit }, v) {
        commit('SET_SELECTED_UNIT', null)
        commit('SET_SELECTED_PARAMETER_KEYS', xor(state.selectedParameterKeys, [v]))
    },

    toggleMultipleSelectedParameterKeys({ state, commit }, values) {
        commit('SET_SELECTED_UNIT', null)
        commit('SET_SELECTED_PARAMETER_KEYS', xor(state.selectedParameterKeys, values))
    },

    setSelectedParameterKeys({ state, commit }, v) {
        commit('SET_SELECTED_PARAMETER_KEYS', [v])
    },

    resetSelectedParameterKeys({ getters, commit }) {
        commit('SET_SELECTED_UNIT', null)
        commit('SET_SELECTED_PARAMETER_KEYS', [...(getters.preset?.keysOnly?.length ? [getters.preset.keysOnly[0]] : [])])
    },

    setTracesVisibility({ state, commit }, traces) {
        const v = {
            ...state.tracesVisibility,
            ...traces,
        }
        commit('SET_TRACES_VISIBILITY', v)
    },

    toggleInspector({ state, commit }, value) {
        commit('SET_IS_INSPECTOR_VISIBLE', value ?? !state.isInspectorVisible)
    },

    checkActiveCircuits({ state, commit, getters, rootGetters }, tabId) {
        if (rootGetters['entities-manager/selectedCircuits'](tabId, true).length > 1 && state.selectedParameterKeys?.length > 1) {
            this.$toast.info('You can\'t select more than one circuit when two or more parameters are selected')
            return false
        }
        return true
    },
}
