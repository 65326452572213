import Report from '@/orm/models/Report'
import { eliberate } from '@/utils/state'

export default {
    start({ state, commit, getters, dispatch }, colors) {
        try {
            // const asyncTasks = async() => {}
        } catch (e) {
            console.warn(e)
        }
    },

    setTabIndex({ commit, getters }, value) {
        commit('SET_TAB_INDEX', value)
    },

    updateCheckedVirtualItems({ state, commit }, payload) {
        commit('UPDATE_CHECKED_VIRTUAL_ITEMS', payload)
    },

    /**
     * Sets virtual meters tree array
     * Should run on init and when entities manager update
     *
     * @param {Object} options.state
     * @param {Function} options.commit
     * @param {Object} options.rootGetters
     */
    setVirtualTree({ state, commit, rootGetters }, selected) {
        const oldCustomers = state.virtualTree
        const tree = eliberate(rootGetters['entities-manager/virtualMetersTree'])
        const checkedVirtualItems = []

        this.$_.each(tree, (customer, customerIndex) => {
            const oldPresets = oldCustomers[customerIndex] ? oldCustomers[customerIndex].children : []

            this.$_.each(customer.children, (preset, presetIndex) => {
                let oldCircuits = []
                const oldPreset = oldPresets.find(op => op.presetId === preset.presetId)
                if (oldPreset) {
                    oldCircuits = oldPreset.children
                    preset.selected = false
                }

                const preSelected = this.$_.find(selected, s => {
                    return s.id === preset.presetId
                })
                if (preSelected) {
                    preset.selected = true
                    customer.selected = true
                }

                this.$_.each(preset.children, (circuit, circuitIndex) => {
                    const oldCircuit = oldCircuits.find(c => c.id === circuit.id)
                    const preSelect = preSelected
                        ? this.$_.find(preSelected.circuitIds, c => {
                            return c === circuit.id
                        })
                        : undefined

                    circuit.presetId = preset.presetId
                    if (preSelect) {
                        circuit.selected = true
                        checkedVirtualItems.push(circuit)
                    } else if (oldCircuit) {
                        circuit.selected = oldCircuit.selected
                    } else if (circuit.selected) {
                        circuit.selected = false
                    }
                })
            })
        })

        commit('SET_VIRTUAL_TREE', tree)
        commit('LOAD_CHECKED_VIRTUAL_ITEMS', checkedVirtualItems)
    },

    async saveReport({ commit, dispatch, state, getters }, { data }) {
        try {
            const report = eliberate(data)
            let res
            const onSuccess = msg => this.$toast.success(msg)
            const onError = msg => this.$toast.error(msg)
            if (report.reportId) {
                // update
                res = await Report.api().put(`reports/${report.reportId}`, report)
                if (res.response.status === 200) {
                    onSuccess(this.$t('form.responses.update_success'))
                } else {
                    onError(this.$t('form.responses.update_failed'))
                }
            } else {
                // create
                res = await Report.api().post('reports', report)
                if (res.response.status === 200) {
                    onSuccess(this.$t('form.responses.create_success'))
                } else {
                    onError(this.$t('form.responses.create_failed'))
                }
            }
            return res
        } catch (err) {
            console.warn('Error while saving a report.', err)
        }
    },

    async downloadReport(_ctx, params) {
        try {
            const res = await this.$axios.$get('/reports/kwh', params)
            const onSuccess = msg => this.$toast.success(msg)
            const onError = msg => this.$toast.error(msg)
            if (res.status === 200) {
                onSuccess(this.$t('form.responses.download_success'))
            } else {
                onError(this.$t('form.responses.download_failed'))
            }
            return res
        } catch (err) {
            console.warn('Error while fetching download URL link for report.', err)
        }
    },

    async deleteReport({ commit, dispatch, state }, id) {
        try {
            const onSuccess = msg => this.$toast.success(msg)
            const onError = msg => this.$toast.error(msg)
            const confirmed = await this._vm.$confirm(this.$t('pages.reports.confirm_delete_report'), {
                buttonTrueText: this.$t('btn.yes'),
                buttonFalseText: this.$t('btn.no'),
                title: this.$t('txt.please_confirm'),
                color: 'red',
            })
            if (confirmed) {
                const res = await Report.api().delete('reports/' + id, { delete: id })
                if (res.response.status === 200) {
                    onSuccess(this.$t('form.responses.delete_success'))
                } else {
                    onError(this.$t('form.responses.delete_failed'))
                }
                return res
            }
        } catch (err) {
            console.warn('Error while deleting a report.', err)
        }
    },
}
