import { Model } from '@vuex-orm/core'
import Asset from './Asset'
import Meta from '@/orm/models/Meta'

export default class Activity extends Model {
    static entity = 'activities'

    static primaryKey = 'activityId'

    static fields() {
        // `this.belongsTo` is for belongs to relationship. The first argument
        // is the Model class, and second is the foreign key.
        return {
            activityId: this.attr(null),
            assetId: this.attr(null),
            entity: this.attr(Activity.entity),
            icon: this.attr('battery-half'),
            activityName: this.attr(''),
            friendlyName: this.attr(''),
            presetId: this.attr(null),
            selected: this.boolean(true),
            metas: this.morphMany(Meta, 'entity_id', 'entity_name'),
            asset: this.belongsTo(Asset, 'assetId'),
            editable: this.boolean(true),
        }
    }

    /**
     * Get activity parent
     */
    get parent() {
        return Asset.query().whereId(this.assetId).first()
    }

    /**
     * Get activity children
     */
    get children() {
        return []
    }

    /**
     * Get parent site
     */
    get parentSite() {
        const asset = this.parent
        const circuit = asset.parent
        const meter = circuit.parent
        return meter.parent
    }

    /**
     * Get parent meter
     */
    get parentMeter() {
        const asset = this.parent
        const circuit = asset.parent
        return circuit.parent
    }

    /**
     * Get parent circuit
     */
    get parentCircuit() {
        const asset = this.parent
        return asset.parent
    }

    /**
     * Get parent asset
     */
    get parentAsset() {
        return this.parent
    }

    /**
     * Get relationships
     */
    get relationships() {
        // asset
        const asset = this.parent
        // circuit
        const circuit = asset.parent
        // meter
        const meter = circuit.parent
        // site
        const site = meter.parent

        return {
            asset,
            circuit,
            meter,
            site,
        }
    }
}
