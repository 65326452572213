import { Model } from '@vuex-orm/core'

export default class Meta extends Model {
    static entity = 'metas'

    static fields() {
        return {
            id: this.attr(null),
            key: this.attr(null),
            value: this.attr(null),
            scope: this.attr(''), // Optional. Eg: gas / electricity
            entity_id: this.attr(null),
            entity_name: this.attr(null),
        }
    }
}

/*

Some use cases:

return getters.query().whereHas('metas', query => {
    query.where('scope', scope).where('key', 'selected').where('value', true)
}).get()

const meta = Meta.query()
                .where('scope', 'home')
                .where('key', 'selected')
                .where('entity_name', item.entity)
                .where('entity_id', parseInt(item.$id))
                .first()

Meta.insert({
    data: {
        // id: 1,
        scope: 'home',
        key: 'selected',
        value: true,
        entity_name: item.entity,
        entity_id: parseInt(item.$id)
    }
})
*/
