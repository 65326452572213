
import ItemCard from './ItemCard'

export default {
    name: 'ProcessWaste',

    components: {
        ItemCard,
    },

    props: {
        active: Boolean,
        waste: {
            type: Array,
            required: true,
        },
    },
}
