import { orderBy, sum } from 'lodash'
import { date, getTimezoneOffset, systemTimezoneObservesDST, getDSTIndexes } from './date'
import { generatePeriodData, extremePoints } from './period-shift'
import moment from '~/composables/useMoment'
import { isNumeric } from '~/utils/tools'

export function kwhFromKw(kwValue, resolution) {
    return kwValue / (3600 / resolution)
}

// export function co2eFromKwh({ value, resolution, point, start, date }) {
//     if (!date && point && start && resolution) {
//         date = pointToDate({ start, point, resolution })
//     }
//     const year = moment(date).format('YYYY')
//     const emissionsFactor = carbonEmissionsFactorByYear(year)
//     return value * emissionsFactor
// }

export function calcLoadFactor(p, e, range) {
    if (!e || !p || !range) return 0
    return e / (p * range) * 100
}

export function carbonEmissionsFactorByYear(year) {
    switch (year) {
        case 2019:
            return 0.2556
        case 2020:
            return 0.23314
        default:
            return 0.23314
    }
}

export function pfFromKwAndKva(kwValue, kvaValue) {
    if (kwValue > kvaValue) {
        kwValue = kvaValue
    }
    return kwValue / kvaValue || NaN
}

export function kvarFromKwAndKva(kwValue, kvaValue) {
    if (kwValue > kvaValue) {
        kwValue = kvaValue
    }
    return Math.sqrt(kvaValue ** 2 - kwValue ** 2)
}

export function kvarhFromKwAndKva(kwValue, kvaValue, resolution) {
    return kvarFromKwAndKva(kwValue, kvaValue) * (resolution / 3600)
}

export function kvahFromKva(kvaValue, resolution) {
    return kvaValue * (resolution / 3600)
}

export function total(data = []) {
    return sum(data)
}

/**
 * @name dataSetsConsolidation
 * Calculates the consolidation of the data sets
 * Works for data sets in the following format
 * dataSet: { uid, startTime, data }
 *
 * @param { Array } dataSets
 * @param { Number } resolution in seconds (eg: 900, 1800)
 */
export function dataSetsConsolidation(dataSets, resolution) {
    // first order data sets by length in desc order
    dataSets = orderBy(dataSets, 'data.length', 'desc')
    // calculate the difference in points for each trace in relation to the longest trace
    // so we keep the loop light
    dataSets = dataSets.filter(ds => ds)
    if (!dataSets.length) return

    // start with longest trace
    const longestDataSet = generatePeriodData([], { start: dataSets[0].startTime, resolution })

    const pointsStart = dataSets.map((ds, idx) => {
        return extremePoints({
            min: dataSets[0].startTime,
            start: ds.startTime,
            resolution,
        }).pointStart
    })

    const data = longestDataSet.map((value, i) => {
        let n = 0
        let sum = value ?? 0
        let nDataSetsLength = dataSets.length

        while (dataSets[n]) {
            const j = i - pointsStart[n]
            if (dataSets[n].data[j] > 0) {
                sum += dataSets[n].data[j] ?? 0
            }
            if (dataSets[n].data[j] === undefined) {
                nDataSetsLength--
            }
            n++
        }

        // for pf we use avg
        if (String(dataSets[0].uid).includes('pf_')) {
            return sum / nDataSetsLength
        }

        return sum
    })

    const pointsLength = data.length
    const startTime = +date(dataSets[0].startTime, 'x')
    // TODO fix endTime for month resolution
    const endTime = startTime + pointsLength * resolution * 1e3
    const total = sum(data)

    return {
        data,
        total,
        endTime,
        startTime,
        pointsLength,
    }
}

/**
 * Transform aggregates
 *
 * Loops through the aggregates params and
 * divides certain units to 1000 in order to transfrom from W to kW
 *
 * @param {Object} aggregates
 *
 * @returns {Object} aggregates
 */
export function transformAggregates(aggregates) {
    for (const param in aggregates.max) {
        // divide those units value by 1000
        if (['AE', 'E', 'P', 'Q', 'RE', 'S'].includes(param)) {
            aggregates.max[param].value /= 1000
        }
    }
    return aggregates
}

/**
 *
 * @param {array<object>} datasets
 * @param datetime
 */
export function getPointsByDatetime(datasets, datetime) {
    datetime = moment(datetime)
    const points = []
    datasets.forEach(({ x0, dx, y, x, stackgroup }) => {
        if (stackgroup) {
            if (y.length === 1) {
                return y[0]
            }
            if (!dx) {
                let i = 0
                while (y[i]) {
                    if (moment(x[i]).hours(0) >= moment(datetime).hours(0)) {
                        points.push(y[i])
                        break
                    }
                    i++
                }
            }
            const index = Math.floor(datetime.diff(moment(x0)) / dx)
            points.push(y[index])
        }
    })
    return points
}

export function numberUnitFormatter(value, lookup, digits) {
    if (!value) return
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup.slice().reverse().find(item =>
        value >= item.value,
    ) || lookup[0]
    return {
        value: (value / item.value).toFixed(digits).replace(rx, '$1'),
        unit: item.unit,
    }
}

export function formatValueWithUnit(value, parameter, n = 2, returnObject = false, fallbackValue = '') {
    // first check if unit has special symbols for different exponents, like kg => tons
    // or fallback to classic k, M, G etc
    // console.log(value)

    const baseUnit = parameter.unit ?? '§'
    const units = parameter.units ? Object.values(parameter.units) : null
    const prefixes = [
        ['T', 1e12],
        ['G', 1e9],
        ['M', 1e6],
        ['k', 1e3],
        ['', 1],
    ]
    const r = {
        value,
        unit: baseUnit,
        appliedMultiplier: 1,
        unitBeforeValue: parameter.unitBeforeValue,
        parameter,
    }
    let formattedValue = value
    if (isNumeric(value)) {
        if (!parameter.disableMultipliers) {
            if (units) {
                for (const [symbol, e] of orderBy(units, '1', 'desc')) {
                    if (Math.abs(value) >= e) {
                        r.unit = symbol
                        r.value = value / e
                        r.appliedMultiplier = e
                        break
                    }
                }
            } else {
                // eslint-disable-next-line prefer-const
                for (let [k, [symbol, e]] of prefixes.entries()) {
                    if (Math.abs(value) >= e) {
                        if (parameter.unit.includes(symbol)) {
                            symbol = prefixes[k - 1][0]
                        }
                        r.unit = `${symbol}${parameter.unit.replace(/[kMG]/, '')}`
                        r.value = value / e
                        r.appliedMultiplier = e
                        break
                    }
                }
            }
        } else if (parameter.forcedMultiplier) {
            let symbol
            if (units) {
                symbol = units.find(([, value]) => value === parameter.forcedMultiplier)?.[0]
            } else {
                symbol = `${prefixes.find(([, value]) => value === parameter.forcedMultiplier)?.[0]}${parameter.unit.replace(/[kMG]/, '')}`
            }
            r.unit = `${symbol}`
            r.value = value / parameter.forcedMultiplier
            r.appliedMultiplier = parameter.forcedMultiplier
        }

        // if(
        //     (parameter.disableMultipliers || r.appliedMultiplier === 1) && (Math.abs(r.value) > 0 && Math.abs(r.value) < 1)
        // ){
        //     const p = Math.abs(Math.floor(Math.log10(Math.abs(r.value))))
        //     n = Math.max(n, p)
        //     console.log(r.value, p, n)
        // }
        formattedValue = nf(r.value, n, null, parameter.unitBeforeValue, parameter.disableMultipliers, r.appliedMultiplier)
    } else {
        return
    }
    if (returnObject) {
        return {
            ...r,
            formattedValue,
            formattedValueWithUnit: nf(r.value, n, r.unit, r.unitBeforeValue, parameter.disableMultipliers, r.appliedMultiplier),
        }
    }
    return nf(r.value, n, r.unit, r.unitBeforeValue, parameter.disableMultipliers, r.appliedMultiplier)
}

export function nf(v, n = 2, unit = null, unitBeforeValue = false, disableMultipliers, appliedMultiplier) {
    // if multiplier is not applied and value is too tiny, relevant digits won't be shown. The following address this issue
    if (
        (disableMultipliers || appliedMultiplier === 1) &&
        (Math.abs(v) > 0 && Math.abs(v) < 1)
    ) {
        const p = Math.abs(Math.floor(Math.log10(Math.abs(v))))
        n = Math.max(n, p)
    }
    v = Number(v).toLocaleString(undefined, { minimumFractionDigits: n, maximumFractionDigits: n })
    if (unit) {
        v = (unitBeforeValue ? `${unit} ` : '') +
            v +
            (!unitBeforeValue ? ` ${unit}` : '')
    }
    return v
}

export function filterBySiteIds(items, siteIds) {
    return items.map(item => {
        item.children = item.children && item.children.length ? filterBySiteIds(item.children, siteIds) : []

        if (!item.siteId || siteIds.includes(Number(item.siteId))) {
            item._siteId = `${item.siteId}`
            return item
        }
        return undefined
    }).filter(e => e)
}

export function mergeDifferentResolutionsDatasets(baseline, drilldown, timezone) {
    if (baseline.resolution < drilldown.resolution) {
        [drilldown, baseline] = [baseline, drilldown]
    }
    // Check if the one minute period is currently inside a DST period
    const _isDrilldownDST = moment(drilldown.startTime).isDST()
    // Check if the baseline data was modified to account for DST
    const { dstStartIndexes } = getDSTIndexes(baseline.startTime, baseline.endTime, baseline.resolution)
    const hasDSTIndexes = dstStartIndexes.length
    const userSystemOffset = new Date().getTimezoneOffset() // The user's OS offset with UTC
    const userSettingsOffset = getTimezoneOffset(timezone) // The user's setting offset with UTC
    /*  Fixes a niche scenario in non-DST timezones such as India
        Applicable where these three conditions are true:
        - the one min resolution start date has daylight savings applied
        - the baseline has the DST fix applied (CVP-2599)
        - the OS timezone does not observe DST */
    const nonDstTzOffset = _isDrilldownDST && hasDSTIndexes && systemTimezoneObservesDST() === false ? 60 : 0
    const offset = userSystemOffset + userSettingsOffset + nonDstTzOffset
    const startTime = baseline.startTime
    const baseResolution = baseline.resolution * 1e3
    const ddStartTime = moment(drilldown.startTime).add(offset, 'minutes').valueOf()
    const ddEndTime = moment(drilldown.endTime).add(offset, 'minutes').valueOf()
    let addedDrilldown = false

    const { dstEndIndexes } = getDSTIndexes(drilldown.startTime, drilldown.endTime, drilldown.resolution)
    const drilldownDSTEndIndex = dstEndIndexes[0]
    return baseline.data.reduce((acc, y, i) => {
        const x = startTime + (i * baseResolution)
        if (x < ddStartTime || x > ddEndTime) {
            acc.x.push(x)
            acc.y.push(y)
        } else if (!addedDrilldown) {
            drilldown.data.reduce((_, y, j) => {
                // Prevents the data being added twice during the DST hour
                if (!drilldownDSTEndIndex || j < drilldownDSTEndIndex || j >= drilldownDSTEndIndex + 60) {
                    const x = ddStartTime + j * drilldown.resolution * 1e3
                    acc.x.push(x)
                    acc.y.push(y)
                }
                return _
            })
            addedDrilldown = true
        }
        return acc
    }, { x: [], y: [] })
}

export function expandDataset(dataset) {
    return {
        ...dataset,
        data: dataset.data.reduce((acc, y, i) => {
            const x = dataset.startTime + (i * dataset.resolution * 1e3)
            acc.x.push(x)
            acc.y.push(y)
            return acc
        }, { x: [], y: [] }),
    }
}

/* Sort items positioning children items next to their parent
*/
export function parentSort(items) {
    const parents = new Map()
    for (let index = 0; index < items.length; index++) {
        const item = items[index]
        const parent = item.parentId
        if (parent) {
            if (!parents.has(parent)) parents.set(parent, [])
            parents.get(parent).push(item)
            items.splice(index, 1)
            index--
        }
    }
    parents.forEach((children, parent) => {
        const index = items.findIndex(c => c.id === parent)
        items.splice(index + 1, 0, ...children)
    })
    return items
}
