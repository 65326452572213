
import FormMixin from './form.mixin'

export default {
    name: 'EditDemand',

    mixins: [FormMixin],

    data() {
        return {

        }
    },
}
