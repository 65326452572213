
import FormMixin from './form.mixin'
import PeriodSelect from '~/components/common/period-select'
import moment from '~/composables/useMoment'

export default {
    name: 'SelectProcessName',
    components: { PeriodSelect },
    mixins: [FormMixin],

    // validations() {
    //     // return {
    //     //     draft: {
    //     //         required,
    //     //         minLength: minLength(1),
    //     //         $each: {
    //     //             name: {
    //     //                 required,
    //     //                 minLength: minLength(3),
    //     //             },
    //     //         },
    //     //     },
    //     // }
    // },

    computed: {
        min() {
            return moment().subtract(6, 'months').format()
        },
        max() {
            return moment().subtract(1, 'days').format()
        },
    },

    methods: {
        onPeriodChange(e, k) {
            this.$set(this.draft.steps[k], 'range', e)
        },
        addProcess() {
            this.draft.steps.push({
                name: '',
                outputType: 'Internal',
                range: {
                    startDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                    endDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                    startTime: moment().subtract(1, 'day').startOf('day').add(8, 'hours').format('HH:mm'),
                    endTime: moment().subtract(1, 'day').startOf('day').add(16, 'hours').format('HH:mm'),
                },
            })
        },
        removeProcess(i) {
            this.draft.steps.splice(i, 1)
        },
    },
}
