
import { genericUnits } from '../const'
import FormMixin from './form.mixin'

export default {
    name: 'ShippingOptions',

    mixins: [FormMixin],

    computed: {
        genericUnits() {
            return genericUnits
        },
    },
}
