export default () => ({
    initialised: false,
    loading: true,
    // Selected Unit ID
    unitId: 'kwh',
    unitSummaries: [],
    dailyReadings: null,
    monthlyReadings: null,
    aggregates: [],
    baseEntity: 'sites', // sites | presets
    active: { // active / isolated
        entity: 'sites', // can change to circuits | pipes
        ids: [],
    },
    selected: { // selected entities
        entity: 'sites', // can change to circuits | pipes
        ids: [],
    },
    commodity: undefined, // all
    commodities: [], //  [undefined, 'gas', 'power'],
    comparison: null,
})
