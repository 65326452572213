
import { set, compact } from 'lodash'
import { decimal, required, minLength } from 'vuelidate/lib/validators'
import FormMixin from './form.mixin'
import EventDemandSampler from '~/components/events/event-demand-sampler'
import EntitiesSelect from '~/components/common/entities-select-treeview/entities-select-manager'

export default {
    name: 'EditEntities',

    components: {
        EntitiesSelect,
        EventDemandSampler,
    },

    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                occurrence: {
                    demandType: {
                        required,
                    },
                    demandValue: {
                        required,
                        decimal,
                        minValue: val => Number(val) >= 0.00001,
                    },
                },
                entities: {
                    required,
                    minLength: minLength(1),
                },
            },
        }

        // if (this.draft.occurrence.demandType === 'historic') {
        //     schema.draft.occurrence.demandRange = {
        //         endDate: { required },
        //         endTime: { required },
        //         startDate: { required },
        //         startTime: { required },
        //     }
        // }

        // if (this.draft.occurrence.demandType === 'similar') {
        //     schema.draft.occurrence.similarProcess = { required }
        // }

        return schema
    },

    data() {
        return {
            title: 'Edit entities',
            demandRangeMenu: false,
            similarProcesses: [],
        }
    },

    computed: {
        disabled() {
            return {
                power: (items, selectedItems) => {
                    const allowedSiteIds = compact([...selectedItems.map(({ siteId }) => siteId), this.draft.allowedSiteId])
                    if (allowedSiteIds.length) {
                        items.forEach((item, k) => {
                            set(items[k], 'locked', !allowedSiteIds.includes(item.id))
                        })
                    }
                },
                gas: (items, selectedItems) => {
                    const allowedSiteIds = compact([...selectedItems.map(({ siteId }) => siteId), this.draft.allowedSiteId])
                    if (allowedSiteIds.length) {
                        items.forEach((item, k) => {
                            set(items[k], 'locked', !allowedSiteIds.includes(item.id))
                        })
                    }
                },
            }
        },
    },

    watch: {
        'draft.entities': {
            immediate: true,
            async handler(entities) {
                if (entities.length) {
                    const result = await this.$api.events.loadSimilar({
                        entities: entities.map(({ id, type }) => ({ id, type })),
                    })
                    this.similarProcesses = result.flatMap(group => {
                        return group.events.flatMap(event => {
                            return event.schedule.periods
                        })
                    })
                }
            },
        },
        'draft.occurrence.demandType': {
            handler(val) {
                // close historic demand range menu
                if (this.demandRangeMenu && val !== 'historic') {
                    this.demandRangeMenu = false
                }
            },
        },
        'draft.occurrence.similarProcess': {
            handler(val) {
                // update demandValue from process
                if (val) {
                    this.draft.occurrence.demandValue = val.details.demand.kWh
                }
            },
        },
    },
}
