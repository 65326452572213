
import { mapGetters } from 'vuex'
import { cloneDeep, compact, find } from 'lodash'
import uniqBy from 'lodash/uniqBy'
import { genericUnits } from '../const'

// Components
import FormMixin from './form.mixin'
import MaterialCard from '~/components/flows/cards/MaterialCard'
import EnergyCard from '~/components/flows/cards/EnergyCard'
import MaterialDialog from '~/components/flows/material-dialog'

export default {
    name: 'MaterialsForm',

    components: {
        MaterialCard,
        MaterialDialog,
        EnergyCard,
    },

    mixins: [FormMixin],

    props: {
        purchaseFields: Boolean,
        type: {
            type: String,
            default: 'material',
        },
        forceSelectedOnTop: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        expandedPanel: 0,
        dateMenu: false,
        materialDialog: false,
        searchMaterial: '',
        selectedOnTop: false,
        inventory: [],
        energy: [],
        entities: [],
        defaults: {
            id: null,
            index: -1,
            outputType: 'Internal',
        },
    }),

    computed: {
        ...mapGetters('flows', ['allMaterials', 'allFuels', 'getInventory']),
        siteId() {
            return this.dialog?.dataSet?.find(i => i.id === 'origin')?.draft?.id
        },
        inventoryItems() {
            let items
            if (this.type === 'material') {
                items = cloneDeep(this.allMaterials).filter(e => !['disposal', 'energy'].includes(e.categoryName?.toLowerCase()))
                // filter inventory
                // if (this.defaults.id === 'materials') {
                //     const siteId = this.siteId
                //     items = items.filter(item => item.inventory[siteId]?.[0]?.value > 0)
                // }
            } else if (this.type === 'fuel') {
                items = cloneDeep(this.allFuels)
            } else if (this.type === 'all') {
                items = cloneDeep([...this.allMaterials, ...this.allFuels]).filter(e => e.categoryName?.toLowerCase() !== 'disposal')
            } else if (this.type === 'input') {
                const __data = this.__data()
                items = cloneDeep(this.allMaterials).filter(e => !e.friendlyName)
                const ids = this.id.split('-')
                const pid = ids[1]
                const outputFromPreviousStep = __data[`output-${pid - 1}`] || []
                if (outputFromPreviousStep) {
                    // console.log(outputFromPreviousStep)
                }

                // items = remove(items, e => outputFromPreviousStep.map(e => e.id).includes(e.id) )
                // items.push(...outputFromPreviousStep.map(e => {
                //     return {
                //         ...this.allMaterials.find(({ id }) => id === e.id),
                //         materialId: e.id,
                //         value: e.value,
                //     }
                // }))
                items = [...outputFromPreviousStep.map(e => {
                    return {
                        ...this.allMaterials.find(({ id }) => id === e.id),
                        materialId: e.id,
                        value: e.value,
                        showInfoIcon: true,
                    }
                }), ...items]
                items = uniqBy(items, 'id')
                // console.log(items)
            } else if (this.type === 'output') {
                items = cloneDeep(this.allMaterials).filter(e => !!e.friendlyName).map(e => ({ ...e, name: e.friendlyName }))
            }

            if (this.searchMaterial) {
                items = items.filter(i => {
                    return i.name.toLowerCase().includes(this.searchMaterial)
                })
            }

            if (this.selectedOnTop) {
                items = items.sort((a, b) => {
                    if (this.draft.find(({ id }) => a.id === id)) {
                        return -1
                    }
                    return 0
                })
            }
            // const selectedIds = this.draft.map(e => e.id)
            return items // .filter(e => selectedIds.includes(e.id))
        },
        genericUnits() {
            return genericUnits
        },
    },

    watch: {
        forceSelectedOnTop(e) {
            if (e) {
                this.selectedOnTop = e
            }
        },
    },

    mounted() {
        window[`__MATS_${this._uid}`] = this
    },

    methods: {
        init() {
            const __data = this.__data()
            const ids = this.id.split('-')
            const id = ids[0]
            const pid = ids[1]
            // console.log('init', this.id)
            // set defaults - needed for outputType for output materials
            this.defaults.id = id
            this.defaults.index = parseInt(ids[1])
            this.defaults.outputType = __data.options[this.defaults.index]?.outputType || 'Internal'

            if (this.type === 'fuel') {
                this.energy = __data[`machine-${pid}`]?.template?.profile?.entities || []
                this.entities = __data[`machine-${pid}`]?.entities || []
            }

            const machine = find(__data, (e, k) => k === `machine-${pid}`)
            let items = []
            if (id === 'fuels') {
                items = (machine?.template?.input?.fuels || []).map(fuel => {
                    return {
                        ...fuel,
                        combustionProcessId: this.allFuels.find(e => e.id === fuel.materialId)?.combustionProcessId,
                    }
                })
            }
            if (id === 'input') {
                const outputFromPreviousStep = __data[`output-${pid - 1}`] || []
                items = (machine?.template?.input?.materials || [])
                    .concat(outputFromPreviousStep.map(e => {
                        return {
                            ...this.allMaterials.find(({ id }) => id === e.id),
                            materialId: e.id,
                            showInfoIcon: true,
                            value: e.value,
                        }
                    }))
            }
            if (id === 'output') {
                items = machine?.template?.output?.materials || []
                // attach output type
                items = items.map(item => {
                    return {
                        ...item,
                        outputType: this.defaults.outputType,
                    }
                })
            }
            if (id === 'waste') {
                items = machine?.template?.output?.waste || []
            }

            // console.log(cloneDeep(items))
            this.draft = uniqBy(compact(items.map(e => {
                const m = this.allMaterials.find(({ id }) => id === e.materialId || id === e.id)
                if (!m) {
                    console.log('not found', e)
                    return undefined
                }
                return {
                    ...e,
                    id: e.materialId,
                    unit: m.units ? m.units[0] : 'kg',
                }
            })), 'id')
            // console.log(this.id, this.draft)
            const siteId = __data.location?.siteId || __data.origin?.siteId || __data.destination?.siteId
            if (siteId) {
                this.inventory = this.getInventory(siteId)
            }
        },
        addOrUpdateItem(item) {
            // if (this.defaults.id === 'output' && !item.outputType) {
            //     item.outputType = this.defaults.outputType || 'Internal'
            // }
            this.draft = [...this.draft.filter(({ id }) => item.id !== id), item]
        },

        removeItem({ id }) {
            this.draft = this.draft.filter(item => item.id !== id)
        },

        onReveal(id) {},
    },
}
