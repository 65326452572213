
import { mapMutations } from 'vuex'
import DialogHeader from '~/components/dialogs/header'
import SelectAppMode from '~/components/widgets/select-app-mode'
export default {
    name: 'SelectAppModeDialog',
    components: { DialogHeader, SelectAppMode },
    data: () => ({
        dialog: false,
    }),
    mounted() {
        this.displayLoader(false)
        this.dialog = true
    },
    methods: {
        ...mapMutations('global', {
            displayLoader: 'SET_LOADER_VISIBLE',
        }),
        saveChoice() {
            this.dialog = false
        },
    },
}
