
import FormMixin from '~/components/flows/forms/form.mixin'
import { genericUnits } from '~/components/flows/const'

export default {
    name: 'SelectInputs',

    mixins: [FormMixin],

    props: {
        inputs: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            title: 'Process',
        }
    },

    computed: {
        genericUnits() {
            return genericUnits
        },
    },

    methods: {
        // getSupplierById(supplierId){
        //     return this.supplierNodes.find(({ id }) => id === supplierId)
        // }
    },
}
