export function float(val, rounding = 2) {
    return parseFloat(Number(val).toFixed(rounding))
}

export function numberWithOrdinal(n) {
    if (!n) return ''
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export function getMax(data, withIndex = false) {
    if (withIndex) {
        return data.reduce((max, p, k) => {
            return p > max.value
                ? {
                    value: p,
                    index: k,
                }
                : max
        }, {
            value: data[0],
            index: null,
        })
    } else {
        return data.reduce((max, p) => {
            return p > max ? p : max
        }, data[0])
    }
}

export function getMin(data, withIndex = false) {
    if (withIndex) {
        return data.reduce((min, p, k) => {
            return p < min.value
                ? {
                    value: p,
                    index: k,
                }
                : min
        }, {
            value: data[0],
            index: null,
        })
    } else {
        return data.reduce((min, p) => {
            return p < min ? p : min
        }, data[0])
    }
}

export function randomInteger(min = 0, max = Number.MAX_SAFE_INTEGER) {
    return Math.ceil(Math.random() * (max - min) + min)
}

export function arrayOfRandomIntegers(length = 0, min = 0, max = Number.MAX_SAFE_INTEGER) {
    return Array.from({ length }, () => randomInteger(min, max))
}

/**
 * Percentage difference
 *
 * @param {Number} a
 * @param {Number} b
 * @returns {Number} diff
 */
export function percentageDifference(a, b, rounding = 2) {
    const value = float((a - b) / b * 100, rounding)
    if (value === Infinity) return 0
    return value
}

/**
 * Percentage Difference between two numbers
 * @param  {int} base First and original number
 * @param  {int} peak Second new number to differentiate
 * @param  {boolean} round Round return value to nearest whole numer?
 * @return {int} Percentage difference between lowest and highest number
 */
export function percentDiff(base, peak, round = false) {
    const diff = (peak - base) / base * 100
    return round ? Math.round(diff) : diff
}

export function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        console.warn('lines are of length 0')
        return false
    }

    const denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
        console.warn('lines are parallel')
        return false
    }

    const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        console.warn('intersection along the segments')
        return false
    }

    // Return a object with the x and y coordinates of the intersection
    const x = x1 + ua * (x2 - x1)
    const y = y1 + ua * (y2 - y1)
    return { x, y }
}
