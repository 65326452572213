
import { genericUnits } from '../const'
import FormMixin from './form.mixin'

export default {
    name: 'EditOutput',

    mixins: [FormMixin],

    data(vm) {
        return {
            title: vm.value.id ? 'Edit output' : 'Add new output',
        }
    },

    computed: {
        genericUnits() {
            return genericUnits
        },
    },
}
