
import { mapActions, mapState } from 'vuex'
import GetStartedWithNetZero from '~/components/carbon/get-started/index'
import DialogHeader from '~/components/dialogs/header'

export default {
    name: 'GetStartedDialog',

    components: {
        GetStartedWithNetZero,
        DialogHeader,
    },

    provide() {
        return { dialog: this }
    },

    data: () => ({
        value: false,
        isFullScreen: false,
    }),

    computed: {
        ...mapState([
            'getStarted',
        ]),
    },

    watch: {
        getStarted(value) {
            this.value = Boolean(value)
        },
    },

    methods: {
        ...mapActions([
            'toggleGetStarted',
        ]),

        toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen
        },
    },
}
