import { Model } from '@vuex-orm/core'
import Meter from './Meter'

export default class Contract extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'contracts'

    static primaryKey = 'id'

    static apiConfig = {}

    /**
     * @returns {Fields}
     */
    static fields() {
        return {
            id: this.attr(null),
            mpan: this.attr(null),
            start_date: this.attr(null),
            end_date: this.attr(null),
            supplier_mpid: this.attr(null),
            day_rate: this.attr(null),
            night_rate: this.attr(null),
            offpeak_weekend_rate: this.attr(null),
            standing_charge: this.attr(null),
            settlement_charge: this.attr(null),
            supplier_elements: this.attr(null),
            passed_through_elements: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            rules: this.attr(null),
            supplier_name: this.attr(null),
            is_live: this.attr(null),
            is_past: this.attr(null),
            is_future: this.attr(null),
            reliability: this.attr(null),
            meter_number: this.attr(null),
            standing_charges: this.attr(null),
            unit_rates: this.attr(null),
        }
    }

    get meter() {
        return Meter.query().where('mpanBottom', this.mpan || this.meter_number).first()
    }
}
