
import { mapGetters } from 'vuex'
import { genericUnits } from '../const'
import FormMixin from './form.mixin'
import MaterialDialog from '~/components/flows/material-dialog'

export default {
    name: 'MaterialForm',

    components: {
        MaterialDialog,
    },

    mixins: [FormMixin],

    props: {
        error: {
            type: Object,
            required: true,
        },
        purchaseFields: Boolean,
    },

    data: () => ({
        dateMenu: false,
        materialDialog: false,
        searchMaterial: '',
    }),

    computed: {
        ...mapGetters('flows', [
            'inventoryNodes',
        ]),
        materialItems() {
            // eslint-disable-next-line eqeqeq
            return this.inventoryNodes.filter(i => i.type == 'material').map(i => {
                i.icon = 'asset'
                return i
            })
        },
        genericUnits() {
            return genericUnits
        },
    },

    methods: {
        createMaterial() {
            this.materialDialog = !this.materialDialog
            if (this.draft.material) {
                this.draft.material = null
            }
        },
    },
}
