
import { required, minLength, decimal } from 'vuelidate/lib/validators'
import DraftWaste from './draft-waste.vue'
import FormMixin from './form.mixin'
import { scrollToBottom } from '~/utils/tools'

export default {
    name: 'EditWaste',

    components: {
        DraftWaste,
    },

    mixins: [FormMixin],

    validations: {
        draft: {
            output: {
                items: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        name: {
                            required,
                            minLength: minLength(2),
                        },
                        amount: {
                            required,
                            decimal,
                            minValue: val => Number(val) >= 0.00001,
                        },
                        disposalType: {
                            required,
                        },
                        unit: {
                            required,
                        },
                        wasteType: {
                            required,
                        },
                    },
                },
            },
        },
    },

    data() {
        return {
            title: 'Disposal settings', // vm.value.id ? 'Edit waste' : 'Add waste',
            expandedPanel: 0,
        }
    },

    methods: {
        addWaste() {
            this.draft.output.items.push({
                name: 'Waste',
                amount: 0,
                unit: 'kg',
                disposalType: undefined,
                wasteType: undefined,
            })
            setTimeout(() => {
                this.expandedPanel = this.draft.output.items.length - 1
                scrollToBottom(this.$refs.panels.$el)
            }, 200)
        },

        removeWaste(i) {
            this.draft.output.items = this.draft.output.items.filter((_v, k) => k !== i)
        },
    },
}
