import EditDemand from './edit-demand'
import EditEntities from './edit-entities'
import EditShipping from './edit-shipping'
import EditSupplier from './edit-supplier'
import EditInputAmount from './edit-input-amount'
import RenameNode from './rename-node'
import EditMaterial from './edit-material'
import EditOutput from './edit-output'
import EditWaste from './edit-waste'
import EditCustomerShippedAmount from './edit-customer-shipped-amount'
import ComboAddMaterialAndSupplier from './combo-add-material-and-supplier'
import EditSite from './edit-site'
import MaterialForm from './material'
import MaterialsForm from './materials'
import SelectMaterials from './select-materials'
import SelectInputs from './select-inputs'
import EditLocation from './edit-location'
import ShippingOptions from './shipping-options'
import ProcessDetails from './ProcessDetails'
import ProcessMachine from './ProcessMachine'
import ProcessSummary from './ProcessSummary'
import SelectCategory from './select-category'
import SelectBusiness from './select-business'
import SelectSite from './select-site'
import SelectFlowType from './select-flow-type'
import SelectMachine from './select-machine'
import SelectWaste from './select-waste'
import SelectProcessName from './select-process-name'

export default {
    EditDemand,
    EditEntities,
    EditShipping,
    EditSupplier,
    EditInputAmount,
    RenameNode,
    EditOutput,
    EditWaste,
    EditCustomerShippedAmount,
    EditMaterial,
    ComboAddMaterialAndSupplier,
    EditSite,
    MaterialForm,
    MaterialsForm,
    SelectMaterials,
    SelectInputs,
    EditLocation,
    ShippingOptions,
    ProcessDetails,
    ProcessMachine,
    ProcessSummary,
    SelectCategory,
    SelectBusiness,
    SelectSite,
    SelectFlowType,
    SelectMachine,
    SelectWaste,
    SelectProcessName,
}
