
import { mapGetters } from 'vuex'
import AssetTagsAutocomplete from '../AssetTagsAutocomplete.vue'
import CarbonAssetForm from '~/components/carbon/asset-form.vue'

export default {
    name: 'AddAssetsCard',

    components: {
        CarbonAssetForm,
        AssetTagsAutocomplete,
    },

    data: () => ({
        action: null,
        tag: null,
    }),

    computed: {
        ...mapGetters('entities/carbon-sources', [
            'assets',
            'categories',
            'itemByTag',
        ]),
        progressValue() {
            return Math.min(100 / 3 * this.assets.length, 100)
        },
    },

    watch: {
        tag(tag) {
            if (!this.action) return
            let payload
            if (tag) {
                const item = this.itemByTag(tag)
                payload = {
                    parent: item.$toJson(),
                    parentId: item.id,
                }
            }
            this.$set(this.action, 'payload', payload)
        },
    },

    methods: {
        toggleAction() {
            if (this.action) {
                this.action = null
                this.tag = undefined
            } else {
                this.action = {
                    type: 'ADD__ASSET',
                    readOnlyCategory: true,
                }
            }
        },
    },
}
