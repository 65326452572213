import { Model } from '@vuex-orm/core'

export default class RagConfiguration extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'ragconfigurations'

    static apiConfig = {}

    /**
     * @returns {Fields}
     */
    static fields() {
        return {
            gspGroup: this.attr(null),
            red: this.attr(null),
            amber: this.attr(null),
            green: this.attr(null),
        }
    }
}
