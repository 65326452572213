
import { every, isFunction, isString, zipObject } from 'lodash'
import FlowInventory from '~/components/flows/flow-inventory'
import formComponents from '~/components/flows/forms'

export default {
    components: {
        FlowInventory,
        ...formComponents,
    },
    provide() {
        return {
            __data: () => this.computedData,
            dialog: this,
        }
    },
    props: {
        buttonTrueText: {
            type: String,
            default: 'Continue',
        },
        buttonFalseText: {
            type: String,
            default: 'Cancel',
        },
        buttonTrueColor: {
            type: String,
            default: 'primary',
        },
        buttonFalseColor: {
            type: String,
            default: 'default',
        },
        buttonFalseFlat: {
            type: Boolean,
            default: false,
        },
        buttonTrueFlat: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'primary',
        },
        icon: {
            type: String,
            default: null,
        },
        components: {
            type: Array,
            required: true,
        },
        persistent: Boolean,
        title: {
            type: String,
            default: '',
        },
        editableTitle: Boolean,
        fullscreen: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [Number, String],
            default: 900,
        },
        cardProps: {
            type: Object,
            default: () => ({
                height: '600px',
            }),
        },
        flowType: {
            type: Object,
            default: () => null,
        },
    },
    data: vm => ({
        step: 1,
        titleModel: String(vm.title),
        isEditingTitle: false,
        myComponents: vm.components,
        inventoryOpen: false,
        loading: false,
        mask: false,
    }),
    computed: {
        myActiveComponent() {
            return this.myComponents[this.step - 1]
        },
        activeComponentId() {
            return this.myActiveComponent?.id
        },
        activeComponent() {
            return this.$refs.components.find((c, _i) => c?.id === this.activeComponentId)
        },
        activeProcessIndex() {
            const id = +this.activeComponentId
            if (id) {
                const processRgx = /[0-9]/.exec(id)
                if (Array.isArray(processRgx)) {
                    return Number(processRgx[0])
                } else if (id === 'summary') {
                    return this.processesData.length - 1
                }
            }
            return 0
        },
        computedData() {
            return {
                step: this.step,
                flowType: this.flowType,
                ...(this.$refs.components ? zipObject(this.$refs.components.map((c, k) => c.id || k), this.$refs.components.map(c => c.draft)) : {}),
            }
        },
        dataSet() {
            return this.myComponents
                .filter(({ id }) => !['flowType', 'summary'].includes(id))
                .map(({ id: uid }) => {
                    const component = this.$refs.components?.find(c => c?.id === uid)
                    let id = uid
                    if (/(-)[0-9]/.test(id)) {
                        id = uid.split('-').shift()
                    }
                    return {
                        id,
                        uid,
                        draft: component?.draft,
                    }
                })
        },
        processesData() {
            const dataSet = this.dataSet
            const options = dataSet.find(({ id }) => id === 'options')
            if (
                !options ||
                this.activeFlowType?.id !== 'manufacture' // other flows are 'delivery', 'purchase'
            ) return []
            const props = ['machine', 'fuels', 'input', 'output', 'waste'] // 'location', 'category'
            return options.draft.map((process, processIdx) => {
                return {
                    ...process,
                    items: props.map(prop => {
                        const uid = [prop, processIdx].join('-')
                        return dataSet.find(d => d.uid === uid)
                    }),
                }
            })
        },
        activeFlowType() {
            return this.$refs.components?.find(c => c?.id === 'flowType')?.draft
        },
    },
    mounted() {
        document.addEventListener('keyup', this.onEnterPressed)
    },
    destroyed() {
        document.removeEventListener('keyup', this.onEnterPressed)
    },
    methods: {
        activeComponentIsValid() {
            const stepId = this.myComponents[this.step - 1].id
            const value = this.activeComponent.draft

            switch (true) {
                case stepId.includes('flowType'):
                    if (value.id && isString(value.id)) {
                        return true
                    } else {
                        this.$toast.info('Please select the flow type')
                        return false
                    }
                case stepId.includes('options'):
                    if (value.name !== '' && every(value.steps, e => e.name !== '')) {
                        return true
                    } else {
                        this.$toast.info('Please verify all the processes have a valid name')
                        return false
                    }
                case stepId.includes('location'):
                case stepId.includes('destination'):
                case stepId.includes('origin'):
                    if (value.id) {
                        return true
                    } else {
                        this.$toast.info('Please select a location')
                        return false
                    }
                case stepId.includes('category'):
                    if (value.id) {
                        return true
                    } else {
                        this.$toast.info('Please select a category')
                        return false
                    }
                case stepId.includes('machine'):
                    if (value.id) {
                        return true
                    } else {
                        this.$toast.info('Please select a machine')
                        return false
                    }
                case stepId.includes('fuels'):
                case stepId.includes('input'):
                case stepId.includes('output'):
                    if (every(value, e => e.value !== '' && Number(e.value) > 0)) {
                        return true
                    } else {
                        this.$toast.info('Please check the items you selected and their amount')
                        return false
                    }
                case stepId.includes('waste'):
                    if (every(value, e => e.amount !== '' && Number(e.amount) > 0 && e.disposalProcessId)) {
                        return true
                    } else {
                        this.$toast.info('Please check the items you selected, their amount and the disposal type')
                        return false
                    }
                case stepId.includes('summary'):
                    return true
                case stepId.includes('materials'):
                    if (value.length > 0 && every(value, e => e.value !== '' && Number(e.value) > 0)) {
                        return true
                    } else {
                        this.$toast.info('Please check the items you selected and their amount')
                        return false
                    }
            }

            return false
        },
        onEnterPressed(e) {
            if (e.keyCode === 13) {
                e.stopPropagation()
                this.next()
            }
        },
        prev() {
            if (this.step === 1) {
                this.cancel()
            } else {
                this.step--
            }
        },
        next() {
            if (!this.activeComponentIsValid()) return
            if (isFunction(this.myComponents[this.step - 1].onChange)) {
                this.myComponents[this.step - 1].onChange(this.computedData, this)
            }
            if (this.step === this.myComponents.length) {
                this.save()
            } else {
                this.step++
            }
        },
        cancel() {
            this.resValue = {}
            this.$destroy()
        },
        save() {
            this.resValue = zipObject(this.$refs.components.map((c, k) => c.id || k), this.$refs.components.map(c => c.draft))
            this.$destroy()
        },
        pickComponent(e) {
            if (isString(e)) {
                return e
            } else if (isFunction(e)) {
                return e(this.computedData)
            }
        },
    },
}
