import { forOwn, isObject, isNumber } from 'lodash'
import throttle from 'lodash/throttle'
const DEFAULT_LOADING_RESET_TIMEOUT = 5e3
const timeoutReferences = {}
function setLoadingCounters({ state, commit }, payload) {
    const data = {}
    // console.log(payload)
    forOwn(payload, (e, path) => {
        let resetAfter = DEFAULT_LOADING_RESET_TIMEOUT
        if (isObject(e) && isNumber(e.value)) {
            // if(e.value === get(state.loadingCounters, path)){
            //     // console.log('skipping1')
            //     return
            // }
            data[path] = e.value
            if (isNumber(e.resetAfter)) {
                resetAfter = e.resetAfter
            }
        } else {
            // if(e === get(state.loadingCounters, path)){
            //     // console.log('skipping2')
            //     return
            // }
            data[path] = e
        }
        if (data[path]) {
            // console.log(`setting autoreset after ${resetAfter}ms for ${path}`)
            clearTimeout(timeoutReferences[path])
            timeoutReferences[path] = setTimeout(() => {
                // if(Number(get(state.loadingCounters, path)) === 0){
                //     console.log('shouldnt get here')
                // }
                // console.log(`executing autoreset after ${resetAfter}ms for ${path}`)
                // commit('SET_LOADING_COUNTERS', { [path]: 0 })
            }, resetAfter)
        } else {
            clearTimeout(timeoutReferences[path])
            // console.log(`skipping autoreset for ${path}`)
        }
    })
}
export default {
    nuxtServerInit({ state, commit }, ctx) {
        commit('TOGGLE_CHART_SIDE', this.$cookie.get('chart_on_right_side'))
    },
    toggleChartSide({ state, commit }) {
        const v = !state.chartOnRightSide
        this.$cookie.set('chart_on_right_side', v)
        commit('TOGGLE_CHART_SIDE', v)
    },
    setSpinnerLoader({ state, commit }, payload) {
        commit('SET_SPINNER_LOADER', payload)
    },
    setDownloadLoader({ state, commit }, payload) {
        commit('SET_DOWNLOAD_LOADER', payload)
    },
    setLoadingCounters: throttle(setLoadingCounters, 100, { leading: true }),

    /**
     * asynchronously generate a treeview so ui doesn't freeze
     * @param commit
     * @param rootGetters
     * @returns {Promise<unknown>}
     */
    generateEntitiesTreeview({ commit, rootGetters }) {
        return new Promise((resolve, reject) => {
            const sites = rootGetters['entities/sites/all']().map(({ id, name }) => ({ id, name, type: 'site' }))
            const meters = rootGetters['entities/meters/all']().map(({ id, name, siteId }) => ({ id, name, parentId: siteId, type: 'meter' }))
            const circuits = rootGetters['entities/circuits/all']()
                .map(({ id, name, meterId, siteId }) => ({ id, name, parentId: meterId, type: 'circuit', siteId }))
            const gasMeters = rootGetters['entities/gas-meters/all']().map(({ id, name, siteId }) => ({ id, name, parentId: siteId, type: 'gas-meter' }))
            const pipes = rootGetters['entities/pipes/all']().map(({ id, name, meterId, siteId }) => ({ id, name, parentId: meterId, type: 'pipe', siteId }))
            const items = {
                power: [],
                gas: [],
                virtualMeters: [],
            }
            function addItem(sites, items) {
                const site = sites.shift()
                const siteForGas = { ...site }
                site.children = meters.filter(({ parentId }) => parentId === site.id).map(meter => ({
                    ...meter,
                    children: circuits.filter(({ parentId }) => parentId === meter.id),
                }))
                siteForGas.children = gasMeters.filter(({ parentId }) => parentId === site.id).map(meter => ({
                    ...meter,
                    children: pipes.filter(({ parentId }) => parentId === meter.id),
                }))
                items.power.push(site)
                items.gas.push(siteForGas)
                if (sites.length) {
                    setTimeout(() => {
                        addItem(sites, items)
                    }, 1)
                } else {
                    // console.log(items)
                    commit('SET_ENTITIES_TREEVIEW', items)
                    resolve()
                }
            }
            addItem(sites, items)
        })
    },

    setDialogWaiting({ commit }, v) {
        commit('SET_DIALOG_WAITING', v)
    },
}
