
import Logo from '~/components/logo'

export default {
    name: 'EulaAcceptance',

    components: {
        Logo,
    },

    data: () => ({
        dialog: null,
    }),

    mounted() {
        this.dialog = true
    },

    methods: {
        async accept() {
            const res = await this.$api.auth.acceptEula()
            if (res.success) {
                this.$emit('on-dialog-close')
                this.dialog = false
            }
        },
    },
}
