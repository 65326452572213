
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, debounce, forOwn, set, isArray, isFunction } from 'lodash'

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        types: {
            type: Array,
            default: () => ['power', 'gas', 'materials', 'processes'],
        },
        disabled: {
            type: Object,
            default: () => {},
        },
        error: Boolean,
    },
    data(vm) {
        return {
            search: null,
            loading: false,
            selectedTab: vm.types[0],
            selectedEntities: {
                power: vm.value.filter(({ type, commodity }) => type === 'clamp' && commodity === 'power'),
                gas: vm.value.filter(({ type, commodity }) => type === 'clamp' && commodity === 'gas'),
                materials: vm.value.filter(({ type }) => type === 'virtual'),
            },
            open: {
                power: [],
                gas: [],
                materials: [],
            },
            tabs: [
                {
                    id: 'power',
                    label: 'Power',
                    icon: '$bolt',
                },
                {
                    id: 'gas',
                    label: 'Gas',
                    icon: '$fire',
                },
                {
                    id: 'materials',
                    label: 'Materials',
                    icon: '$cubes',
                },
                {
                    id: 'processes',
                    label: 'Processes',
                    icon: '$gears',
                },
            ],
            droppedItems: [],
        }
    },
    computed: {
        ...mapGetters('global', {
            treeviewItems: 'treeviewItems',
        }),
        items() {
            const items = cloneDeep(this.treeviewItems)
            forOwn(this.disabled, (disabledItems, type) => {
                if (isArray(disabledItems)) {
                    disabledItems.forEach(k => {
                        set(items, `${type}${k}.locked`, true)
                    })
                } else if (isFunction(disabledItems)) {
                    disabledItems(items[type], this.selectedEntities[type])
                }
            })
            return items
        },
    },
    watch: {
        selectedEntities: {
            deep: true,
            handler: debounce(function(v) {
                // console.log({ selectedEntities: v })
                const r = [
                    ...v.power.map(e => {
                        return {
                            type: 'clamp',
                            id: e.id,
                            commodity: 'power',
                            siteId: e.siteId,
                            // siteId: this.items.power.find(({ id }) => e).siteId
                        }
                    }),
                    ...v.gas.map(e => {
                        return {
                            type: 'clamp',
                            id: e.id,
                            commodity: 'gas',
                            siteId: e.siteId,
                            // siteId: this.items.gas.find(({ id }) => e).siteId
                        }
                    }),
                    // ...v['materials'].map(e => {
                    //     return {
                    //         type: 'virtual',
                    //         id: e
                    //     }
                    // }),
                ]
                this.$emit('input', r)
            }, 50),
        },
        search(search) {
            if (search && search.length) {
                this.oldOpenStatus = this.oldOpenStatus || cloneDeep(this.open[this.selectedTab])
                this.$refs[`treeview-${this.selectedTab}`][0].updateAll(search && search.length)
            } else {
                this.$set(this.open, this.selectedTab, this.oldOpenStatus)
                this.oldOpenStatus = null
            }
        },
    },
    mounted() {
        this.loading = true
        this.generateEntitiesTreeview().then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions('global', {
            generateEntitiesTreeview: 'generateEntitiesTreeview',
        }),
    },
}
