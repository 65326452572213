
import { mapMutations } from 'vuex'
import Logo from '~/components/logo'

export default {
    name: 'LoaderDialog',

    components: {
        Logo,
    },

    data() {
        return {
            loadingText: '',
        }
    },

    computed: {
        loadItems() {
            return this.$store.state.global.loader.loadItems
        },

        isVisible() {
            return this.$store.state.global.loader.visible
        },

        indicator() {
            return this.$store.state.global.loader.indicator
        },

        progressBar() {
            return Math.min((100 / Object.keys(this.loadItems).length) * this.indicator) || 0
        },
    },

    watch: {
        indicator(val) {
            this.start()
        },
    },

    mounted() {
        this.start()
    },

    methods: {
        ...mapMutations('global', {
            resetLoader: 'RESET_LOADER',
            setVisible: 'SET_LOADER_VISIBLE',
        }),

        close() {
            this.setVisible(false)
        },

        start() {
            if (!this.isVisible && this.indicator > 0) {
                this.setVisible(true)
            }

            this.renderMessage(this.indicator)
            this.finish()
        },

        finish() {
            if (this.isVisible && this.indicator > 0 && Object.keys(this.loadItems).length === this.indicator) {
                this.loadingText = 'Rendering...'
                setTimeout(() => {
                    this.resetLoader()
                }, 3000)
            }
        },

        renderMessage(index) {
            const loadItemsArray = Object.keys(this.loadItems)
            let message = ''
            if (loadItemsArray.length) {
                const nextLoadItem = loadItemsArray[index > 0 ? index - 1 : index]
                message = `Fetching ${nextLoadItem.toUpperCase().replace('_', ' ')}...`
            }

            this.loadingText = message
        },
    },
}
