
export default {
    methods: {
        onFocus(e) {
        },
        onBlur(e) {
            this.cellBeingEdited = null
            const col = Number(e.target.dataset.column)
            const row = Number(e.target.dataset.row)
            this.$emit('update:cell', {
                row,
                col,
                value: e.target.value,
            })
        },
        makeCellEditable(columnIndex, rowIndex) {
            this.cellBeingEdited = [columnIndex, rowIndex]
            this.$nextTick(() => {
                this.$el.querySelector(`[data-row="${rowIndex}"][data-column="${columnIndex}"]`)?.focus()
            })
        },
    },
}
