
import { required, numeric } from 'vuelidate/lib/validators'
import { genericUnits } from '../const'
import FormMixin from './form.mixin'
import Geocoder from '~/components/common/geocoder'

export default {
    name: 'ComboAddMaterialAndSupplier',

    components: { Geocoder },

    // TODO - remove mixin from here, add it to each component, handle validation within next method ?
    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                material: {
                    output: {
                        amount: {
                            required,
                            numeric,
                        },
                    },
                },
                supplier: {
                    business: {
                        required,
                    },
                },
            },
        }
        if (this.draft.supplier.hasShippingAddress) {
            schema.draft.supplier.shippingAddress = { required }
        }
        return schema
    },

    data(vm) {
        return {
            step: 1,
            title: `${vm.value.draft ? 'Adding' : 'Editing'} ${vm.value.material.material.name} for ${vm.value.material?.process?.name}`,
        }
    },

    computed: {
        genericUnits() {
            return genericUnits
        },
    },

    methods: {
        next() {
            if (this.step === 1) {
                // validate form
                this.$v.draft.material.$touch()
                if (!this.$v.draft.material.$error) {
                    this.step = 2
                }
            } else if (this.step === 2) {
                this.$emit('save')
            }
        },
    },
}
