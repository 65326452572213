
import ProcessCategory from './process/items/ProcessCategory'
import ProcessFlowType from './process/items/ProcessFlowType'
import ProcessLocation from './process/items/ProcessLocation'
import ProcessOrigin from './process/items/ProcessOrigin'
import ProcessSummaryPanel from './parts/ProcessSummaryPanel'

export default {
    name: 'FlowInventory',

    components: {
        ProcessCategory,
        ProcessFlowType,
        ProcessLocation,
        ProcessOrigin,
        ProcessSummaryPanel,
    },

    props: {
        activeComponentId: {
            type: String,
            default: '',
        },
        activeProcessIndex: {
            type: Number,
            default: 0,
        },
        dataSet: {
            type: Array,
            default: () => [],
        },
        flowType: {
            type: Object,
            default: () => (null),
        },
        processes: {
            type: Array,
            default: () => [],
        },
    },

    data: () => ({
        model: false,
        panel: 0,
    }),

    computed: {
        activeProcess() {
            return this.processes[this.activeProcessIndex]
        },
        category() {
            return this.dataSet.find(i => i.id === 'category')?.draft
        },
        location() {
            return this.dataSet.find(i => ['destination', 'location'].includes(i.id))?.draft
        },
        origin() {
            return this.dataSet.find(i => i.id === 'origin')?.draft
        },
        activeProcessCompleteItems() {
            return this.activeProcess.items.filter(item => !this.$_.isEmpty(item?.draft))
        },
        activeProcessProgress() {
            return this.processProgress(this.activeProcess)
        },
        badge() {
            return `${this.activeProcessIndex + 1}/${this.processes.length}`
        },
    },

    watch: {
        activeProcessIndex(index) {
            this.panel = index
        },
    },

    methods: {
        onClick(e) {
            // if (e.target.classList.contains('v-list')) {
            //     this.model = true
            // }
            // e.stopPropagation()
            // e.preventDefault()
            return false
        },
        onClickOutside(e) {
            return false
        },
        processCompleteItems(process) {
            return process.items.filter(item => !this.$_.isEmpty(item?.draft))
        },
        processIncompleteItems(process) {
            return process.items.filter(item => this.$_.isEmpty(item?.draft))
        },
        processIsComplete(process) {
            return (this.processCompleteItems(process).length === process.items.length)
        },
        processProgress(process) {
            return (this.processCompleteItems(process).length / process.items.length) * 100
        },
    },
}
