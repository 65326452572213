export default ({ $vuetify, app, store }) => {
    // setup theme.name getter
    Object.defineProperty($vuetify.theme, 'name', {
        get() {
            return $vuetify.theme.dark ? 'dark' : 'light'
        },
    })

    // check if we have set the theme as a cookie
    // manually changing the vuetify theme only works on the client side
    if (process.client) {
        const cookieTheme = app.$cookie.get('theme')
        const settingsTheme = store.state.settings?.general?.theme

        // theme priority goes as follows: settings > cookie > default
        const theme = settingsTheme ?? cookieTheme ?? 'dark'

        // check if we need to update the cookie
        if (cookieTheme !== theme) {
            app.$cookie.set('theme', theme)
        }

        // check if dark theme is set appropriately
        const isDarkTheme = theme !== 'light'
        if ($vuetify.theme.dark !== isDarkTheme) {
            $vuetify.theme.dark = isDarkTheme
        }
    }
}
