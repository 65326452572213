
export default {
    name: 'MaterialCard',

    props: {
        material: {
            type: Object,
            required: true,
        },
        genericUnits: {
            type: Array,
            default: () => [],
        },
        error: {
            type: Object,
            default: () => ({
                amount: {},
                unit: {},
            }),
        },
        selectedItems: {
            type: Array,
            default: () => [],
        },
        inInventory: {
            type: Object,
            default: () => null,
        },
        defaults: {
            type: Object,
            default: () => {},
        },
    },

    data: vm => {
        // console.log(vm.selectedItems)
        return {
            reveal: false,
            draft: {
                id: vm.material.id,
                // amount: vm.selectedItems.find(e => e.id === vm.material.id)?.value || '',
                unit: vm.material.units[0].split('/').shift(),
                combustionProcessId: vm.material.combustionProcessId,
                outputType: vm.defaults.outputType,
            },
        }
    },

    computed: {
        selected() {
            // console.log(this.selectedItems, this.material.id)
            // return []
            return this.selectedItems.find(i => i.id === this.material.id)
        },
    },

    watch: {
        reveal(val) {
            // console.log(this.draft)
            if (val) {
                this.$nextTick(() => {
                    this.$refs.qty.$el.querySelector('input').focus()
                })
            }
        },
        selectedItems(selectedItems) {
            // console.log()
            this.draft = {
                id: this.material.id,
                value: selectedItems.find(e => e.id === this.material.id)?.value || '',
                unit: this.material.units[0].split('/').shift(),
                combustionProcessId: this.material.combustionProcessId,
                outputType: this.material.outputType || this.defaults.outputType,
            }
        },
    },

    methods: {
        confirm() {
            this.$emit('add-or-update', {
                ...this.draft,
                amount: Number(this.draft.amount),
            })
            this.reveal = false
        },
        removeSelected() {
            this.$emit('remove', this.draft)
            this.draft.amount = ''
        },
        onCardClick() {
            this.$emit('reveal', this.material.id)
            this.reveal = true
        },
    },
}
