/**
 * Store modules middleware
 *
 *  This is an attempt to intialize store modules from here
 *
 *  There are 2 downsides to this approach
 *      1. Is working only with ssr: false
 *      2. It feels slow, since it's happening before page render
 *      3. Doable, if we manage to integrate it with the loader
 *
 * @param {Object} context
 */
export default function({ app, req, route, store }) {
    // If nuxt generate, pass this middleware
    if (process.server && !req) return

    // no modules should be initialize before auth
    if (!app.$auth.loggedIn) return

    const storeModules = route.meta.reduce((storeModules, meta) => {
        if (meta.storeModules) {
            storeModules = app.$_.union(storeModules, meta.storeModules)
        }
        return storeModules
    }, [])

    return Promise.all(
        storeModules.map(module => {
            return store.dispatch(`${module}/init`)
        }),
    )
}
