import { Model } from '@vuex-orm/core'
import {
    concat,
    each,
    find,
    filter,
    uniqueId,
    map,
    toArray,
    sortBy,
} from 'lodash'

export default class TreeNode extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'nodes'

    static fields() {
        return {
            id: this.attr(null),
            icon: this.attr(''),
            name: this.attr(''),
            color: this.attr(''),
            active: this.boolean(false),
            circuits: this.attr(null),
            period: this.attr(null),
            dateTimes: this.attr(null),
            resolution: this.attr(null),
            order: this.attr(1),
            showChildren: this.attr(null),
            parentId: this.attr(null),
            type: this.string(null),
            groupId: this.string(null),
            editable: this.boolean(false),
            selected: this.boolean(false),
            selectedChild: this.attr(0),
            customActions: this.attr([]),
            datepickerSelection: this.attr(null),
            timepickerSelection: this.attr([]),
            // dropdown: this.attr([]),
        }
    }

    static beforeCreate(model) {
        /**
         * some of the new models need to be immediately selected, others don't
         */
        if (['unit'].includes(model.type)) {
            model.active = true
            TreeNode.update({
                where: e => {
                    return true
                },
                data: {
                    active: false,
                },
            }).then(() => { })
        }

        /**
         * handler the order increment value
         */
        const latestInsertedNode = TreeNode.query().where('type', model.type).orderBy('order', 'desc').first()
        if (latestInsertedNode) {
            model.order = latestInsertedNode.order + 1
        }

        /**
         * make sure the parent has showChildren = true
         */
        TreeNode.update({
            where: e => e.id === model.parentId,
            data: {
                showChildren: true,
            },
        }).then(() => { })
    }

    static afterCreate(model) {
        /**
         * check if there's any consecutive sibling
         */
        if (model.type === 'bookmark') {
            const siblings = TreeNode.query().where(e => e.parentId === model.parentId).orderBy('id', 'asc').get()
            siblings.forEach(sibling => {
                sibling.$update({ groupId: null })
            })

            siblings.forEach((sibling, k) => {
                // let groupId = uniqueId('bookmark-group-')
                let prevElement = siblings[k - 1]
                /** first element of the loop */
                if (!prevElement) {
                    sibling.$update({ groupId: uniqueId('bookmark-group-') })
                    return
                }
                prevElement = sibling.constructor.find(prevElement.id)
                if (sibling.period.start === prevElement.period.end + 1) {
                    sibling.$update({ groupId: prevElement.groupId })
                } else {
                    sibling.$update({ groupId: uniqueId('bookmark-group-') })
                }
            })
        }

        // fetch items ???
    }

    get sortedTimepickerSelection() {
        return sortBy(this.timepickerSelection, 'value')
    }

    get groupedChildren() {
        const groups = {}
        this.children.forEach(child => {
            if (child.groupId) {
                groups[child.groupId] = groups[child.groupId] || {
                    id: child.id,
                    children: [],
                    isGroup: true,
                }
                groups[child.groupId].children.push(child)
            }
        })
        const groupedChildren = filter(toArray(groups), e => e.children.length > 1)
        return groupedChildren
    }

    get ungroupedChildren() {
        const groups = {}
        this.children.forEach(child => {
            if (child.groupId) {
                groups[child.groupId] = groups[child.groupId] || {
                    children: [],
                }
                groups[child.groupId].children.push(child)
            }
        })
        const children = map(filter(toArray(groups), e => e.children.length === 1), ({ children }) => {
            return children[0]
        })
        return children
    }

    get groupedAndUngroupedChildren() {
        let children = [...this.groupedChildren, ...this.ungroupedChildren]
        children = sortBy(children, 'id')
        return children
    }

    get group() {
        return TreeNode.query().where('groupId', this.groupId).orderBy('id', 'asc').get()
    }

    get parent() {
        return this.parentId ? TreeNode.find(this.parentId) : null
    }

    get children() {
        return TreeNode.query().where('parentId', this.id).orderBy('id', 'asc').get()
    }

    get ancestors() {
        return this.parent ? concat([this.parent], this.parent.ancestors) : []
    }

    get activeAncestors() {
        return filter(this.ancestors, e => e.active)
    }

    get descendants() {
        let children = this.children
        each(this.children, child => {
            children = concat(children, child.descendants)
        })
        return children
    }

    get activeDescendants() {
        return filter(this.descendants, e => e.active)
    }

    get siblings() {
        return TreeNode.query().where(e => e.parentId === this.parentId && e.id !== this.id).orderBy('order').get()
    }

    get activeSibling() {
        return find(this.siblings, e => e.active)
    }

    get lineage() {
        return concat(this.ancestors, [this], this.descendants)
    }

    activateAncestors() {
        each(this.ancestors, d => d.$update({ active: true, showChildren: true }))
    }

    deactivateDescendants() {
        each(this.descendants, d => d.$update({ active: false, showChildren: null }))
    }

    deactivateNephews() {
        each(this.siblings, sibling => {
            sibling.deactivateDescendants()
        })
    }

    deactivateSiblings() {
        each(this.siblings, d => d.$update({ active: false, showChildren: false }))
    }

    $nuke() {
        each(this.descendants, d => d.$delete())
        this.$delete()
    }

    static insertOrActivate(data) {
        return new Promise(resolve => {
            if (TreeNode.find(data.id)) {
                TreeNode.update({
                    where: e => e.id === data.id,
                    data: {
                        active: true,
                    },
                }).then(e => resolve(e))
            } else {
                TreeNode.insert({
                    data,
                }).then(e => resolve(e))
            }
        })
    }
}
