export default {
    INIT(state) {
        state.initialised = true
    },

    SET_EMISSIONS(state, { assets, nodes }) {
        if (assets) {
            state.emissions.assets = Object.freeze(assets)
        }
        if (nodes) {
            state.emissions.nodes = Object.freeze(nodes)
        }
    },
}
