
import { forOwn } from 'lodash'

export default {
    name: 'ScopeAndCategoriesGrid',

    props: {
        emissions: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: 'Greenhouse gas emissions data',
        },
    },

    computed: {
        totalScope3Emissions() {
            // console.log(this.emissions)
            let totalScope3 = 0
            forOwn(this.emissions, (rows, k) => {
                if (k === 'Downstream scope 3 emissions' || k === 'Upstream scope 3 emissions') {
                    rows.forEach(({ value }) => (totalScope3 += value))
                }
            })
            return totalScope3
        },
        totalEmissions() {
            let totalScope1And2 = 0
            forOwn(this.emissions, (rows, k) => {
                if (k === 'Scopes 1 and 2') {
                    rows.forEach(({ value }) => (totalScope1And2 += value))
                }
            })
            return this.totalScope3Emissions + totalScope1And2
        },
    },

    mounted() {
        // console.log('ScopeAndCategoriesGrid emissions: ', this.emissions)
    },
}
