import Vue from 'vue'
import { truncate } from 'lodash'
import moment from '~/composables/useMoment'
import Unit from '~/orm/models/Unit'
import { dateFormatByResolution, FORMAT_TIME } from '~/utils/date'

export function host(url) {
    const host = url
        .replace(/^https?:\/\//, '')
        .replace(/\/.*$/, '')
        .replace('?id=', '/')
    const parts = host.split('.').slice(-3)
    if (parts[0] === 'www') {
        parts.shift()
    }
    return parts.join('.')
}

function pluralize(time, label) {
    if (time === 1) {
        return time + label
    }
    return time + label + 's'
}

export function trunc(v, n, e = '...') {
    return truncate(v, { length: n, omission: e })
}

export function timeAgo(time) {
    const between = Date.now() / 1000 - Number(time)
    if (between < 3600) {
        return pluralize(~~(between / 60), ' minute')
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), ' hour')
    } else {
        return pluralize(~~(between / 86400), ' day')
    }
}

export function capitalize(value) {
    if (!value) return ''
    return String(value).charAt(0).toUpperCase() + String(value).toLowerCase().slice(1)
}

export function n(value, n = 2) {
    if (!value) return 0
    return Number(value).toLocaleString(undefined, { minimumFractionDigits: n, maximumFractionDigits: n })
}

export function numberWithSign(value) {
    if (value <= 0 || isNaN(value)) return n(value, 1)
    return '+' + n(value, 1)
}

export function toFixed(value, minimumFractionDigits = 2) {
    return Number(value).toFixed(minimumFractionDigits)
}

export function d(value, outputFormat = 'MMM Do', inputFormat) {
    const date = moment(value, inputFormat)
    return date.format(outputFormat)
}

export function date(value, outputFormat = 'MMM Do', inputFormat = 'YYYY-MM-DD') {
    return moment(value, inputFormat).format(outputFormat)
}

export function time(value, outputFormat = FORMAT_TIME, inputFormat = 'HH:mm:ss') {
    return moment(value, inputFormat).format(outputFormat)
}

export function dateForResolution(value, resolution) {
    const format = dateFormatByResolution(resolution)
    return d(value, format)
}

export function initials(value) {
    if (!value) return ''
    return value
        .split(' ')
        .map(v => v.charAt(0).toUpperCase())
        .join('')
}

export function ucfirst(value) {
    if (!value) return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function array(value) {
    if (Array.isArray(value)) {
        return value.join(', ')
    }
    return value
}

export function money(v, currencySymbol = '£') {
    return currencySymbol + n(v)
}

export function unitValue(v, unitId, precision = 2, fromSymbol = '', debug = false) {
    const { value } = Unit.normalize(v, unitId, precision, fromSymbol, debug)
    return value
}

export function unitSymbol(v, unitId, precision = 2, fromSymbol = '') {
    const { symbol } = Unit.normalize(v, unitId, precision, fromSymbol)
    return symbol
}

const filters = {
    array,
    capitalize,
    d,
    date,
    dateForResolution,
    host,
    initials,
    money,
    n,
    numberWithSign,
    time,
    timeAgo,
    toFixed,
    ucfirst,
    unitSymbol,
    unitValue,
    truncate: trunc,
    trunc,
}

export default filters

// register all filters
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

// Filter / disable known warnings
Vue.config.warnHandler = (message, vm, componentTrace) => {
    if (message.includes('The .native modifier for v-on is only valid on components but it was used on')) {
        return
    }
    console.warn(message + componentTrace)
}
