
import darkLogo from '~/assets/images/logo-dark.png'
import lightLogo from '~/assets/images/logo-light.png'
export default {
    props: {
        rotate: {
            type: Boolean,
            default: true,
        },
        width: {
            type: Number,
            default: 40,
        },
    },
    data() {
        return {
            darkLogo,
            lightLogo,
        }
    },
}
