export default {
    SET_ACTIVE_ENTITIES(state, { type, active }) {
        state.activeEntities[type] = active
    },

    ADD_ACTIVE_ENTITY(state, { type, euid }) {
        state.activeEntities[type].push(euid)
    },

    REMOVE_ACTIVE_ENTITY(state, { type, euid }) {
        const index = state.activeEntities[type].indexOf(euid)
        if (index >= 0) { state.activeEntities[type].splice(index, 1) }
    },

    SET_SELECTED_ENTITIES(state, { type, selected }) {
        state.selectedEntities[type] = selected
    },

    ADD_SELECTED_ENTITY(state, { type, euid }) {
        state.selectedEntities[type].push(euid)
    },

    REMOVE_SELECTED_ENTITY(state, { type, euid }) {
        const index = state.selectedEntities[type].indexOf(euid)
        if (index >= 0) { state.selectedEntities[type].splice(index, 1) }
    },

    SET_SHOW_MPAN(state, value) {
        state.showMpan = value
    },

    SET_SHOW_FRIENDLY_NAME(state, value) {
        state.showFriendlyName = value
    },
}
