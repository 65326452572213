import { ucfirst } from './filters'
import { setLocale } from '~/composables/useMoment'

export default ({ app }, inject) => {
    // beforeLanguageSwitch called right before setting a new locale
    app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
        // set moment locale
        setLocale(newLocale)
    }
    // onLanguageSwitched called right after a new locale has been set
    // app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    //     console.log('afterLanguageSwitch: ', oldLocale, newLocale)
    //     app.i18n.setLocaleCookie(newLocale)
    // }
    setLocale(app.i18n.locale)

    // inject $tUcf - wrap translate into ucfirst func
    inject('tUcf', function(key, ...args) {
        return ucfirst(app.i18n.t(key, ...args))
    })

    // inject $tcUcf
    inject('tcUcf', function(key, ...args) {
        return ucfirst(app.i18n.tc(key, ...args))
    })
}
