
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { isString } from 'lodash'
import MachineCard from './../cards/machine-card'
import FormMixin from './form.mixin'

export default {
    name: 'SelectMachine',
    components: { MachineCard },
    mixins: [FormMixin],
    validations() {
        return {
            draft: {
                id: required,
            },
        }
    },
    data() {
        return {
            machines: [],
            searchMachine: '',
        }
    },

    computed: {
        ...mapGetters('flows', ['machinesByCategoryId']),
        filteredMachines() {
            if (!this.searchMachine || (isString(this.searchMachine) && this.searchMachine.trim() === '')) {
                return this.machines
            }
            return this.machines.filter(m =>
                [m.name, m.model].join(' ').toLowerCase().includes(String(this.searchMachine).toLowerCase()),
            )
        },
    },

    mounted() {
        this.init()
    },

    methods: {
        init() {
            const __data = this.__data()
            if (__data.category) {
                this.machines = this.machinesByCategoryId(__data.category.id)
            }
        },
        onInput(e) {
            this.draft = this.draft?.id === e.id ? {} : e
        },
    },
}
