export default () => ({
    initialised: false,
    materials: [],
    flows: [],
    fuels: [],
    processCategories: [],
    thirdParties: [],
    disposals: [],
    machines: [],
})
