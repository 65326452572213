export default {
    // inventoryNodes(state) {
    //     return state.inventory.reduce((res, { process, flows }, index) => {
    //         res.push(process)
    //         if (flows.length) {
    //             flows.forEach(flow => {
    //                 if (!res.find(({ id }) => id === flow.id)) {
    //                     res.push({
    //                         ...process,
    //                         ...flow,
    //                         type: 'process',
    //                         isFlow: true,
    //                     })
    //                 }
    //             })
    //         }
    //         return res
    //     }, [])
    // },

    getInventory: state => siteId => {
        // console.log(state.materials)
        const inventory = {}
        state.materials.filter(({ inventory }) => inventory[siteId])
            .forEach(material => {
                inventory[material.id] = {
                    id: material.id,
                    value: material.inventory[siteId][0].value,
                    unit: material.inventory[siteId][0].unit,
                }
            },
            )

        return inventory
    },

    allMaterials(state) {
        return state.materials
    },

    allFuels(state) {
        return state.fuels
    },

    fuelById: state => id => {
        return state.fuels.find(fuel => fuel.id === id)
    },

    processCategories(state) {
        return state.processCategories
    },

    materialById: state => id => {
        return state.materials.find(material => material.id === id)
    },

    thirdPartyById: state => searchId => {
        return state.thirdParties.find(({ id }) => searchId === id)
    },

    allDisposals(state) {
        return state.disposals
    },

    machinesByCategoryId: state => searchId => {
        return state.machines.filter(({ categoryId }) => searchId === categoryId)
    },
}
