import ItemCard from './ItemCard'
import ProcessCategory from './ProcessCategory'
import ProcessFlowType from './ProcessFlowType'
import ProcessFuels from './ProcessFuels'
import ProcessInventory from './ProcessInventory'
import ProcessLocation from './ProcessLocation'
import ProcessMachine from './ProcessMachine'
import ProcessOrigin from './ProcessOrigin'
import ProcessRange from './ProcessRange'
import ProcessWaste from './ProcessWaste'

export default {
    ItemCard,
    ProcessCategory,
    ProcessFlowType,
    ProcessFuels,
    ProcessInventory,
    ProcessLocation,
    ProcessMachine,
    ProcessOrigin,
    ProcessRange,
    ProcessWaste,
}
