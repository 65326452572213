
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { TYPES } from '~/components/flows/const'

const newDraft = (base = {}) => ({
    ...base,
    type: TYPES.MATERIAL,
})

export default {
    name: 'MaterialDialog',

    mixins: [validationMixin],

    validations() {
        const schema = {
            draft: {
                name: {
                    required,
                },
                category: {
                    required,
                },
            },
        }
        return schema
    },

    props: {
        value: Boolean,
        categories: {
            type: Array,
            default: () => (['Metal', 'Plastic', 'Other']),
        },
        base: {
            type: Object,
            default: () => ({}),
        },
    },

    data: vm => ({
        draft: newDraft(vm.base),
        loading: false,
        title: 'Add material',
    }),

    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },

    methods: {
        toggle() {
            this.dialog = !this.dialog
        },

        reset() {
            this.draft = newDraft()
            this.$v.$reset()
        },

        cancel() {
            this.dialog = false
            this.reset()
        },

        async save() {
            this.$v.$touch()
            if (this.$v.draft.$error) return
            this.loading = true
            try {
                await this.$store.dispatch('flows/storeInventory', this.draft)
                this.$toast.success('New material stored successfully.')
                this.reset()
            } catch (err) {
                this.$toast.error('Error storing new inventory. ' + err.message)
            } finally {
                this.loading = false
            }
        },
    },
}
