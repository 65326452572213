
export default {
    name: 'AppName',
    computed: {
        cssClass() {
            return [
                'app-name',
                `app-name--${this.$vuetify.theme.name}`,
            ]
        },
    },
}
