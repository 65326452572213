
export default {
    name: 'MaterialSummaryCard',
    props: {
        material: {
            type: Object,
            default: () => ({}),
        },
        input: {
            type: Object,
            default: () => null,
        },
        output: {
            type: Object,
            default: () => null,
        },
        base: {
            type: Number,
            default: 999,
        },
    },

    computed: {
        sum() {
            let sum = this.base
            if (this.input) {
                sum -= this.input.value
            }
            if (this.output) {
                sum += this.output.value
            }
            return sum
        },
    },
}
