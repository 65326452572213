import { dynamicResolution2 } from '~/utils/resolution'

export default {
    dynamicResolution(_state, _getters, rootState) {
        return dynamicResolution2(rootState.period.end, rootState.period.start)
    },

    selectedSiteIds(_state, _getters, rootState) {
        return rootState.entities['carbon-sources'].selectedSites
    },
}
