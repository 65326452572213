/* eslint-disable no-unreachable-loop */
import { Model } from '@vuex-orm/core'
import { isEqual, uniq } from 'lodash'
import { buildTreeviewByCommodity, buildTreeviewBySite, buildTreeviewItems, ENTITY_NAMES, idFromEntity, isEndEntity, parseEUIDs } from '~/utils/entities'

/**
 * Describing what's the scope of the treeview
 * Eg: gas scoped treeviews, are used on the gas page
 * Currently there are only 3 scopes: gas, electricity and dashboard
 *
 * @var {Object}
 */
export const TREEVIEW_SCOPES = {
    GAS: 'gas',
    ELECTRICITY: 'electricity',
    DASHBOARD: 'dashboard',
    WATER: 'water',
}

export default class Treeview extends Model {
    static entity = ENTITY_NAMES.Treeview
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(null),
            icon: this.attr(null),
            type: this.attr(null), // 'normal' or 'virtual'
            scope: this.attr(null), // 'electricity', 'gas' or 'dashboard'
            name: this.attr(''),
            active: this.attr([]), // active euids
            selected: this.attr([]), // selected euids
        }
    }

    /**
     * Fetch function
     * - here we populate the db with static data
     * - until the API will be ready to provide an endpoint for it
     *
     * @returns {Void}
     */
    static fetch() {
        // if already "fetched"
        if (this.all().length > 0) return

        const data = [
            {
                id: 'electricity',
                icon: '$building',
                name: 'Home',
                scope: TREEVIEW_SCOPES.ELECTRICITY,
                type: 'normal',
            },
            {
                id: 'electricity--virtual',
                icon: '$layerGroup',
                name: 'Virtual Meters',
                scope: TREEVIEW_SCOPES.ELECTRICITY,
                type: 'virtual',
            },
            {
                id: 'gas',
                icon: '$building',
                name: 'Home',
                scope: TREEVIEW_SCOPES.GAS,
                type: 'normal',
            },
            {
                id: 'gas--virtual',
                icon: '$layerGroup',
                name: 'Virtual Meters',
                scope: TREEVIEW_SCOPES.GAS,
                type: 'virtual',
            },
            {
                id: 'water',
                icon: '$building',
                name: 'Home',
                scope: TREEVIEW_SCOPES.WATER,
                type: 'normal',
            },
            // {
            //     id: 'water--virtual',
            //     icon: '$layerGroup',
            //     name: 'Virtual',
            //     scope: TREEVIEW_SCOPES.WATER,
            //     type: 'virtual',
            // },
            // {
            //     id: 'dashboard--by-site',
            //     icon: '$building',
            //     name: 'By Site',
            //     scope: TREEVIEW_SCOPES.DASHBOARD,
            //     type: 'normal',
            // },
            // {
            //     id: 'dashboard--by-commodity',
            //     icon: '$layerGroup',
            //     name: 'By Commodity',
            //     scope: TREEVIEW_SCOPES.DASHBOARD,
            //     type: 'normal',
            // },
        ]

        this.insert({ data })
    }

    static for(scope) {
        return this.query()
            .where('scope', scope)
            .get()
    }

    static active() {
        return this.getters('active')
    }

    async init({ active, selected } = {}) {
        // init tv
        await this.$update({
            id: this.id,
            active: active || [],
            selected: selected || [...this.initialItems],
        })
    }

    reset() {
        this.$update({
            id: this.id,
            active: [],
            selected: [...this.initialItems],
        })
    }

    setActive(active = []) {
        if (isEqual(active, this.active)) return
        if (active.length) {
            this.$store().dispatch('period/checkMinResolution', { euids: active }, { root: true })
        }
        this.$update({
            id: this.id,
            active,
        })
    }

    setSelected(selected = []) {
        const sorted = [...selected].sort()
        if (isEqual(sorted, this.selected)) return
        if (sorted.length) {
            this.$store().dispatch('period/checkMinResolution', { euids: sorted }, { root: true })
        }
        this.$update({
            id: this.id,
            selected: sorted,
        })
    }

    selectPresetItems(preset) {
        // eslint-disable-next-line eqeqeq
        const presetItem = this.items[0].children.find(p => p.id == preset.id)
        if (presetItem && presetItem.children.length) {
            this.setSelected([
                presetItem.euid,
                ...presetItem.children.map(c => c.euid),
            ])
        }
    }

    removeSelectedNoLongerInPreset(preset) {
        const flatten = array => array.flatMap(p => [
            p,
            ...flatten(p.children || []),
        ])
        // eslint-disable-next-line eqeqeq
        const presetItem = this.items[0]?.children.find(p => p.id == preset.id)
        if (presetItem) {
            const presetEntities = flatten(presetItem.children)

            const euids = this.selected.filter(p => !p.includes(presetItem.euid) ||
                presetEntities.map(o => o.euid).includes(p))
            this.setSelected(euids)
        }
    }

    selectSites(siteIds = []) {
        if (this.type !== 'normal') return
        const sites = this.items[0].children.filter(s => siteIds.includes(s.id))
        const euids = sites.map(site => {
            // site children are meters, but if meters have no data page will be empty
            // in that case need to add at least the first circuit/pipe
            return [
                site.euid,
                ...site.children.map(c => c.euid),
                ...(site.children.map(m => {
                    if (m.activatable) {
                        return []
                    } else {
                        return m.children.map(c => c.euid).flat()
                    }
                }).flat()),
            ]
        }).flat()
        return this.setSelected(euids)
    }

    parsedEUIDs(options) {
        return parseEUIDs(this.euids, options)
    }

    entityIds(entity) {
        return this
            .parsedEUIDs({ entities: [entity], reverse: true })
            .map(idFromEntity)
    }

    get hasChanges() {
        return this.active.length || !isEqual(
            [...this.initialItems].sort(),
            [...this.selected].sort(),
        )
    }

    get isVirtual() {
        return this.type === 'virtual'
    }

    get items() {
        const store = this.$store()
        let query
        let items = []
        if (this.scope === TREEVIEW_SCOPES.DASHBOARD) {
            query = store.getters['entities/sites/query']()
            items = query.with(['meters', 'gasMeters']).get()
            switch (true) {
                case this.id.includes('--by-site'):
                    return buildTreeviewBySite(items)
                case this.id.includes('--by-commodity'):
                    return buildTreeviewByCommodity(items)
                default:
                    console.warn('Unknown item type.', this)
                    break
            }
        }

        query = store.getters['entities/customers/query']()
        if (this.isVirtual) {
            query = query.with('presets', q => q.where('scope', this.scope))
        } else {
            const e = this.scope === TREEVIEW_SCOPES.ELECTRICITY ? 'meters' : `${this.scope}Meters`
            const path = this.scope === TREEVIEW_SCOPES.ELECTRICITY ? 'meters.circuits' : `${this.scope}Meters.pipes`
            query = query.with('sites',
                q => q.has(e).with(path, query => query.where('parentId', null)),
            )
        }
        items = query.get()
        return buildTreeviewItems({ items, store, scope: this.scope })
    }

    get initialItems() {
        const items = []
        if (this.scope === TREEVIEW_SCOPES.DASHBOARD) {
            for (const item of this.items) {
                items.push(item.euid)
                for (const child of item.children) {
                    items.push(child.euid)
                    for (const nephew of child.children) {
                        items.push(nephew.euid)
                    }
                }
            }
            return items
        }
        let hasMain = false
        let hasSiteOrPreset = false
        for (const customer of this.items) {
            for (const site of customer.children) {
                // in the case of virtual items, the site is the preset
                if (this.isVirtual) {
                    items.push(site.euid)
                    hasSiteOrPreset = true
                    break
                }
                for (const meter of site.children) {
                    if (meter.activatable) {
                        hasMain = true
                        items.push(meter.euid)
                        break
                    } else if (meter.children.length > 0) {
                        for (const circuit of meter.children) {
                            if (circuit.activatable) {
                                items.push(circuit.euid)
                                break
                            } else if (circuit.children.length > 0) {
                                items.push(circuit.children[0].euid)
                                if (circuit.children.length === 1) items.push(circuit.euid)
                                break
                            }
                        }
                        break
                    }
                }
                if (hasMain && site.children.length === 1) {
                    hasSiteOrPreset = true
                    items.push(site.euid)
                }
                break
            }
            if (hasSiteOrPreset && customer.children.length === 1) items.push(customer.euid)
            break
        }

        return items
    }

    get openItems() {
        const items = []
        for (const customer of this.items) {
            items.push(customer.euid)
            if (this.isVirtual) break

            for (const site of customer.children) {
                items.push(site.euid)
                for (const meter of site.children) {
                    if (!meter.activatable) {
                        items.push(meter.euid)
                    }
                    break
                }
                break
            }
            break
        }
        return items
    }

    get euids() {
        return this.active.length > 0 ? this.active : this.selected
    }

    get electricityEuids() {
        return this.euids.filter(euid => {
            if (euid.includes(':') || euid.includes('pipes') || euid.includes('gas-meters')) return false
            return true
        })
    }

    /**
     * End euids
     *  - except 'customers' and 'sites` entities
     */
    get endEuids() {
        return this.euids.filter(euid => isEndEntity(euid))
    }

    /**
     * End selected entities euids
     *  - except 'customers' and 'sites` entities
     */
    get selectedEndEuids() {
        return this.selected.filter(euid => isEndEntity(euid))
    }

    /**
     * End selected entities' ids
     *  - except 'customers' and 'sites` entities
     */
    get selectedEndIds() {
        return parseEUIDs(this.selected.filter(euid => isEndEntity(euid)), { reverse: true }).map(idFromEntity)
    }

    get circuitsIds() {
        return this.entityIds(ENTITY_NAMES.Circuit)
    }

    get metersIds() {
        return this.entityIds(ENTITY_NAMES.Meter)
    }

    get gasMetersIds() {
        return this.entityIds(ENTITY_NAMES.GasMeter)
    }

    get waterMetersIds() {
        return this.entityIds(ENTITY_NAMES.WaterMeter)
    }

    get allMetersIds() {
        return [
            ...this.metersIds,
            ...this.gasMetersIds,
        ]
    }

    get sitesIds() {
        return this.entityIds(ENTITY_NAMES.Site)
    }

    /**
     * Get pipes IDs
     * - we are usig 'uniq' since we can have duplicates
     *   when same pipe belongs to multiple presets
     */
    get pipesIds() {
        return uniq(this.entityIds(ENTITY_NAMES.Pipe))
    }

    get waterPipesIds() {
        return uniq(this.entityIds(ENTITY_NAMES.WaterPipe))
    }

    get presetsIds() {
        return this.entityIds(ENTITY_NAMES.Preset)
    }

    get endParsedEUIDs() {
        return parseEUIDs(this.endEuids, { reverse: true })
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object}
     */
    toJSON() {
        return {
            ...this.$toJson(),
            items: this.items,
            initialItems: this.initialItems,
            openItems: this.openItems,
        }
    }
}
