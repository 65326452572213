
import AddDataToAssetsCardVue from './AddDataToAssetsCard'
import AddAssetsCard from './AddAssetsCard'
import NetZeroNews from './NetZeroNews.vue'
import VideoTutorialsCard from './VideoTutorialsCard'
import { TRACKING_PAGES } from '~/utils/tracking'

export default {
    name: 'GetStartedWithNetZero',

    components: {
        AddDataToAssetsCardVue,
        AddAssetsCard,
        NetZeroNews,
        VideoTutorialsCard,
    },

    props: {
        enableSkip: Boolean,
        enableInPageHeader: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        this.$api.tracking.logPageLoadingTime(TRACKING_PAGES.CARBON, { alt_page: 'get-started' })
    },
}
