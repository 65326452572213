export const CACHE_KEY_SEPARATOR = '_'

/**
 * Cache key generator
 *
 * The items array should contain
 * [unit, resolution, circuitId]
 * in this order
 *
 * @param {Array} items
 * @returns {String} key
 */
export function generateCacheKey(items = []) {
    return items.join(CACHE_KEY_SEPARATOR)
}
