import ClickHouseService from './ClickHouseService'
import ElectricityApiService from './ElectricityApi'
import EventsService from './Events'
import FlowsService from './Flows'
import AuthService from './Auth'
import FileService from './File'
import NetZeroService from './NetZero'
import TrackingService from './Tracking'

const fetchConfig = { credentials: 'omit', referrerPolicy: 'no-referrer' }

export default class ApiService {
    constructor({ $axios, $config, store }) {
        this.$axios = $axios
        this.$config = $config
        this.$store = store
    }

    get electricity() {
        if (!this._electricity) {
            this._electricity = new ElectricityApiService(this)
        }
        return this._electricity
    }

    get clickHouse() {
        if (!this._clickHouse) {
            this._clickHouse = new ClickHouseService(this)
        }
        return this._clickHouse
    }

    get file() {
        if (!this._file) {
            this._file = new FileService(this)
        }
        return this._file
    }

    get netZero() {
        if (!this._netZero) {
            this._netZero = new NetZeroService(this)
        }
        return this._netZero
    }

    get events() {
        if (!this._events) {
            this._events = new EventsService(this)
        }
        return this._events
    }

    get flows() {
        if (!this._flows) {
            this._flows = new FlowsService(this)
        }
        return this._flows
    }

    get auth() {
        if (!this._auth) {
            this._auth = new AuthService(this)
        }
        return this._auth
    }

    get tracking() {
        if (process.client) {
            if (!this._tracking) {
                this._tracking = new TrackingService(this)
            }
            return this._tracking
        }
    }

    async chatToken(params) {
        const response = await this.$axios.get(`${this.$config.axios.chatServiceURL}/getToken`, {
            params,
        })
        return response.data.token
    }

    /**
     * Fetch news from the clearvue.business website
     *
     * @param {Object}  params
     * @param {Boolean}  mock
     *
     * @return  {Promise}
     */
    async fetchNews(params = {}) {
        let news = []
        try {
            news = await fetch('/marketing/posts?' + new URLSearchParams(params), fetchConfig).then(res => res.json())
            if (!Array.isArray(news)) {
                throw new TypeError('Received invalid news.')
            }
        } catch (err) {
            news = []
            console.warn('Error fetching news: ', err)
        }
        return news
    }

    /**
     * Fetch post image clearvue.business website
     *
     * @param {Number|String} imgId
     *
     * @return  {Promise}
     */
    async fetchPostImg(imgId = '') {
        let img
        try {
            img = await fetch('/marketing/media/' + imgId, fetchConfig).then(res => res.json())
            if (img?.id !== imgId) { // some way to validate the image
                throw new Error('Received invalid image.')
            }
        } catch (err) {
            img = {}
            console.warn('Error fetching image by id: ', err)
        }
        return img
    }

    fetchShippingRoute(params = {}) {
        return this.$axios.$get('/route', { params })
    }

    /**
     * @param query {String}
     * @return {Promise}
     */
    async searchAddress(query = '', limit = 10) {
        let addresses
        try {
            // fetch addresses
            addresses = await this.$axios.$get('/search-api/addresses/search', { params: { query, limit } })
        } catch (err) {
            console.warn('Error while searching addresses. ', err)
            addresses = []
        }
        return addresses
    }

    /**
     * @param  {String}
     * @return {Promise}
     */
    async fetchAddress(id = '') {
        // to get the full details once you know the ID
        // https://stage-ngp-uk-address-search-4iqhzhf6zq-ew.a.run.app/address/ZTg1MmIzNDI2MTBjYjJiIDEyNDU4Njc5MDMgNDhiZjNhNjllMmMyMDI2
        let address
        try {
            // fetch address
            address = await this.$axios.$get(`/search-api/addresses/address/${id}`)
        } catch (err) {
            console.warn('Error while searching address. ', err)
            address = {}
        }
        return address
    }

    /**
     * Search company autocomplete
     *
     * @param   {Object}  params
     *
     * @return  {Array} companies
     */
    async searchCompany(params = {}) {
        try {
            const { companies } = await this.$axios.$get('/search-api/companies/search', { params })
            return companies
        } catch (err) {
            console.warn('Error while searching company. ', err)
            return []
        }
    }

    /**
     * Search place autocomplete
     *
     * @param   {Object}  params
     *
     * @return  {Array} results
     */
    async placeAutocomplete(params = {}) {
        let results
        try {
            results = await this.$axios.$get('/search-api/places/autocomplete', { params })
        } catch (err) {
            console.warn('Error on place autocomplete.', err)
            results = []
        }
        return results
    }

    /**
     * Find place by text query
     *
     * @param   {Object}  params
     *
     * @return  {Array} results
     */
    async placeFind(params = {}) {
        let results
        try {
            results = await this.$axios.$get('/search-api/places/find', { params })
        } catch (err) {
            console.warn('Error on place find. ', err)
            results = []
        }
        return results
    }

    /**
     * Fetch place details
     *
     * @param   {Object}  params
     *
     * @return  {Array} results
     */
    async fetchPlaceDetails(params = {}) {
        let results
        try {
            results = await this.$axios.$get('/search-api/places/details', { params })
        } catch (err) {
            console.warn('Error on fetch place details.', err)
            results = []
        }
        return results
    }

    /**
     * Fetch list of countries
     *
     * @return  {Array} results
     */
    async fetchCountries() {
        try {
            const { data: countries } = await this.$axios.$get('/search-api/countries')
            return countries
        } catch (err) {
            console.warn('Error while searching company. ', err)
            return []
        }
    }
}
