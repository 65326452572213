import { sync } from 'vuex-router-sync'
import createLogger from 'vuex/dist/logger'

export default ({ app, store }) => {
    // https://github.com/vuejs/vuex-router-sync
    sync(store, app.router)

    if (process.env.NODE_ENV !== 'production' && store.state.debug) {
        // Vuex mutations logger
        createLogger()(store)
    }
}
