import colors from 'vuetify/es5/util/colors'
import chroma from 'chroma-js'

// const colorPalette = {
//     anchor: colors.blue.darken1,
//     base: colors.blue.darken1,
//     accent: colors.blue.darken1,
//     primary: colors.blue.darken1,
//     secondary: colors.grey.darken1,
//     success: colors.green.darken1,
//     info: colors.blue.darken1,
//     warning: colors.amber.darken1,
//     error: colors.red.darken1,
// }

/**
 * Color themes that can be chosen by client
 * The first key is the default color if no cookie has been stored on clients browser
 * Add another color by choosing 1 from below link
 * @see https://vuetifyjs.com/en/styles/colors#colors
 * @type {string[]}
 */
export const themeColors = ['blue', 'orange', 'pink', 'light-green', 'red', 'cyan']

export const extraColors = (theme = 'dark') => {
    return {
        light: {
            navigatorBackgroundColor: colors.shades.white,
            chartGridStrokeColor: 'rgb(230, 230, 230)',
            chartMinorGridStrokeColor: colors.grey.lighten2,
            ragColors: {
                red: '#f00',
                amber: '#ffb82a',
                green: '#05c905',
                day: '#ece83e',
                night: '#143277',
            },
            entityColors: [
                '#f7a35c',
                '#8085e9',
                // '#f15c80',
                '#e4d354',
                '#2b908f',
                '#f45b5b',
                '#91e8e1',
                '#9bc147',
                '#7db8e0',
                '#782b8b',
                '#91e8e1',
                '#b61e4f',
                '#f0e847',
                '#881b55',
                '#4499cf',
                '#f45b5b',
                '#519049',
                // '#f15c80',
                '#e3ae48',
                '#31378b',
                '#d6dc3e',
                '#b71d59',
                '#90ed7d',
                '#a21f2d',
                '#e6aeb1',
                '#e4d354',
                '#cb2a70',
                '#f7a35c',
                '#5ba29a',
                '#d48828',
                '#d05631',
                '#2b908f',
                '#e6b4e5',
                '#38693c',
                '#8085e9',
                '#6caf4e',
                '#3b6bad',
                '#cd4b29',
                '#90ed7d',
            ],
            mainEntityColors: [
                '#1e4be1',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
                '#2fa1f5',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
                '#2fa1f5',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
                '#2fa1f5',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
            ],
            virtualEntityColors: [
                '#ef7e1d',
                '#2731f6',
                '#b73051',
                '#cfba20',
                '#19ddda',
                '#a92828',
                '#64b8b2',
                '#9bc147',
                '#7db8e0',
                '#782b8b',
                '#91e8e1',
                '#b61e4f',
                '#f0e847',
                '#881b55',
                '#4499cf',
                '#f45b5b',
                '#519049',
                // '#f15c80',
                '#e3ae48',
                '#31378b',
                '#d6dc3e',
                '#b71d59',
                '#90ed7d',
                '#a21f2d',
                '#e6aeb1',
                '#e4d354',
                '#cb2a70',
                '#f7a35c',
                '#5ba29a',
                '#d48828',
                '#d05631',
                '#2b908f',
                '#e6b4e5',
                '#38693c',
                '#8085e9',
                '#6caf4e',
                '#3b6bad',
                '#cd4b29',
                '#90ed7d',
            ],
            dashboard: {
                widgets: {
                    // background: 'rgba(29, 27, 65, .75)',
                    background: 'grey lighten-5',
                    // background: 'black',
                    title: 'grey darken-2',
                },
            },
        },
        dark: {
            navigatorBackgroundColor: colors.grey.darken4,
            chartGridStrokeColor: '#1f1f1f',
            chartMinorGridStrokeColor: colors.shades.black,
            ragColors: {
                red: '#f00',
                amber: '#ffb82a',
                green: '#0F0',
                day: '#fff906',
                night: '#162d63',
            },
            entityColors: [
                '#f7a35c',
                '#8085e9',
                // '#f15c80',
                '#e4d354',
                '#2b908f',
                '#f45b5b',
                '#91e8e1',
                '#9bc147',
                '#7db8e0',
                '#782b8b',
                '#91e8e1',
                '#b61e4f',
                '#f0e847',
                '#881b55',
                '#4499cf',
                '#f45b5b',
                '#519049',
                // '#f15c80',
                '#e3ae48',
                '#31378b',
                '#d6dc3e',
                '#b71d59',
                '#90ed7d',
                '#a21f2d',
                '#e6aeb1',
                '#e4d354',
                '#cb2a70',
                '#f7a35c',
                '#5ba29a',
                '#d48828',
                '#d05631',
                '#2b908f',
                '#e6b4e5',
                '#38693c',
                '#8085e9',
                '#6caf4e',
                '#3b6bad',
                '#cd4b29',
                '#90ed7d',
            ],
            mainEntityColors: [
                '#1e4be1',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
                '#2fa1f5',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
                '#2fa1f5',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
                '#2fa1f5',
                '#fc7710',
                '#2b8933',
                '#804D76',
                '#38A3A5',
                '#c5a639',
                '#92a893',
            ],
            virtualEntityColors: [
                '#ef7e1d',
                '#2731f6',
                '#b73051',
                '#cfba20',
                '#19ddda',
                '#a92828',
                '#64b8b2',
                '#9bc147',
                '#7db8e0',
                '#782b8b',
                '#91e8e1',
                '#b61e4f',
                '#f0e847',
                '#881b55',
                '#4499cf',
                '#f45b5b',
                '#519049',
                // '#f15c80',
                '#e3ae48',
                '#31378b',
                '#d6dc3e',
                '#b71d59',
                '#90ed7d',
                '#a21f2d',
                '#e6aeb1',
                '#e4d354',
                '#cb2a70',
                '#f7a35c',
                '#5ba29a',
                '#d48828',
                '#d05631',
                '#2b908f',
                '#e6b4e5',
                '#38693c',
                '#8085e9',
                '#6caf4e',
                '#3b6bad',
                '#cd4b29',
                '#90ed7d',
            ],
            dashboard: {
                widgets: {
                    // background: 'rgba(29, 27, 65, .75)',
                    background: 'grey darken-4',
                    // background: 'black',
                    title: 'grey lighten-2',
                },
            },

        },
    }[theme]
}

export const getCssVarValue = varName => {
    const el = document.querySelector('.v-application')
    return getComputedStyle(el).getPropertyValue(varName)
}

const scopesColors = [
    '#1e54dc',
    '#77b022',
    '#e86f3a',
]

export const netZeroScopeColors = () => {
    return Array(3).fill('').map((v, j) =>
        Array(4).fill('').map((k, i) =>
            chroma(scopesColors[j]).darken(i * 0.67)?.hex(),
        ))
}

export function generateThemes() {
    return {
        light: {
            'card-background': '#ffffff',
            'card-title-bar': '#e9e9e9',
            'btn-background': '#f0f0f0',
            'header-background': '#e9eef2',
            'application-background': '#e9eef2',
            'dialog-content-background': '#f5f5f5',
            'generic-text': '#333333',
            'extreme-opposite': '#000',
            'generic-line': '#dfdfdf',
            // overrides:
            info: colors.indigo.base,
            primary: colors.indigo.base,
            'selection-background': '#e0e6ff',
        },
        dark: {
            'card-background': '#2B2B2B',
            'card-title-bar': colors.grey.darken4,
            'btn-background': '#1B1B1B',
            'header-background': '#171717',
            'application-background': '#171717', // '#000000',
            'dialog-content-background': '#282626',
            'generic-text': '#f0f0f0',
            'extreme-opposite': '#fff',
            'generic-line': '#000',
            // overrides:
            info: colors.indigo.lighten1,
            primary: colors.indigo.lighten1,
            'selection-background': '#40424a',
        },
    }
}
