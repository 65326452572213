
import { required } from 'vuelidate/lib/validators'
import FormMixin from './form.mixin'
import Geocoder from '~/components/common/geocoder'
import SiteSelector from '~/components/controls/site-selector'

export default {
    name: 'EditSite',

    components: { SiteSelector, Geocoder },

    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                site: {
                    business: {
                        required,
                    },
                },
            },
        }
        if (this.draft.site.hasShippingAddress) {
            schema.draft.site.shippingAddress = { required }
        }
        return schema
    },

    data(vm) {
        return {
            title: `${vm.value.site ? 'Edit' : 'Add'} site`,
        }
    },

    computed: {
        items() {
            return this.$store.getters['entities/sites/all']()
        },
    },

    methods: {
        onUpdateSiteFromList(siteId) {
            const site = this.$store.getters['entities/sites/find'](siteId)
            this.draft.site = {
                id: siteId,
                business: {
                    address: site.fullAddress,
                    location: site.position,
                    name: site.name,
                },
            }
        },
        onUpdateSiteFromGeocoder(e) {
            this.draft.site = { id: e.business.id ? null : e.id, business: e.business }
        },
    },
}
