
import { mapGetters } from 'vuex'
import chroma from 'chroma-js'

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters('flows', ['materialById']),
    },

    methods: {
        getColor(v) {
            const scale = chroma.scale(['#f00', '#0f0']).mode('lrgb')
            return scale(v / 100).hex()
        },
    },
}
