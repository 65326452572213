
import { required } from 'vuelidate/lib/validators'
import FormMixin from './form.mixin'

export default {
    name: 'ProcessDetails',

    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                name: {
                    required,
                },
                category: {
                    required,
                },
            },
        }
        return schema
    },

    computed: {
        categories() {
            return ['Category A1', 'Category B2', 'Category xyz']
        },
    },
}
