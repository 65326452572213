import getInitialState from './state'

export default {
    SET_INITIALISED(state, value) {
        state.initialised = value
    },

    RESET(state) {
        Object.assign(state, getInitialState())
    },

    TOGGLE_LOADING(state) {
        state.loading = !state.loading
    },

    SET_LOADING(state, value) {
        state.loading = value
    },

    SET_TREE_ITEMS(state, payload) {
        for (const p in payload) {
            state.treeItems[p] = payload[p]
        }
    },

    SET_TAB_INDEX(state, value) {
        state.tabIndex = value
    },

    SET_DRILL_DOWN(state, value) {
        state.drillDown = value
    },

    SET_COMPARISON(state, value) {
        state.comparison = value
    },

    TOGGLE_ANALYSIS_FEATURE(state, { key, active }) {
        state.analysisFeatures[key].active = active
    },

    RESET_ANALYSIS_FEATURES(state) {
        const initialState = {}
        for (const [key, value] of Object.entries(state.analysisFeatures)) {
            initialState[key] = {
                ...value,
                active: false,
            }
        }
        state.analysisFeatures = initialState
    },

    SET_SELECTED_UNIT(state, value) {
        state.selectedUnit = value
    },

    ADD_SNAPSHOT(state, value) {
        state.snapshots.push(value)
    },

    SET_SNAPSHOTS(state, value) {
        state.snapshots = value
    },

    SET_SELECTED_POINT(state, value) {
        state.selectedPoint = value
    },

    SET_AUTO_Y_AXIS(state, value) {
        state.autoYAxis = value
    },
    SET_DEFAULT_AUTO_Y_AXIS(state, value) {
        state.defaultAutoYAxis = value
    },

    SET_TRIADS_READINGS(state, v) {
        state.triadsReadings = v
    },

    SET_LOAD_FACTOR_DATA(state, v) {
        state.loadFactorData = v
    },

    SET_CONSOLIDATION(state, value) {
        state.consolidation = value
    },

    TOGGLE_RAG(state, value = null) {
        state.ragEnabled = value
    },

    SET_PIT(state, value) {
        state.pit = value
    },

    SET_ANNOTATIONS_MODE(state, value) {
        state.annotationsMode = value
    },

    SET_METER_CONTRACTS(state, value) {
        state.selectedMeterContracts = value
    },

    SET_SELECTED_PARAMETERS_PRESET(state, value) {
        state.selectedParametersPreset = value
    },

    SET_SELECTED_PARAMETER_KEYS(state, value) {
        state.selectedParameterKeys = value
    },

    SET_TRACES_VISIBILITY(state, value) {
        state.tracesVisibility = value
    },

    SET_IS_INSPECTOR_VISIBLE(state, value) {
        state.isInspectorVisible = value
    },
}
