
import { mapState } from 'vuex'
import FormMixin from './form.mixin'
import BusinessCard from './../cards/business-card'

export default {
    name: 'SelectBusiness',
    components: { BusinessCard },
    mixins: [FormMixin],

    data() {
        return {

        }
    },

    computed: {
        ...mapState('flows', {
            businesses: 'thirdParties',
        }),
    },

    methods: {
        onInput(e) {
            this.draft = this.draft?.id === e.id ? {} : e
        },
    },
}
