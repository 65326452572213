import _ from 'lodash'

/**
 * Inject the lodash utility library
 * @param ctx
 * @param inject
 */
export default (ctx, inject) => {
    // Inject 'key', where key is the namespace of the library
    // -> app.$key
    // -> this.$key in vue components
    // -> this.$key in store actions/mutations

    // lodash
    inject('_', _) // -> app.$_
}
