import pick from 'lodash/pick'
import Component from './AsyncDialog.vue'

function Install(Vue, options = {}) {
    const property = options.property || '$asyncDialog'
    delete options.property
    const app = options.app
    delete options.app
    if (!app) {
        console.warn('Module AsyncDialog needs vuetify instance. Use Vue.use(AsyncDialog, { vuetify })')
    }

    const $elements = Object.entries(app).filter(([key]) => key.includes('$'))
    const Ctor = Vue.extend(Object.assign({
        ...pick(app, ['vuetify', 'store', 'i18n']),
        ...Object.fromEntries($elements),
        $moment: app.context.$moment,
    }, Component))

    function createDialogCmp(options) {
        const container = document.querySelector('[data-app=true]') || document.body
        return new Promise(resolve => {
            const cmp = new Ctor(Object.assign({}, {
                propsData: Object.assign({}, Vue.prototype[property].options, options),
                destroyed: () => {
                    container.removeChild(cmp.$el)
                    resolve(cmp.resValue)
                },
            }))
            container.appendChild(cmp.$mount().$el)
        })
    }

    function show(options = {}) {
        return createDialogCmp(options)
    }

    Vue.prototype[property] = show
    Vue.prototype[property].options = options || {}
}

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(Install)
}

export default Install
