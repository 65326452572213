
export default {
    name: 'MaterialsSummaryGrid',

    props: {
        materials: {
            type: Array,
            default: () => [],
        },
        inventory: {
            type: Array,
            default: () => [
                // {
                //     currentUnit:  'kg',
                //     currentValue:  43,
                //     material: 'claqlx87a007wfkv60bgq5uuh',
                //     prevPurchasedUnit: 'kg',
                //     prevPurchasedValue: 43,
                //     site: '30139',
                // }
            ],
        },
    },

    computed: {
        items() {
            return this.materials.reduce((acc, val) => {
                acc.push(
                    val.categoryName,
                    val.name,
                    `${val.value} (${val.unit})`,
                    this.materialInventoryValue(val.id),
                )
                return acc
            }, [])
        },
    },

    methods: {
        materialInventoryValue(materialId) {
            const inventory = this.inventory.find(i => i.material === materialId)
            if (inventory) {
                return `${inventory.currentValue} (${inventory.currentUnit})`
            }
            return 0
        },
    },
}
