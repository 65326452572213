import { mapKeys } from 'lodash'
import { numberUnitFormatter } from '~/utils/calculations'

// same HTTP status codes from https://www.npmjs.com/package/http-status-codes
export const statusCodes = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    MOVED_PERMANENTLY: 301,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    SERVICE_UNAVAILABLE: 503,
}

/**
 * Consolidate API response for multi entities
 * - useful when fetching aggregates for clamps & presets
 *
 * @param {Object} data
 * @returns {Object} res
 */
export function consolidateResponse(data = {}) {
    return {
        ...(data.clamps || data.sites || {}),
        ...mapKeys(data.presets || {}, (v, k) => `V${k}`),
    }
}

/**
 * Transform API response for Net Zero Targets
 *
 * @param {Array} targets
 * @returns void
 */
export function transformTargets(targets = [], sources) {
    targets.forEach(target => {
        const lookup = [
            { value: 1, unit: 'kgCO₂e' },
            { value: 1e3, unit: 'TCO₂e' },
        ]

        // status settings
        const status = target.status
        if (target.status === 'not_achieved') {
            target.statusIcon = {
                text: '$close',
                color: 'orange',
                status,
            }
        } else if (target.status === 'achieved') {
            target.statusIcon = {
                text: '$check',
                color: 'success',
                status,
            }
        } else if (target.status === 'under_evaluation') {
            target.statusIcon = {
                text: '$hourglass',
                color: 'primary',
                status,
            }
        } else if (target.status === 'not_started') {
            target.statusIcon = {
                text: '$hourglassClock',
                color: 'grey',
                status,
            }
        }

        // reduction/emissions denormalization
        if (target.plannedReductionUnit === 'kg') {
            const normalizedPR = numberUnitFormatter(target.plannedReduction, lookup, 3)
            target.plannedReduction = normalizedPR.value
            target.plannedReductionUnit = normalizedPR.unit
        }

        const normRefEmissions = numberUnitFormatter(target.referenceEmissions, lookup, 3)
        const normActualEmissions = numberUnitFormatter(target.actualEmissions, lookup, 3)
        target.emissionsSoFar = normRefEmissions ? `${normRefEmissions.value}${normRefEmissions.unit}` : '-'
        target.targetEmissions = normActualEmissions ? `${normActualEmissions.value}${normActualEmissions.unit}` : '-'

        // setting carbon-sources
        target.sources = target.relations?.map(i =>
            sources.find(({ id }) => id === i),
        )
    })
}
