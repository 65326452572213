import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3e5a5c1e = () => interopDefault(import('../pages/alert-ack.vue' /* webpackChunkName: "pages/alert-ack" */))
const _4a0488fe = () => interopDefault(import('../pages/alerts.vue' /* webpackChunkName: "pages/alerts" */))
const _53dcbb57 = () => interopDefault(import('../pages/carbon.vue' /* webpackChunkName: "pages/carbon" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _e08f7dd4 = () => interopDefault(import('../pages/demo-accounts.vue' /* webpackChunkName: "pages/demo-accounts" */))
const _e64e6412 = () => interopDefault(import('../pages/electricity.vue' /* webpackChunkName: "pages/electricity" */))
const _7cf6e1aa = () => interopDefault(import('../pages/entities.vue' /* webpackChunkName: "pages/entities" */))
const _2015c96e = () => interopDefault(import('../pages/entities/index.vue' /* webpackChunkName: "pages/entities/index" */))
const _31797da8 = () => interopDefault(import('../pages/entities/sites.vue' /* webpackChunkName: "pages/entities/sites" */))
const _b9922dfa = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _05c0c10b = () => interopDefault(import('../pages/flows.vue' /* webpackChunkName: "pages/flows" */))
const _448a1202 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _602d7742 = () => interopDefault(import('../pages/gas.vue' /* webpackChunkName: "pages/gas" */))
const _37a4b5ff = () => interopDefault(import('../pages/impersonate.vue' /* webpackChunkName: "pages/impersonate" */))
const _30234a40 = () => interopDefault(import('../pages/jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _088b7ccc = () => interopDefault(import('../pages/league-table.vue' /* webpackChunkName: "pages/league-table" */))
const _8758567c = () => interopDefault(import('../pages/net-zero.vue' /* webpackChunkName: "pages/net-zero" */))
const _752b7c76 = () => interopDefault(import('../pages/net-zero/index.vue' /* webpackChunkName: "pages/net-zero/index" */))
const _5373109e = () => interopDefault(import('../pages/net-zero/entities.vue' /* webpackChunkName: "pages/net-zero/entities" */))
const _7f0576c1 = () => interopDefault(import('../pages/net-zero/table.vue' /* webpackChunkName: "pages/net-zero/table" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7cc8bd45 = () => interopDefault(import('../pages/reports.vue' /* webpackChunkName: "pages/reports" */))
const _5159081d = () => interopDefault(import('../pages/water.vue' /* webpackChunkName: "pages/water" */))
const _527ec100 = () => interopDefault(import('../pages/auth/activate.vue' /* webpackChunkName: "pages/auth/activate" */))
const _51167460 = () => interopDefault(import('../pages/auth/forgot-pwd.vue' /* webpackChunkName: "pages/auth/forgot-pwd" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _1caa43a3 = () => interopDefault(import('../pages/auth/login-as.vue' /* webpackChunkName: "pages/auth/login-as" */))
const _7ee727a4 = () => interopDefault(import('../pages/auth/sign-up.vue' /* webpackChunkName: "pages/auth/sign-up" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alert-ack",
    component: _3e5a5c1e,
    name: "alert-ack___en"
  }, {
    path: "/alerts",
    component: _4a0488fe,
    name: "alerts___en"
  }, {
    path: "/carbon",
    component: _53dcbb57,
    name: "carbon___en"
  }, {
    path: "/contact",
    component: _e64111b4,
    name: "contact___en"
  }, {
    path: "/demo-accounts",
    component: _e08f7dd4,
    name: "demo-accounts___en"
  }, {
    path: "/electricity",
    component: _e64e6412,
    name: "electricity___en"
  }, {
    path: "/entities",
    component: _7cf6e1aa,
    children: [{
      path: "",
      component: _2015c96e,
      name: "entities___en"
    }, {
      path: "sites",
      component: _31797da8,
      name: "entities-sites___en"
    }]
  }, {
    path: "/events",
    component: _b9922dfa,
    name: "events___en"
  }, {
    path: "/flows",
    component: _05c0c10b,
    name: "flows___en"
  }, {
    path: "/forbidden",
    component: _448a1202,
    name: "forbidden___en"
  }, {
    path: "/fr",
    component: _2dfb1658,
    name: "index___fr"
  }, {
    path: "/gas",
    component: _602d7742,
    name: "gas___en"
  }, {
    path: "/impersonate",
    component: _37a4b5ff,
    name: "impersonate___en"
  }, {
    path: "/jobs",
    component: _30234a40,
    name: "jobs___en"
  }, {
    path: "/league-table",
    component: _088b7ccc,
    name: "league-table___en"
  }, {
    path: "/net-zero",
    component: _8758567c,
    children: [{
      path: "",
      component: _752b7c76,
      name: "net-zero___en"
    }, {
      path: "entities",
      component: _5373109e,
      name: "net-zero-entities___en"
    }, {
      path: "table",
      component: _7f0576c1,
      name: "net-zero-table___en"
    }]
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile___en"
  }, {
    path: "/reports",
    component: _7cc8bd45,
    name: "reports___en"
  }, {
    path: "/water",
    component: _5159081d,
    name: "water___en"
  }, {
    path: "/auth/activate",
    component: _527ec100,
    name: "auth-activate___en"
  }, {
    path: "/auth/forgot-pwd",
    component: _51167460,
    name: "auth-forgot-pwd___en"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___en"
  }, {
    path: "/auth/login-as",
    component: _1caa43a3,
    name: "auth-login-as___en"
  }, {
    path: "/auth/sign-up",
    component: _7ee727a4,
    name: "auth-sign-up___en"
  }, {
    path: "/fr/alert-ack",
    component: _3e5a5c1e,
    name: "alert-ack___fr"
  }, {
    path: "/fr/alerts",
    component: _4a0488fe,
    name: "alerts___fr"
  }, {
    path: "/fr/carbon",
    component: _53dcbb57,
    name: "carbon___fr"
  }, {
    path: "/fr/contact",
    component: _e64111b4,
    name: "contact___fr"
  }, {
    path: "/fr/demo-accounts",
    component: _e08f7dd4,
    name: "demo-accounts___fr"
  }, {
    path: "/fr/electricity",
    component: _e64e6412,
    name: "electricity___fr"
  }, {
    path: "/fr/entities",
    component: _7cf6e1aa,
    children: [{
      path: "",
      component: _2015c96e,
      name: "entities___fr"
    }, {
      path: "sites",
      component: _31797da8,
      name: "entities-sites___fr"
    }]
  }, {
    path: "/fr/events",
    component: _b9922dfa,
    name: "events___fr"
  }, {
    path: "/fr/flows",
    component: _05c0c10b,
    name: "flows___fr"
  }, {
    path: "/fr/forbidden",
    component: _448a1202,
    name: "forbidden___fr"
  }, {
    path: "/fr/gas",
    component: _602d7742,
    name: "gas___fr"
  }, {
    path: "/fr/impersonate",
    component: _37a4b5ff,
    name: "impersonate___fr"
  }, {
    path: "/fr/jobs",
    component: _30234a40,
    name: "jobs___fr"
  }, {
    path: "/fr/league-table",
    component: _088b7ccc,
    name: "league-table___fr"
  }, {
    path: "/fr/net-zero",
    component: _8758567c,
    children: [{
      path: "",
      component: _752b7c76,
      name: "net-zero___fr"
    }, {
      path: "entities",
      component: _5373109e,
      name: "net-zero-entities___fr"
    }, {
      path: "table",
      component: _7f0576c1,
      name: "net-zero-table___fr"
    }]
  }, {
    path: "/fr/profile",
    component: _d5f21fa2,
    name: "profile___fr"
  }, {
    path: "/fr/reports",
    component: _7cc8bd45,
    name: "reports___fr"
  }, {
    path: "/fr/water",
    component: _5159081d,
    name: "water___fr"
  }, {
    path: "/fr/auth/activate",
    component: _527ec100,
    name: "auth-activate___fr"
  }, {
    path: "/fr/auth/forgot-pwd",
    component: _51167460,
    name: "auth-forgot-pwd___fr"
  }, {
    path: "/fr/auth/login",
    component: _8e1272a8,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/login-as",
    component: _1caa43a3,
    name: "auth-login-as___fr"
  }, {
    path: "/fr/auth/sign-up",
    component: _7ee727a4,
    name: "auth-sign-up___fr"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
