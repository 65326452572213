
export default {
    computed: {
        items() {
            const items = [
                {
                    icon: '$userGroup',
                    title: 'Impersonate',
                    to: this.localePath('/impersonate'),
                },
            ]
            if (this.$config.features.demoAccountsEditorEnabled) {
                items.push(
                    {
                        icon: '$userClock',
                        title: 'Demo',
                        to: this.localePath('/demo-accounts'),
                    },
                )
            }
            return items
        },
        activeRoute() {
            return this.$route.matched[0]?.path
        },
    },

    methods: {
        async downloadJSON() {
            this.downloadButtonDisabled = true
            const result = await this.$store.dispatch('electricity/downloadJSON')
            if (result !== true) {
                this.$toast.show(result)
            }
            this.downloadButtonDisabled = false
        },
    },
}
