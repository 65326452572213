/**
 * Entities middleware
 *
 * NOT in use any more since CVP-1584
 *
 * @param {Object} context
 */
export default async function({ app, req, route, store }) {
    // If nuxt generate, pass this middleware
    if (process.server && !req) return

    // no entities should be fetched before auth
    if (!app.$_.get(app, '$auth.user.customer_id')) return

    let entities = route.meta.reduce((entities, meta) => {
        if (meta.entities) {
            if (typeof meta.entities === 'function') {
                entities = app.$_.union(entities, meta.entities(app))
            } else {
                entities = app.$_.union(entities, meta.entities)
            }
        }
        return entities
    }, [])

    if (!entities) return

    if (!Array.isArray(entities)) {
        entities = [entities]
    }

    const result = await Promise.allSettled(
        entities.map(entity => {
            return store.getters[`entities/${entity}/query`]().getModel().fetch()
        }),
    )
    result.forEach(response => {
        if (response.status === 'rejected') {
            console.log(response)
        }
    })
    return result
}
