export default () => ({
    initialUnit: 'U_GENE', // 'cbm', 'CBM' or 'm3'
    initialised: null,
    loading: false,
    loadingLevel: 0,
    treeviews: [],
    selectedUnit: 'U_GENE', // 'cm',
    selectedPoint: null,
    autoYAxis: false,
    defaultAutoYAxis: false,
    triadsReadings: [],
    loadFactorData: null,
    ragEnabled: false,
    analysisFeatures: {
        maxDemands: {
            active: false,
            name: 'Max Demands',
            description: '',
            type: 'annotation',
        },
        loadFactor: {
            active: false,
            name: 'Load factor',
            description: '',
            type: 'flag',
        },
        averageLines: {
            active: false,
            name: 'Average lines',
            description: '',
            // type: ['filter', 'annotation']
        },
        peakTimes: {
            active: false,
            name: 'Peak times',
            description: '',
            type: 'annotation',
        },
        triads: {
            active: false,
            name: 'Triads',
            description: '',
            type: 'annotation',
        },
    },
    consolidation: false,
    annotationsMode: false,
    comparison: null,
    selectedParametersPreset: null,
    maxSelectableParameters: 4,
    maxSelectableCircuitsWhenMultiParameter: 2,
    tracesVisibility: {},
    isInspectorVisible: false,
})
