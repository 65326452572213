
import processItems from '~/components/flows/process/items'

export default {
    name: 'ProcessSummaryPanel',

    components: {
        ...processItems,
    },

    props: {
        active: Boolean,
        activeComponentId: {
            type: String,
            default: '',
        },
        processIdx: {
            type: Number,
            default: 0,
        },
        process: {
            type: Object,
            required: true,
        },
    },

    computed: {
        machine() {
            return this.itemById('machine')?.draft
        },
        fuels() {
            return this.itemById('fuels')?.draft
        },
        waste() {
            return this.itemById('waste')?.draft
        },
    },

    mounted() {
        window[`__PSP_${this.processIdx}`] = this
    },

    methods: {
        itemById(id) {
            return this.process.items.find(item => item?.id === id)
        },
    },
}
