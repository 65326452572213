
export default {
    name: 'EnergyCard',

    props: {
        material: {
            type: Object,
            required: true,
        },
        entities: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        // console.log(vm.selectedItems)
        return {

        }
    },

    computed: {

    },

    methods: {
        findEntitiesByCommodity(commodity) {
            const entities = this.entities.find(e => e.commodity === commodity)
            return entities
        },
    },
}
