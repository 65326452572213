import localforage from 'localforage' // 1 hour, expiration for DB other than the current customer's
import { version } from '~/package.json'
const STORAGE_EXPIRATION = 3.6e6
export default class StorageService {
    _dbsArchive = []
    constructor({ name }) {
        try {
            // this._storage = localStorage
            this._storage = localforage.createInstance({
                name,
            })

            this._dbsArchive = localforage.createInstance({
                name: '_dbs_archive',
            })
            this.updateDBsList(name)
        } catch (err) {
            console.warn(err.message)
        }
    }

    has(key) {
        return Object.prototype.hasOwnProperty.call(this._storage, key)
    }

    async get(key) {
        try {
            const value = await this._storage.getItem(key)
            return JSON.parse(value)
        } catch (err) {
            console.log('err: ', err)
            // This code runs if there were any errors.
            return undefined
        }
    }

    set(key, data) {
        return this._storage.setItem(key, JSON.stringify(data))
    }

    remove(key) {
        return this._storage.removeItem(key)
    }

    async destroy() {
        await this._storage.clear()
        await this._storage.dropInstance()
    }

    async updateDBsList(newDBName) {
        await this.deleteOldDBs(newDBName)
        await this._dbsArchive.setItem(newDBName, { date: Date.now(), version })
    }

    deleteOldDBs(except) {
        try {
            this._dbsArchive.iterate(async(value, key) => {
                if (
                    !value.date ||
                    !value.version ||
                    Date.now() - value.date > STORAGE_EXPIRATION ||
                    value.version !== version
                ) {
                    // console.info(`deleting data for ${key}`)
                    // if it's used by another tab/window, the dropInstance will fail
                    // so i will just clear all the entries
                    await localforage
                        .createInstance({
                            name: key,
                        })
                        .clear()
                    await this._dbsArchive.removeItem(key)
                }
            })
        } catch (e) {
            console.warn(e)
        }
    }
}
