export const state = {
    /**
     * Active treeview id
     *
     * @type {Object}
     */
    id: null,
}

export const getters = {
    active(state, getters) {
        return getters.query()
            .where('id', state.id)
            .first()
    },
    isVirtualTab(state, getters) {
        return getters.active?.type === 'virtual'
    },
}

export const mutations = {
    SET_ID(state, id) {
        state.id = id
    },
}

export const actions = {
    init({ getters }) {
        getters.all().forEach(treeview => treeview.init())
    },

    setId({ commit, state }, id) {
        if (state.id !== id) {
            commit('SET_ID', id)
        }
    },

    reset({ getters }) {
        getters.all().forEach(treeview => treeview.reset())
    },
}
