
import { minLength, numeric, required } from 'vuelidate/lib/validators'
import { genericUnits } from '../const'
import FormMixin from './form.mixin'
import { sortDates } from '~/utils/date'

export default {
    name: 'ProcessMachine',

    components: {

    },

    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                machineId: { required },
                dateRange: {
                    required,
                    minLength: minLength(2),
                },
                fuels: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        fuelId: {
                            required,
                        },
                        quantity: {
                            required,
                            numeric,
                        },
                        unit: {
                            required,
                        },
                    },
                },
            },
        }
        return schema
    },

    props: {

    },

    data: () => ({
        dateMenu: false,
    }),

    computed: {
        machines() {
            return [
                {
                    machineId: 1,
                    name: 'Machine 1',
                },
                {
                    machineId: 2,
                    name: 'Machine 2',
                },
                {
                    machineId: 3,
                    name: 'Machine 3',
                },
            ]
        },
        fuels() {
            return [
                {
                    fuelId: 1,
                    name: 'Fuel 1',
                },
                {
                    fuelId: 2,
                    name: 'Fuel 2',
                },
                {
                    fuelId: 3,
                    name: 'Fuel 3',
                },
            ]
        },
        genericUnits() {
            return genericUnits
        },
        dateRangeText() {
            if (this.draft.dateRange.length < 2) return ''
            const sortedDates = sortDates(this.draft.dateRange).map(v => v.format('Do MMM YYYY'))
            return sortedDates.join(' to ')
        },
    },

    methods: {
        onInput(e) {
            clearTimeout(this._timeout)
            this._timeout = setTimeout(() => {
                if (e.length === 2) {
                    this.dateMenu = false
                }
            }, 300)
        },
    },
}
