
import ItemCard from './ItemCard'

export default {
    name: 'ProcessCategory',

    components: {
        ItemCard,
    },

    props: {
        active: Boolean,
        category: {
            type: Object,
            default: () => ({
                icon: '$gears',
            }),
        },
    },
}
