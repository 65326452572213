
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        logo() {
            return this.value.name.split(' ').map(([e]) => e).join('').replace(/[^a-zA-Z&]+/g, '').substr(0, 5)
        },
    },
}
