
import ItemCard from './ItemCard'

export default {
    name: 'ProcessOrigin',

    components: {
        ItemCard,
    },

    props: {
        active: Boolean,
        origin: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        address() {
            if (this.origin.entity === 'sites') {
                return [
                    this.origin.siteName || this.origin.name,
                    this.origin.address1 ? [this.origin.city, this.origin.address1].join(', ') : this.origin.address,
                ].join(', ')
            }
            return this.origin.address
        },
    },
}
