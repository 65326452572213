// Utils
import { get, isEqual, map, remove } from 'lodash'
import { FROM_ROOT } from '~/utils/state'
import { percentageDifference } from '~/utils/math'
import { apiEntityName, parseId, sitesSupportedCommodities } from '~/utils/entities'
import Circuit from '~/orm/models/Circuit'

export default {
    async init({ commit, dispatch, rootState }) {
        // setup circuits & pipes
        await Promise.all([
            // sites
            dispatch('entities/sites/setup', {}, FROM_ROOT),
            // circuits
            dispatch('entities/circuits/setup', {}, FROM_ROOT),
            dispatch('entities/circuits/setupVirtual', {}, FROM_ROOT),
            // pipes
            dispatch('entities/pipes/setup', {}, FROM_ROOT),
            dispatch('entities/pipes/setupVirtual', {}, FROM_ROOT),
        ])

        // init/update period
        const periodDefaults = { resolution: 1800 }
        if (!rootState.period.initialised) {
            await dispatch('period/init', periodDefaults, FROM_ROOT)
        } else if (rootState.period.interval === 'hour') {
            periodDefaults.interval = null
            periodDefaults.shift = null
            periodDefaults.start = this.$moment().subtract(1, 'month').startOf('h')
            periodDefaults.end = this.$moment().startOf('h')
            await dispatch('period/update', periodDefaults, FROM_ROOT)
        } else if (rootState.period.resolution !== 1800) {
            await dispatch('period/update', periodDefaults, FROM_ROOT)
        }

        // select sites
        commit('SET_BASE_ENTITY', 'sites')
        if (rootState.selectedSiteIds.length > 0) {
            dispatch('setSelected', { entity: 'sites', ids: rootState.selectedSiteIds })
        } else {
            dispatch('selectAllSites')
        }

        // fetch readings
        dispatch('fetchReadings')
        // init
        commit('INIT')
    },

    selectAllSites({ dispatch, getters }) {
        dispatch('setSelected', { entity: 'sites', ids: getters.sites.map(s => s.id) })
    },

    setSelected({ commit, dispatch }, payload) {
        dispatch('checkSupportedCommodities', payload)
        commit('SET_SELECTED', payload)
        if (payload.baseEntity) {
            commit('SET_BASE_ENTITY', payload.baseEntity)
        }
    },

    /**
     * Check if sites are having both commodities
     * and update / disable commodity if necessary
     */
    checkSupportedCommodities({ dispatch, getters, state }, payload) {
        if (payload.entity === 'sites') {
            const sites = getters.sitesByIds(payload.ids)
            const commodities = sitesSupportedCommodities(sites)
            if (!isEqual([...commodities].sort(), [...state.commodities].sort())) { dispatch('setCommodities', commodities) }
        }
    },

    setCommodities({ commit, dispatch, state }, commodities = []) {
        commit('SET_COMMODITIES', commodities)
        if (!commodities.includes(state.commodity)) { dispatch('setCommodity', commodities[0]) }
    },

    setCommodity({ commit, dispatch, getters, state }, value) {
        dispatch('resetActive')
        commit('SET_COMMODITY', value)
        const commodityUnits = getters.units
        const foundCommodityUnit = commodityUnits.find(u => u.id === state.unitId)
        if (!foundCommodityUnit) { commit('SET_UNIT_ID', this.$_.get(commodityUnits, '0.id')) }
    },

    resetActive({ commit, state }) {
        if (!['presets', 'sites'].includes(state.active.entity) || state.active.ids.length > 0) {
            commit('RESET_ACTIVE')
        }
    },

    toggleEntityIds({ commit, getters, state }, entityId) {
        let activeEntityIds = [...state.active.ids]
        if (!entityId) { activeEntityIds = [] } else if (activeEntityIds.includes(entityId)) { remove(activeEntityIds, id => id === entityId) } else { activeEntityIds.push(entityId) }

        if (!isEqual(activeEntityIds, state.active.ids)) { commit('SET_ACTIVE', { ids: activeEntityIds, entity: getters.entity }) }
    },

    setComparison({ commit }, value) {
        commit('SET_COMPARISON', value)
    },

    toggleComparison({ dispatch, state, rootGetters }) {
        const comparison = state.comparison ? null : this.$_.clone(rootGetters['period/comparison'])
        dispatch('setComparison', comparison)
    },

    async updateUnitSummaries({ state, commit, dispatch, getters, rootGetters }, externalCommit = false) {
        let unitSummaries = []

        if (getters.selectedEntities.length > 0) {
            commit('SET_LOADING', true)
            const baseComparison = rootGetters['period/comparison']
            const resolution = getters.dynamicResolution
            const promises = [
                dispatch('fetchUnitReadings'),
                dispatch('fetchUnitAggregates'),
            ]

            // if isolated circuits
            if (state.active.ids.length > 0) {
                promises.push(dispatch('fetchUnitAggregates', { forAll: true }))
            }

            const [readings, aggregates, allEntitiesAggregates] = await Promise.all(promises)
            unitSummaries = getters.units.map(unitModel => {
                const unit = unitModel.$toJson()
                const value = unitModel.valueFromAggregates(aggregates.data)
                const total = allEntitiesAggregates ? unitModel.valueFromAggregates(allEntitiesAggregates.data) : value
                let comparison
                if (aggregates.comparison) {
                    comparison = {
                        ...baseComparison,
                        value: unitModel.valueFromAggregates(aggregates.comparison.data),
                        from: aggregates.comparison.from,
                        to: this.$moment(aggregates.comparison.to).subtract(1, 's').format(),
                        resolution: aggregates.comparison.resolution,
                    }
                    if (unitModel.id === 'kva' && aggregates.comparison.extra) {
                        comparison.extra = {
                            value: unitModel.valueFromAggregates(aggregates.comparison.extra.data),
                            resolution: aggregates.comparison.extra.resolution,
                            excessCost: this.$_.get(aggregates.comparison, 'extra.data.sum.C_DUXCAP.value', 0),
                        }
                    }
                }
                let title = this.$i18n.t(`units.${unit.id}.name`)
                if (unit.param === 'RE') {
                    title = this.$i18n.t('interpolations.billable_unitname', { unitName: title })
                }
                const summary = {
                    ...unit,
                    title,
                    diff: percentageDifference(value, comparison?.value),
                    value,
                    total,
                    from: aggregates.from,
                    to: this.$moment(aggregates.to).subtract(1, 's').format(),
                    resolution,
                    comparison,
                    readings: readings.map(({ data, ...r }) => {
                        return {
                            ...r,
                            data: get(data, unit.param, []),
                        }
                    }),
                }

                if (unitModel.id === 'kva' && aggregates.extra) {
                    summary.extra = {
                        excessCost: this.$_.get(aggregates.extra, 'data.sum.C_DUXCAP.value', 0),
                        value: unitModel.valueFromAggregates(aggregates.extra.data),
                        resolution: aggregates.extra.resolution,
                    }
                    summary.extra.total = allEntitiesAggregates?.extra ? unitModel.valueFromAggregates(allEntitiesAggregates.extra.data) : summary.extra.value
                    summary.extra.diff = percentageDifference(summary.extra.value, comparison?.extra?.value)
                }

                if (unitModel.id === 'pf' && aggregates.extra) {
                    summary.total = summary.value = allEntitiesAggregates?.extra ? unitModel.valueFromAggregates(allEntitiesAggregates.extra.data) : unitModel.valueFromAggregates(aggregates.extra.data)
                }

                return summary
            })
        }

        const executeCommit = () => {
            commit('SET_UNIT_SUMMARIES', unitSummaries)
            commit('SET_LOADING', false)
        }

        if (externalCommit) {
            return executeCommit
        }

        executeCommit()
    },

    async fetchReadings({ commit, getters, rootGetters }, externalCommit = false) {
        let dailyReadings = null
        let monthlyReadings = null
        if (getters.selectedEntities.length > 0) {
            const daterange = rootGetters['period/maxRange']
            const dailyPayload = {
                daterange,
                fields: getters.params,
                resolution: 'day',
                ...getters.entitiesPayload,
            }
            const monthlyPayload = {
                ...dailyPayload,
                resolution: 'month',
            }

                ;[dailyReadings, monthlyReadings] = await Promise.all([
                this.$api.clickHouse.fetchReadings(dailyPayload),
                this.$api.clickHouse.fetchReadings(monthlyPayload),
            ])
        }

        const executeCommit = () => {
            commit('SET_READINGS', {
                dailyReadings,
                monthlyReadings,
            })
        }

        if (externalCommit) {
            return executeCommit
        }

        executeCommit()
    },

    async fetchUnitReadings({ getters, rootGetters, state }) {
        const canCompare = !rootGetters['period/isAll']
        const daterange = rootGetters['period/rangeToISO']
        const basePayload = {
            daterange,
            trim: false,
            fields: getters.params,
            resolution: getters.dynamicResolution,
            ...getters.entitiesPayload,
        }

        let items = []
        const commodities = [undefined, 'power', 'gas']
        if (state.commodity) {
            items = [{ commodity: state.commodity, comparison: false }]
        } else {
            items = commodities.filter(c => !getters.disabledCommodities.includes(c)).map(commodity => ({ commodity, comparison: false }))
        }

        if (canCompare) {
            if (state.comparison && !getters.isComparingSamePeriod) {
                items = items.flatMap(item => {
                    return [
                        item,
                        { ...item, comparison: true },
                        { ...item, customComparison: true },
                    ]
                })
            } else {
                items = items.flatMap(item => {
                    return [
                        item,
                        { ...item, comparison: true },
                    ]
                })
            }
        }

        const comparisonRangeToIso = state.comparison && [
            this.$moment(state.comparison.start).toISOString(),
            this.$moment(state.comparison.end).toISOString()]

        const payloads = items.map(item => {
            const payload = { ...basePayload }
            if (item.commodity) {
                payload.commodities = [item.commodity]
                if (Array.isArray(payload.clampIds)) {
                    payload.clampIds = getters.filterClampIdsByCommodity(payload.clampIds, item.commodity)
                }
            }
            if (item.comparison) {
                payload.daterange = rootGetters['period/comparisonRangeToISO']
            }
            if (item.customComparison) {
                payload.daterange = comparisonRangeToIso
            }
            return payload
        })
        const promises = payloads.map(p => {
            if (Array.isArray(p.clampIds) && p.clampIds.length === 0) {
                return {}
            }
            return this.$api.clickHouse.fetchReadings(p)
        })
        const responses = await Promise.all(promises)

        return responses.map((res, idx) => {
            // if (Object.keys(res).length === 0) return false
            return {
                data: res.data ?? [],
                ...items[idx],
                from: res.from,
                to: res.to,
            }
        })
    },

    /**
     * Fetch Unit Aggregates
     *
     * @todo fix this when passing forAll param (has isolated entities)
     *
     * @param {Object} getters
     * @param {Object} rootGetters
     * @param {Boolean} comparison - flag for compared period
     * @param {Boolean} forAll - flag for all entities
     *
     * @return {Promise} aggregates result
     */
    async fetchUnitAggregates({ dispatch, getters, rootGetters }, { forAll = false } = {}) {
        const daterange = rootGetters['period/rangeToISO']
        const basePayload = {
            daterange,
            precision: 2,
            forAll,
            resolution: getters.dynamicResolution,
            ...getters.aggregatesPayload,
            ...(forAll ? getters.allEntitiesPayload : getters.entitiesPayload),
        }

        const payloads = [basePayload]

        if (!forAll && !rootGetters['period/isAll']) {
            payloads.push({
                ...basePayload,
                daterange: rootGetters['period/comparisonRangeToISO'],
                comparison: true,
            })
        }

        const promises = payloads.map(p => dispatch('fetchUnitAggregatesWithExtra', p))
        const [aggregates, comparison] = await Promise.all(promises)

        return {
            ...aggregates,
            comparison,
        }
    },

    async fetchUnitAggregatesWithExtra({ getters, rootState, state }, payload) {
        const promises = [this.$api.clickHouse.fetchAggregates(payload)]
        if (state.commodity !== 'gas') {
            promises.push(
                // separate extra request to fetch kva aggregates
                // this is so it will then match the value on electricity page @ base resolution of 15'/30' resolution
                this.$api.clickHouse.fetchAggregates({
                    ...this.$_.pick(payload, ['daterange', 'precision', 'siteIds', 'clampIds', 'presetIds']),
                    resolution: rootState.period.resolution,
                    ...getters.extraAggregatesPayload,
                    // isExtra: true,
                }),
            )
        }
        const [aggregates, extra] = await Promise.all(promises)
        return {
            ...aggregates,
            extra,
        }
    },

    async fetchAggregates({ commit, getters, rootGetters, state }, externalCommit = false) {
        let aggregates = []
        if (getters.selectedEntities.length > 0) {
            const daterange = rootGetters['period/rangeToISO']
            const entity = getters.entity
            const entitiesPayload = getters.allEntitiesPayload
            const payload = {
                daterange,
                splitCircuits: true,
                precision: 3,
                resolution: getters.dynamicResolution,
                ...getters.aggregatesPayload,
                ...entitiesPayload,
            }

            // add gov parameters (for cost calculation)
            if (state.unitId === 'cost') {
                payload.sum.push('C_GRO', 'C_GCCL', 'C_GFIT', 'C_GCMOP', 'C_GCMOB', 'C_GCFOP', 'C_GCFOB')
            }
            // add day, night, red, amber, green parameters (for cost and energy, used in table breakdown)
            if (['cost', 'kwh'].includes(state.unitId)) {
                payload.sum.push('U_SD', 'U_SN', 'U_DUR', 'U_DUA', 'U_DUG')
            }

            const res = await this.$api.clickHouse.fetchAggregates(payload)
            const alerts = rootGetters['entities/alerts/all']()
            const whichKey = getters.entity === 'sites' ? 'siteId' : 'id'
            const alertObj = {}
            alerts.forEach(alert => {
                alert.entities.forEach(ent => {
                    if (ent instanceof Circuit && ent[whichKey] && alert.notifications > 0) {
                        if (!alertObj[ent[whichKey]]) {
                            alertObj[ent[whichKey]] = []
                        }
                        alertObj[ent[whichKey]].push({ id: alert.id, not: alert.notifications })
                    }
                })
            })

            // map data
            aggregates = map(res.data[apiEntityName(entity)], (value, key) => {
                const id = parseId(key)
                const entityModel = getters.entityModel({ entity, id })
                const totalAlert = this.$_.sumBy(alertObj[id], 'not') || 0
                const maxValueObj = this.$_.maxBy(alertObj[id], 'not') || { id: 0 }
                return {
                    ...value,
                    id,
                    entity: entityModel.entity,
                    name: entityModel.getName(),
                    friendlyName: entityModel.getName({ friendly: true }),
                    isMainIncomer: entityModel.isMainIncomer,
                    siteName: entityModel.siteName,
                    alert: {
                        id: maxValueObj.id,
                        value: totalAlert,
                    },
                }
            })
        }

        const executeCommit = () => {
            // set aggregates
            commit('SET_AGGREGATES', aggregates)
        }

        if (externalCommit) {
            return executeCommit
        }

        executeCommit()
    },
}
