
import ComparisonCard from '~/components/cards/comparison-card.vue'

export default {
    name: 'SelectAppMode',
    components: { ComparisonCard },
    props: {
        allowThemeUpdate: {
            type: Boolean,
            default: false,
        },
        settingsAppMode: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        appMode: 'lite',
        loading: false,
    }),
    methods: {
        onChoiceClick(value) {
            if (this.settingsAppMode) {
                this.$emit('update:settings-app-mode', value)
            } else {
                this.appMode = value
            }
        },
        async saveChoice() {
            this.loading = true
            const updates = {
                appMode: this.appMode,
            }
            if (this.allowThemeUpdate) {
                const theme = this.appMode === 'pro' ? 'dark' : 'light'
                if (this.$config.envName !== 'demo') {
                    this.$store.dispatch('setTheme', { theme, updateTheme: true })
                }
                updates.theme = theme
            }
            this.loading = false
            // Used to store appMode locally for demo users
            localStorage.setItem('localAppMode', this.appMode)
            this.$emit('on-close')

            await this.$store.dispatch('updateSettings', {
                general: updates,
            })
        },
    },
}
