
import { required } from 'vuelidate/lib/validators'
import FormMixin from './form.mixin'
import Geocoder from '~/components/common/geocoder'

export default {
    name: 'EditLocation',

    components: { Geocoder },

    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                location: {
                    required,
                },
            },
        }
        return schema
    },

    data() {
        return {
            items: [],
        }
    },

    mounted() {
        this.init()
    },

    methods: {
        async init() {
            this.items = await this.$api.flows.fetchSuppliers()
        },
    },
}
