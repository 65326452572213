import _ from 'lodash'

export default {
    entityModelQuery: (state, getters, rootState, rootGetters) => entity => {
        return rootGetters[`entities/${entity}/query`]()
    },

    entityModel: (state, getters) => entity => {
        return getters.entityModelQuery(entity).getModel()
    },

    virtualSelectedCircuitIds(state) {
        const circuitIds = []
        _.each(state.virtualTree, customer => {
            _.each(customer.children, virtualMeter => {
                _.each(virtualMeter.children, circuit => {
                    if (circuit.selected) {
                        circuitIds.push(circuit.id)
                    }
                })
            })
        })
        return circuitIds
    },
}
