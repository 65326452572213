/* Utils */
import camelCase from 'lodash/camelCase'

/**
 * Font Awesome Abreviations
 * ----------------
 * fab - font awesome brands
 * fad - font awesome duotone
 * far - font awesome regular
 * fas - font awesome solid
 * fass - font awesome sharp solid
 * fasr - font awesome sharp regular
 * fasl - font awesome sharp light
 */

// component name used for rendering the icons
const component = 'font-awesome-icon'

const icons = {
    fac: [
        'cvc02',
        'cvelectricity',
        'cvgas',
        'cvwater',
        'cvalarm',
        'cvcalendar',
        'cvdashboard',
        'cvgrid',
        'cvflows',
        'cvnetzero',
    ],
    fab: [
        'gitter',
    ],
    fad: [
        // moved all to fass (sharp solid)
    ],
    far: [
        // moved all to fasr (sharp regular)
    ],
    fas: [
        // moved all to fass (sharp solid)
    ],
    fasl: [

    ],
    fasr: [
        'alarm-clock',
        'angle-down',
        'angle-up',
        'arrow-up',
        'arrow-down',
        'chart-bar',
        'chevrons-right',
        'chevrons-left',
        'chevron-right',
        'chevron-left',
        'columns',
        'gauge-high',
        'home',
        'list',
        'lock',
        'pencil',
        'play',
        'power-off',
        'rotate-right',
        'shuffle',
        'timer',
        'trash-can',
        'triangle-exclamation',
    ],
    fass: [
        'alarm-plus',
        'arrow-alt-from-left',
        'arrow-down-a-z',
        'arrow-progress',
        'arrow-right-arrow-left',
        'backward',
        'bell-on',
        'bolt',
        'bolt-auto',
        'bolt-lightning',
        'bookmark',
        'building',
        'bullseye-arrow',
        'c',
        'calculator',
        'calendar-days',
        'caret-right',
        'caret-down',
        'caret-up',
        'chart-line',
        // 'check',
        'check-double',
        'chart-line-up-down',
        'circle-info',
        'circle-plus',
        'circle-user',
        'circle',
        'circle-dot',
        'clock',
        'clone',
        'cloud-arrow-down',
        'dash',
        'diagram-project',
        'dollar-sign',
        'down',
        'download',
        'ellipsis',
        'ellipsis-vertical',
        'euro-sign',
        'eye',
        'eye-slash',
        'fast-backward',
        'fast-forward',
        'file',
        'file-csv',
        'file-pdf',
        'flask',
        'floppy-disk',
        'folder',
        'folder-open',
        'forward',
        'gauge-simple-high',
        'gears',
        'globe',
        'grip-dots-vertical',
        'grip-lines-vertical',
        'h',
        'handshake',
        'hourglass',
        'hourglass-clock',
        'hyphen',
        'key',
        'industry-windows',
        'layer-group',
        'left-from-line',
        'lightbulb-exclamation',
        'list-check',
        'lock',
        'magnifying-glass',
        'minus',
        'mountain',
        'n',
        'objects-column',
        'paperclip',
        'pen',
        'plus',
        'question',
        'right',
        'right-from-line',
        'rotate-left',
        'rotate-right',
        'scale-unbalanced',
        'share',
        'shield-check',
        'sterling-sign',
        'sync',
        'screwdriver-wrench',
        'sort-up',
        'table',
        'triangle',
        'up',
        'user-clock',
        'user-secret',
        'truck-arrow-right',
        'user-group',
        'user-lock',
        'user-xmark',
        'warehouse-full',
        'x-mark',

        // ex duotone
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'abacus',
        'alarm-exclamation',
        'arrow-down-arrow-up',
        'arrow-down-right',
        'arrow-down-to-bracket',
        'arrow-left',
        'arrow-right',
        'arrow-up-arrow-down',
        'arrow-up-right',
        'bars-filter',
        'boxes-stacked',
        'calendar-circle-user',
        'cash-register',
        'car',
        'caravan',
        'cars',
        'cart-arrow-down',
        'cart-arrow-up',
        'cart-minus',
        'chart-network',
        'cart-plus',
        'cart-shopping-fast',
        'charging-station',
        'chart-line-up',
        'chart-tree-map',
        'cheese-swiss',
        'coins',
        'cubes',
        'down-left-and-up-right-to-center',
        'dumpster',
        'face-sad-tear',
        'faucet-drip',
        'file-chart-column',
        'file-chart-line',
        'file-contract',
        'fire',
        'flag-pennant',
        'flask-vial',
        'flux-capacitor',
        'fire-flame-simple',
        'folder-arrow-down',
        'folder-arrow-up',
        'gas-pump',
        'gear',
        'hand-pointer',
        'heat',
        'image',
        'layer-plus',
        'leaf',
        'lightbulb',
        'location-check',
        'location-dot',
        'microchip',
        'parachute-box',
        'percent',
        'paper-plane',
        'paper-plane-top',
        'pipe-valve',
        'plane',
        'plate-utensils',
        'recycle',
        'sensor',
        'ship',
        'shirt',
        'shovel',
        'square-1',
        'square-2',
        'square-3',
        'square-4',
        'square-5',
        'table-cells-large',
        'tree',
        'truck',
        'truck-container',
        'truck-tow',
        'typewriter',
        'up-right-and-down-left-from-center',
        'up-down',
        'upload',
        'van-shuttle',
        'warehouse-full',
        'wheat-awn',
        'wind-turbine',
    ],
}

function buildIcons() {
    const builtIcons = {}
    for (const type in icons) {
        // console.log(type)
        for (const icon of icons[type]) {
            // console.log(icon)
            builtIcons[camelCase(icon)] = {
                component,
                props: {
                    icon: [type, icon],
                },
            }
        }
    }
    return builtIcons
}

export default {
    iconfont: 'faSvg',
    values: {
        // built icons
        ...buildIcons(),
        // custom icons - to use other name than that of the icon
        arrowDownDuo: {
            component,
            props: {
                icon: ['fasr', 'arrow-down'],
            },
        },
        arrowsAltV: {
            component,
            props: {
                icon: ['fass', 'sort'],
            },
        },
        cancel: {
            component,
            props: {
                icon: ['fass', 'xmark-large'],
            },
        },
        check: {
            component,
            props: {
                icon: ['fass', 'check'],
            },
        },
        checkboxIndeterminate: {
            component,
            props: {
                icon: ['fasl', 'square-minus'],
            },
        },
        checkboxOff: {
            component,
            props: {
                icon: ['fasl', 'square'],
            },
        },
        checkboxOn: {
            component,
            props: {
                icon: ['fass', 'square-check'],
            },
        },
        clear: {
            component,
            props: {
                icon: ['fass', 'circle-xmark'],
            },
        },
        close: {
            component,
            props: {
                icon: ['fass', 'xmark'],
            },
        },
        delete: {
            component,
            props: {
                icon: ['fasr', 'trash-can'],
            },
        },
        dropdown: {
            component,
            props: {
                icon: ['fasl', 'chevron-down'],
            },
        },
        edit: {
            component,
            props: {
                icon: ['fass', 'pen-to-square'],
            },
        },
        enterFullscreen: {
            component,
            props: {
                icon: ['fass', 'expand'],
            },
        },
        exitFullscreen: {
            component,
            props: {
                icon: ['fass', 'compress'],
            },
        },
        info: {
            component,
            props: {
                icon: ['fass', 'info'],
            },
        },
        next: {
            component,
            props: {
                icon: ['fasr', 'chevron-right'],
            },
        },
        play: {
            component,
            props: {
                icon: ['fasr', 'play'],
            },
        },
        prev: {
            component,
            props: {
                icon: ['fasr', 'chevron-left'],
            },
        },
        sort: {
            component,
            props: {
                icon: ['fasr', 'arrow-down'],
            },
        },
        subgroup: {
            component,
            props: {
                icon: ['fasl', 'chevron-down'],
            },
        },

    },
}
