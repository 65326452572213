import { get, pick } from 'lodash'
import moment from '~/composables/useMoment'
import { difference, humanizeDuration, isYTD, isFullYear, DEFAULT_TIMEZONE } from '~/utils/date'
import { SHIFTS } from '~/utils/period-shift'
import { AVAILABLE_RESOLUTIONS, dynamicResolution, VALID_RESOLUTIONS } from '~/utils/resolution'

export default {
    timezone(_state, _getters, rootState) {
        return get(rootState, 'settings.general.timezone', DEFAULT_TIMEZONE)
    },

    resolution(state) {
        return state.resolution
    },

    interval(state) {
        return state.resolution * 1e3
    },

    isYTD(state) {
        return isYTD(state.start, state.end)
    },

    isFullYear(state) {
        return isFullYear(state.start, state.end)
    },

    isAll(state) {
        return state.start.valueOf() === state.min.valueOf() && state.end.valueOf() === state.max.valueOf()
    },

    canCompare(state) {
        return SHIFTS.includes(state.shift)
    },

    maxRange(state) {
        return [
            moment(state.min).toISOString(),
            moment(state.max).toISOString(),
        ]
    },

    comparison(state) {
        return pick(state, ['start', 'end', 'resolution', 'shift'])
    },

    range(state) {
        const start = moment(state.start)
        const end = moment(state.end)
        return [start, end]
    },

    rangeToFormat: (_state, getters) => format => {
        return getters.range.map(m => m.format(format))
    },

    rangeToISO(_state, getters) {
        return getters.range.map(m => m.toISOString())
    },

    absoluteComparisonRange: (_state, getters) => {
        return getters.range.map(date => moment(date).subtract(1, 'year'))
    },

    relativeComparisonRange: (state, getters) => {
        const start = moment(state.start)
        const end = moment(state.start)

        if (getters.canCompare) {
            start.subtractResolution(state.shift)
        } else {
            const diff = difference(state.end, start)
            start.subtract(diff)
        }

        return [
            start,
            end,
        ]
    },

    comparisonRange: (state, getters) => {
        return state.absoluteComparison ? getters.absoluteComparisonRange : getters.relativeComparisonRange
    },

    comparisonRangeToISO(_state, getters) {
        return getters.comparisonRange.map(m => m.toISOString())
    },

    isCurrentResolutionStacking(state) {
        return !!AVAILABLE_RESOLUTIONS.find(e => e.stacked && e.value === state.resolution)
    },

    dynamicResolution(state) {
        return dynamicResolution(state.end, state.start)
    },

    humanizedDuration(state) {
        return humanizeDuration(state.start, state.end, true)
    },

    startEndDuration(state, getters) {
        const timeFormat = 'MMM Do HH:mm, Y'
        const start = moment(state.start).format(timeFormat)
        const end = moment(state.end).subtract(1, 's').format(timeFormat)
        return {
            start,
            end,
            duration: getters.humanizedDuration,
            secondsDuration: moment(state.end).diff(moment(state.start), 'seconds'),
        }
    },

    isMonthResolution(state) {
        return VALID_RESOLUTIONS.MONTH === state.resolution
    },

    dateSettings(_state, getters) {
        const isMonthResolution = getters.isMonthResolution
        return {
            datePickerType: isMonthResolution ? 'month' : 'date',
            forcedDuration: isMonthResolution
                ? { amount: 1, unitOfTime: 'month', format: 'YYYY-MM' }
                : false,
        }
    },
}
