
// Utils
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'
import FormMixin from './form.mixin'
import { isScrolledToBottom } from '~/utils/tools'

// Components
// import RouteMap from '../parts/RouteMap'
// import RouteTimeline from '../parts/RouteTimeline'
import ScopeAndCategoriesGrid from '~/components/flows/parts/ScopeAndCategoriesGrid'
import MaterialsSummaryGrid from '~/components/flows/parts/MaterialsSummaryGrid'
// import SummaryTable from '~/components/flows/parts/SummaryTable'
import SummaryTimeline from '~/components/flows/parts/SummaryTimeline'
import EditShipping from '~/components/flows/forms/edit-shipping'

export default {
    name: 'ProcessSummary',

    components: {
        // RouteMap,
        // RouteTimeline,
        ScopeAndCategoriesGrid,
        MaterialsSummaryGrid,
        // SummaryTable,
        SummaryTimeline,
        EditShipping,
    },

    mixins: [FormMixin],

    provide() {
        return { processSummary: this }
    },

    inject: ['dialog'],

    props: {
        introText: {
            type: String,
            default: `
                The calculation of Scope 3 emissions is based on the Greenhouse Gas Protocol Corporate Value
                Chain (Scope 3) Accounting and Reporting Standard and the Guidance for Accounting and Reporting
                Corporate GHG Emissions in the Chemical Sector Value Chain (WBCSD). The Scope 3 emissions are
                calculated by category in accordance with the guidelines of the GHG Protocol Standard (at least “minimum
                boundaries “).
            `,
        },
        withShipping: {
            type: [Boolean, Object],
            default: false,
        },
    },

    data: () => ({
        showDownloadSnack: false,
        email: '',
        sentEmail: false,
        template: null,
        origin: null,
        destination: null,
        materials: [],
    }),

    computed: {
        emissions() {
            if (!this.template) return {}
            return this.$_.groupBy(this.template.emissions, 'heading')
        },
        ...mapGetters('flows', ['allMaterials']),
    },

    watch: {
        loading(v) {
            if (v) {
                setTimeout(() => (this.loading = false), 5000) // this is to avoid getting stuck in loading state
            }
        },
    },

    created() {
        this.onScroll = this.$_.debounce(this.onScroll, 300)
    },

    methods: {
        async init() {
            const data = this.__data()
            console.log('data: ', data)
            this.origin = this.withShipping?.origin || data.origin?.location
            this.destination = this.withShipping?.destination || data.destination?.location
            if (this.withShipping && this.$refs.shipping) {
                await this.$refs.shipping.init()
                data.transport = cloneDeep(this.$refs.shipping.draft)
                console.log('transport', data.transport)
            }
            this.dialog.loading = true
            this.materials = [
                ...(data.materials || []),
                ...(data.input || []),
                ...(data.output || []),
                ...this.inputsAndOutputs(data),
            ].map(e => {
                return {
                    ...e,
                    ...this.allMaterials.find(({ id }) => id === e.id),
                }
            })
            console.log('materials: ', this.materials)
            try {
                const payload = this.prepareForTemplate(data)
                this.template = await this.$api.flows.fetchTemplateFromWizard(payload)
                console.log('template: ', this.template)
            } catch (err) {
                console.warn('ProcessSummary:init:caughtError: ', err)
            } finally {
                this.dialog.loading = false
            }
        },
        downloadReport() {
            console.log('download report...')
        },
        sendReportToEmail() {
            console.log('send report to email: ', this.email)
            this.sentEmail = true
            setTimeout(() => {
                this.email = ''
                this.sentEmail = false
            }, 5000)
        },
        onScroll(evt) {
            this.showDownloadSnack = isScrolledToBottom(evt.target)
        },
        inputsAndOutputs(data) {
            const materials = []
            for (let i = 0; i < 3; i++) {
                console.log(i)
                materials.push(
                    ...this.$_.get(data, `input-${i}`, []),
                    ...this.$_.get(data, `output-${i}`, []),
                )
            }
            return materials
        },
    },
}
