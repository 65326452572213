import Vue from 'vue'
import moment from '~/composables/useMoment'
export default class PeriodService {
    #timer = null
    #periodResolver = null
    #period = {
        start: moment().subtract(1, 'month').startOf('h'),
        end: moment().startOf('h'),
    }

    #duration = 0

    label = Vue.observable({ start: this.#period.start, end: this.#period.end })
    labelChanged = Vue.observable({ value: null })

    updatePeriodWithDebounce(period, periodResolver = null, duration = 0) {
        this.#duration = duration
        this.#periodResolver = periodResolver
        this.updateLabel(period)
        this.cancelTimer()
        if (duration) this.#setTimer()
        else this.#resolvePeriod()
    }

    updateLabel(period) {
        this.#period = { ...period }
        const { start, end } = this.#period
        if (!start && !end) return false
        this.label.start = start
        this.label.end = end

        this.labelChanged.value = Date.now()
    }

    cancelTimer() {
        if (this.#timer) {
            clearTimeout(this.#timer)
            this.#timer = null
        }
    }

    #setTimer() {
        this.#timer = setTimeout(() => this.#resolvePeriod(), this.#duration)
    }

    #resolvePeriod() {
        if (this.#periodResolver) {
            this.#periodResolver(this.#period)
        }
    }

    servicePeriodState() {
        return this.#period
    }
}
