export const faC02 = {
    prefix: 'fac',
    iconName: 'cvc02',
    icon: [
        12, 11,
        [],
        null,
        'm6.09,2.57v2.94h-1.42v-2.75c0-1.06-.22-1.24-.63-1.24s-.61.18-.61,1.24v7.7c0,1.06.23,1.22.63,1.22s.61-.17.61-1.22v-2.92h1.42v3.07c0,1.65-.68,2.47-2.03,2.47s-2.03-.81-2.03-2.46V2.59C2.04.94,2.7.13,4.05.13s2.04.79,2.04,2.44Zm4.91,0v8.03c0,1.65-.68,2.47-2.03,2.47s-2.03-.81-2.03-2.46V2.59C6.95.94,7.61.13,8.96.13s2.04.79,2.04,2.44Zm-1.42.18c0-1.06-.22-1.24-.63-1.24s-.61.18-.61,1.24v7.7c0,1.06.23,1.22.63,1.22s.61-.17.61-1.22V2.76Zm4.45,8.23v-1.11c0-.96-.35-1.43-1.14-1.43s-1.16.46-1.16,1.42v1.19h.81v-1.08c0-.61.11-.72.35-.72s.33.11.33.72v.89c0,1.51-1.54.84-1.54,2.89v2.12h2.28v-2.19h-.83v1.38h-.66v-1.17c0-1.51,1.57-1.01,1.57-2.91Z',
    ],
}
export const faWater = {
    prefix: 'fac',
    iconName: 'cvwater',
    icon: [
        12, 11,
        [],
        null,
        'm4.74.34h1.41v12.69h-1.41v-5.85h-1.3v5.85h-1.45V.34h1.45v5.4h1.3V.34Zm3.33,7.96c-.78,0-1.17.47-1.17,1.43v1.2h.81v-1.09c0-.62.12-.73.36-.73s.33.11.33.73v.9c0,1.53-1.56.85-1.56,2.91v2.14h2.3v-2.21h-.84v1.39h-.67v-1.18c0-1.53,1.59-1.02,1.59-2.93v-1.12c0-.97-.36-1.44-1.15-1.44Zm5.93-6.2v9.15c0,1.31-.68,1.96-2.04,1.96s-2.04-.63-2.04-1.94V2.11c0-1.3.67-1.94,2.03-1.94s2.06.63,2.06,1.93Zm-1.43.18c0-.5-.2-.71-.63-.71s-.62.22-.62.71v8.82c0,.47.2.7.63.7s.61-.23.61-.7V2.28Z',
    ],
}

export const faElectricity = {
    prefix: 'fac',
    iconName: 'cvelectricity',
    icon: [
        12, 12,
        [],
        null,
        'm3.06,9.12h3.91c.15,0,.28.13.28.28,0,.02,0,.04,0,.06l-1.35,6.32h0s0,.05.03.06c0,0,0,0,0,0,.02.01.05,0,.07-.02,0,0,0,0,0,0l6.25-8.48c.1-.13.07-.31-.06-.41-.05-.04-.11-.06-.17-.06h-3.91c-.15,0-.28-.13-.28-.28,0-.02,0-.04,0-.06L9.17.22s0-.05-.03-.06c0,0,0,0,0,0,0,0-.02,0-.03,0-.02,0-.03.01-.04.02L2.82,8.66c-.1.13-.07.31.06.41.05.04.11.06.17.06Z',
    ],
}
export const faGas = {
    prefix: 'fac',
    iconName: 'cvgas',
    icon: [
        12, 12,
        [],
        null,
        'm7.99.17c-1.24,2.7-3.88,8.33-3.84,11.37.04,2.92,1.72,4.29,3.84,4.29s3.76-1.37,3.84-4.29c.04-3.03-2.6-8.67-3.84-11.37Zm0,14.93c-1.41,0-2.52-.91-2.55-2.85-.03-2.01,1.73-5.75,2.55-7.55.82,1.79,2.58,5.53,2.55,7.55-.05,1.94-1.14,2.85-2.55,2.85Zm0-7.33s1.65,3.54,1.63,4.83c-.03,1.24-.73,1.82-1.63,1.82s-1.61-.58-1.63-1.82c0-.74.77-2.87.77-2.87l.47.88.39-2.83Z',
    ],
}

export const faAlarm = {
    prefix: 'fac',
    iconName: 'cvalarm',
    icon: [
        12, 12,
        [],
        null,
        'm8,2.45h-.73v-.73c0-.41.33-.73.73-.73s.73.33.73.73v.73h-.73Zm3.31,5.61v-1.85c0-1.8-1.46-3.27-3.27-3.27h-.09c-1.8,0-3.27,1.46-3.27,3.27v1.85c0,1.57-.46,3-1.86,3.7v1.31h10.35v-1.31c-1.4-.7-1.86-2.13-1.86-3.7Zm-3.51,6.96h.4c.82,0,1.48-.66,1.48-1.48h-3.36c0,.82.66,1.48,1.48,1.48Z',
    ],
}

export const faCalendar = {
    prefix: 'fac',
    iconName: 'cvcalendar',
    icon: [
        13, 13,
        [],
        null,
        'm1.95,4.28v11.4h12V4.28H1.95Zm10.62,6.67h-2.1v-1.94h2.1v1.94Zm-3.49,0h-2.1v-1.94h2.1v1.94Zm0,1.4v1.94h-2.1v-1.94h2.1Zm-5.59-3.33h2.1v1.94h-2.1v-1.94Zm3.49-1.4v-1.94h2.1v1.94h-2.1Zm-1.4-1.94v1.94h-2.1v-1.94h2.1Zm-2.1,6.67h2.1v1.94h-2.1v-1.94Zm6.98,1.94v-1.94h2.1v1.94h-2.1Zm2.1-6.67h-2.1v-1.94h2.1v1.94Zm1.38-5.2v1.4H1.95v-1.4h1.86V.35h1.4v2.07h5.58V.35h1.4v2.07h1.76Z',
    ],
}

export const faDashboard = {
    prefix: 'fac',
    iconName: 'cvdashboard',
    icon: [
        13, 13,
        [],
        null,
        'm7.52,6.56v8.71H2V6.56h5.52ZM2,.73v4.85s5.52,0,5.52,0V.73s-5.52,0-5.52,0Zm6.29,9.7v4.85s5.71,0,5.71,0v-4.85s-5.71,0-5.71,0Zm0-9.7v8.73s5.71,0,5.71,0V.73s-5.71,0-5.71,0Z',
    ],
}

export const faGrid = {
    prefix: 'fac',
    iconName: 'cvgrid',
    icon: [
        13, 13,
        [],
        null,
        'm13.96.14H2.01v15.57h12V.14h-.05Zm-6.67,14.15h-3.84v-5.66h3.84v5.66Zm0-7.08h-3.84V1.56h3.84v5.66Zm5.28,7.08h-3.84v-5.66h3.84v5.66Zm0-7.08h-3.84V1.56h3.84v5.66Z',
    ],
}

export const faFlows = {
    prefix: 'fac',
    iconName: 'cvflows',
    icon: [
        13, 13,
        [],
        null,
        'm14.01,4.92V1.92h-3v2.43l-1,1.46c-.33-.13-.69-.2-1.07-.2-.28,0-.54.05-.79.12l-1.25-2.25V.73h-3v3h1.52l1.49,2.67c-.42.39-.73.9-.87,1.48h-.99v-.79h-3v3h3v-.79h.99c.32,1.31,1.5,2.29,2.91,2.29.38,0,.73-.08,1.07-.2l1,1.46v2.43h3v-3h-1.67l-1.17-1.69c.47-.53.77-1.22.77-1.99s-.3-1.46-.77-1.99l1.17-1.69h1.67Zm-6.21,3.68c0-.63.51-1.14,1.14-1.14s1.14.51,1.14,1.14-.51,1.14-1.14,1.14-1.14-.51-1.14-1.14Z',
    ],
}

export const faNetZero = {
    prefix: 'fac',
    iconName: 'cvnetzero',
    icon: [
        13, 13,
        [],
        null,
        'm2,.73v14.54h12V.73H2Zm2.68,7.9h-1.41V2.06h1.41v6.57Zm2.75,0h-1.41V2.06h1.41v6.57Z',
    ],
}
