import { Model } from '@vuex-orm/core'
import Circuit from './Circuit'
import Activity from './Activity'
import Meta from '@/orm/models/Meta'

export default class Asset extends Model {
    static entity = 'assets'

    static primaryKey = 'assetId'

    static fields() {
        // `this.belongsTo` is for belongs to relationship. The first argument
        // is the Model class, and second is the foreign key.
        return {
            assetId: this.attr(null),
            circuitId: this.attr(null),
            entity: this.attr(Asset.entity),
            icon: this.attr('charging-station'),
            assetName: this.attr(''),
            friendlyName: this.attr(''),
            presetId: this.attr(null),
            selected: this.boolean(true),
            metas: this.morphMany(Meta, 'entity_id', 'entity_name'),
            circuit: this.belongsTo(Circuit, 'circuitId'),
            activities: this.hasMany(Activity, 'assetId'),
            editable: this.boolean(true),
        }
    }

    /**
     * Get asset parent
     */
    get parent() {
        return Circuit.query().whereId(this.circuitId).first()
    }

    /**
     * Get asset children
     */
    get children() {
        return Activity.query()
            .where('assetId', this.assetId)
            .get()
    }

    /**
     * Get parent site
     */
    get parentSite() {
        const circuit = this.parent
        const meter = circuit.parent
        return meter.parent
    }

    /**
     * Get parent meter
     */
    get parentMeter() {
        const circuit = this.parent
        return circuit.parent
    }

    /**
     * Get parent circuit
     */
    get parentCircuit() {
        return this.parent
    }

    /**
     * Get relationships
     */
    get relationships() {
        // circuit
        const circuit = this.parent
        // meter
        const meter = circuit.parent
        // site
        const site = meter.parent

        // activities
        const activities = Activity.query()
            .where('assetId', this.assetId)
            .get()

        return {
            site,
            meter,
            circuit,
            activities,
        }
    }
}
