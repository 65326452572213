import { remove } from 'lodash'
import moment from '~/composables/useMoment'
import { md5 } from '~/utils/tools'

export default class ClickHouseService {
    _tableCache = {}
    _inspectorCache = {}

    constructor({ $axios, $config, $store }) {
        this.$axios = $axios
        this.$config = $config
        this.$store = $store
    }

    /**
     * Fetch readings for circuits
     *
     * @param { resolution, daterange, fields, ids } params
     * @returns {Promise}
     */
    async fetchReadings(params) {
        const url = '/readings-api/readings'
        let readings = {}
        params.timezone = this.$store.getters['period/timezone']
        try {
            readings = await this.$axios.$get(url, { params: ClickHouseService.fakeCircuitsPatch(params) })
        } catch (err) {
            if (!this.$axios.isCancel(err)) {
                console.warn('Error while fetching readings data', err)
            }
        }
        return readings
    }

    async fetchAggregates(params) {
        const url = '/readings-api/aggregates'
        let res = {}
        params.timezone = this.$store.getters['period/timezone']
        try {
            res = await this.$axios.$get(url, { params: { ...ClickHouseService.fakeCircuitsPatch(params), precision: 8 } })
        } catch (err) {
            if (!this.$axios.isCancel(err)) {
                console.warn('Error while fetching aggregates data. ', err)
            }
        }
        return res
    }

    /**
     * Override to fetch main incomer if it doesn't exist CVP-2003
     *
     * @param {Object} params
     * @returns {Object} params
     */
    static fakeCircuitsPatch(params) {
        if (!Array.isArray(params.clampIds)) { return params }
        const meterIds = remove(params.clampIds, e => e[0] === 'f').map(e => e.replace(/^./, ''))
        if (meterIds.length === 0) { return params }
        if (Array.isArray(params.meterIds)) { params.meterIds.push(...meterIds) } else { params.meterIds = meterIds }
        return params
    }

    async dataTableSummary(params) {
        const key = md5(JSON.stringify(params))
        const url = '/api/analysis/table'
        if (!this._tableCache[key]) {
            try {
                this._tableCache[key] = await this.$axios.$post(url, params)
            } catch (err) {
                if (this.$axios.isCancel(err)) {
                    return
                }
                console.warn('Error while fetching table items. ', err)
            }
        }

        return this._tableCache[key]
    }

    async fetchCircuitsRankings(field = 'E') {
        const url = '/api/rankings/sum/energy'
        const result = new Map()
        const maxAttempts = 9 // big number here cos without rankings the app is not usable, can't afford this to fail
        let attemptNumber = 1
        const _get = async() => {
            try {
                const { data } = await this.$axios.$get(url)
                data.forEach(e => {
                    result.set(e.circuit_id, {
                        startTime: moment(e.from_millis).format(),
                        endTime: moment(e.to_millis).format(),
                        totalKwData: e[field],
                    })
                })
            } catch (err) {
                console.warn(`attempt #${attemptNumber} failed - fetchCircuitsRankings error: `, err.message)
                if (attemptNumber < maxAttempts) {
                    attemptNumber++
                    await _get()
                }
            }
        }

        await _get()

        return result
    }

    /**
     * Fetch table items
     * @param unit
     * @param  {Object} params
     * @return {Array} items
     */
    async getInspectorData(params) {
        params = { ...params }
        const url = '/api/analysis'
        const key = md5(JSON.stringify(params))
        if (!this._inspectorCache[key]) {
            try {
                params.start = moment(params.start)
                params.end = moment(params.end)
                params.start = +params.start
                params.end = +params.end
                this._inspectorCache[key] = await this.$axios.$post(url, params)
            } catch (err) {
                if (!this.$axios.isCancel(err)) {
                    console.warn('Error while fetching inspector data items. ', err)
                }
            }
        }
        return this._inspectorCache[key]
    }

    fetchExtendedCostData(params = {}) {
        const url = '/api/analysis/cost-breakdown'
        return this.$axios.$get(url, { params: ClickHouseService.fakeCircuitsPatch(params) })
    }

    async fetchMinPF(params) {
        const uri = '/api/analysis/threshold-pf'
        let res = []
        try {
            res = await this.$axios.$get(uri, { params })
        } catch (err) {
            console.warn('Error while fetching min PF data. ', err)
        }
        return res
    }

    async fetchBandedAggregates(payload) {
        const uri = '/readings-api/aggregates'
        payload.timezone = this.$store.getters['period/timezone']
        let res = {}
        try {
            res = await this.$axios.$get(uri, { params: payload })
        } catch (err) {
            console.warn('Error while fetching min banded aggregates data. ', err.message)
        }
        return res
    }

    async fetchRagData({ start, end, circuits, gspGroup }) {
        const uri = '/api/analysis/rag-breakdown'
        let res = []
        try {
            res = await this.$axios.$get(uri, { params: { start, end, circuits, gspGroup } })
        } catch (err) {
            console.warn('Error while fetching RAG data. ', err)
        }
        return res
    }

    /**
     * Fetch table items
     * @param  {Object} params
     * @return {Array} items
     */
    async getTableItemsPoint(params) {
        let items = []
        const uri = '/api/analysis/point'
        // const uri = 'http://192.168.0.53:3333/v1/analysis/table'
        try {
            items = await this.$axios.$get(uri, { params })
        } catch (err) {
            if (this.$axios.isCancel(err)) {
                return
            }
            console.warn('Error while fetching table items point. ', err)
        }
        return items
    }

    /**
     * Fetch league table items
     * @param  {Object} params
     * @return {Object} response data
     */
    async fetchLeagueTable(params) {
        const uri = '/leaguetable'
        // example: https://stage-pro-analytics-4iqhzhf6zq-nw.a.run.app/v3/leaguetable?daterange[]=2021-01-02&daterange[]=2022-01-02&splitBy=sites
        try {
            return await this.$axios.$get(uri, { params })
        } catch (err) {
            if (!this.$axios.isCancel(err)) {
                console.warn('Error while fetching league table. ', err.message)
            }
        }
    }

    async getTriadsData(params) {
        let res = []
        try {
            res = await this.$axios.$post('/api/analysis/triads', params)
        } catch (err) {
            console.warn('Error while fetching triads data. ', err.message)
        }
        return res
    }
}
