
export default {
    name: 'NewsCard',

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        img: {},
    }),

    mounted() {
        this.loadImg()
    },

    methods: {
        async loadImg() {
            if (!this.item.featured_media) return
            try {
                this.img = await this.$api.fetchPostImg(this.item.featured_media)
            } catch (e) {
                this.img = {}
                console.warn('Error fetching net zero img. ', e.message)
            }
        },
    },
}
