
import GetStartedDialog from './get-started'
import ChangePasswordDialog from './change-password-dialog'
import NewsDialog from './news'
import SelectAppModeDialog from './select-app-mode-dialog'
import Loader from './loader'
import EulaAcceptance from '~/components/dialogs/eula-acceptance'

const DIALOGS = {
    SELECT_APP_MODE: 'SELECT_APP_MODE',
    WHATS_NEW_ENABLED: 'WHATS_NEW_ENABLED',
}

export default {
    name: 'DialogsManager',
    components: {
        EulaAcceptance,
        GetStartedDialog,
        NewsDialog,
        SelectAppModeDialog,
        Loader,
        ChangePasswordDialog,
    },
    data() {
        return {
            dialogToOpen: null,
            requiresPasswordChange: false,
            requiresEulaAcceptance: false,
        }
    },
    mounted() {
        this.requiresEulaAcceptance = this.$auth.user.eulaLink && !this.$api.auth.impersonator()
        this.requiresPasswordChange = this.$auth.user.tempPasswordFlag && !this.$api.auth.impersonator()

        this.dialogToOpen = this.shouldDisplayAppModeDialog()
            ? DIALOGS.SELECT_APP_MODE
            : this.$config.features.whatsNewEnabled
                ? DIALOGS.WHATS_NEW_ENABLED
                : null
    },
    methods: {
        isOpen(dialog = '') {
            return DIALOGS[dialog] === this.dialogToOpen
        },
        shouldDisplayAppModeDialog() {
            const isDemo = this.$api.$config.envName === 'demo'
            const localAppMode = localStorage.getItem('localAppMode')
            if (localAppMode && isDemo) {
                this.$store.dispatch('updateSettings', {
                    general: {
                        appMode: localAppMode,
                    },
                })
                return
            }
            if (isDemo) {
                return true
            }

            return this.$store.state.settings.general.appMode === null
        },
    },
}
