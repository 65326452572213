
export default {
    name: 'ComparisonCard',

    props: {
        title: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
}
