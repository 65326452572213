
import ChangePassword from '~/components/profile/change-password'

export default {
    name: 'ChangePasswordDialog',

    components: {
        ChangePassword,
    },

    data: () => ({
        dialogMessage: '',
        dialog: null,
        showPanel: true,
    }),

    mounted() {
        this.dialogMessage = this.$t('warnings.logged_in_with_temp_pwd')
        this.dialog = true
    },

    methods: {
        closeDialog() {
            this.$emit('on-dialog-close')
            this.showPanel = false
            setTimeout(() => {
                this.dialog = false
            }, 2000)
        },
    },
}
