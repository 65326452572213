
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'NewsDialog',

    data: () => ({
        value: false,
        index: 0,
        selectedItem: 0,
        stopShow: false,
        img: {},
    }),

    computed: {
        ...mapGetters([
            'hasNews',
        ]),
        ...mapState([
            'news',
        ]),
        activeItem() {
            return this.news[this.index]
        },
        imgUrl() {
            return this.$_.get(this.img, 'media_details.sizes.large.source_url', this.img.source_url)
        },
    },

    watch: {
        news: {
            immediate: true,
            handler(news) {
                if (news.length > 0) {
                    setTimeout(() => {
                        this.value = true
                    }, 600)
                }
            },
        },
        activeItem: {
            immediate: true,
            handler(item) {
                this.img = {}
                this.loadImg(item)
            },
        },
    },

    created() {
        this.loadImg = this.$_.debounce(this.loadImg, 120)
    },

    methods: {
        close() {
            this.value = false
            if (this.stopShow) {
                this.$store.dispatch('updateSettings', {
                    changelog: { whatsNew: false },
                })
            }
        },

        next() {
            if (this.index === this.news.length - 1) {
                this.index = 0
            } else {
                this.index++
            }
        },

        onItemClick(item) {
            this.index = this.news.indexOf(item)
        },

        async loadImg(item) {
            if (!item) {
                this.img = {}
                return
            }
            try {
                this.img = await this.$api.fetchPostImg(item.featured_media)
            } catch (e) {
                this.img = {}
            }
        },
    },
}
