// ORM
import Queue from 'promise-queue'
import { filter, groupBy, minBy, orderBy, sortBy } from 'lodash'
import pDebounce from 'p-debounce'
import Unit from '../models/Unit'
import Meter from '../models/Meter'
import { extraColors } from '~/config/colors'
// Utils
import { generateCacheKey } from '~/utils/cache'
import { transformAggregates, parentSort } from '~/utils/calculations'
import { calculateRequestEntitiesIds } from '~/utils/entities'
// import Circuit from '~/orm/models/Circuit'
// import { AVAILABLE_RESOLUTIONS, VALID_RESOLUTIONS } from '~/utils/resolution'

export const state = {
    /**
     * Cached items keys array
     *
     * This is essentially the array of props in loaded items of the cache service
     * which main purpose of having it here is the reactivity
     * for start this is used in the circuits list to visually show
     * when a circuit has data loaded for selected unit
     *
     * @type {Array}
     */
    cached: [],

    areSetup: false,
    areVirtualSetup: false,
}

export const getters = {
    selected(state, getters) {
        return [] // outdated
    },

    ids(state, getters) {
        return getters.all().map(c => c.id)
    },

    realIds(state, getters) {
        return getters.real.map(c => c.id)
    },

    virtualIds(state, getters) {
        return getters.virtual.map(c => c.id)
    },

    sorted(state, getters) {
        return getters.query()
            .orderBy('childrenCount', 'desc')
            .orderBy('totalKwData', 'desc')
            .get()
    },

    haveData(state, getters) {
        return getters.query()
            .where(e => e.hasData && !e.virtual)
            .orderBy('childrenCount', 'desc')
            .orderBy('totalKwData', 'desc')
            .get()
    },

    haveStartEndTimes(state, getters) {
        return getters.haveData.filter(c => c.startTime && c.endTime)
    },

    startTimeSortedList(state, getters) {
        return sortBy(getters.haveStartEndTimes, ['startTime'])
    },

    endTimeSortedList(state, getters) {
        return sortBy(getters.haveStartEndTimes, ['endTime'])
    },

    firstData(state, getters) {
        return getters.startTimeSortedList[0]
    },

    lastData(state, getters) {
        return getters.endTimeSortedList.lastItem
    },

    minResolution(state, getters) {
        return minBy(getters.haveData.filter(c => !c.isNHH), 'sampleRate')?.sampleRate
    },

    /**
     * Get main incomer
     * @param  {Object} state
     * @param  {Object} getters
     * @return {Circuit}
     */
    main(state, getters) {
        return getters.query().where('isMainIncomer', true).first()
    },

    real(state, getters) {
        return getters.query().where('virtual', false).get()
    },

    virtual(state, getters) {
        return getters.query().where('virtual', true).get()
    },

    /**
     * Get main incomer
     * @param  {Object} state
     * @param  {Object} getters
     * @return {Circuit}
     */
    mainOrFallback(state, getters) {
        return getters.main || getters.haveData[0] || null
    },

    /**
     * Get all the main incomers
     * @param state
     * @param getters
     * @returns {{length}|*|*[]}
     */
    mains(state, getters) {
        return getters.query().where('isMainIncomer', true).get()
    },

    /**
     * Get all the main incomers
     * @param state
     * @param getters
     * @returns {{length}|*|*[]}
     */
    mainsOrFallback(state, getters) {
        const mains = getters.mains
        if (mains.length > 0) {
            return mains
        }
        const haveData = getters.haveData
        return haveData[0] ? [haveData[0]] : []
    },

    hasMainIncomer(state, getters) {
        return getters.main && getters.main.isMainIncomer
    },

    onlyMainIncomers: (state, getters) => {
        return getters.query()
            .where('isMainIncomer', true)
            .where('virtual', false)
            .get()
    },

    notRenderedCircuits: (state, getters) => {
        return filter(getters.all(), { rendered: false, virtual: false })
    },

    isCircuitDataCached: (state, getters, rootState) => ({ unit, circuitId }) => {
        const key = generateCacheKey([unit, rootState.period.resolution, circuitId])
        return state.cached.includes(key)
    },

    isThereAtLeastOneMeterWithNoMainIncomer: () => {
        let isThereAtLeastOneMeterWithNoMainIncomer = false
        const meters = Meter.all()
        meters.forEach(meter => {
            if (!meter.children.find(e => e.isMainIncomer)) {
                isThereAtLeastOneMeterWithNoMainIncomer = true
            }
        })
        return isThereAtLeastOneMeterWithNoMainIncomer
    },
}

export const mutations = {
    SET_ARE_SETUP(state, value) {
        state.areSetup = value
    },

    SET_ARE_VIRTUAL_SETUP(state, value) {
        state.areVirtualSetup = value
    },

    SET_CACHED(state, items) {
        state.cached = items
    },
}

export const actions = {
    /**
     * Setup circuits
     *
     * will setup the following params for each circuit
     * color
     *
     * @param {Object} context
     * @return {Void}
     */
    setup: pDebounce(async function({ commit, dispatch, getters, state, rootGetters }, { forced = false } = {}) {
        if (!forced && state.areSetup) return
        const data = []
        const groupedByMeter = groupBy(getters.all().filter(({ meterId }) => meterId), 'meterId')
        const { entityColors, mainEntityColors } = extraColors(rootGetters.theme)

        let colorIndex = 0
        let mainColorIndex = 0

        const circuitsExtraInfo = await this.$api.clickHouse.fetchCircuitsRankings()

        for (const meterId in groupedByMeter) {
            let circuits = groupedByMeter[meterId].map(circuit => (
                {
                    ...circuit,
                    ...(circuitsExtraInfo.get(circuit.id) || {}),
                    hasData: !!(circuitsExtraInfo.get(circuit.id) && circuitsExtraInfo.get(circuit.id).E !== null),
                }
            ))
            circuits = orderBy(circuits, 'totalKwData', 'desc')
            circuits = parentSort(circuits)
            if (!circuits) continue
            const newData = circuits.map(circuit => {
                if (circuit.isMainIncomer) {
                    circuit.color = circuit.color || mainEntityColors[mainColorIndex]
                    circuit.backupColor = mainEntityColors[mainColorIndex]
                    mainColorIndex++
                    if (!mainEntityColors[mainColorIndex]) {
                        mainColorIndex = 0
                    }
                } else {
                    circuit.color = circuit.color || entityColors[colorIndex]
                    circuit.backupColor = entityColors[colorIndex]
                    colorIndex++
                    if (!entityColors[colorIndex]) {
                        colorIndex = 0
                    }
                }

                return circuit
            })
            data.push(
                ...newData,
            )

            // if it doesn't have a monitored main incomer, create a fake one
            // if(!circuits.find(({ isMainIncomer }) => isMainIncomer)){
            //     // console.log(meterId, circuits, circuits.find(({ isMainIncomer }) => isMainIncomer))
            //     // console.log('create fake main incomer', newData)
            //     const ranges = newData.map(({ startTime, endTime }) => [startTime, endTime])
            //     const startTime = minBy(ranges, 0)[0]
            //     const endTime = maxBy(ranges, 1)[1]
            //     const color = mainEntityColors[mainColorIndex]
            //     mainColorIndex++
            //     if (! mainEntityColors[mainColorIndex]) {
            //         mainColorIndex = 0
            //     }
            //     const fakeMainIncomer = Circuit.hydrate({
            //         id: `f${meterId}`,
            //         isMainIncomer: true,
            //         name: 'Main incomer',
            //         meterId: Number(meterId),
            //         totalKwh: 1,
            //         totalKwData: 1,
            //         // parentId: meterId,
            //         sampleRate: VALID_RESOLUTIONS.MINUTE,
            //         manufacturer: 1,
            //         color,
            //         startTime,
            //         endTime
            //     })
            //     data.push(fakeMainIncomer)
            // }
        }

        // update circuits
        await dispatch('insert', { data })
        // dispatch('setAggregates')
        commit('SET_ARE_SETUP', true)
    }, 100),

    /**
     * Setup virtual circuits
     *
     * will setup the following params for each circuit
     * startTime, endTime, totalKwData, color
     *
     * ! for virtual circuits we are not fetching aggregates here becasue is taking too long
     *
     *
     * @param {Object} context
     * @param {Object} payload
     * @returns {Promise}
     */
    setupVirtual: pDebounce(async function({ commit, dispatch, getters, state, rootGetters }, { forced = false } = {}) {
        if (!forced && state.areVirtualSetup) return
        const colors = extraColors(rootGetters.theme).virtualEntityColors
        let colorIndex = 0
        const data = getters.all().map(circuit => {
            if (circuit.virtual) {
                const siblings = circuit.siblings
                const totalKwData = this.$_.sumBy(siblings, 'totalKwData')
                const startTime = this.$_.get(this.$_.minBy(siblings, 'startTime'), 'startTime')
                const endTime = this.$_.get(this.$_.maxBy(siblings, 'endTime'), 'endTime')
                const color = circuit.color || colors[colorIndex]
                const backupColor = colors[colorIndex++]
                // reset color index when exceeded
                if (!colors[colorIndex]) colorIndex = 0

                return {
                    ...circuit,
                    totalKwData,
                    startTime,
                    endTime,
                    color,
                    backupColor,
                }
            }

            return { ...circuit }
        })
        // update virtual circuits
        await dispatch('insert', { data })
        // don't wait for virtual aggregates
        // dispatch('setVirtualAggregates')
        commit('SET_ARE_VIRTUAL_SETUP', true)
    }, 200),

    /**
     * Set aggregates
     *
     * @param {*} storeContext
     *
     * @returns {Void}
     */
    async setAggregates({ dispatch, getters }) {
        // fetch and get aggregates
        const aggregates = await dispatch('fetchAggregates')

        const data = getters.all().map(circuit => {
            if (circuit.hasData && !circuit.virtual) {
                return {
                    ...circuit,
                    aggregates: aggregates[circuit.id] || {},
                }
            }

            return { ...circuit }
        })

        // update circuits aggregates
        await dispatch('insert', { data })
    },

    /**
     * Fetch aggregates
     *
     * @param {Object} storeContext
     *
     * @returns {Void}
     */
    async fetchAggregates({ getters, rootState, rootGetters }) {
        const params = Unit.params(['GVOL', 'U_GENE', 'C_GENE', 'GCAL'])
        const { period } = rootState
        const entities = getters.query().where('virtual', false).where(e => e.hasData).get()
        const payload = {
            precision: 2,
            splitCircuits: true,
            max: params,
            clampIds: calculateRequestEntitiesIds(entities, 'electricity').clampIds,
            resolution: period.resolution,
            daterange: rootGetters['period/maxRange'],
            for: 'setup',
        }

        const aggregates = {}
        try {
            // fetch aggregates data
            const { data } = await this.$api.clickHouse.fetchAggregates(payload)

            // map data to our preferences
            for (const circuitId in data.clamps) {
                aggregates[circuitId] = transformAggregates(data.clamps[circuitId])
            }
        } catch (err) {
            console.warn('Error while fetching circuits aggregates. ', err.message)
        }
        return aggregates
    },

    /**
     * Set virtual aggregates
     *
     * @param {*} storeCtx
     *
     * @returns {Void}
     */
    async setVirtualAggregates({ dispatch, getters }) {
        // set a queue for the virtual circuits aggregates
        const queue = new Queue(2, Infinity)

        const promises = getters.virtual.map(async circuit => {
            // fetch circuit aggregates
            const aggregates = await queue.add(() => dispatch('fetchCircuitAggregates', circuit))
            return {
                ...circuit,
                aggregates,
            }
        })
        // await promises
        const data = [
            ...getters.real,
            ...await Promise.all(promises),
        ]
        // update virtual circuits
        await dispatch('insert', { data })
    },

    /**
     * Fetch aggregates per circuit
     *
     * @param {Object} storeContext
     *
     * @returns {Void}
     */
    async fetchCircuitAggregates({ rootState }, circuit) {
        const params = Unit.params()
        const { period } = rootState
        const entities = circuit.virtual
            ? circuit.siblings
            : [circuit]

        const payload = {
            precision: 2,
            max: params,
            clampIds: calculateRequestEntitiesIds(entities).clampIds,
            resolution: period.resolution,
            daterange: [
                this.$moment(period.min).format(),
                this.$moment(period.max).format(),
            ],
            for: 'setup',
        }

        let aggregates = {}
        try {
            // fetch aggregates data for each virtual circuit
            const res = await this.$api.clickHouse.fetchAggregates(payload)
            if (res?.data) {
                aggregates = transformAggregates(res.data)
            }
        } catch (err) {
            console.warn('Error while fetching circuits aggregates. ', err.message)
        }

        return aggregates
    },
}
