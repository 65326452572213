
import { required } from 'vuelidate/lib/validators'
import FormMixin from './form.mixin'
import SiteCard from './../cards/site-card'

export default {
    name: 'SelectSite',
    components: { SiteCard },
    mixins: [FormMixin],
    validations() {
        return {
            draft: {
                id: required,
            },
        }
    },
    data(vm) {
        return {
            sites: vm.$store.getters['entities/sites/all'](),
        }
    },
    methods: {
        onInput(e) {
            this.draft = this.draft?.id === e.id ? {} : e
        },
    },
}
