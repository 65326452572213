
import passwordMixin from '~/mixins/passwordMixin'

export default {
    name: 'ChangePassword',
    mixins: [passwordMixin],
    props: {
        dialogMessage: {
            type: String,
            default: null,
        },
        dialog: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            //
        }
    },
    watch: {
        dialog(newValue, oldValue) {
            if (!oldValue && newValue) {
                this.errorMessages = null
                this.$emit('update:dialog-message', null)
                this.changePasswordBtnDisabled = false
                this.showSuccessMsg = false
                this.password = {
                    password: '',
                    passwordConfirm: '',
                    oldPassword: '',
                }
            }
        },
    },
    methods: {
        async submit() {
            this.changePasswordBtnDisabled = true
            await this.changePassword()
            if (this.errorMessages) {
                this.changePasswordBtnDisabled = false
                return
            }
            this.$v.$reset()
            this.$emit('on-close')
        },
    },
}
