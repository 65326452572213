/**
 * Vuex ORM plugin
 */
export default {
    /**
     * Install plugin
     *
     * @param {Object} components [contains Vuex ORM objects such as Model and Query.]
     * @param {Object} options [plugin options]
     * */
    install(components, options) {
        // Add global (static) method or property.
        components.Model.state = function() {
            return {
                fetched: false,
                fetchingPromise: null,
            }
        }

        components.Model.fetchUri = function() {
            const baseURL = this.apiConfig.baseURL || ''
            return `${baseURL}/${this.apiConfig?.url || this.entity}`
        }

        components.Model.fetch = async function({ forced } = {}) {
            const entity = this.entity
            const store = this.store()
            const state = store.state.entities[entity]

            if (state.fetched && !forced) return
            if (!forced) {
                await this.commit(() => {
                    store.state.global.loader.visible = true
                    store.state.global.loader.loadItems[entity] = false
                })
            }

            let promise = state.fetchingPromise
            if (!promise) {
                promise = store.$queue.add(() => this.api().get(this.fetchUri()))
                    .then(r => {
                        this.commit(state => {
                            if (!forced) {
                                store.state.global.loader.loadItems[entity] = true
                                store.state.global.loader.indicator += 1
                            }
                            state.fetched = true
                            state.fetchingPromise = null
                        })
                    })

                this.commit(state => {
                    state.fetchingPromise = promise
                })
            }

            return promise
        }

        components.Model.dupeEntities = async function() {
            function random(a) {
                return a[Math.floor((Math.random() * a.length))]
            }
            const siteIds = []
            const meterIds = []
            const sites = this.store().$db().model('sites').all().map(e => {
                const newId = Math.round(Math.random() * 1e5) + 1e5
                siteIds.push(newId)
                return {
                    ...e.$toJson(),
                    [this.store().$db().model('sites').primaryKey]: newId,
                }
            })
            const meters = this.store().$db().model('meters').all().map(e => {
                const newId = Math.round(Math.random() * 1e5) + 1e5
                meterIds.push(newId)
                return {
                    ...e.$toJson(),
                    siteId: random(siteIds),
                    [this.store().$db().model('meters').primaryKey]: newId,
                }
            })
            const circuits = this.store().$db().model('circuits').all().map(e => {
                const newId = Math.round(Math.random() * 1e5) + 1e5
                // meterIds.push(newId)
                return {
                    ...e.$toJson(),
                    meterId: random(meterIds),
                    [this.store().$db().model('circuits').primaryKey]: newId,
                }
            })

            await this.store().$db().model('sites').insert({
                data: sites,
            })
            await this.store().$db().model('meters').insert({
                data: meters,
            })
            await this.store().$db().model('circuits').insert({
                data: circuits,
            })
            // await this.addTest(this.all())
            this.store().dispatch('electricity/setTreeItems', { forced: true })
        }

        // Add an instance method or property.
        // components.Query.prototype.instanceMethod = function () {}
    },
}
