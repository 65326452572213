
/* eslint-disable */
import { getGoogleMapsAPI } from 'gmap-vue'
import FormMixin from '~/components/flows/forms/form.mixin'
import * as gmapCfg from '~/config/gmap'
import CarbonSource from '~/orm/models/CarbonSource'
import CarbonAssetForm from '~/components/carbon/asset-form'
import { get, minBy, maxBy } from 'lodash'
import { secondsToDuration } from '~/utils/date'
import DialogHeader from '~/components/dialogs/header'

function colorByTransportType(type = 'road') {
    const typeColors = {
        road: '#ff9800',
        air: '#0ae54d',
        sea: '#46c3c3', // '#2196f3',
    }
    return typeColors[type]
}

export default {
    name: 'EditShippingDialog',

    components: {
        CarbonAssetForm,
        DialogHeader,
    },

    mixins: [FormMixin],

    props: {
        transportTypes: {
            type: Array,
            default: () => ([
                { text: 'Automatic', value: 'auto' },
                // { text: 'Air', value: 'air' },
                // { text: 'Rail', value: 'rail' },
                { text: 'Road', value: 'road' },
                // { text: 'Sea', value: 'sea' },
            ]),
        }
    },

    data: (vm) => {
        return {
            shippingRoutePositions: [],
            deliveryVehicles: [],
            mapData: {
                center: {
                    lat: 55.40240,
                    lng: -3.46288,
                },
                zoom: 5,
                mapTypeId: 'roadmap', // 'terrain'
                mapMode: null,
                toolbarPosition: 'TOP_CENTER',
                mapDraggable: true, // false,
                mapCursor: null,
                polylineOptions: {
                    fillColor: '#777',
                    fillOpacity: 0.4,
                    strokeWeight: 2,
                    strokeColor: '#0ae54d',
                    draggable: false,
                    editable: false,
                    clickable: true
                },
                shapes: [],
                showPath: false,
            },
            shipping: {
                transportType: vm.value.transportType,
                vehicle: vm.value.vehicle,
            },
            action: null,
            showDetails: false,
            fetchingRoute: false,
        }
    },

    computed: {
        mapOptions() {
            return {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                draggable: this.mapData.mapDraggable,
                draggableCursor: this.mapData.mapCursor,
                styles: gmapCfg.styles,
                minZoom: 2,
                maxZoom: 15,
            }
        },

        google: getGoogleMapsAPI,

        duration() {
            const seconds = get(this.draft, 'time.value', 0)
            return secondsToDuration(seconds)
        }
    },

    watch: {
        'shipping.transportType': {
            handler(val) {
                this.hidePath()
                if (val) {
                    this.showPath()
                }
            },
            // immediate: true,
        },
        'shipping.vehicle': {
            handler(val) {
                this.showPath()
            },
        },
        draft: {
            deep: true,
            handler(v, o){
                if(o?.legs && !v.legs){
                    console.log('something removed the legs')
                    // stackTrace()
                    this.draft.legs = o.legs
                }
            }
        }
    },

    methods: {
        updateShippingRoutePositions() {
            const positions = []
            if (! this.google.maps) return positions
            const legs = this.$_.get(this.draft, 'legs', [])
            legs.forEach(({ coordinates }) => {
                positions.push(new this.google.maps.LatLng(...coordinates[0]))
                // positions.push(new this.google.maps.LatLng(...coordinates.lastItem))
            })
            if (legs.length > 0) {
                positions.push(new this.google.maps.LatLng(...legs.lastItem.coordinates.lastItem))
            }
            // console.log('positions', positions)
            this.shippingRoutePositions = positions
        },
        updateDeliveryVehicles() {
            const vans = CarbonSource.query().where('parentId', 'at-vehicle-van').get().map(({ id, name }) => ({ id, name, group: 'Van', icon: '$vanShuttle' }))
            const trucks = CarbonSource.query().where('parentId', 'at-vehicle-hgv').get().map(({ id, name }) => ({ id, name, group: 'Truck', icon: '$truck' }))
            vans.push({
                isBtn: true,
                group: 'Van',
                name: 'vans',
                text: 'Add van',
                parentId: 'at-vehicle-van',
            })
            trucks.push({
                isBtn: true,
                group: 'Truck',
                name: 'trucks',
                text: 'Add truck',
                parentId: 'at-vehicle-hgv',
            })
            this.deliveryVehicles = [
                // { header: 'Default' },
                { icon: '$truckContainer', id: 'default', name: 'Default' },
                { divider: true },
                { header: 'Vans' },
                ...vans,
                { divider: true },
                { header: 'Trucks' },
                ...trucks,
            ]
        },
        async init(){
            // console.log('initializing shipping form')
            const data = this.__data()
            this.draft.origin = this.draft.origin || data.origin?.location
            this.draft.destination = this.draft.destination || data.destination?.location
            if(this.draft.origin && this.draft.destination){
                this.hidePath()
                setTimeout(this.showPath, 500)
            } else {
                console.warn('missing details for shipping planning')
            }
        },

        deInit(){ // TODO: rename -> destroy
            // console.log('de init')
            this.hidePath()
            this.draft.origin = null
            this.draft.destination = null
        },

        async fetchRoute() {
            const payload = {
                latFrom: get(this.draft, 'origin.lat', 0),
                lngFrom: get(this.draft, 'origin.lng', 0),
                latTo: get(this.draft, 'destination.lat', 0),
                lngTo: get(this.draft, 'destination.lng', 0),
                transportType: this.shipping.transportType,
                // deliveryVehicleId: get(this.shipping, 'vehicle.id'),
            }
            this.fetchingRoute = true
            try {
                const { data, stats } = await this.$api.fetchShippingRoute(payload)
                // console.log('data', data)
                this.draft = { ...this.draft, legs: data, ...stats, ...this.shipping }
            } catch (error) {
                // handle error ?
            } finally {
                this.fetchingRoute = false
            }
        },

        calcBounds() {
            if (this.draft.legs) {
                const edges = {
                    north: Number.MIN_SAFE_INTEGER,
                    south: Number.MAX_SAFE_INTEGER,
                    west: Number.MAX_SAFE_INTEGER,
                    east: Number.MIN_SAFE_INTEGER
                }
                this.draft.legs.forEach(({ coordinates }) => {
                    const minLat = minBy(coordinates, 0)[0]
                    const minLng = minBy(coordinates, 1)[1]
                    const maxLat = maxBy(coordinates, 0)[0]
                    const maxLng = maxBy(coordinates, 1)[1]
                    edges.north = Math.max(edges.north, maxLat)
                    edges.south = Math.min(edges.south, minLat)
                    edges.west = Math.min(edges.west, minLng)
                    edges.east = Math.max(edges.east, maxLng)
                })
                return edges
            }
        },

        fitBounds() {
            const bounds = this.calcBounds()
            if (bounds) {
                this.$refs.gmap.$mapPromise.then(map => {
                    map.fitBounds(bounds)
                })
            }
        },

        buildShapes() {
            if (!this.draft.legs) {
                return []
            }
            // console.log(this.draft)
            this.updateShippingRoutePositions()
            return this.draft.legs.map(({ type, coordinates: path }) => {
                return {
                    type: 'polyline',
                    overlay: {
                        path: path.map(([lat, lng]) => ({ lat, lng })),
                        geodesic: true,
                        strokeColor: colorByTransportType(type), // '#8aff80',
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    }
                }
            })
        },

        async showPath() {
            await this.fetchRoute()
            this.mapData.shapes = this.buildShapes()
            this.updateDeliveryVehicles()
            this.mapData.mapMode = 'edit'
            this.fitBounds()
            this.showDetails = true
        },

        hidePath() {
            console.log('hiding path')
            this.mapData.shapes = []
            this.mapData.mapMode = null
            this.showDetails = false
        },

        // togglePath() {
        //     this.mapData.showPath = !this.mapData.showPath
        //     if (this.mapData.showPath) {
        //         this.showPath()
        //     } else {
        //         this.hidePath()
        //     }
        // },

        async addAsset(item) {
            this.$refs.vehicleAutocomplete.$refs.menu.isActive = false

            if (!item.parentId) {
                let confirmed = await this.$confirm('What type of vehicle would you like to add?', {
                    buttonTrueText: 'Delivery Van',
                    buttonFalseText: 'Delivery Truck',
                    buttonTrueColor: 'primary',
                    buttonFalseColor: 'primary',
                })
                item.parentId = confirmed ? 'at-vehicle-van' : 'at-vehicle-hgv'
            }
            const parent = CarbonSource.find(item.parentId)
            this.action = {
                type: 'ADD',
                title: this.$tUcf('label.add_new_entity_for_parent', {
                    entity: this.$tc('entity.asset.name'),
                    parent: parent.name,
                }),
                payload: {
                    parent: parent.$toJson(),
                    parentId: parent.id,
                    attributes: {
                        siteId: parent._siteId
                    }
                }
            }
        },

        iconByType(type) {
            switch (type) {
                case 'sea':
                    return '$ship'
                case 'road':
                    return '$truck'
                default:
                    return '$building'
            }
        },

        legDuration({ value }) {
            return secondsToDuration(value)
        },
    },
}
