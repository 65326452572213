import { FROM_ROOT } from '@/utils/state'

export default {
    async init({ commit, dispatch, getters, state, rootState }, { forced = false } = {}) {
        // init cache
        await this.$cache.init()
        await dispatch('entities/sites/setup', {}, FROM_ROOT)
        await dispatch('entities/circuits/setup', {}, FROM_ROOT)
        await dispatch('entities/pipes/setup', {}, FROM_ROOT)
        await dispatch('entities/circuits/setupVirtual', {}, FROM_ROOT)
        // init period
        await dispatch('period/init', {}, FROM_ROOT)
        // update period resolution
        if (rootState.period.resolution !== 1800) {
            await dispatch('period/update', { resolution: 1800 }, FROM_ROOT)
        }
        commit('INIT')
    },
}
