import { Model } from '@vuex-orm/core'
import Site from './Site'
import Meter from './Meter'
import Preset from './Preset'
import Circuit from './Circuit'

export default class Customer extends Model {
    static entity = 'customers'
    // static primaryKey = 'customerId'

    static fetchUri() {
        return this.entity + '/' + this.store().$auth.user.customer_id
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            customerCode: this.attr(null),
            settings: this.attr(null),
            city: this.attr(null),
            country: this.attr(null),
            county: this.attr(null),
            createdAt: this.attr(null),
            address1: this.attr(null),
            address2: this.attr(null),
            postCode: this.attr(null),
            sites: this.hasMany(Site, 'customerId'),
            presets: this.hasMany(Preset, 'customerId'),
        }
    }

    static beforeCreate(model) {
        if (model.$store().$cookie.get('anon')) {
            model.name = 'ACME, inc'
        }
    }

    get entity() {
        return Customer.entity
    }

    get customerId() {
        return this.id
    }

    /**
     * Get customer children
     *
     * This is a smart getter which loads children
     * based on what's loaded from query via 'with' function
     * eg: with('sites') will load sites as children
     * eg: with('presets') will load presets as children
    */
    get children() {
        if (this.sites.length) {
            return this.sites
        }
        if (this.presets.length) {
            return this.presets
        }
        return []
        // return Site.query().where(s => s.customerId === this.customerId).get()
    }

    get descendants() {
        // sites
        const sites = this.sites.length
            ? this.sites
            : Site.query().where(s => s.customerId === this.id).get()
        const sitesIds = sites.map(s => s.siteId)
        // meters
        const meters = Meter.query().where(m => sitesIds.includes(m.siteId)).get()
        const metersIds = meters.map(m => m.id)
        // circuits
        const circuits = Circuit.query().where(c => metersIds.includes(c.meterId)).get()
        const circuitsIds = circuits.map(c => c.id)

        return {
            sites,
            sitesIds,
            meters,
            metersIds,
            circuits,
            circuitsIds,
        }
    }

    getName() {
        return this.name
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
