export default {
    SET_INITIALISED(state, value) {
        state.initialised = value
    },

    SET_MATERIALS(state, value) {
        state.materials = value
    },

    SET_FUELS(state, value) {
        state.fuels = value
    },

    ADD_INVENTORY(state, item) {
        state.inventory.push(item)
    },

    SET_FLOWS(state, flows) {
        state.flows = flows
    },

    SET_PROCESS_CATEGORIES(state, v) {
        state.processCategories = v
    },

    SET_THIRD_PARTIES(state, v) {
        state.thirdParties = v
    },

    SET_DISPOSALS(state, v) {
        state.disposals = v
    },

    SET_MACHINES(state, v) {
        state.machines = v
    },
}
