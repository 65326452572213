/* eslint-disable no-mixed-operators */
// Utils
import uniqBy from 'lodash/uniqBy'
import { idFromEntity, parseEUID, parseEUIDs } from '~/utils/entities'

export default {
    entityQuery: (state, getters, rootState, rootGetters) => entity => {
        return rootGetters[`entities/${entity}/query`]()
    },

    entityModel: (state, getters) => entity => {
        return getters.entityQuery(entity).getModel()
    },

    sites(state, getters) {
        return getters.entityQuery('sites').with('meters.circuits').all()
    },

    allMetersHasMainIncomer(state, getters, rootState, rootGetters) {
        const allMeters = rootGetters['entities/meters/all']()
        const allMainIncomers = rootGetters['entities/circuits/onlyMainIncomers']
        return allMeters.length === allMainIncomers.length
    },

    activeEntities: (state, getters) => (type = 'all') => {
        const euids = state.activeEntities[type]
        return euids.map(euid => {
            const { entity, id } = parseEUID(euid, true)
            return getters.entityQuery(entity).find(id)
        })
    },

    filteredEUIDs: state => ({
        stand = 'selected',
        type = 'all',
        entities = ['circuits', 'meters', 'presets'],
    } = {}) => {
        return state[`${stand}Entities`][type].filter(euid => {
            for (const entity of entities) {
                if (euid.includes(entity)) return true
            }
            return false
        })
    },

    activeEUIDs: state => (type = 'all') => {
        return state.activeEntities[type].filter(euid => {
            return euid.includes('circuits') || euid.includes('meters') || euid.includes('presets')
        })
    },

    selectedEUIDs: state => (type = 'all') => {
        return state.selectedEntities[type].filter(euid => {
            return euid.includes('circuits') || euid.includes('meters') || euid.includes('presets')
        })
    },

    euids: (state, getters) => (type = 'all') => {
        const activeEUIDs = getters.activeEUIDs(type)
        if (activeEUIDs.length > 0) return activeEUIDs
        return getters.selectedEUIDs(type)
    },

    activeCircuits: (state, getters) => (type = 'all') => {
        const euids = state.activeEntities[type]
        const parsedEuids = parseEUIDs(euids, { reverse: true })
        const circuitsIds = parsedEuids.filter(({ entity }) => entity === 'circuits').map(idFromEntity)
        const metersIds = parsedEuids.filter(({ entity }) => entity === 'meters').map(idFromEntity)
        const presetsIds = parsedEuids.filter(({ entity }) => entity === 'presets').map(idFromEntity)

        return getters
            .entityQuery('circuits')
            .where(c => {
                return c.hasData && (
                    circuitsIds.includes(c.id) ||
                    presetsIds.includes(c.presetId) && c.virtual ||
                    metersIds.includes(c.meterId) && c.isMainIncomer
                )
            }).get()
    },

    selectedCircuits: (state, getters, rootState) => (type = 'all', forceSelected = false) => {
        let euids = forceSelected ? state.selectedEntities[type] : state.activeEntities[type]
        if (euids.length === 0) {
            euids = state.selectedEntities[type]
        }
        const parsedEuids = parseEUIDs(euids, { reverse: true })
        const circuitsIds = parsedEuids.filter(({ entity }) => entity === 'circuits').map(idFromEntity)
        const metersIds = parsedEuids.filter(({ entity }) => entity === 'meters').map(idFromEntity)
        const sitesIds = parsedEuids.filter(({ entity }) => entity === 'sites').map(idFromEntity)
        const presetsIds = parsedEuids.filter(({ entity }) => entity === 'presets').map(idFromEntity)

        const circuits = getters
            .entityQuery('circuits')
            .where(c => {
                return c.hasData && (
                    circuitsIds.includes(c.id) ||
                    presetsIds.includes(c.presetId) && c.virtual ||
                    metersIds.includes(c.meterId) && c.isMainIncomer
                )
            }).get()

        return rootState.electricity.tabIndex !== '0' && sitesIds.length
            ? [...circuits, ...getters
                .entityQuery('sites')
                .whereIdIn(sitesIds).get()]
            : circuits
    },

    selectedCircuitsMeters: (state, getters) => (type = 'all') => {
        const selectedCircuits = getters.selectedCircuits(type)
        const meters = selectedCircuits.filter(e => !e.virtual).map(c => c.relationships.meter || c.relationships.meters)
        return uniqBy(meters, 'id')
    },

    circuitsMeters: () => circuits => {
        if (!circuits) return []
        const meters = circuits.filter(e => !e.virtual).flatMap(c => c.relationships.meter || c.relationships.meters).filter(m => m)
        return uniqBy(meters, 'id')
    },

    validatedTreeview: (_state, _getters, _rootState, rootGetters) => treeview => {
        const validatedEntities = euids => euids
            ? euids.filter(euid => {
                const { entity, id } = parseEUID(euid, { reverse: true })
                return !!rootGetters[`entities/${entity}/find`](id)
            })
            : []

        return {
            ...treeview,
            selected: validatedEntities(treeview.selected),
            active: validatedEntities(treeview.active),
        }
    },
}
