export default {
    INIT(state) {
        state.initialised = true
    },

    SET_AGGREGATES(state, value) {
        state.aggregates = value
    },

    SET_BASE_ENTITY(state, entity) {
        state.baseEntity = entity
    },

    SET_COMMODITY(state, value) {
        state.commodity = value
    },

    SET_COMMODITIES(state, value = []) {
        state.commodities = value
    },

    SET_LOADING(state, value) {
        state.loading = value
    },

    SET_ACTIVE(state, { entity, ids }) {
        if (entity) { state.active.entity = entity }
        if (ids) { state.active.ids = ids }
    },

    RESET_ACTIVE(state) {
        state.active.entity = 'sites'
        state.active.ids = []
    },

    SET_SELECTED(state, { entity, ids }) {
        if (state.active.ids.length > 0) {
            state.active.entity = 'sites'
            state.active.ids = []
        }
        if (entity) { state.selected.entity = entity }
        if (ids) { state.selected.ids = ids }
    },

    SET_READINGS(state, { dailyReadings, monthlyReadings }) {
        state.dailyReadings = dailyReadings
        state.monthlyReadings = monthlyReadings
    },

    SET_UNIT_ID(state, unitId) {
        state.unitId = unitId
    },

    SET_UNIT_SUMMARIES(state, value) {
        state.unitSummaries = value
    },

    SET_COMPARISON(state, value) {
        state.comparison = value
    },
}
