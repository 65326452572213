
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import FormMixin from '~/components/flows/forms/form.mixin'
import moment from '~/composables/useMoment'
const manufacturingStep = [
    {
        name: 'SelectMachine',
        title: 'Select the machine',
        description: 'The machine that will be used to run this process',
        id: 'machine',
        auto: true,
        props: {
            value: {},
        },
        onChange: (data, dialog) => {

        },
    },
    {
        name: 'MaterialsForm',
        title: 'Select Input fuels',
        description: 'The fuels that will be used in this process and their quantities',
        id: 'fuels',
        props: {
            value: [],
            type: 'fuel',
        },
    },
    {
        name: 'MaterialsForm',
        title: 'Select Input materials',
        description: 'The materials that will be used in this process and their quantities',
        id: 'input',
        props: {
            value: [],
            type: 'input',
        },
    },
    {
        name: 'MaterialsForm',
        title: 'Select Output products',
        id: 'output',
        props: {
            value: [],
            type: 'output',
        },
    },
    {
        name: 'SelectWaste',
        title: 'Waste production and disposal',
        description: 'Select waste production and disposal for this process',
        id: 'waste',
        props: {
            value: [],
        },
    },
]
export default {
    name: 'SelectFlowType',
    mixins: [FormMixin],
    inject: ['dialog'],
    validations() {
        return {
            draft: {
                required,
                id: required,
            },
        }
    },
    data() {
        return {
            types: [
                {
                    name: 'Receive goods from a supplier',
                    icon: '$parachuteBox',
                    id: 'purchase',
                    components: [
                        {
                            name: 'SelectProcessName',
                            title: 'Select the delivery name',
                            description: 'This will be the name assigned to the delivery',
                            id: 'options',
                            props: {
                                value: {
                                    name: '',
                                    steps: [
                                        {
                                            name: 'Purchase',
                                            outputType: 'Internal',
                                            range: {
                                                startDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                                                endDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                                                startTime: moment().subtract(1, 'day').startOf('day').add(8, 'hours').format('HH:mm'),
                                                endTime: moment().subtract(1, 'day').startOf('day').add(16, 'hours').format('HH:mm'),
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            name: 'SelectSite',
                            title: 'Select a site',
                            description: 'This is the site where the materials will be delivered',
                            id: 'destination',
                            auto: true,
                            props: {
                                value: {},
                            },
                        },
                        {
                            name: 'SelectBusiness',
                            title: 'Select the supplier',
                            description: 'The supplier that is sending the shipping',
                            id: 'origin',
                            auto: true,
                            props: {
                                value: {},
                            },
                        },
                        {
                            name: 'MaterialsForm',
                            title: 'Select Receivables',
                            id: 'materials',
                            props: {
                                value: [],
                                type: 'material',
                            },
                        },
                        {
                            name: 'ProcessSummary',
                            title: 'Summary of Scope 3 Greenhouse Gas Emissions',
                            id: 'summary',
                            props: {
                                withShipping: true,
                                value: [],
                            },
                        },
                    ],
                },
                {
                    name: 'Design a new process',
                    icon: '$gears',
                    id: 'manufacturing',
                    components: [
                        {
                            name: 'SelectProcessName',
                            title: 'Process name and length',
                            description: 'Choose the process name and length',
                            id: 'options',
                            props: {
                                value: {
                                    name: '',
                                    steps: [
                                        {
                                            name: '',
                                            outputType: 'Internal',
                                            range: {
                                                startDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                                                endDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                                                startTime: moment().subtract(1, 'day').startOf('day').add(8, 'hours').format('HH:mm'),
                                                endTime: moment().subtract(1, 'day').startOf('day').add(16, 'hours').format('HH:mm'),
                                            },
                                        },
                                    ],
                                },
                            },
                            onChange: (data, components) => {
                                this.dialog.myComponents = this.dialog.myComponents.filter(e => !['SelectMachine', 'MaterialsForm', 'SelectWaste'].includes(e.name))
                                this.dialog.myComponents.splice(4, 0, ...this.makeSteps(data.options.steps.length, data.options.steps))
                            },
                        },
                        {
                            name: 'SelectSite',
                            title: 'Select a site',
                            description: 'This is the site where the process will run',
                            id: 'location',
                            auto: true,
                            props: {
                                value: {},
                            },
                        },
                        {
                            name: 'SelectCategory',
                            title: 'Select a category',
                            description: 'The category for this process',
                            id: 'category',
                            auto: true,
                            props: {
                                value: {},
                            },
                        },
                        {
                            name: 'ProcessSummary',
                            title: 'Summary of Scope 3 Greenhouse Gas Emissions',
                            id: 'summary',
                            props: {
                                value: [],
                            },
                        },
                    ],
                },
                {
                    name: 'Ship goods to a customer',
                    icon: '$truck',
                    id: 'delivery',
                    components: [
                        {
                            name: 'SelectProcessName',
                            title: 'Process options',
                            description: 'Name the delivery, choose output type and running period',
                            id: 'options',
                            props: {
                                value: {
                                    name: '',
                                    steps: [
                                        {
                                            name: 'Delivery',
                                            outputType: 'External',
                                            range: {
                                                startDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                                                endDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
                                                startTime: moment().subtract(1, 'day').startOf('day').add(8, 'hours').format('HH:mm'),
                                                endTime: moment().subtract(1, 'day').startOf('day').add(16, 'hours').format('HH:mm'),
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            name: 'SelectSite',
                            title: 'Select the site you are delivering from',
                            description: 'This is the site where the materials will be shipped from',
                            id: 'origin',
                            auto: true,
                            props: {
                                value: {},
                            },
                        },
                        {
                            name: 'SelectBusiness',
                            title: 'Select the customer',
                            description: 'The customer that is going to receive the materials',
                            id: 'destination',
                            auto: true,
                            props: {
                                value: {},
                            },
                        },
                        {
                            name: 'MaterialsForm',
                            title: 'Select materials',
                            description: 'These are the materials that are going to be shipped',
                            id: 'materials',
                            props: {
                                value: [],
                                type: 'material',
                            },
                        },
                        {
                            name: 'ProcessSummary',
                            title: 'Summary of Scope 3 Greenhouse Gas Emissions',
                            id: 'summary',
                            props: {
                                withShipping: true,
                                value: [],
                            },
                        },
                    ],
                },
            ],
        }
    },

    computed: {
        ...mapGetters('flows', ['allMaterials', 'allFuels', 'machinesByCategoryId']),
    },

    mounted() {
        if (this.draft.id) {
            const flow = this.types.find(({ id }) => id === this.draft.id)
            if (flow) {
                this.select(flow, true)
            }
        }
    },

    methods: {
        select(item, force = false) {
            if (force) {
                this.draft = item
            } else {
                this.draft = this.draft.id === item.id ? {} : item
            }
            this.dialog.myComponents = [this.dialog.myComponents[0], ...item.components]
            this.$nextTick(this.dialog.next)
        },
        makeSteps(l, options) {
            const steps = []
            for (let i = 0; i < l; i++) {
                steps.push(...manufacturingStep.map(e => {
                    return {
                        ...e,
                        id: `${e.id}-${i}`,
                        title: `${e.title} for process ${i + 1}: ${options[i].name}`,
                    }
                }))
            }
            return steps
        },
    },
}
