/* eslint-disable no-unreachable */
import { random, times, uniqBy } from 'lodash'
import { delay } from '~/utils/tools'

export default class EventsService {
    constructor({ $axios, $config }) {
        this.$axios = $axios
        this.$config = $config
    }

    fetchMaterials() {
        return this.$axios.$get('events-api/materials', { params: { limit: 99999 } })
    }

    async fetchFuels() {
        const { processes } = await this.fetchProcessesByCategoryId('combustion')
        return {
            data: uniqBy(processes.map(({ process }) => {
                return { ...process.inputs[0], combustionProcessId: process.id }
            }), 'id'),
        }
    }

    async fetchDisposals() { // c-waste-generated
        const { processes } = await this.fetchProcessesByCategoryId('disposal')
        const compactedItems = processes.map(({ process }) => {
            return {
                ...process.inputs[0],
                disposalProcessId: process.id,
                key: process.name,
            }
        })
        const data = {}
        // console.log(compactedItems)
        compactedItems.forEach(e => {
            const [category, name, disposalType] = e.key.split('|')
            // console.log({ category, name, disposalType })
            data[category] = data[category] || {
                categoryName: category,
                items: [],
            }
            const i = data[category].items.findIndex(({ name: searchName }) => name === searchName)
            if (i >= 0) {
                data[category].items[i].disposalTypes.push({
                    name: disposalType,
                    disposalProcessId: e.disposalProcessId,
                })
            } else {
                data[category].items.push({
                    id: e.id,
                    name: e.name,
                    category,
                    netZero: e.netZero,
                    disposalTypes: [
                        {
                            name: disposalType,
                            disposalProcessId: e.disposalProcessId,
                        },
                    ],
                })
            }
        })
        // console.log(data, Object.values(data).flatMap(e => e.items))
        return {
            data: Object.values(data).flatMap(e => e.items),
        }
    }

    fetchProcessCategories() {
        // return {
        //     data: [
        //         {
        //             id: 1,
        //             name: 'test'
        //         }
        //     ]
        // }
        return this.$axios.$get('events-api/flows/processes/categories')
    }

    fetchProcessesByCategoryId(categoryId) {
        return this.$axios.$get('events-api/flows/processes', { params: { categoryIds: [categoryId] } })
    }

    async storeInventory(payload = {}) {
        // return this.$axios.$post('events-api/flows/inventory', payload)
        await delay(1000)
        return payload
    }

    async fetchSuppliers() {
        // return this.$axios.$post('events-api/flows/inventory', payload)
        await delay(1000)
        return [
            {

                business: {
                    id: 1,
                    name: 'ACME 1',
                    address: '',
                    location: {
                        lat: 30,
                        lng: 14,
                    },
                },
            },
            {

                business: {
                    id: 2,
                    name: 'ACME 2',
                    address: '',
                    location: {
                        lat: 30,
                        lng: 14,
                    },
                },
            },
            {
                business: {
                    id: 3,
                    name: 'ACME 3',
                    address: '',
                    location: {
                        lat: 30,
                        lng: 14,
                    },
                },
            },
        ]
    }

    fetchFlowTemplate(type = 'basic') {
        const url = `events-api/flows/templates/generate?type=${type}`
        return this.$axios.$get(url)
    }

    fetchTemplateFromWizard(payload) {
        const url = 'events-api/flows/templates/generate'
        return this.$axios.$put(url, payload)
    }

    fetchFlows() {
        // console.log('fetching flows')
        const url = 'events-api/flows'
        return this.$axios.$get(url)
    }

    fetch3rdParties() {
        const url = 'events-api/thirdparties'
        return this.$axios.$get(url)
    }

    async fetchMachines(getters) {
        const url = 'events-api/flows/machines'
        return this.$axios.$get(url)
        const materials = getters['flows/allMaterials']
        const fuels = getters['flows/allFuels']
        const disposals = getters['flows/allDisposals']
        // console.log(disposals)
        await delay(1000)
        return times(12, k => {
            return {
                id: k,
                name: `Machine ${k + 1}`,
                model: 'Siemens abc1',
                efficiency: random(25, 70),
                entities: [
                    {
                        id: 1,
                        demand: 9,
                        commodity: 'gas',
                    },
                    {
                        id: 1,
                        demand: 6,
                        commodity: 'power',
                    },
                ],
                waste: [
                    {
                        ...disposals.find(({ name }) => name.includes('Aggregates')),
                        amount: 10,
                        unit: 'kg',
                        disposalProcessId: disposals.find(({ name }) => name.includes('Aggregates')).disposalTypes[0].disposalProcessId,
                    },
                ],
                input: [
                    {
                        ...materials.find(({ name }) => name === 'Soils'),
                        amount: 100,
                        unit: 'kg',
                    },
                ],
                fuel: [
                    {
                        ...fuels.find(({ name }) => name === 'Wood pellets'),
                        amount: 20,
                        unit: 'kg',
                    },
                ],
                output: [
                    {
                        ...materials.find(({ name }) => name === 'Glass'),
                        amount: 100,
                        unit: 'kg',
                    },
                ],
            }
        })
    }

    saveFlow(flow) {
        const url = 'events-api/flows'
        try {
            console.log('saving flow')
            return this.$axios.$post(url, flow)
        } catch (e) {
            console.error(e)
        }
    }

    fetchFlow(id) {
        const url = `events-api/flows/flow/${id}`
        return this.$axios.$get(url)
    }

    getCombinedFlow(flowIds) {
        const url = 'events-api/flows/combine'
        return this.$axios.$get(url, {
            params: {
                flowIds,
            },
        })
    }

    getSummary(flowIds) {
        const url = 'events-api/flows/emissions-summary'
        return this.$axios.$get(url, {
            params: {
                flowIds,
            },
        })
    }

    flushAll() {
        const url = 'events-api/flows/flush-all'
        return this.$axios.$get(url)
    }
}
