
export default {
    name: 'ItemCard',
    props: {
        active: Boolean,
        icon: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
}
