
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        machines: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        onClick() {
            if (this.machines) {
                this.$emit('input', this.value)
            }
        },
    },
}
