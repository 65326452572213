export const FROM_ROOT = { root: true }

/**
 *
 * Disable reactivity for json data
 *
 * @param {json} data
 * @return {json}
 */
export const eliberate = data => {
    return JSON.parse(JSON.stringify(data))
}

/**
 * Sync Dispatcher
 * - useful to commit state change at the same time for multiple actions
 * - CVP-1578
 *
 * @returns {{syncDispatcher: ((function(*=, *): Promise<void>)|*)}}
 */
export function useSyncDispatcher() {
    const fetchIds = {}
    const syncDispatcher = async(methods = [], namespace = '') => {
        const namespacePath = namespace ? `${namespace}/` : ''
        // add a uniq id per request
        const key = methods.join('_')
        const fetchId = +new Date()
        fetchIds[key] = fetchId
        this.$store.dispatch('global/setSpinnerLoader', true, { root: true })
        const resolved = await Promise.all(methods.map(m => {
            const methodPrefixCheck = m.includes('/') ? m : `${namespacePath}${m}`
            return this.$store.dispatch(methodPrefixCheck, true, { root: true })
        }))

        this.$store.dispatch('global/setSpinnerLoader', false, { root: true })
        // check for the uid
        if (fetchIds[key] === fetchId) {
            resolved.forEach(cb => {
                if (typeof cb === 'function') {
                    cb()
                }
            })
        }
    }

    return { syncDispatcher }
}
