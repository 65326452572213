
import { mapGetters, mapState } from 'vuex'
import CarbonSourceForm from '../source-form.vue'
import CarbonSource from '~/orm/models/CarbonSource'

export default {
    name: 'AddDataToAssetsCard',

    components: {
        CarbonSourceForm,
    },

    inject: {
        dialog: {
            default: () => ({}),
        },
    },

    data: () => ({
        assetIndex: -1,
        manualInput: false,
        activeSource: null,
    }),

    computed: {
        ...mapGetters('entities/carbon-sources', ['assets']),
        ...mapState('carbon', ['emissions']),
        assetsList() {
            return this.assets.map(asset => asset.name)
        },
        activeAsset() {
            return this.assets[this.assetIndex]
        },
        assetsWithData() {
            return this.assets.filter(this.assetHasData)
        },
        progressValue() {
            return Math.min(100 / this.assets.length * this.assetsWithData.length, 100)
        },
    },

    watch: {
        activeAsset(asset) {
            if (asset) {
                this.activeSource = CarbonSource.query().withAllRecursive().find(asset.id)
            } else {
                this.activeSource = null
            }
        },
        'dialog.isFullScreen'() {
            this.onFullscreenChange()
        },
    },

    mounted() {
        this.onFullscreenChange = this.$_.debounce(this.onFullscreenChange, 360)
    },

    methods: {
        assetHasData(asset) {
            return !!this.emissions.assets.find(({ assetId }) => asset.id === assetId)
        },
        assetByNameHasData(assetName) {
            const asset = this.assets.find(a => a.name === assetName)
            return asset && this.assetHasData(asset)
        },
        onFullscreenChange() {
            if (!this.$refs.slide) return
            this.$refs.slide.setWidths()
        },
    },
}
