function getPath(ctx) {
    let path = ctx.localePath(ctx.from.path, ctx.i18n.fallbackLocale)
    path = path.replaceAll('/', '')
    if (path === '') path = 'dashboard'
    return path
}

export default function(ctx) {
    if (!ctx.from) return
    if (ctx.localePath(ctx.route.path, ctx.i18n.fallbackLocale) === '/auth/login') return
    const path = getPath(ctx)
    const selectedSiteIds = ctx.store.getters[`${path}/selectedSiteIds`]
    if (!Array.isArray(selectedSiteIds)) return
    ctx.store.dispatch('setSelectedSiteIds', selectedSiteIds)
}
