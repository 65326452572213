
import AppHeader from './parts/header'
import AppFooter from './parts/footer'
import AppDrawer from './parts/agents-drawer'
import ChangePassword from '~/components/profile/change-password'
import { maskEmailAddress } from '~/utils/tools'

export default {

    components: {
        AppHeader,
        AppFooter,
        AppDrawer,
        ChangePassword,
    },

    data() {
        return {
            zoom: 100,
            isLaptop: false,
            dialog: false,
            dialogMessage: '',
        }
    },

    mounted() {
        try {
            /**
             * the following will generate a unique browser signature and store it in the localstorage
             * such signature will also attached to any report sent to sentry and will show up in the footer
             */
            if (!this.$cache.$localStorage.has('signature')) {
                this.$cache.$localStorage.set('signature', ('00000000' + (Math.random() * 1e8)).slice(-8))
            }
            const signature = this.$cache.$localStorage.get('signature')
            const { customer_id: customerId } = this.$store.state.auth.user
            const instanceId = `${customerId ? customerId + '-' : ''}${this.$store.state.auth.user.id}-${signature}`
            const sentryUserData = {
                email: maskEmailAddress(this.$store.state.auth.user.name),
                id: instanceId,
            }
            this.$store.commit('global/SET_INSTANCE_ID', instanceId)
            this.$sentry.setUser(sentryUserData)
        } catch (e) {
            console.warn(e)
        }

        setTimeout(() => {
            if (!this.$api.auth.jsonToken().customerId && this.$auth.user.tempPasswordFlag) {
                this.dialogMessage = this.$t('warnings.logged_in_with_temp_pwd')
                this.dialog = true
            }
        }, 1)
    },

    methods: {
        loadScript(url, callback) {
            if (typeof document === 'undefined') return
            const script = document.createElement('script')
            if (script.readyState) { // only required for IE <9
                script.onreadystatechange = function() {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null
                        callback()
                    }
                }
            } else { // Others
                script.onload = function() {
                    callback()
                }
            }
            script.setAttribute('src', url)
            script.setAttribute('id', 'ze-snippet')
            document.head.appendChild(script)
        },

        onResize() {
            this.zoom = Math.round((window.outerWidth / window.innerWidth) * 100)
            // this.zoom = Math.round((window.outerHeight / window.innerHeight) * 100)
            const actualHeight = window.outerHeight * this.zoom / 100
            this.isLaptop = actualHeight <= 1080

            if (typeof this.$vuetify.breakpoint.isLaptop === 'undefined') {
                this.$set(this.$vuetify.breakpoint, 'isLaptop', this.isLaptop)
            } else if (this.$vuetify.breakpoint.isLaptop !== this.isLaptop) {
                this.$vuetify.breakpoint.isLaptop = this.isLaptop
            }
        },
    },
}
