import pluralize from 'pluralize'
import { Database } from '@vuex-orm/core'

// Create new instance of Database.
const database = new Database()

// Auto load all the models and their modules
;(function loadModels() {
    // Allow us to dynamically require all model and their modules files.
    // https://webpack.js.org/guides/dependency-management/#require-context
    const requireModel = require.context(
        // Search for files in the models directory.
        './models',
        // Search for files in subdirectories.
        true,
        // Include any .js files that are not this file or a unit test.
        /^((?!index|\.unit\.).)*\.js$/,
    )

    const requireModule = require.context(
        // Search for files in the models directory.
        './modules',
        // Search for files in subdirectories.
        true,
        // Include any .js files that are not this file or a unit test.
        /^((?!index|\.unit\.).)*\.js$/,
    )
    // console.log('requireModule',requireModule.keys())
    // For every model...
    requireModel.keys().forEach(fileName => {
        const model = requireModel(fileName)
        // console.log(model)
        const moduleFileName = moduleFileNameFromModelFileName(fileName)
        // console.log(moduleFileName)
        // check if model has a module
        if (requireModule.keys().includes(moduleFileName)) {
            const modelModule = requireModule(moduleFileName)
            database.register(model.default, modelModule)
        } else {
            database.register(model.default)
        }
    })

    // If the environment supports hot reloading...
    /*
        if (module.hot) {
            // Whenever any Vuex module is updated...
            module.hot.accept(requireModel.id, () => {
                // Update
                loadModels()
            })
        }
    */
})()

// helper function to retrieve module file name from model file name
function moduleFileNameFromModelFileName(modelFileName) {
    const modelName = modelFileName
    // Remove the "./" from the beginning.
        .replace(/^\.\//, '')
    // Remove the file extension from the end.
        .replace(/\.\w+$/, '')

    const moduleName = pluralize(modelName)

    return modelFileName.replace(modelName, moduleName)
}

export default database
