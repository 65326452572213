import { Model } from '@vuex-orm/core'
import Alert from '~/orm/models/Alert'
import Contract from '~/orm/models/Contract'
import Job from '~/orm/models/Job'
import Notification from '~/orm/models/Notification'
import RagConfiguration from '~/orm/models/RagConfiguration'
import Report from '~/orm/models/Report'
import UserAnnotation from '~/orm/models/UserAnnotation'
import CarbonSource from '~/orm/models/CarbonSource'
import Unit from '~/orm/models/Unit'
import ParametersPreset from '~/orm/models/ParametersPreset'

export default ({ $axios, $config }) => {
    Model.globalApiConfig.baseURL = $config.axios.entitiesBaseURL
    Alert.apiConfig.baseURL = $config.axios.alertsBaseURL
    Contract.apiConfig.baseURL = $config.axios.contractsBaseURL
    Job.apiConfig.baseURL = $config.axios.jobsBaseURL
    Notification.apiConfig.baseURL = $config.axios.alertsBaseURL
    RagConfiguration.apiConfig.baseURL = $config.axios.ratesBaseURL
    Report.apiConfig.baseURL = $config.axios.reportsServiceURL
    UserAnnotation.apiConfig.baseURL = $config.axios.notesBaseURL
    if (process.server) { // proxy doesn't seem to work on SSR
        CarbonSource.apiConfig.baseURL = $config.axios.netZeroServiceURL
    } else {
        CarbonSource.apiConfig.baseURL = '/net-zero-api'
    }
    Unit.apiConfig.baseURL = $config.axios.umrURL
    ParametersPreset.apiConfig.baseURL = '/parameters-api'

    Model.setAxios($axios)
}
