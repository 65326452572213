
import { validationMixin } from 'vuelidate'
import { cloneDeep, isEmpty, isFunction } from 'lodash'
import { prepareForTemplate } from '~/components/flows/utils-3'

export default {
    mixins: [validationMixin],
    inject: ['__data', 'dialog'],
    props: {
        value: {
            type: [Object, Array, String, Number],
            required: true,
        },
        currentStep: {
            type: Number,
            default: null,
        },
        myStep: {
            type: Number,
            default: null,
        },
        id: {
            type: [String, Number],
            default: null,
        },
        auto: {
            type: Boolean,
            default: false,
        },
    },

    data(vm) {
        return {
            loading: false,
            draft: null, // cloneDeep(vm.value)
        }
    },

    watch: {
        draft: {
            deep: true,
            handler(v) {
                this.$emit('input', v)
                if (this.auto && !isEmpty(v)) {
                    this.dialog.mask = true
                    setTimeout(() => {
                        this.dialog.next()
                        this.dialog.mask = false
                    }, 500)
                }
            },
        },
        value: {
            deep: true,
            immediate: true,
            handler(v) {
                this.draft = cloneDeep(v)
            },
        },
        currentStep: {
            immediate: true,
            handler() {
                if (this.currentStep === this.myStep) {
                    isFunction(this.init) && this.init()
                } else {
                    isFunction(this.deInit) && this.deInit()
                }
            },
        },
    },

    methods: {
        prepareForTemplate(r) {
            return prepareForTemplate(r)
        },
    },
}
