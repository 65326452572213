/* eslint-disable no-mixed-operators */
import Queue from 'promise-queue'
import { filter, get, groupBy, orderBy, sortBy, minBy, maxBy } from 'lodash'
import Unit from '../models/Unit'
import WaterMeter from '../models/WaterMeter'
import { extraColors } from '~/config/colors'
import { generateCacheKey } from '~/utils/cache'
import { parentSort, transformAggregates } from '~/utils/calculations'
import { VALID_RESOLUTIONS } from '~/utils/resolution'

export const state = {
    /**
     * Cached items keys array
     *
     * This is essentially the array of props in loaded items of the cache service
     * which main purpose of having it here is the reactivity
     * for start this is used in the pipes list to visually show
     * when a pipe has data loaded for selected unit
     *
     * @type {Array}
     */
    cached: [],

    areSetup: false,
    areVirtualSetup: false,
}

export const getters = {
    treeview(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/treeviews/active']
    },

    selected(_state, getters) {
        const waterMetersIds = get(getters, 'treeview.waterMetersIds', [])
        const presetsIds = get(getters, 'treeview.presetsIds', [])
        const pipesIds = get(getters, 'treeview.waterPipesIds', [])
        return getters
            .query()
            .where(p => {
                return pipesIds.includes(p.id) ||
                    presetsIds.includes(p.presetId) && p.virtual ||
                    waterMetersIds.includes(p.meterId) && p.isMainIncomer
            }).get()
    },

    /**
     * The selected getter returns the active elements if there are some
     * This getter forces to return the real selected ones
     */
    selectedForced(_state, getters) {
        const selectedIds = get(getters, 'treeview.selectedEndIds', [])

        return getters
            .query()
            .where(p => {
                return selectedIds.includes(p.id) ||
                    selectedIds.includes(p.presetId) && p.virtual ||
                    selectedIds.includes(p.meterId) && p.isMainIncomer
            }).get()
    },

    selectedIds(_state, getters) {
        return getters.selected.map(p => p.id)
    },

    ids(state, getters) {
        return getters.all().map(p => p.id)
    },

    realIds(state, getters) {
        return getters.real.map(p => p.id)
    },

    virtualIds(state, getters) {
        return getters.virtual.map(p => p.id)
    },

    sorted(state, getters) {
        return getters.query()
            .orderBy('childrenCount', 'desc')
            .orderBy('totalKwData', 'desc')
            .get()
    },

    haveData(state, getters) {
        return getters.query()
            .where(e => !e.virtual)
            .orderBy('childrenCount', 'desc')
            .get()
    },

    haveStartEndTimes(state, getters) {
        return getters.haveData.filter(p => p.startTime && p.endTime)
    },

    startTimeSortedList(state, getters) {
        return sortBy(getters.haveStartEndTimes, ['startTime'])
    },

    endTimeSortedList(state, getters) {
        return sortBy(getters.haveStartEndTimes, ['endTime'])
    },

    firstData(state, getters) {
        return getters.startTimeSortedList[0]
    },

    lastData(state, getters) {
        return getters.endTimeSortedList.lastItem
    },

    minResolution(state, getters) {
        const minimumResolution = minBy(getters.haveData, 'sampleRate')?.sampleRate
        // 15 minutes is currently the lowest supported value by water/gas charts
        return minimumResolution < VALID_RESOLUTIONS._15_MINUTES ? VALID_RESOLUTIONS._15_MINUTES : minimumResolution
    },

    initialResolution(state, getters) {
        const maxResolution = maxBy(getters.haveData, 'sampleRate')?.sampleRate
        return maxResolution < VALID_RESOLUTIONS._15_MINUTES ? VALID_RESOLUTIONS._15_MINUTES : maxResolution
    },

    /**
     * Get main incomer
     * @param  {Object} state
     * @param  {Object} getters
     * @return {WaterPipe}
     */
    main(state, getters) {
        return getters.query().where('isMainIncomer', true).first()
    },

    real(state, getters) {
        return getters.query().where('virtual', false).get()
    },

    virtual(state, getters) {
        return getters.query().where('virtual', true).get()
    },

    /**
     * Get main incomer
     * @param  {Object} state
     * @param  {Object} getters
     * @return {WaterPipe}
     */
    mainOrFallback(state, getters) {
        return getters.main || getters.haveData[0] || null
    },

    /**
     * Get all the main incomers
     * @param state
     * @param getters
     * @returns {{length}|*|*[]}
     */
    mains(state, getters) {
        return getters.query().where('isMainIncomer', true).get()
    },

    /**
     * Get all the main incomers
     * @param state
     * @param getters
     * @returns {{length}|*|*[]}
     */
    mainsOrFallback(state, getters) {
        return getters.mains || getters.haveData[0] ? [getters.haveData[0]] : []
    },

    hasMainIncomer(state, getters) {
        return getters.main && getters.main.isMainIncomer
    },

    onlyMainIncomers: (state, getters) => {
        return getters.query()
            .where('isMainIncomer', true)
            .where('virtual', false)
            .get()
    },

    notRenderedPipes: (state, getters) => {
        return filter(getters.all(), { rendered: false, virtual: false })
    },

    isPipeDataCached: (state, getters, rootState, rootGetters) => ({ unit, id }) => {
        const key = generateCacheKey([unit, rootState.period.resolution, id])
        return state.cached.includes(key)
    },

    isThereAtLeastOneMeterWithNoMainIncomer: (state, getters, rootGetters) => {
        let isThereAtLeastOneMeterWithNoMainIncomer = false
        const meters = WaterMeter.all()
        meters.forEach(meter => {
            if (!meter.children.find(e => e.isMainIncomer)) {
                isThereAtLeastOneMeterWithNoMainIncomer = true
            }
        })
        return isThereAtLeastOneMeterWithNoMainIncomer
    },
}

export const mutations = {
    SET_ARE_SETUP(state, value) {
        state.areSetup = value
    },

    SET_ARE_VIRTUAL_SETUP(state, value) {
        state.areVirtualSetup = value
    },

    SET_CACHED(state, items) {
        state.cached = items
    },
}

export const actions = {
    /**
     * Setup pipes
     *
     * will setup the following params for each pipe
     * color
     *
     * @param {Object} context
     * @return {Void}
     */
    async setup({ commit, dispatch, getters, state, rootGetters }, { forced = false } = {}) {
        if (!forced && state.areSetup) return
        const data = []
        let colorIndex = 0
        const { entityColors } = extraColors(rootGetters.theme)
        const pipesExtraInfo = await this.$api.clickHouse.fetchCircuitsRankings('E')
        const groupedByMeter = groupBy(getters.all().filter(({ siteId }) => siteId), 'siteId')
        for (const meterId in groupedByMeter) {
            let pipes = groupedByMeter[meterId].map(pipe => ({
                ...pipe,
                ...(pipesExtraInfo.get(pipe.id) || {}),
            }))
            pipes = orderBy(pipes, 'totalKwData', 'desc')
            pipes = parentSort(pipes)
            const newData = pipes.map(pipe => {
                pipe.color = pipe.color || entityColors[colorIndex]
                pipe.backupColor = entityColors[colorIndex]
                colorIndex++
                if (!entityColors[colorIndex]) {
                    colorIndex = 0
                }
                return pipe
            })

            data.push(
                ...newData,
            )
        }
        // update pipes
        await dispatch('insert', { data })
        // dispatch('setAggregates')
        commit('SET_ARE_SETUP', true)
    },

    /**
     * Setup virtual pipes
     *
     * will setup the following params for each pipe
     * startTime, endTime, totalKwData, color
     *
     * ! for virtual pipes we are not fetching aggregates here becasue is taking too long
     *
     *
     * @param {Object} context
     * @param {Object} payload
     * @returns {Promise}
     */
    async setupVirtual({ commit, dispatch, getters }, { forced = false } = {}) {
        if (!forced && state.areVirtualSetup) return

        // eslint-disable-next-line no-undef
        const colors = $nuxt.$vuetify.theme.currentTheme.virtualEntityColors
        let colorIndex = 0
        const data = getters.all().map(pipe => {
            if (pipe.virtual) {
                const siblings = pipe.siblings
                const totalKwData = this.$_.sumBy(siblings, 'totalKwData')
                const startTime = this.$_.get(this.$_.minBy(siblings, 'startTime'), 'startTime')
                const endTime = this.$_.get(this.$_.maxBy(siblings, 'endTime'), 'endTime')
                const color = pipe.color || colors[colorIndex]
                const backupColor = colors[colorIndex++]
                if (!colors[colorIndex]) colorIndex = 0 // reset color index when exceeded

                return {
                    ...pipe,
                    totalKwData,
                    startTime,
                    endTime,
                    color,
                    backupColor,
                }
            }

            return { ...pipe }
        })
        // update virtual pipes
        await dispatch('insert', { data })
        // don't wait for virtual aggregates
        // dispatch('setVirtualAggregates')
        commit('SET_ARE_VIRTUAL_SETUP', true)
    },

    /**
     * Set aggregates
     *
     * @param {*} storeCtx
     *
     * @returns {Void}
     */
    async setAggregates({ dispatch, getters }) {
        // fetch and get aggregates
        const aggregates = await dispatch('fetchAggregates')
        const data = getters.all().map(pipe => {
            if (!pipe.virtual) { // Math.abs(pipe.totalKwData) > 0 &&
                return {
                    ...pipe,
                    aggregates: aggregates[pipe.pipeId] || {},
                }
            }
            return { ...pipe }
        })
        // update pipes aggregates
        await dispatch('insert', { data })
    },

    /**
     * Fetch aggregates
     *
     * @param {Object} storeContext
     *
     * @returns {Void}
     */
    async fetchAggregates({ getters, rootState, rootGetters }) {
        const params = Unit.params()
        const { period } = rootState
        const payload = {
            precision: 2,
            splitCircuits: true,
            max: params,
            clampIds: getters.query().where('virtual', false).get().map(p => p.pipeId),
            resolution: period.resolution,
            daterange: rootGetters['period/maxRange'],
        }

        const aggregates = {}
        try {
            // fetch aggregates data
            const { data } = await this.$api.clickHouse.fetchAggregates(payload)
            // map data to our preferences
            for (const pipeId in data.clamps) {
                aggregates[pipeId] = transformAggregates(data.clamps[pipeId])
            }
        } catch (err) {
            console.warn('Error while fetching pipes aggregates. ', err.message)
        }

        return aggregates
    },

    /**
     * Set virtual aggregates
     *
     * @param {*} storeCtx
     *
     * @returns {Void}
     */
    async setVirtualAggregates({ dispatch, getters }) {
        // set a queue for the virtual pipes aggregates
        const queue = new Queue(2, Infinity)

        const promises = getters.all().map(async pipe => {
            if (pipe.virtual) {
                // fetch pipe aggregates
                const aggregates = await queue.add(() => dispatch('fetchPipeAggregates', pipe))
                return {
                    ...pipe,
                    aggregates,
                }
            }

            return { ...pipe }
        })
        // await promises
        const data = await Promise.all(promises)

        // update virtual pipes
        await dispatch('insert', { data })
    },

    /**
     * Fetch aggregates per pipe
     *
     * @param {Object} storeContext
     *
     * @returns {Void}
     */
    async fetchPipeAggregates({ rootState }, pipe) {
        const params = Unit.params()
        const { period } = rootState
        const ids = pipe.virtual
            ? pipe.siblings.map(c => c.pipeId)
            : [pipe.pipeId]

        const payload = {
            precision: 2,
            max: params,
            ids,
            resolution: period.resolution,
            daterange: [
                this.$moment(period.min).format(),
                this.$moment(period.max).format(),
            ],
        }

        let aggregates = {}
        try {
            // fetch aggregates data for each virtual pipe
            const res = await this.$api.clickHouse.fetchAggregates(payload)
            if (res?.data) {
                aggregates = transformAggregates(res.data)
            }
        } catch (err) {
            console.warn('Error while fetching pipes aggregates. ', err.message)
        }

        return aggregates
    },
}
