import { Model } from '@vuex-orm/core'

export default class Note extends Model {
    static entity = 'notes'

    static primaryKey = 'noteId'

    static fields() {
        return {
            noteId: this.attr(null),
            entityId: this.attr(null),
            entityType: this.attr(null),
            title: this.attr(null),
            text: this.attr(null),
        }
    }
}
