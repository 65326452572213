import { get, unionBy, remove, compact } from 'lodash'
import { consolidateEntities, getIds } from '~/utils/entities'
import ParametersPreset from '~/orm/models/ParametersPreset'

export default {
    /**
     * Filter selected circuits
     * so that we don't include both parents and children
     * logic as per CLAL-385 ticket requirements
     *
     * @returns {Array} circuits
     */
    consolidatedPipes(_state, getters, rootState) {
        const { period } = rootState
        const pipes = getters.realSelectedPipes
        return consolidateEntities(pipes, period)
    },

    /**
     * Filtered pipes IDs
     *
     * @returns {Array} pipeIds
     */
    consolidatedPipesIds(_state, getters) {
        return getIds(getters.consolidatedPipes)
    },

    /**
     * Flag to which the comparison is disabled
     *
     * @param {*} state
     * @param {*} getters
     * @param {*} rootState
     * @param {*} rootGetters
     */
    isComparisonDisabled(_state, getters, _rootState, rootGetters) {
        return !rootGetters['period/canCompare'] || getters.selectedPipes.length !== 1
    },

    /**
     * Real selected pipes
     * > in the case of virtual pipes
     *   we want to return their children instead
     *
     * @param {Object} state
     * @param {Object} getters
     * @param rootState
     * @param rootGetters
     */
    realSelectedPipes(_state, getters, _rootState, rootGetters) {
        let selectedPipes = [...getters.selectedPipes]
        const virtualPipes = remove(selectedPipes, c => c.$id.includes('V'))
        for (const vc of virtualPipes) {
            const presetId = parseInt(vc.$id.replace('V', ''))
            const preset = rootGetters['entities/presets/find'](presetId)
            const presetPipes = get(preset, 'circuitsChildren', []).filter(c => !c.$id.includes('V'))
            selectedPipes = unionBy(selectedPipes, presetPipes, '$id')
        }
        return selectedPipes
    },

    realSelectedPipesIds(_state, getters) {
        return getIds(getters.realSelectedPipes)
    },

    selectedPipes(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/water-pipes/selected']
    },
    /**
     * The selectedPipes getter returns the active elements if there are some
     * This getter forces to return the real selected ones
     */
    selectedPipesForced(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/water-pipes/selectedForced']
    },

    selectedPipesIds(_state, getters) {
        return getIds(getters.selectedPipes)
    },

    selectedSiteIds(_state, getters) {
        return compact([...new Set(getters.selectedPipes.map(pipe => pipe.siteId))])
    },

    supported(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/water-meters/all']().length > 0
    },

    unit(state) {
        return state.selectedUnit || state.initialUnit
    },

    treeview(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/treeviews/active']
    },

    hasChanges(_state, getters) {
        return getters.treeview?.hasChanges
    },

    preset(state) {
        return ParametersPreset.find(state.selectedParametersPreset)
    },

    availableSelectedUnit(state, getters) {
        if (getters.preset?.keysOnly.includes(state.selectedUnit)) {
            return state.selectedUnit
        }
    },
}
