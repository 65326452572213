
import { required, numeric } from 'vuelidate/lib/validators'
import { genericUnits } from '../const'
import FormMixin from './form.mixin'

export default {
    name: 'EditMaterial',

    mixins: [FormMixin],

    validations() {
        const schema = {
            draft: {
                amount: {
                    required,
                    numeric,
                },
            },
        }
        return schema
    },

    data(vm) {
        return {
            genericUnits,
            title: 'Edit material',
            // title: `${vm.draft.material.draft ? 'Adding' : 'Editing'} ${vm.draft.material.material.name} for ${vm.draft.material.process.name}`
        }
    },
}
