
import { unzip, cloneDeep, compact } from 'lodash'
import CarbonInputGrid from '~/components/carbon/carbon-input-grid'
import CarbonInputMapping from '~/components/carbon/carbon-input-mapping'
import Site from '~/orm/models/Site'
import { arrayToCsv } from '~/utils/file'
import { fileDownload, isDate, isNumeric } from '~/utils/tools'
import { TRACKING_EVENTS } from '~/utils/tracking'

export default {
    name: 'CarbonSourceForm',

    components: {
        CarbonInputGrid,
        CarbonInputMapping,
    },
    provide() {
        return {
            sourceForm: this,
        }
    },
    props: {
        source: {
            type: Object,
            required: true,
        },
        initialStep: {
            type: Number,
            default: 2,
        },
    },

    data(vm) {
        return {
            step: vm.initialStep,
            rawData: null,
            rawFile: null,
            fileName: '',
            formattedData: [],
            csvLineSeparator: ['\r\n', '\n\r', '\r', '\n'],
            csvColumnSeparator: [';', ',', '\t'],
            csvHasHeaders: false,
            transpose: false,
            mapping: [],
            smartColumnsUsedIndexes: [],
            editMode: false,
            showOverlay: false,
            columnAnalysisProgress: 0,
            cancelAnalysis: false,
            columnsValidation: [],
            manualMode: false,
            units: {},
            loading: false,
        }
    },

    computed: {
        parametersSatisfied() {
            return compact(this.columnsValidation.map((e, k) => this.isColumnValid(k))).length === this.source.parent.inputs.length
        },
        attributes() {
            const { siteId, ...rest } = this.source.attributes
            return { ...rest, ...(siteId && { 'label.site_name': Site.query().find(siteId).name }) }
        },
        columnColors() {
            return this.$vuetify.theme.isDark
                ? ['#51547599', '#51617599', '#51707599']
                : ['#ABADD0ED', '#B5CCECED', '#9CD0DAED']
        },
    },

    watch: {
        source: {
            immediate: true,
            handler(source) {
                const inputs = this.$_.get(source, 'parent.inputs', [])
                inputs.forEach(({ key, units }) => {
                    if (units && units.length) {
                        this.units[key] = units[0]
                    }
                })
                if (this.step === 3 && this.manualMode) {
                    this.initManualInput()
                }
                if (this.step === 2) {
                    this.$nextTick(() => {
                        const _this = this
                        if (this.$refs.file) {
                            this.$refs.file.oninput = function() {
                                _this.handleFiles(this.files)
                            }
                        }
                    })
                }
            },
        },
        step(step) {
            if (step === 2) {
                this.$nextTick(() => {
                    const _this = this
                    if (this.$refs.file) {
                        this.$refs.file.oninput = function() {
                            _this.handleFiles(this.files)
                        }
                    }
                })
            }
        },
    },

    beforeDestroy() {
        this.step = 2
        this.rawData = null
        this.formattedData = null
        document.onkeydown = null
    },

    mounted() {
        const _this = this
        this.$refs.file.oninput = function() {
            _this.handleFiles(this.files)
        }
        document.onkeydown = async e => {
            if (e.key === 'v' && e.ctrlKey) {
                const r = await e.view.navigator.clipboard.readText()
                this.rawData = r
                this.step = 3
                this.rawFile = `data:application/vnd.ms-excel;base64,${btoa(r)}`
                this.formatCSVData()
            }
        }
    },

    methods: {
        isColumnValid(columnIndex) {
            if (!this.columnsValidation[columnIndex]) {
                return null
            } else if (this.columnsValidation[columnIndex].filter(e => e === false).length) {
                return false
            }
            return true
        },
        handleFiles(files) {
            const supportedFileTypes = ['csv'] // only accept CSV
            const reader = new FileReader()
            reader.onload = e => {
                const fullPath = this.$refs.file.value
                if (fullPath) {
                    const startIndex = (fullPath.includes('\\') ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'))
                    const filename = fullPath.substring(startIndex)
                    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                        this.fileName = filename.substring(1)
                    }
                }
                const ext = fullPath.split('.').pop()
                if (ext && !supportedFileTypes.includes(ext.toLowerCase())) {
                    this.$toast.error(this.$t('validation.file_type_csv'), { position: 'top-left' })
                    return
                }
                this.rawFile = e.target.result
                const str = e.target.result.split('base64,')
                this.rawData = atob(str[1])
                this.formatCSVData()
                this.step = 3
            }
            reader.readAsDataURL(files[0])
        },
        formatCSVData() {
            if (!this.rawData) return false
            let pickedLineSeparator
            let pickedColumnSeparator

            // try to guess the line separator
            for (const symbol of this.csvLineSeparator) {
                const re = new RegExp(symbol, 'g')
                const count = (this.rawData.match(re) || []).length
                if (count) {
                    pickedLineSeparator = symbol
                    break
                }
            }

            if (!pickedLineSeparator) {
                console.warn('Could not find line separator')
                return false
            }

            const lines = this.rawData.split(pickedLineSeparator).filter(e => e.length)

            // try to guess the column separator
            for (const symbol of this.csvColumnSeparator) {
                const re = new RegExp(symbol, 'g')
                const count = (lines[0].match(re) || []).length
                if (count) {
                    pickedColumnSeparator = symbol
                    break
                }
            }

            if (!pickedColumnSeparator) {
                console.warn('Could not find column separator')
                return false
            }

            this.formattedData = lines.map(line => line.split(pickedColumnSeparator).map(c => c.trim()))

            // check for empty or incomplete lines
            this.formattedData = this.formattedData.filter((e, k) => {
                // console.log(k, e.filter(o => o.length > 0).length, e.length)
                return e.filter(o => o.length > 0).length >= e.length - 2
            })

            // transpose matrix
            this.formattedData = unzip(this.formattedData)
            this.mapping = new Array(this.formattedData.length).fill(null)
            this.columnsValidation = new Array(this.formattedData.length).fill(null)

            // check if data has headers, but just using the first 2 rows
            this.csvHasHeaders = this.checkCSVForHeaders()
        },
        checkCSVForHeaders() {
            const firstTwoRows = this.formattedData.map(e => e.slice(0, 2))
            let discrepancies = 0
            // console.log(firstTwoRows)
            firstTwoRows.forEach(([row1, row2]) => {
                const isRow1Significant = isDate(row1).dt?._isValid || isNumeric(row1)
                const isRow2Significant = isDate(row2).dt?._isValid || isNumeric(row2)
                // console.log({
                //     row1,
                //     row2,
                //     isRow1Significant,
                //     isRow2Significant
                // })
                if (isRow1Significant !== isRow2Significant) {
                    discrepancies++
                }
            })
            return discrepancies > 0
        },
        async onMappingUpdate({ columnIndex, value }) {
            // check if this column content is compatible with the chosen type
            try {
                this.cancelAnalysis = false
                this.showOverlay = true
                const dataToValidate = this.csvHasHeaders ? this.formattedData[columnIndex].slice(1) : this.formattedData[columnIndex]
                const valid = await this.validateColumnAsync(dataToValidate, value.type, 0)
                this.$set(this.columnsValidation, columnIndex, valid)
                this.showOverlay = false
                // check if mapping already contains that value
                const oldIndex = this.mapping.findIndex(e => e && e.key === value.key)
                if (oldIndex > -1) {
                    this.$set(this.mapping, oldIndex, null)
                    this.$set(this.columnsValidation, oldIndex, null)
                }
                this.$set(this.mapping, columnIndex, value)
            } catch (e) {
                this.showOverlay = false
                this.$set(this.mapping, columnIndex, value)
                this.$nextTick(() => {
                    this.$set(this.mapping, columnIndex, null)
                })
                this.$set(this.columnsValidation, columnIndex, null)
            }
        },
        validateColumnAsync(data, type, i) {
            const result = []
            this.columnAnalysisProgress = 0
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (this.cancelAnalysis) {
                        this.columnAnalysisProgress = 0
                        clearInterval(interval)
                        reject(new Error('Analysis canceled.'))
                    }
                    this.columnAnalysisProgress = Math.ceil(i / data.length * 100)
                    if (data[i]) {
                        result.push(this.validateSingleValue(data[i], type))
                        i++
                    } else {
                        clearInterval(interval)
                        resolve(result)
                    }
                }, 1)
            })
        },
        validateSingleValue(value, type) {
            switch (type) {
                case 'date':
                    return isDate(value)
                case 'number':
                    return isNumeric(value) && value > 0
                default:
                    return true
            }
        },
        onCellEdit({ row, col, value }) {
            // replace the value with the new one
            const t = cloneDeep(this.formattedData)
            t[col][this.csvHasHeaders ? row + 1 : row] = value
            this.formattedData = t

            // validate the single cell
            const type = this.mapping[col]?.type
            if (type && value.length) {
                const valid = this.validateSingleValue(value, type)
                if (!this.columnsValidation[col]) {
                    this.$set(this.columnsValidation, col, [])
                }
                this.$set(this.columnsValidation[col], row, valid)
            } else if (value === '') {
                if (this.columnsValidation[col] && this.columnsValidation[col][row]) {
                    this.$delete(this.columnsValidation[col], row)
                }
            }
            // add a new row if last row was changed
            if (this.manualMode && value !== '' && row === this.formattedData[0].length - 1) {
                this.formattedData.forEach(e => {
                    e.push('')
                })
            }
        },
        onMultipleCellsEdit({ columnIndex: col, cellsToUpdate }) {
            cellsToUpdate.forEach(({ rowIndex: row, value }) => this.onCellEdit({ row, col, value }))
        },
        initManualInput() {
            this.mapping = this.source.parent.inputs.map(e => e)
            this.formattedData = this.source.parent.inputs.map(e => {
                return new Array(10).fill('')
            })
            this.manualMode = true
            this.step = 3
        },
        async saveData() {
            const dataArray = unzip(this.formattedData).filter(e => {
                return compact(e).length === e.length
            })
            let userHeaders
            if (this.csvHasHeaders) {
                userHeaders = dataArray.shift()
            }
            const timestampIndex = this.mapping.findIndex(({ key }) => key === 'timestamp')
            const payload = {
                // origin: this.manualMode ? `manual_${Date.now()}` : `file_${Date.now()}`,
                data: dataArray.map(e => {
                    const timestamp = isDate(e[timestampIndex]).dt.toISOString()
                    const inputs = []
                    const extra = []
                    e.forEach((value, k) => {
                        if (k === timestampIndex) return
                        if (!this.mapping[k]) {
                            extra.push({
                                userHeader: userHeaders && userHeaders[k] ? userHeaders[k] : `column-${k}`,
                                index: k,
                                value,
                            })
                        } else if (this.mapping[k]) {
                            inputs.push({
                                key: this.mapping[k].key,
                                unit: this.units[this.mapping[k].key]?.key,
                                value,
                            })
                        }
                    })
                    return {
                        timestamp,
                        inputs,
                        extra,
                    }
                }),
            }
            if (!this.manualMode && this.rawFile) {
                payload.file = this.rawFile
                payload.meta = {
                    filename: this.fileName,
                }
            }

            this.loading = true
            try {
                await this.source.uploadData(payload)
                this.$api.tracking.addToBuffer({ type: TRACKING_EVENTS.ASSETS_ADD_DATA })
                this.$toast.success('Data saved')
                this.$eventBus.$emit('on-data-change')
                this.$emit('cancel')
            } catch (err) {
                const errorMsg = this.$_.get(err, 'response.data.message', 'Something went wrong')
                this.$toast.error(errorMsg)
            } finally {
                this.loading = false
            }
        },
        onUnitAssign({ key, unit }) {
            this.$set(this.units, key, unit)
        },

        /**
         * Get an example value for input
         * - useful when building the csv template
         *
         * @param {Object}  input
         *
         * @return {String}
         */
        inputExampleValue(input) {
            let example = ''
            switch (input.type) {
                case 'date':
                    example = new Date().toLocaleDateString('en-GB')
                    break
                case 'number':
                    example = '0.00'
                    break
                case 'enum':
                    example = input.options[0].name
                    break
            }
            return example
        },

        /**
         * Download CSV Template
         *
         * @return {Void} downloads csv template
         */
        downloadCsvTemplate() {
            const inputs = this.$_.get(this.source, 'parent.inputs')
            if (!inputs) {
                return
            }
            const csvItem = {}
            inputs.forEach(input => {
                let key = input.name
                if (input.canonical) {
                    if (input.canonical.includes('(')) {
                        key += ` - ${input.canonical}`
                    } else {
                        key += `(${input.canonical})`
                    }
                }
                csvItem[key] = this.inputExampleValue(input)
            })
            const csvItems = [csvItem]

            try {
                const csv = arrayToCsv(csvItems)
                const timestamp = new Date().valueOf()
                const assetName = this.$_.snakeCase(this.source.name)
                const fileName = `${assetName}_data_csv_template_${timestamp}.csv`
                fileDownload(csv, fileName)
            } catch (err) {
                console.warn('Download csv template error: ', err.message)
            }
        },
    },
}
