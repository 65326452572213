export const EXPORT_TO_FILE_MODES = [
    {
        mode: 'pdf',
    },
    {
        mode: 'csv',
    },
]

/**
 * Take an array of objects of similar structure and convert it to a CSV.
 *
 * @param      {Array}  options.data            Array of data
 * @param      {String} options.columnDelimiter Column separator, defaults to ","
 * @param      {String} options.lineDelimiter   Line break, defaults to "\n"
 * @return     {String}                         CSV
 */
export const arrayToCsv = (data = null, columnDelimiter = ',', lineDelimiter = '\n') => {
    if (data === null || !data.length) {
        return null
    }

    const keys = Object.keys(data[0])

    let result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter

    data.forEach(item => {
        keys.forEach((key, index) => {
            if (index > 0) {
                result += columnDelimiter
            }
            result += typeof item[key] === 'string' && item[key].includes(columnDelimiter) ? `'${item[key]}'` : item[key]
        })
        if (item !== data.lastItem) {
            result += lineDelimiter
        }
    })

    return result
}
