export default class LocalStorageService {
    constructor() {
        try {
            this._storage = localStorage
        } catch (err) {
            console.warn(err.message)
        }
    }

    has(key) {
        return Object.prototype.hasOwnProperty.call(this._storage, key)
    }

    get(key) {
        if (this.has(key)) {
            return JSON.parse(this._storage[key])
        }
        return undefined
    }

    set(key, data) {
        return this._storage.setItem(key, JSON.stringify(data))
    }

    remove(key) {
        return this._storage.removeItem(key)
    }

    destroy() {
        this._storage.clear()
    }
}
