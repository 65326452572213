
import { mapGetters } from 'vuex'
import { minLength, numeric, required } from 'vuelidate/lib/validators'
// import { scrollToBottom } from '~/utils/tools'
import { cloneDeep } from 'lodash'
import { genericUnits } from '../const'

// Components
import FormMixin from './form.mixin'
import WasteCard from '~/components/flows/cards/waste-card'

export default {
    name: 'SelectWaste',

    components: {
        WasteCard,
    },

    mixins: [FormMixin],

    validations() {
        return {
            draft: {
                required,
                minLength: minLength(1),
                $each: {
                    id: {
                        required,
                    },
                    amount: {
                        required,
                        numeric,
                    },
                    unit: {
                        required,
                    },
                    disposalProcessId: {
                        required,
                    },
                },
            },
        }
    },

    props: {
        purchaseFields: Boolean,
        type: {
            type: String,
            default: 'material',
        },
        forceSelectedOnTop: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        expandedPanel: 0,
        dateMenu: false,
        searchMaterial: '',
        selectedOnTop: false,
    }),

    computed: {
        ...mapGetters('flows', ['allDisposals']),
        inventoryItems() {
            let items = cloneDeep(this.allDisposals)

            if (this.selectedOnTop) {
                items = items.sort((a, b) => {
                    if (this.draft.find(({ id }) => a.id === id)) {
                        return -1
                    }
                    return 0
                })
            }
            return items
        },
        genericUnits() {
            return genericUnits
        },
    },

    watch: {
        forceSelectedOnTop(e) {
            if (e) {
                this.selectedOnTop = e
            }
        },
    },

    mounted() {
        window[`__MATS_${this._uid}`] = this
    },

    methods: {
        addOrUpdateItem(item) {
            // console.log(item)
            this.draft = [...this.draft.filter(({ id }) => item.id !== id), item]
        },

        removeItem({ id }) {
            this.draft = this.draft.filter(item => item.id !== id)
        },

        onReveal(id) {

        },
    },
}
