/* Models */
import { Model } from '@vuex-orm/core'
import { find, orderBy, uniq, groupBy } from 'lodash'
import Site from './Site'
// import Meta from './Meta'
import Circuit from './Circuit'
import Contract from './Contract'

/* Utils */
export default class Meter extends Model {
    static entity = 'meters'
    // static primaryKey = 'meterId'

    static apiConfig = {
        dataKey: 'data',
        url: 'meters',
        params: {
            commodity: ['power'],
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            siteId: this.attr(null),
            type: this.number(0),
            icon: this.attr('$calculator'),
            name: this.attr(''),
            serialNumber: this.attr(null),
            address: this.attr(null),
            installDate: this.attr(null),
            supplier: this.attr(null),
            dno: this.attr(null),
            dc: this.attr(null),
            da: this.attr(null),
            mop: this.attr(null),
            mpanTop: this.attr(''),
            mpanBottom: this.attr(''),
            // site: this.belongsTo(Site, 'siteId'),
            // metas: this.morphMany(Meta, 'entity_id', 'entity_name'),
            circuits: this.hasMany(Circuit, 'meterId'),
            editable: this.boolean(true),
            kvaThreshold: this.number(300),
            gspGroup: this.attr('_F'),
        }
    }

    get meterId() {
        return this.id
    }

    /**
     * this function is not good enough.
     * It should check the following and not just the group:
     * GSP
     * LLFC (last 3 chars of mpan top)
     * PC (first two chars of mpan top)
     * Dist ID (first two chars of mpan bottom)
     * @param meters
     * @returns {boolean}
     */
    static haveSameGroup(meters = []) {
        const gspGroups = meters.map(meter => meter.gspGroup)
        return uniq(gspGroups).length === 1
    }

    static haveDUOS(meters = []) {
        return meters.every(m => {
            return m.liveContract && m.liveContract.passed_through_elements && m.liveContract.passed_through_elements.includes('DUOS_RED')
        })
    }

    static haveDifferentDayNightRates(meters = []) {
        return meters.every(m => {
            return m.liveContract && m.liveContract.day_rate && m.liveContract.night_rate && m.liveContract.day_rate !== m.liveContract.night_rate
        })
    }

    get entity() {
        return Meter.entity
    }

    get mainIncomer() {
        return find(this.children, { isMainIncomer: true })
    }

    get children() {
        let circuits = this.circuits
        if (circuits.length === 0) {
            circuits = this.getChildren()
        }
        return orderBy(circuits, ['childrenCount', 'totalKwData'], ['desc', 'desc'])
    }

    get fullName() {
        return `${this.parent.name} [${this.mpanBottom}]`
    }

    /**
    * Get meter parent
    */
    get parent() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
     * Get site
     */
    get site() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
    * Get meter siblings
    * Other meters under the same parent
    */
    get siblings() {
        return Meter.query()
            .where(record => {
                return record.siteId === this.siteId &&
                    record.id !== this.id
            })
            .get()
    }

    /**
    * Get relationships
    */
    get relationships() {
        // site
        const site = this.parent
        // circuits
        const circuits = Circuit.query()
            .where('meterId', this.id)
            // .with('assets.activities')
            .get()

        /*
            // assets
            const assets = reduce(
                circuits,
                (reducedAssets, circuit) => {
                    reducedAssets.push(...circuit.assets)
                    return reducedAssets
                },
                []
            )
            // activities
            const activities = reduce(
                assets,
                (reducedActivities, asset) => {
                    reducedActivities.push(...asset.activities)
                    return reducedActivities
                },
                []
            )
        */

        return {
            site,
            circuits,
            // assets,
            // activities
        }
    }

    get contracts() {
        return Contract.query().where(c => c.mpan === this.mpanBottom || c.meter_number === this.mpanBottom).get()
    }

    get liveContract() {
        return Contract.query().where(c => (c.mpan === this.mpanBottom || c.meter_number === this.mpanBottom) && c.is_live === true).first()
    }

    get ragConfiguration() {
        return (async() => {
            const rag = await this.$store().$api.electricity.getRag(this.gspGroup)
            return rag && rag.length && groupBy(rag, 'name')
        })()
    }

    getName({ showMpan } = {}) {
        if (showMpan) {
            return this.mpanBottom
        }

        return this.name && !this.name.toLowerCase().includes('test')
            ? this.name
            : `Meter ${this.$id}`
    }

    getChildren() {
        return Circuit.query().where('meterId', this.id).get()
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
