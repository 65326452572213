
export default {
    data() {
        return {
            downloadButtonDisabled: false,
        }
    },

    computed: {
        items() {
            const unseenNotifications = this.$store.state.entities.notifications.inbox?.counts?.unseenNotifications

            const items = [
                {
                    icon: '$cvelectricity',
                    title: this.$t('pages.electricity.title'),
                    to: this.localePath('/electricity'),
                },
                {
                    icon: '$cvgas', // '$gasPump',
                    title: this.$t('pages.gas.title'),
                    to: this.localePath('/gas'),
                },
            ]
            if (this.$config.features.dashboardEnabled) {
                items.unshift({
                    icon: '$cvdashboard',
                    title: this.$t('pages.dashboard.title'),
                    to: this.localePath('/'),
                })
            }
            if (this.$config.features.waterEnabled) {
                items.push({
                    icon: '$cvwater',
                    title: this.$t('pages.water.title'),
                    to: this.localePath('/water'),
                })
            }
            if (this.$config.features.jobsEngineEnabled) {
                items.push({
                    icon: '$arrowProgress', // '$abacus',
                    title: this.$t('pages.jobs.title'),
                    to: this.localePath('/jobs'),
                })
            }
            if (this.$config.features.leagueTable) {
                items.push({
                    icon: '$cvgrid',
                    title: 'League table',
                    to: this.localePath('/league-table'),
                })
            }
            if (this.$config.features.alertsEnabled) {
                items.push({
                    icon: '$cvalarm',
                    title: this.$t('pages.alerts.title'),
                    to: this.localePath('/alerts'),
                    notifications: unseenNotifications,
                })
            }
            if (this.$config.features.carbonEnabled) {
                items.push({
                    icon: '$cvc02',
                    title: 'Carbon',
                    to: '/carbon',
                    // badge: 'beta',
                })
            }
            if (this.$config.features.eventsEnabled) {
                items.push({
                    icon: '$cvcalendar',
                    title: 'Events',
                    to: '/events',
                })
            }
            if (this.$config.features.flowDesignerEnabled) {
                items.push({
                    icon: '$cvflows',
                    title: 'Flows',
                    to: '/flows',
                })
            }
            if (this.$config.features.netZeroPage) {
                items.push({
                    icon: '$cvnetzero',
                    title: 'Net zero',
                    to: '/net-zero',
                })
            }
            if (this.$config.features.entitiesPage) {
                items.push({
                    icon: '$gears',
                    title: 'Entities',
                    to: '/entities',
                })
            }
            return items
        },
        canDownload() {
            const customer = this.$store.getters['entities/customers/all']()[0]
            return (
                customer &&
                customer.settings &&
                customer.settings.downloadsEnabled &&
                customer.settings.downloadsEnabled.length
            )
        },
        activeRoute() {
            return this.$route.matched[0]?.path
        },
    },

    methods: {
        async downloadJSON() {
            this.downloadButtonDisabled = true
            const result = await this.$store.dispatch('electricity/downloadJSON')
            if (result !== true) {
                this.$toast.show(result)
            }
            this.downloadButtonDisabled = false
        },
    },
}
