import useCookie from 'cookie-universal'
import icons from '~/config/vuetify-icons'
import { generateThemes } from '~/config/colors'

export default function(ctx) {
    const cookie = useCookie(ctx.req, ctx.res)
    const theme = cookie.get('theme') ?? 'dark'
    return {
        icons,
        theme: {
            dark: theme !== 'light',
            options: {
                customProperties: true,
                // variations: false
            },
            themes: generateThemes('blue'),
        },
    }
}
