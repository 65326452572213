import Vue from 'vue'
import sortable from 'sortablejs'

Vue.directive('test', {
    bind: (el, binding) => {
        if (process.env.NODE_ENV !== 'production') {
            Object.keys(binding.value).forEach(value => {
                el.setAttribute(`data-test-${value}`, binding.value[value])
            })
        }
    },
})

Vue.directive('sortable-data-table', {
    bind(el, binding, vNode) {
        const sortableElement = el
        const options = {
            animation: 150,
            onStart: function(event) {
                vNode.data.on.start(event)
            },
            onEnd: function(event) {
                vNode.data.on.sorted(event)
            },
        }
        sortable.create(sortableElement, options)
    },
})
