
export default {
    props: {
        value: {
            type: [Number, String],
            default: null,
        },
        items: {
            type: Array,
            required: true,
        },
        disabled: Boolean,
        selectProps: {
            type: Object,
            default: () => ({
                solo: true,
            }),
        },
        width: {
            type: String,
            default: '200px',
        },
        height: {
            type: String,
            default: '28px',
        },
        error: Boolean,
    },

    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
