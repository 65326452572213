
import UserMenu from './user-menu'
import Logo from '@/components/logo'

export default {
    components: {
        Logo,
        UserMenu,
    },
    computed: {
        customerName() {
            return this.$store.getters['entities/customers/query']().first()?.getName()
        },
        isNotImpersonatePage() {
            return !this.$route.path.includes('impersonate')
        },
    },
}
