import moment from '~/composables/useMoment'
import { TRACKING_EVENTS, TRACKING_ROUTE_PAIRS, TRACKING_STORE_PAIRS, TRACKING_PAGES } from '~/utils/tracking'
export default class TrackingService {
    _SYNC_INTERVAL
    _MAX_BUFFER_SIZE = 50
    _buffer = []
    _isSyncing = false
    _syncInterval

    // Page view tracking
    _pageLoading
    _path
    _PAGE_VIEW_TIMEOUT = 30000

    constructor({ $axios, $config, auth, $store }) {
        this.$axios = $axios
        this.$config = $config
        this.$auth = auth
        this.$store = $store
        this.$store.subscribe(mutation => this._trackStoreEvent(mutation))
        this._SYNC_INTERVAL = this.$config.trackingSyncInterval || 30000
        this._createSyncBufferInterval()
        if (typeof window !== 'undefined') {
            window.addEventListener('beforeunload', () => {
                this._syncBuffer()
            })
        }
    }

    async logout() {
        try {
            await this._syncBuffer()
            this._buffer = []
        } catch (e) {
            console.warn('An issue occurred during logout sync', e)
        }
    }

    logPageChange(path) {
        if (!path) {
            return
        }
        const cleanPath = path === '/' ? 'dashboard' : path.replace(/^\//, '')
        this.addToBuffer({ type: TRACKING_EVENTS.ROUTE_TO, metadata: { destination: cleanPath } })
        this._initPageLoading(cleanPath)
        // Update after event is logged so that event.path will be the source path
        this._path = cleanPath
    }

    _initPageLoading(path) {
        if (!path || !TRACKING_PAGES[path.toUpperCase()]) {
            return
        }
        if (this._pageLoading) {
            // If the previous page has not finished loading, clear the timeout
            clearTimeout(this._pageLoading.timeoutEvent)
        }
        this._pageLoading = {
            path,
            startTime: performance.now(),
            timeoutEvent: setTimeout(() => {
                this.logPageLoadingTime(path, { duration: this._PAGE_VIEW_TIMEOUT })
            }, this._PAGE_VIEW_TIMEOUT),
        }
    }

    logPageLoadingTime(path, metadata) {
        if (!this._pageLoading) {
            return
        }
        if (this._pageLoading.path !== path) {
            console.warn('Attempted to log page loading time for a different page', path)
            return
        }
        const duration = Math.floor(performance.now() - this._pageLoading.startTime)
        this.addToBuffer({
            path,
            type: TRACKING_EVENTS.PAGE_LOADED,
            metadata: { duration, ...metadata },
        })
        clearTimeout(this._pageLoading.timeoutEvent)
        this._pageLoading = null
    }

    clearPageLoading() {
        clearTimeout(this._pageLoading?.timeoutEvent)
        this._pageLoading = null
    }

    trackAPI(method, url) {
        const type = TRACKING_ROUTE_PAIRS.get(`${method}:${url}`)
        if (!type) {
            return
        }
        const event = { type }
        if (type === TRACKING_EVENTS.LOGIN) {
            // force page view to be 'login' in this case
            this.logPageChange('login')
        } else if (type === TRACKING_EVENTS.DOWNLOAD) {
            const reportType = url.replace('reports/', '')
            event.metadata = { report_type: reportType }
        }
        this.addToBuffer(event)
    }

    addToBuffer(item) {
        if (!item?.type) {
            console.warn('attempted to log event without a type', item)
            return
        }
        const event = {
            path: this._path || 'unknown',
            created_at: moment().toISOString(),
            ...item,
        }
        this._buffer.push(event)
        if (this._buffer.length >= this._MAX_BUFFER_SIZE) {
            this._syncBuffer()
        }
    }

    async _syncBuffer() {
        try {
            if (this._shouldBlockSync()) {
                return
            }
            this._isSyncing = true
            const bufferLength = this._buffer.length
            if (bufferLength === 0) {
                // User is active but has not logged any events
                this.addToBuffer({ type: TRACKING_EVENTS.PING })
            }
            await this.$axios.$post('/monitoring-api/events', { events: this._buffer })
            // in case new events were logged during request
            this._buffer = this._buffer.slice(bufferLength)
        } catch (e) {
            console.info('Error while syncing buffer', e)
        } finally {
            this._isSyncing = false
        }
    }

    _shouldBlockSync() {
        const onLoginPage = this.$store.$router.currentRoute.path === '/auth/login'
        const customerId = this.$auth?.jsonToken()?.customerId
        const isLoggedOut = !customerId && customerId !== 0
        const isImpersonating = this.$auth.impersonator()
        const isProdOrDemo = this.$config.envName === 'production' || this.$config.envName === 'preprod' || this.$config.envName === 'demo'
        const isImpersonatingOnProd = isImpersonating && isProdOrDemo
        // When a user is inactive and there are no events to log we should not ping
        const shouldNotPing = !this._buffer.length && document?.visibilityState === 'hidden'
        return !this.$config.features.usersMonitoringEnabled ||
            onLoginPage ||
            isLoggedOut ||
            isImpersonatingOnProd ||
            !document ||
            this._isSyncing ||
            shouldNotPing
    }

    _createSyncBufferInterval() {
        if (this._syncInterval) {
            return
        }
        const boundSyncBuffer = this._syncBuffer.bind(this)
        this._syncInterval = setInterval(boundSyncBuffer, this._SYNC_INTERVAL)
    }

    _trackStoreEvent(mutation) {
        const type = TRACKING_STORE_PAIRS.get(mutation.type)
        if (!type) {
            return
        }
        const event = { type }
        switch (type) {
            case TRACKING_EVENTS.PARAMETERS_SELECTION: {
                if (!mutation.payload) {
                    return
                }

                const parameterIds = typeof mutation.payload === 'string' ? [mutation.payload] : mutation.payload
                const mapParameterIds = (mutation.type === 'dashboard/SET_UNIT_ID')
                    ? parameterIds.map(id => {
                        return this.$store.getters['dashboard/units'].find(({ id: unitId }) => unitId === id)?.param
                    })
                    : parameterIds

                event.metadata = { parameter_ids: mapParameterIds }
                break
            }
            case TRACKING_EVENTS.TOGGLE_ABSOLUTE_COMPARISON:
                event.metadata = { active: mutation.payload }
                break
            case TRACKING_EVENTS.RAG:
                if (mutation.payload === false) {
                    return
                }
                break
            case TRACKING_EVENTS.COMPARISON:
                if (mutation.payload === null) {
                    return
                }
                break
            case TRACKING_EVENTS.TRIADS:
                if (mutation.payload !== 'triad') {
                    return
                }
                break
            case TRACKING_EVENTS.INSPECTOR:
                if (mutation.payload.unit !== 'kw') {
                    return
                }
                break
            default:
                console.warn('Uncaught tracking event type', type)
                break
        }
        this.addToBuffer(event)
    }
}
