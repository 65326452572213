
import { mapGetters } from 'vuex'
import ItemCard from './ItemCard'
import MaterialSummaryCard from '~/components/flows/cards/MaterialSummaryCard'

export default {
    name: 'ProcessInventory',

    components: {
        ItemCard,
        MaterialSummaryCard,
    },

    props: {
        active: Boolean,
        process: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters('flows', ['allMaterials']),
        inputMaterials() {
            return this.process.items.find(s => s?.id === 'input')?.draft || []
        },
        inputMaterialIds() {
            return this.inputMaterials.map(m => m?.id)
        },
        outputMaterials() {
            return this.process.items.find(s => s?.id === 'output')?.draft || []
        },
        outputMaterialIds() {
            return this.outputMaterials.map(m => m?.id)
        },
        sortedMaterials() {
            return [...this.allMaterials]
                .filter(m => this.inputMaterialIds.includes(m.id) || this.outputMaterialIds.includes(m.id))
                .sort((a, b) => {
                    if (this.inputMaterialIds.includes(a.id) || this.outputMaterialIds.includes(a.id)) {
                        return -1
                    }
                    return 0
                })
        },
    },
}
