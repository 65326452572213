import { Model } from '@vuex-orm/core'
import { uniq, groupBy, cloneDeep } from 'lodash'
import Circuit from './Circuit'
import Customer from './Customer'
import GasMeter from './GasMeter'
import Meter from './Meter'
import Pipe from './Pipe'
import Site from './Site'

export default class Preset extends Model {
    static entity = 'presets'
    static ACCESS_TYPES = {
        PUBLIC: 'public',
        READ_ONLY: 'public-readonly',
        PRIVATE: 'private',
    }

    static apiConfig = {
        dataKey: 'data',
        url: 'virtuals',
        params: {
            commodity: ['gas', 'power'],
        },
        actions: {
            async hide(e) {
                return await this.put(`${Preset.apiConfig.url}/${e.id}`, { commodity: this.commodity, isHidden: true }, { params: {}, dataKey: undefined })
            },
            async unhide(e) {
                return await this.put(`${Preset.apiConfig.url}/${e.id}`, { commodity: this.commodity, isHidden: false }, { params: {}, dataKey: undefined })
            },
        },
    }

    /**
     * Fields method
     *
     * @returns {Object}
     */
    static fields() {
        return {
            id: this.uid(),
            customerId: this.attr(null),
            scope: this.attr(''),
            name: this.attr(''),
            icon: this.attr('$layerGroup'),
            siteIds: this.attr([]),
            meterIds: this.attr([]),
            circuitIds: this.attr([]),
            color: this.attr(null),
            createdAt: this.attr(null),
            updatedAt: this.attr(null),
            // circuits: this.hasManyBy(Circuit, 'circuitIds'),
            customer: this.belongsTo(Customer, 'customerId'),
            // sites: this.hasManyBy(Site, 'siteIds', 'siteId'),
            // meters: this.hasManyBy(Meter, 'meterIds'),
            // pipes: this.hasManyBy(Pipe, 'circuitIds'),
            editable: this.boolean(true),
            access: this.attr(this.ACCESS_TYPES.READ_ONLY),
            isEditable: this.boolean(true),
            commodity: this.attr(null),
            type: this.attr(null),
            isHidden: this.attr(null),
            sampleRate: this.attr(null),
            manufacturers: this.attr(null),
        }
    }

    // get sampleRate() {
    //     const realChildren = this.children.filter(({ virtual }) => !virtual)
    //     if (!realChildren.length) {
    //         return VALID_RESOLUTIONS._15_MINUTES
    //     }
    //     return Math.max(...realChildren.map(({ sampleRate }) => sampleRate))
    // }

    // fallback to old primary key
    get presetId() {
        return this.id
    }

    /**
     * Before create method
     *
     * @param {Preset} preset
     */
    static beforeCreate(preset) {
        let scope = preset.scope.toLowerCase()
        if (scope === 'elec') scope = 'electricity'
        preset.scope = scope
    }

    /**
     * Before update method
     *
     * @param {Preset} preset
     */
    static beforeUpdate(preset) {
        let scope = preset.scope.toLowerCase()
        if (scope === 'elec') scope = 'electricity'
        preset.scope = scope
    }

    /**
     * After create method
     *
     * @param {Preset} preset
     */
    static afterCreate({ id, scope, sampleRate, color, manufacturers }) {
        switch (scope) {
            case 'gas':
                // create the Virtual Main Incomer pipe
                Pipe.insert({
                    data: {
                        presetId: id,
                        virtual: true,
                        pipeName: 'Virtual main incomer',
                        friendlyName: 'Virtual main incomer',
                        id: Pipe.virtualId(id),
                        color,
                    },
                })
                break
            default:
                // create the Virtual Main Incomer circuit
                Circuit.insert({
                    data: {
                        presetId: id,
                        sampleRate,
                        virtual: true,
                        circuitName: 'Virtual main incomer',
                        friendlyName: 'Virtual main incomer',
                        id: Circuit.virtualId(id),
                        color,
                        manufacturer: manufacturers,
                    },
                })
        }
    }

    /**
     * After create method
     *
     * @param {Preset} preset
     */
    static afterUpdate({ id, scope, sampleRate, color }) {
        switch (scope) {
            case 'gas':
                Pipe.update({
                    data: {
                        id: Pipe.virtualId(id),
                        color,
                    },
                })
                break
            default:
                Circuit.update({
                    data: {
                        id: Circuit.virtualId(id),
                        color,
                    },
                })
        }
    }

    /**
     * After delete method
     *
     * @param {Preset} preset
     */
    static afterDelete({ id }) {
        // delete the VMI circuit
        Circuit.delete(c => c.presetId === id)
    }

    static for(scope) {
        return this.query()
            .where('scope', scope)
            .get()
    }

    get entity() {
        return Preset.entity
    }

    get isMixed() {
        const manufacturers = this.circuitsChildren.filter(({ virtual, manufacturer }) => !virtual && manufacturer !== null).map(({ manufacturer }) => manufacturer)
        return uniq(manufacturers).length > 1
    }

    /**
     * Children getter
     *
     * @returns {Array}
     */
    get children() {
        // check if effects home entities
        const items = []
        const siteChildren = []
        const { siteIds, meterIds, circuitIds, id } = this

        const circuits = cloneDeep(this.clampClass.query().whereIdIn(circuitIds).get())
        circuits.forEach(c => c.__defineGetter__('children', function() {
            return []
        }))

        const groupedByMeter = groupBy(circuits, 'meterId')
        meterIds.forEach(meterId => {
            if (!groupedByMeter[meterId]) {
                groupedByMeter[meterId] = []
            }
        })

        for (let meterId in groupedByMeter) {
            meterId = Number(meterId)
            if (meterIds.includes(meterId)) {
                const meter = cloneDeep(this.meterClass.query().whereId(meterId).first())
                if (meter) {
                    meter.__defineGetter__('children', function() {
                        return groupedByMeter[meterId]
                    })
                    siteChildren.push(meter)
                }
            } else {
                siteChildren.push(...groupedByMeter[meterId])
            }
        }

        const groupedBySite = groupBy(siteChildren, 'siteId')
        siteIds.forEach(siteId => {
            if (!groupedBySite[siteId]) {
                groupedBySite[siteId] = []
            }
        })

        for (let siteId in groupedBySite) {
            siteId = Number(siteId)
            if (siteIds.includes(siteId)) {
                const site = cloneDeep(Site.query().whereId(siteId).first())
                if (site) {
                    site.__defineGetter__('children', function() {
                        return groupedBySite[siteId]
                    })
                    items.push(site)
                }
            } else {
                items.push(...groupedBySite[siteId])
            }
        }
        const virtualMainIncomer = this.clampClass.query().where(c => {
            return c.presetId === id
        }).first()
        if (virtualMainIncomer) items.push(virtualMainIncomer)
        return items
    }

    /**
     * Circuits getter
     *
     * @returns {Array}
     */
    get circuitsChildren() {
        const { circuitIds, meterIds, id, siteIds } = this
        const presetMeterIds = [...meterIds]
        siteIds.forEach(siteId => {
            const siteMeters = this.meterClass.query().where('siteId', siteId).get()
            const siteMetersIds = siteMeters.map(({ meterId }) => meterId)
            presetMeterIds.push(...siteMetersIds)
        })

        return this.clampClass.query().where(c => {
            return presetMeterIds.includes(c.meterId) ||
                circuitIds.includes(c.id) ||
                c.presetId === id
        }).orderBy('totalKwData', 'desc').get()
    }

    get relationships() {
        // circuits
        const circuits = this.circuitsChildren.filter(c => !c.virtual)

        // meters
        const meterIds = uniq(circuits.map(({ meterId }) => meterId))
        const meters = this.meterClass.query().whereIdIn(meterIds).get()

        return {
            circuits,
            meters,
        }
    }

    get meterClass() {
        return this.scope === 'gas' ? GasMeter : Meter
    }

    get clampClass() {
        return this.scope === 'gas' ? Pipe : Circuit
    }

    get isNHH() {
        return this.manufacturers.includes(10)
    }

    /**
     * Get Preset Name
     *
     * @returns {String}
     */
    getName() {
        return this.name
    }

    supportsUnit(unit) {
        return unit.manufacturers.some(m => this.manufacturers.includes(m))
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object}
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
            name: this.getName(),
        }
    }
}
