
export default {
    props: {
        columnColors: {
            type: Array,
            required: true,
        },
        expectedColumns: {
            type: Array,
            required: true,
        },
        mapping: {
            type: Array,
            required: true,
        },
        units: {
            type: Object,
            required: true,
        },
        source: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {

        }
    },

    computed: {

    },

    methods: {
        onUnitChange(column, unit) {
            console.log(column, unit)
            this.$emit('on-unit-assign', { ...column, unit })
        },
        filteredUnits(e) {
            return e.units.filter(({ restrictions }) => {
                if (!restrictions) return true
                const sourceAttributeValue = this.source.attributes[restrictions.key]
                return restrictions.options.includes(sourceAttributeValue)
            })
        },
    },
}
