import { transformTargets } from '~/utils/api'

export default class NetZeroService {
    constructor({ $axios, $config }) {
        this.$axios = $axios
        this.$config = $config
    }

    /**
     * Fetch CO2 emissions
     *
     * @param  {Object} params
     * @return {Promise}
     */
    async fetchEmissions(params) {
        // /net-zero-api/emissions/series?resolution=month&emissions[]=co2&splitBy[]=nodeId&splitBy[]=timestamp&nodeIds[]=c-mobile-combustion
        const uri = '/net-zero-api/emissions/series'
        const { data } = await this.$axios.$get(uri, { params })
        return data || []
    }

    /**
     * Fetch intensity rules
     *
     * @return {Array}
     */
    async fetchIntensityRules() {
        const uri = '/net-zero-api/intensity/rules/standard'
        return await this.$axios.$get(uri)
    }

    async deleteRawPoint(assetId, pointId) {
        const uri = `/net-zero-api/assets/asset/${assetId}/data/${pointId}`
        return await this.$axios.$delete(uri)
    }

    async editRawPoints(assetId, payload) {
        const uri = `/net-zero-api/assets/asset/${assetId}/data`
        return await this.$axios.$put(uri, payload)
    }

    async loadSummaries(params) {
        const uri = '/net-zero-api/emissions/totals'
        return await this.$axios.$get(uri, {
            params: {
                emissions: ['co2e'],
                splitBy: ['nodeId'],
                nodeIds: [
                    'c-scope-1',
                    'c-scope-2',
                    ...(this.$config.features.carbonShowScope3 ? ['c-scope-3'] : []),
                ],
                ...params,
            },
        })
    }

    async loadCounters() {
        const uri = '/net-zero-api/data/counts?splitBy[]=assetId'
        return await this.$axios.$get(uri)
    }

    async loadCountries() {
        return await this.$axios.$get('/net-zero-api/countries')
    }

    async getUserIntensityRules(params) {
        const uri = '/net-zero-api/intensity/rules'
        return await this.$axios.$get(uri, {
            params,
        })
    }

    async getIntensityReadings() {
        const uri = '/net-zero-api/intensity/rules/readings'
        return await this.$axios.$get(uri)
    }

    async addIntensityRule(payload) {
        const uri = '/net-zero-api/intensity/rules/rule'
        return await this.$axios.$post(uri, payload)
    }

    async saveIntensityRules(payload) {
        const uri = '/net-zero-api/intensity/rules'
        return await this.$axios.post(uri, payload)
    }

    async deleteIntensityRule(ruleId) {
        const uri = `/net-zero-api/intensity/rules/rule/${ruleId}`
        return await this.$axios.$delete(uri)
    }

    async deleteIntensityRuleBySite(ruleId, params) {
        const uri = `/net-zero-api/intensity/rules/rule/${ruleId}/readings`
        return await this.$axios.$delete(uri, { params })
    }

    async saveIntensityReadings(ruleId, payload) {
        const uri = `/net-zero-api/intensity/rules/rule/${ruleId}/readings`
        return await this.$axios.$put(uri, payload)
    }

    async getIntensityValues(params) {
        const uri = '/net-zero-api/intensity/calculate'
        return await this.$axios.$get(uri, {
            params,
        })
    }

    async getNormalisedTotals(payload) {
        const uri = '/net-zero-api/data/normalised/totals'
        return await this.$axios.$get(uri, {
            params: payload,
        })
    }

    async getEmissionsTotals(payload) {
        const uri = '/net-zero-api/emissions/totals'
        return await this.$axios.$get(uri, {
            params: payload,
        })
    }

    async getEmissionsDiagram(params) {
        params.renderer = 'plotly'
        const uri = '/net-zero-api/emissions/visualisations/sankey'
        return await this.$axios.$get(uri, { params })
    }

    /**
     * Fetch NetZero Targets
     *
     * @param  {Object} params
     * @return {Array} targets' data
     */
    async fetchTargets(params, sources) {
        const uri = '/net-zero-api/targets'
        const {
            data: targets,
            meta: { total: totalTargets },
        } = await this.$axios.$get(uri, { params })
        transformTargets(targets, sources)
        return { targets, totalTargets }
    }

    /**
     * Create NetZero Target
     *
     * @param  {Object} target
     * @return {Object} target
     */
    async addTarget(target) {
        const uri = '/net-zero-api/targets'
        return await this.$axios.$post(uri, target)
    }

    /**
     * Update NetZero Target
     *
     * @param  {Object} target
     * @return {Object} target
     */
    async updateTarget(target) {
        const uri = `/net-zero-api/targets/target/${target.id}`
        return await this.$axios.$put(uri, target)
    }

    /**
     * Delete NetZero Target
     *
     * @param  {Object} target
     * @return void
     */
    async deleteTarget(target) {
        const uri = `/net-zero-api/targets/target/${target.id}`
        return await this.$axios.$delete(uri)
    }

    /**
     * Create or update NetZero Target
     *
     * @param  {Object} target
     * @return {Object} target
     */
    async addOrUpdateTarget(target) {
        if (target.id) {
            return await this.updateTarget(target)
        } else {
            return await this.addTarget(target)
        }
    }

    /**
     * Fetch asset categories
     * - used by the autocomplete components
     *
     * @param  {Object} params
     * @return {Promise} response
     */
    fetchCategories(params = {}) {
        const uri = '/net-zero-api/assets/templates/flatstrings'
        return this.$axios.$get(uri, { params })
    }
}
