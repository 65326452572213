import { Model } from '@vuex-orm/core'

export default class Report extends Model {
    static entity = 'reports'

    static primaryKey = 'reportId'

    static apiConfig = {}

    static fields() {
        return {
            // id: this.attr(null),
            reportId: this.uid(),
            customerId: this.attr(null),
            reportName: this.attr(null),
            dateRange: this.attr(null),
            dateRangeStart: this.attr(null),
            dateRangeEnd: this.attr(null),
            resolution: this.attr(null),
            units: this.attr(null),
            // expiry: this.attr(null),
            extensions: this.attr(null),
            virtual: this.boolean(false),
            status: this.attr(null),
            asap: this.attr(null),
            dateScheduleStart: this.attr(null),
            timeScheduleStart: this.attr(null),
            timeScheduleRepeat: this.attr(null),
            // repeat: this.attr(null),
            frequency: this.attr(null),
            weekdays: this.attr(null),
            recipients: this.attr(null),
            meters: this.attr([]),
            createdAt: this.attr(null),
            updatedAt: this.attr(null),
        }
    }
}
