
import { cloneDeep } from 'lodash'
import EditableGrid from './mixins/editable-grid'
export default {
    mixins: [EditableGrid],
    inject: ['sourceForm'],
    props: {
        value: {
            type: Array,
            required: true,
        },
        csvHasHeaders: {
            type: Boolean,
            required: true,
        },
        manualMode: {
            type: Boolean,
            required: true,
        },
        columnColors: {
            type: Array,
            required: true,
        },
        expectedColumns: {
            type: Array,
            required: true,
        },
        mapping: {
            type: Array,
            required: true,
        },
        columnsValidation: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            transposedData: [],
            userHeaders: [],
            errors: {},
            editedCells: {},
            cellBeingEdited: null,
            tableOpacity: 0,
            columnWidths: [],
        }
    },
    computed: {
        columnStyles() {
            return this.mapping.map((e, k) => {
                const columnValidationStatus = this.isColumnValid(k)
                let headerIcon
                let headerIconColor
                let message
                if (columnValidationStatus === true) {
                    headerIcon = '$check'
                    headerIconColor = 'success'
                    message = this.$t('pages.carbon.input_grid_column_ok')
                } else if (columnValidationStatus === false) {
                    headerIcon = '$triangleExclamation'
                    headerIconColor = 'warning'
                    message = this.$t('pages.carbon.input_grid_column_not_ok')
                } else {
                    headerIcon = false
                }
                return {
                    headerIcon,
                    headerIconColor,
                    message,
                }
            })
        },
    },
    watch: {
        mapping: {
            deep: true,
            handler() {
                this.getTransposedData()
            },
        },
        value: {
            deep: true,
            handler() {
                this.getTransposedData()
            },
        },
    },
    mounted() {
        this.getTransposedData()
    },
    methods: {
        getTransposedData() {
            this.tableOpacity = 0
            const t = cloneDeep(this.value)

            if (this.csvHasHeaders) {
                this.userHeaders = t.map(e => e.shift())
            }
            this.transposedData = t
            this.$nextTick(() => {
                this.fixColumnsWidth()
            })
        },
        fixColumnsWidth() {
            const headers = Array.from(this.$el.querySelectorAll('.carbon-input-grid__header'))
            const columns = Array.from(this.$el.querySelectorAll('.carbon-input-grid__column'))
            const widths = new Array(headers.length).fill(140)

            headers.forEach((e, k) => {
                widths[k] = Math.max(widths[k], e.offsetWidth)
            })
            columns.forEach((e, k) => {
                widths[k] = Math.max(widths[k], e.offsetWidth)
            })
            this.columnWidths = widths
            this.$nextTick(() => {
                this.tableOpacity = 1
            })
        },
        onKeyDown(e) {
            if (e.keyCode === 9) {
                e.preventDefault()
            }
            let nextRow
            let nextCol
            switch (e.keyCode) {
                case 13: // enter
                    nextRow = Number(e.target.dataset.row) + 1
                    nextCol = Number(e.target.dataset.column)
                    break
                case 38: // up
                    nextRow = Number(e.target.dataset.row) - 1
                    nextCol = Number(e.target.dataset.column)
                    if (nextRow < 0) {
                        nextRow = 0
                    }
                    break
                case 9: // tab
                case 39: // right
                    nextRow = Number(e.target.dataset.row)
                    if (Number(e.target.dataset.column) === this.transposedData.length - 1) {
                        nextCol = Number(e.target.dataset.column) - 1
                        nextRow = Number(e.target.dataset.row) + 1
                    } else {
                        nextCol = Number(e.target.dataset.column) + 1
                    }
                    break
                case 40: // down
                    nextRow = Number(e.target.dataset.row) + 1
                    nextCol = Number(e.target.dataset.column)
                    if (nextRow > this.transposedData[0].length - 1) {
                        nextRow = this.transposedData[0].length - 1
                    }
                    break
                case 37: // left
                    nextRow = Number(e.target.dataset.row)
                    nextCol = Number(e.target.dataset.column) - 1
                    if (nextCol < 0) {
                        nextCol = 0
                    }
                    break
            }
            if (!isNaN(nextRow) && !isNaN(nextCol)) {
                e.target.blur()
                setTimeout(() => {
                    if (nextRow >= this.transposedData[0].length || nextRow < 0) {
                        return
                    }
                    this.makeCellEditable(nextCol, nextRow)
                }, 10)
            }
        },
        onParameterSelect(columnIndex, columnMeta) {
            this.$emit('update:mapping', { columnIndex, value: columnMeta })
            if (columnMeta.type === 'enum') {
                const cellsToUpdate = this.transposedData[columnIndex].map((value, rowIndex) => {
                    return {
                        rowIndex,
                        value: this.enumValue(columnMeta.options, value),
                    }
                })
                this.$emit('update:cells', { columnIndex, cellsToUpdate })
            }
        },
        getBackgroundColor(columnIndex) {
            const header = this.mapping[columnIndex]
            const parameterIndex = header ? this.expectedColumns.findIndex(e => e.id === header.id) : -1
            return parameterIndex > -1
                ? this.columnColors[parameterIndex]
                : this.$vuetify.theme.themes[this.$vuetify.theme.isDark
                    ? 'dark'
                    : 'light'
                ]['dialog-content-background']
        },
        isColumnValid(columnIndex) {
            if (!this.columnsValidation[columnIndex]) {
                return null
            } else if (this.columnsValidation[columnIndex].filter(e => e === false).length) {
                return false
            }
            return true
        },
        onUnitSelect(rowIndex, columnIndex, item) {
            this.$emit('update:cell', {
                row: rowIndex,
                col: columnIndex,
                value: item.key,
            })
        },
        enumValue(options, cell) {
            const found = options.find(({ key, name }) => {
                key = key.toLowerCase()
                name = name.toLowerCase()
                cell = cell.toLowerCase()
                if (key === cell || name === cell) {
                    return true
                }
                return false
            })
            if (found) {
                return found.key
            }
            return options[0].key
        },
    },
}
