import _ from 'lodash'
import moment from 'moment'
import { set } from 'vue'
import Site from '@/orm/models/Site'

const startTime = moment().subtract(15, 'minutes').valueOf()
const endTime = moment().valueOf()

export const state = () => {
    return {
        sites: [],
        dateInterval: {
            from: startTime,
            to: endTime,
            resolution: 1,
        },
        visibleRange: {
            from: moment().subtract(5, 'minute').valueOf(),
            to: moment().valueOf(),
        },
        analysisFeatures: {
            maxDemands: {
                active: false,
                name: 'Max Demands',
                description: '',
                type: 'annotation',
            },
            // peakDemand:   {
            //     active:      false,
            //     name:        'Peak Demand',
            //     description: '',
            //     type: 'annotation'
            // },
            loadFactor: {
                active: false,
                name: 'Load factor',
                description: '',
                type: 'flag',
            },
            powerFactor: {
                active: false,
                name: 'Power Factor',
                description: '',
                type: ['dataset', 'filter'],
            },
            averageLines: {
                active: false,
                name: 'Average lines',
                description: '',
                // type: ['filter', 'annotation']
            },
            peakTimes: {
                active: false,
                name: 'Peak times',
                description: '',
                type: 'annotation',
            },
            triads: {
                active: false,
                name: 'Triads',
                description: '',
                type: 'annotation',
            },
        },
    }
}

export const mutations = {
    SET_SITES(state, sites) {
        state.sites = sites
    },
    SET_DATE_INTERVAL(state, dateInterval) {
        state.dateInterval = dateInterval
    },
    TOGGLE_CIRCUIT_SELECTED(state, { siteId, meterId, circuitId, selected }) {
        const siteIndex = _.findIndex(state.sites, { siteId })
        const meterIndex = _.findIndex(state.sites[siteIndex].meters, { meterId })
        const circuitIndex = _.findIndex(state.sites[siteIndex].meters[meterIndex].circuits, { circuitId })
        set(state.sites[siteIndex].meters[meterIndex].circuits[circuitIndex], 'selected', selected)
    },
    TOGGLE_ANALYSIS_FEATURE_ACTIVE(state, { featureName, active }) {
        set(state.analysisFeatures[featureName], 'active', active)
    },
    SET_VISIBLE_RANGE(state, visibleRange) {
        state.visibleRange = visibleRange
    },
}

export const getters = {
    selectedCircuits: (state, getters) => {
        return _.filter(getters.circuits, c => c.selected)
    },
    circuits: state => {
        const circuits = []
        _.forEach(state.sites, site => {
            _.forEach(site.meters, meter => {
                _.forEach(meter.circuits, circuit => {
                    circuits.push(circuit)
                })
            })
        })
        return circuits
    },
}

export const actions = {
    init({ dispatch, commit }) {
        const interval = setInterval(() => {
            if (this.state['entities-manager'].initialised) {
                clearInterval(interval)
                const sites = Site.query().withAllRecursive().get()
                // console.log(sites)
                dispatch('buildSitesArray', sites).then(sitesArray => {
                    commit('SET_SITES', sitesArray)
                })
            }
        }, 100)

        // commit('INIT_CIRCUITS', Circuit.query().where('meterId', 1).where(circuit => circuit.circuitId !== 9).get())
    },
    buildSitesArray(ctx, sites) {
        return _.map(sites, site => {
            return {
                ...site,
                selected: site.siteId === 2,
                meters: _.map(site.children, meter => {
                    return {
                        ...meter,
                        children: _.filter(_.map(meter.children, (circuit, k) => {
                            const _isMainIncomer = false// isMainIncomer(circuit)
                            // const selected = meter.meterId === 1 && circuit.circuitId < 9 // @TODO temporary
                            if (meter.meterId === 1 && circuit.circuitId >= 9) {
                                return
                            }
                            return {
                                ..._.pick(circuit, ['circuitId', 'meterId', 'circuitName', 'friendlyName']),
                                selected: _isMainIncomer,
                                borderColor: 'white',
                                backgroundColor: 'black',
                                alternativeColor: 'white',
                                alternativeBackgroundColor: 'white',
                                siteName: site.siteName,
                                highlighted: _isMainIncomer,
                                isMainIncomer: _isMainIncomer,
                            }
                        })),
                    }
                }),
            }
        })
    },
    setDateInterval({ commit }, dateInterval) {
        commit('SET_DATE_INTERVAL', dateInterval)
    },
    toggleCircuitSelected({ commit }, params) {
        commit('TOGGLE_CIRCUIT_SELECTED', params)
    },
    setVisibleRange({ commit }, visibleRange) {
        commit('SET_VISIBLE_RANGE', visibleRange)
    },
    toggleAnalysisFeatureActive({ commit }, params) {
        commit('TOGGLE_ANALYSIS_FEATURE_ACTIVE', params)
    },
}
